@charset "UTF-8";

/*!
 * Bootstrap  v5.2.0 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --bs-blue: #377dff;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #ed4c78;
    --bs-orange: #fd7e14;
    --bs-yellow: #f5ca99;
    --bs-green: #198754;
    --bs-teal: #00c9a7;
    --bs-cyan: #09a5be;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #8c98a4;
    --bs-gray-dark: #71869d;
    --bs-gray-100: #f9fafc;
    --bs-gray-200: #f8fafd;
    --bs-gray-300: #e7eaf3;
    --bs-gray-400: #bdc5d1;
    --bs-gray-500: #97a4af;
    --bs-gray-600: #8c98a4;
    --bs-gray-700: #677788;
    --bs-gray-800: #71869d;
    --bs-gray-900: #1e2022;
    --bs-primary: #377dff;
    --bs-secondary: #71869d;
    --bs-success: #00c9a7;
    --bs-info: #09a5be;
    --bs-warning: #f5ca99;
    --bs-danger: #ed4c78;
    --bs-light: #f9fafc;
    --bs-dark: #132144;
    --bs-primary-rgb: 55, 125, 255;
    --bs-secondary-rgb: 113, 134, 157;
    --bs-success-rgb: 0, 201, 167;
    --bs-info-rgb: 9, 165, 190;
    --bs-warning-rgb: 245, 202, 153;
    --bs-danger-rgb: 237, 76, 120;
    --bs-light-rgb: 249, 250, 252;
    --bs-dark-rgb: 19, 33, 68;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 103, 119, 136;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: Inter, sans-serif;
    --bs-body-font-size: 0.875rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #677788;
    --bs-body-bg: #fff;
    --bs-border-width: 0.0625rem;
    --bs-border-style: solid;
    --bs-border-color: rgba(231, 234, 243, 0.7);
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.5rem;
    --bs-border-radius-sm: 0.3125rem;
    --bs-border-radius-lg: 0.75rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #377dff;
    --bs-link-hover-color: #1366ff;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fdf4eb
}

*,
::after,
::before {
    box-sizing: border-box
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

hr {
    margin: 1.75rem 0;
    color: rgba(231, 234, 243, .7);
    border: 0;
    border-top: .0625rem solid;
    opacity: 1
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #1e2022
}

.h1,
h1 {
    font-size: calc(1.26609375rem + .193125vw)
}

@media (min-width:1200px) {

    .h1,
    h1 {
        font-size: 1.4109375rem
    }
}

.h2,
h2 {
    font-size: calc(1.25625rem + .075vw)
}

@media (min-width:1200px) {

    .h2,
    h2 {
        font-size: 1.3125rem
    }
}

.h3,
h3 {
    font-size: 1.1484375rem
}

.h4,
h4 {
    font-size: .984375rem
}

.h5,
h5 {
    font-size: .875rem
}

.h6,
h6 {
    font-size: .765625rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul {
    padding-left: 2rem
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 400
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

.small,
small {
    font-size: .875em
}

.mark,
mark {
    padding: .1875em;
    background-color: var(--bs-highlight-bg)
}

sub,
sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: var(--bs-link-color);
    text-decoration: none
}

a:hover {
    color: var(--bs-link-hover-color)
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 1em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: 1em;
    color: var(--bs-code-color);
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .1875rem .375rem;
    font-size: 1em;
    color: #1e2022;
    background-color: #e7eaf3;
    border-radius: .3125rem
}

kbd kbd {
    padding: 0;
    font-size: 1em
}

figure {
    margin: 0 0 1rem
}

img,
svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #8c98a4;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none !important
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width:1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

::file-selector-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.09375rem;
    font-weight: 400
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-2 {
        font-size: 4rem
    }
}

.display-3 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-3 {
        font-size: 3rem
    }
}

.display-4 {
    font-size: calc(1.325rem + .9vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-4 {
        font-size: 2rem
    }
}

.display-5 {
    font-size: calc(1.275rem + .3vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-5 {
        font-size: 1.5rem
    }
}

.display-6 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .25rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #8c98a4
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: .0625rem solid var(--bs-border-color);
    border-radius: .5rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: #8c98a4
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1320px
    }
}

@media (min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1480px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}

.col {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%
}

.row-cols-auto>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
    --bs-gutter-x: 2rem
}

.g-5,
.gy-5 {
    --bs-gutter-y: 2rem
}

.g-6,
.gx-6 {
    --bs-gutter-x: 2.5rem
}

.g-6,
.gy-6 {
    --bs-gutter-y: 2.5rem
}

.g-7,
.gx-7 {
    --bs-gutter-x: 3rem
}

.g-7,
.gy-7 {
    --bs-gutter-y: 3rem
}

.g-8,
.gx-8 {
    --bs-gutter-x: 3.5rem
}

.g-8,
.gy-8 {
    --bs-gutter-y: 3.5rem
}

.g-9,
.gx-9 {
    --bs-gutter-x: 4rem
}

.g-9,
.gy-9 {
    --bs-gutter-y: 4rem
}

.g-10,
.gx-10 {
    --bs-gutter-x: 4.5rem
}

.g-10,
.gy-10 {
    --bs-gutter-y: 4.5rem
}

@media (min-width:576px) {
    .col-sm {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 2rem
    }

    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 2rem
    }

    .g-sm-6,
    .gx-sm-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-sm-6,
    .gy-sm-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-sm-7,
    .gx-sm-7 {
        --bs-gutter-x: 3rem
    }

    .g-sm-7,
    .gy-sm-7 {
        --bs-gutter-y: 3rem
    }

    .g-sm-8,
    .gx-sm-8 {
        --bs-gutter-x: 3.5rem
    }

    .g-sm-8,
    .gy-sm-8 {
        --bs-gutter-y: 3.5rem
    }

    .g-sm-9,
    .gx-sm-9 {
        --bs-gutter-x: 4rem
    }

    .g-sm-9,
    .gy-sm-9 {
        --bs-gutter-y: 4rem
    }

    .g-sm-10,
    .gx-sm-10 {
        --bs-gutter-x: 4.5rem
    }

    .g-sm-10,
    .gy-sm-10 {
        --bs-gutter-y: 4.5rem
    }
}

@media (min-width:768px) {
    .col-md {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 2rem
    }

    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 2rem
    }

    .g-md-6,
    .gx-md-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-md-6,
    .gy-md-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-md-7,
    .gx-md-7 {
        --bs-gutter-x: 3rem
    }

    .g-md-7,
    .gy-md-7 {
        --bs-gutter-y: 3rem
    }

    .g-md-8,
    .gx-md-8 {
        --bs-gutter-x: 3.5rem
    }

    .g-md-8,
    .gy-md-8 {
        --bs-gutter-y: 3.5rem
    }

    .g-md-9,
    .gx-md-9 {
        --bs-gutter-x: 4rem
    }

    .g-md-9,
    .gy-md-9 {
        --bs-gutter-y: 4rem
    }

    .g-md-10,
    .gx-md-10 {
        --bs-gutter-x: 4.5rem
    }

    .g-md-10,
    .gy-md-10 {
        --bs-gutter-y: 4.5rem
    }
}

@media (min-width:992px) {
    .col-lg {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 2rem
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 2rem
    }

    .g-lg-6,
    .gx-lg-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-lg-6,
    .gy-lg-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-lg-7,
    .gx-lg-7 {
        --bs-gutter-x: 3rem
    }

    .g-lg-7,
    .gy-lg-7 {
        --bs-gutter-y: 3rem
    }

    .g-lg-8,
    .gx-lg-8 {
        --bs-gutter-x: 3.5rem
    }

    .g-lg-8,
    .gy-lg-8 {
        --bs-gutter-y: 3.5rem
    }

    .g-lg-9,
    .gx-lg-9 {
        --bs-gutter-x: 4rem
    }

    .g-lg-9,
    .gy-lg-9 {
        --bs-gutter-y: 4rem
    }

    .g-lg-10,
    .gx-lg-10 {
        --bs-gutter-x: 4.5rem
    }

    .g-lg-10,
    .gy-lg-10 {
        --bs-gutter-y: 4.5rem
    }
}

@media (min-width:1200px) {
    .col-xl {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 2rem
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 2rem
    }

    .g-xl-6,
    .gx-xl-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-xl-6,
    .gy-xl-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-xl-7,
    .gx-xl-7 {
        --bs-gutter-x: 3rem
    }

    .g-xl-7,
    .gy-xl-7 {
        --bs-gutter-y: 3rem
    }

    .g-xl-8,
    .gx-xl-8 {
        --bs-gutter-x: 3.5rem
    }

    .g-xl-8,
    .gy-xl-8 {
        --bs-gutter-y: 3.5rem
    }

    .g-xl-9,
    .gx-xl-9 {
        --bs-gutter-x: 4rem
    }

    .g-xl-9,
    .gy-xl-9 {
        --bs-gutter-y: 4rem
    }

    .g-xl-10,
    .gx-xl-10 {
        --bs-gutter-x: 4.5rem
    }

    .g-xl-10,
    .gy-xl-10 {
        --bs-gutter-y: 4.5rem
    }
}

@media (min-width:1400px) {
    .col-xxl {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 2rem
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 2rem
    }

    .g-xxl-6,
    .gx-xxl-6 {
        --bs-gutter-x: 2.5rem
    }

    .g-xxl-6,
    .gy-xxl-6 {
        --bs-gutter-y: 2.5rem
    }

    .g-xxl-7,
    .gx-xxl-7 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-7,
    .gy-xxl-7 {
        --bs-gutter-y: 3rem
    }

    .g-xxl-8,
    .gx-xxl-8 {
        --bs-gutter-x: 3.5rem
    }

    .g-xxl-8,
    .gy-xxl-8 {
        --bs-gutter-y: 3.5rem
    }

    .g-xxl-9,
    .gx-xxl-9 {
        --bs-gutter-x: 4rem
    }

    .g-xxl-9,
    .gy-xxl-9 {
        --bs-gutter-y: 4rem
    }

    .g-xxl-10,
    .gx-xxl-10 {
        --bs-gutter-x: 4.5rem
    }

    .g-xxl-10,
    .gy-xxl-10 {
        --bs-gutter-y: 4.5rem
    }
}

.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: rgba(231, 234, 243, 0.7);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: #f9fafc;
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(231, 234, 243, 0.4);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color)
}

.table>:not(caption)>*>* {
    padding: .75rem .75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: .0625rem;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table-group-divider {
    border-top: .125rem solid #e7eaf3
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .5rem .5rem
}

.table-bordered>:not(caption)>* {
    border-width: .0625rem 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 .0625rem
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-striped-columns>:not(caption)>tr>:nth-child(even) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-color: #000;
    --bs-table-bg: #d7e5ff;
    --bs-table-border-color: #c2cee6;
    --bs-table-striped-bg: #ccdaf2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c2cee6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c7d4ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-secondary {
    --bs-table-color: #000;
    --bs-table-bg: #e3e7eb;
    --bs-table-border-color: #ccd0d4;
    --bs-table-striped-bg: #d8dbdf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #ccd0d4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d2d6d9;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-success {
    --bs-table-color: #000;
    --bs-table-bg: #ccf4ed;
    --bs-table-border-color: #b8dcd5;
    --bs-table-striped-bg: #c2e8e1;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #b8dcd5;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bde2db;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-info {
    --bs-table-color: #000;
    --bs-table-bg: #ceedf2;
    --bs-table-border-color: #b9d5da;
    --bs-table-striped-bg: #c4e1e6;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #b9d5da;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfdbe0;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-warning {
    --bs-table-color: #000;
    --bs-table-bg: #fdf4eb;
    --bs-table-border-color: #e4dcd4;
    --bs-table-striped-bg: #f0e8df;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e4dcd4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #eae2d9;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-danger {
    --bs-table-color: #000;
    --bs-table-bg: #fbdbe4;
    --bs-table-border-color: #e2c5cd;
    --bs-table-striped-bg: #eed0d9;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e2c5cd;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e8cbd3;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-light {
    --bs-table-color: #000;
    --bs-table-bg: #f9fafc;
    --bs-table-border-color: #e0e1e3;
    --bs-table-striped-bg: #edeeef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e0e1e3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e6e7e9;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-dark {
    --bs-table-color: #fff;
    --bs-table-bg: #132144;
    --bs-table-border-color: #2b3757;
    --bs-table-striped-bg: #1f2c4d;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #2b3757;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #253252;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem;
    font-size: .875rem;
    color: #1e2022
}

.col-form-label {
    padding-top: .675rem;
    padding-bottom: .675rem;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
    color: #1e2022
}

.col-form-label-lg {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
    font-size: .875rem
}

.col-form-label-sm {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
    font-size: .8125rem
}

.form-text {
    margin-top: 1rem;
    font-size: .875em;
    color: #8c98a4
}

.form-control {
    display: block;
    width: 100%;
    padding: .6125rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    border: .0625rem solid rgba(231, 234, 243, .7);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .3125rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #1e2022;
    background-color: #fff;
    border-color: rgba(140, 152, 164, .25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, .25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-webkit-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control::placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control:disabled {
    background-color: #f8fafd;
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    padding: .6125rem 1rem;
    margin: -.6125rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
    color: #1e2022;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: .0625rem;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::file-selector-button {
    padding: .6125rem 1rem;
    margin: -.6125rem -1rem;
    -webkit-margin-end: 1rem;
    -moz-margin-end: 1rem;
    margin-inline-end: 1rem;
    color: #1e2022;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: .0625rem;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }

    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #f2f2f2
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .6125rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #677788;
    background-color: transparent;
    border: solid transparent;
    border-width: .0625rem 0
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + 1.125rem);
    padding: .5rem 1rem;
    font-size: .8125rem;
    border-radius: .3125rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-sm::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    -moz-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg {
    min-height: calc(1.5em + 1.625rem);
    padding: .75rem 1rem;
    font-size: .875rem;
    border-radius: .3125rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .75rem 1rem;
    margin: -.75rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg::file-selector-button {
    padding: .75rem 1rem;
    margin: -.75rem -1rem;
    -webkit-margin-end: 1rem;
    -moz-margin-end: 1rem;
    margin-inline-end: 1rem
}

textarea.form-control {
    min-height: calc(1.5em + 1.35rem)
}

textarea.form-control-sm {
    min-height: calc(1.5em + 1.125rem)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1.625rem)
}

.form-control-color {
    width: 3rem;
    height: calc(1.5em + 1.35rem);
    padding: .6125rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: .3125rem
}

.form-control-color::-webkit-color-swatch {
    border-radius: .3125rem
}

.form-control-color.form-control-sm {
    height: calc(1.5em + 1.125rem)
}

.form-control-color.form-control-lg {
    height: calc(1.5em + 1.625rem)
}

.form-select {
    display: block;
    width: 100%;
    padding: .6125rem 2.25rem .6125rem 1rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #677788;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2371869d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 13px 9px;
    border: .0625rem solid rgba(231, 234, 243, .7);
    border-radius: .3125rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: rgba(140, 152, 164, .25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, .25)
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    padding-right: 1rem;
    background-image: none
}

.form-select:disabled {
    background-color: #f8fafd
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #677788
}

.form-select-sm {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: .8125rem;
    border-radius: .3125rem
}

.form-select-lg {
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1rem;
    font-size: .875rem;
    border-radius: .3125rem
}

.form-check {
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5rem
}

.form-check-reverse {
    padding-right: 1.5rem;
    padding-left: 0;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5rem;
    margin-left: 0
}

.form-check-input {
    width: 1rem;
    height: 1rem;
    margin-top: .25rem;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(231, 234, 243, .7);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    -webkit-filter: 100%;
    filter: 100%
}

.form-check-input:focus {
    border-color: rgba(140, 152, 164, .25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, .25)
}

.form-check-input:checked {
    background-color: #377dff;
    border-color: #377dff
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #377dff;
    border-color: #377dff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: .5
}

.form-check-label {
    color: #677788
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0
}

.form-switch.form-check-reverse .form-check-input {
    margin-right: -2.5em;
    margin-left: 0
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 1rem 0 rgba(140, 152, 164, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 1rem 0 rgba(140, 152, 164, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #377dff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #c3d8ff
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7eaf3;
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #377dff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #c3d8ff
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7eaf3;
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #97a4af
}

.form-range:disabled::-moz-range-thumb {
    background-color: #97a4af
}

.form-floating {
    position: relative
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: 3.625rem;
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: .0625rem solid transparent;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out, -webkit-transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
    padding: 1rem 1rem
}

.form-floating>.form-control-plaintext::-webkit-input-placeholder,
.form-floating>.form-control::-webkit-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::-moz-placeholder,
.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:-ms-input-placeholder,
.form-floating>.form-control:-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::-ms-input-placeholder,
.form-floating>.form-control::-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::placeholder,
.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown),
.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:not(:-ms-input-placeholder),
.form-floating>.form-control:not(:-ms-input-placeholder) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:-webkit-autofill,
.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: .65;
    -webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    -webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: .0625rem 0
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-floating,
.input-group>.form-select {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .6125rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8c98a4;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: .0625rem solid rgba(231, 234, 243, .7);
    border-radius: .3125rem
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    padding: .75rem 1rem;
    font-size: .875rem;
    border-radius: .3125rem
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
    padding: .5rem 1rem;
    font-size: .8125rem;
    border-radius: .3125rem
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 3.25rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select,
.input-group>:not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -.0625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #00c9a7
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .8125rem;
    color: #fff;
    background-color: #00c9a7;
    border-radius: .5rem
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #00c9a7
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #00c9a7;
    box-shadow: 0 0 1rem 0 rgba(0, 201, 167, .25)
}

.form-select.is-valid,
.was-validated .form-select:valid {
    border-color: #00c9a7
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
    border-color: #00c9a7;
    box-shadow: 0 0 1rem 0 rgba(0, 201, 167, .25)
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: #00c9a7
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #00c9a7
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 1rem 0 rgba(0, 201, 167, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #00c9a7
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
    z-index: 1
}

.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #ed4c78
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .8125rem;
    color: #fff;
    background-color: #ed4c78;
    border-radius: .5rem
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #ed4c78
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #ed4c78;
    box-shadow: 0 0 1rem 0 rgba(237, 76, 120, .25)
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
    border-color: #ed4c78
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: #ed4c78;
    box-shadow: 0 0 1rem 0 rgba(237, 76, 120, .25)
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: #ed4c78
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: #ed4c78
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 1rem 0 rgba(237, 76, 120, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #ed4c78
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
    z-index: 2
}

.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
    z-index: 3
}

.btn {
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.6125rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #677788;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 0.0625rem;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.3125rem;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color)
}

.btn-check:focus+.btn,
.btn:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color)
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity)
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #377dff;
    --bs-btn-border-color: #377dff;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #2f6ad9;
    --bs-btn-hover-border-color: #2c64cc;
    --bs-btn-focus-shadow-rgb: 85, 145, 255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2c64cc;
    --bs-btn-active-border-color: #295ebf;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #377dff;
    --bs-btn-disabled-border-color: #377dff
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #71869d;
    --bs-btn-border-color: #71869d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #607285;
    --bs-btn-hover-border-color: #5a6b7e;
    --bs-btn-focus-shadow-rgb: 134, 152, 172;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #5a6b7e;
    --bs-btn-active-border-color: #556576;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #71869d;
    --bs-btn-disabled-border-color: #71869d
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #00c9a7;
    --bs-btn-border-color: #00c9a7;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #00ab8e;
    --bs-btn-hover-border-color: #00a186;
    --bs-btn-focus-shadow-rgb: 38, 209, 180;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #00a186;
    --bs-btn-active-border-color: #00977d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #00c9a7;
    --bs-btn-disabled-border-color: #00c9a7
}

.btn-info {
    --bs-btn-color: #fff;
    --bs-btn-bg: #09a5be;
    --bs-btn-border-color: #09a5be;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #088ca2;
    --bs-btn-hover-border-color: #078498;
    --bs-btn-focus-shadow-rgb: 46, 179, 200;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #078498;
    --bs-btn-active-border-color: #077c8f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #09a5be;
    --bs-btn-disabled-border-color: #09a5be
}

.btn-warning {
    --bs-btn-color: #000;
    --bs-btn-bg: #f5ca99;
    --bs-btn-border-color: #f5ca99;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #f7d2a8;
    --bs-btn-hover-border-color: #f6cfa3;
    --bs-btn-focus-shadow-rgb: 208, 172, 130;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f7d5ad;
    --bs-btn-active-border-color: #f6cfa3;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f5ca99;
    --bs-btn-disabled-border-color: #f5ca99
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ed4c78;
    --bs-btn-border-color: #ed4c78;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #c94166;
    --bs-btn-hover-border-color: #be3d60;
    --bs-btn-focus-shadow-rgb: 240, 103, 140;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #be3d60;
    --bs-btn-active-border-color: #b2395a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ed4c78;
    --bs-btn-disabled-border-color: #ed4c78
}

.btn-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #f9fafc;
    --bs-btn-border-color: #f9fafc;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d4d5d6;
    --bs-btn-hover-border-color: #c7c8ca;
    --bs-btn-focus-shadow-rgb: 212, 213, 214;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #c7c8ca;
    --bs-btn-active-border-color: #bbbcbd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f9fafc;
    --bs-btn-disabled-border-color: #f9fafc
}

.btn-dark {
    --bs-btn-color: #fff;
    --bs-btn-bg: #132144;
    --bs-btn-border-color: #132144;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #364260;
    --bs-btn-hover-border-color: #2b3757;
    --bs-btn-focus-shadow-rgb: 54, 66, 96;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #424d69;
    --bs-btn-active-border-color: #2b3757;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #132144;
    --bs-btn-disabled-border-color: #132144
}

.btn-outline-primary {
    --bs-btn-color: #377dff;
    --bs-btn-border-color: #377dff;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #377dff;
    --bs-btn-hover-border-color: #377dff;
    --bs-btn-focus-shadow-rgb: 55, 125, 255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #377dff;
    --bs-btn-active-border-color: #377dff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #377dff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #377dff;
    --bs-gradient: none
}

.btn-outline-secondary {
    --bs-btn-color: #71869d;
    --bs-btn-border-color: #71869d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #71869d;
    --bs-btn-hover-border-color: #71869d;
    --bs-btn-focus-shadow-rgb: 113, 134, 157;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #71869d;
    --bs-btn-active-border-color: #71869d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #71869d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #71869d;
    --bs-gradient: none
}

.btn-outline-success {
    --bs-btn-color: #00c9a7;
    --bs-btn-border-color: #00c9a7;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #00c9a7;
    --bs-btn-hover-border-color: #00c9a7;
    --bs-btn-focus-shadow-rgb: 0, 201, 167;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #00c9a7;
    --bs-btn-active-border-color: #00c9a7;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #00c9a7;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #00c9a7;
    --bs-gradient: none
}

.btn-outline-info {
    --bs-btn-color: #09a5be;
    --bs-btn-border-color: #09a5be;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #09a5be;
    --bs-btn-hover-border-color: #09a5be;
    --bs-btn-focus-shadow-rgb: 9, 165, 190;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #09a5be;
    --bs-btn-active-border-color: #09a5be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #09a5be;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #09a5be;
    --bs-gradient: none
}

.btn-outline-warning {
    --bs-btn-color: #f5ca99;
    --bs-btn-border-color: #f5ca99;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #f5ca99;
    --bs-btn-hover-border-color: #f5ca99;
    --bs-btn-focus-shadow-rgb: 245, 202, 153;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f5ca99;
    --bs-btn-active-border-color: #f5ca99;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f5ca99;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f5ca99;
    --bs-gradient: none
}

.btn-outline-danger {
    --bs-btn-color: #ed4c78;
    --bs-btn-border-color: #ed4c78;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ed4c78;
    --bs-btn-hover-border-color: #ed4c78;
    --bs-btn-focus-shadow-rgb: 237, 76, 120;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #ed4c78;
    --bs-btn-active-border-color: #ed4c78;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ed4c78;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ed4c78;
    --bs-gradient: none
}

.btn-outline-light {
    --bs-btn-color: #f9fafc;
    --bs-btn-border-color: #f9fafc;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #f9fafc;
    --bs-btn-hover-border-color: #f9fafc;
    --bs-btn-focus-shadow-rgb: 249, 250, 252;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f9fafc;
    --bs-btn-active-border-color: #f9fafc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f9fafc;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f9fafc;
    --bs-gradient: none
}

.btn-outline-dark {
    --bs-btn-color: #132144;
    --bs-btn-border-color: #132144;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #132144;
    --bs-btn-hover-border-color: #132144;
    --bs-btn-focus-shadow-rgb: 19, 33, 68;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #132144;
    --bs-btn-active-border-color: #132144;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #132144;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #132144;
    --bs-gradient: none
}

.btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #8c98a4;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 85, 145, 255;
    text-decoration: none
}

.btn-link:focus {
    color: var(--bs-btn-color)
}

.btn-link:hover {
    color: var(--bs-btn-hover-color)
}

.btn-group-lg>.btn,
.btn-lg {
    --bs-btn-padding-y: 0.75rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: 0.3125rem
}

.btn-group-sm>.btn,
.btn-sm {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 0.8125rem;
    --bs-btn-border-radius: 0.3125rem
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-menu {
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0.5rem;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.625rem;
    --bs-dropdown-font-size: 0.875rem;
    --bs-dropdown-color: #677788;
    --bs-dropdown-bg: #fff;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: 0.5rem;
    --bs-dropdown-border-width: 0;
    --bs-dropdown-inner-border-radius: 0.5rem;
    --bs-dropdown-divider-bg: rgba(231, 234, 243, 0.7);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
    --bs-dropdown-link-color: #1e2022;
    --bs-dropdown-link-hover-color: #1b1d1f;
    --bs-dropdown-link-hover-bg: rgba(189, 197, 209, 0.2);
    --bs-dropdown-link-active-color: #1e2022;
    --bs-dropdown-link-active-bg: rgba(189, 197, 209, 0.2);
    --bs-dropdown-link-disabled-color: #97a4af;
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.5rem;
    --bs-dropdown-header-color: #8c98a4;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius)
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer)
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--bs-dropdown-spacer)
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer)
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--bs-dropdown-spacer)
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    opacity: 1
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg)
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg)
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: .8125rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-dropdown-link-color)
}

.dropdown-menu-dark {
    --bs-dropdown-color: #e7eaf3;
    --bs-dropdown-bg: #71869d;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-link-color: #e7eaf3;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: rgba(231, 234, 243, 0.7);
    --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --bs-dropdown-link-active-color: #1e2022;
    --bs-dropdown-link-active-bg: rgba(189, 197, 209, 0.2);
    --bs-dropdown-link-disabled-color: #97a4af;
    --bs-dropdown-header-color: #97a4af
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group {
    border-radius: .3125rem
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -.0625rem
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -.0625rem
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: #132144;
    --bs-nav-link-hover-color: #1366ff;
    --bs-nav-link-disabled-color: #bdc5d1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,
.nav-link:hover {
    color: var(--bs-nav-link-hover-color)
}

.nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    --bs-nav-tabs-border-width: 0.0625rem;
    --bs-nav-tabs-border-color: rgba(231, 234, 243, 0.7);
    --bs-nav-tabs-border-radius: 0;
    --bs-nav-tabs-link-hover-border-color: rgba(231, 234, 243, 0.7);
    --bs-nav-tabs-link-active-color: #377dff;
    --bs-nav-tabs-link-active-bg: transparent;
    --bs-nav-tabs-link-active-border-color: #377dff;
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color)
}

.nav-tabs .nav-link {
    margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
    background: 0 0;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius)
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: var(--bs-nav-tabs-link-hover-border-color)
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color)
}

.nav-tabs .dropdown-menu {
    margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills {
    --bs-nav-pills-border-radius: 0.5rem;
    --bs-nav-pills-link-active-color: #132144;
    --bs-nav-pills-link-active-bg: rgba(189, 197, 209, 0.2)
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius)
}

.nav-pills .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg)
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0.5rem;
    --bs-navbar-color: #677788;
    --bs-navbar-hover-color: #377dff;
    --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --bs-navbar-active-color: #677788;
    --bs-navbar-brand-padding-y: 0.40625rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1rem;
    --bs-navbar-brand-color: #677788;
    --bs-navbar-brand-hover-color: #677788;
    --bs-navbar-nav-link-padding-x: 0.75rem;
    --bs-navbar-toggler-padding-y: 0.5rem;
    --bs-navbar-toggler-padding-x: 0.5rem;
    --bs-navbar-toggler-font-size: 1rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23677788' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --bs-navbar-toggler-border-radius: 0.3125rem;
    --bs-navbar-toggler-focus-width: 0;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x)
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    padding-top: var(--bs-navbar-brand-padding-y);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    margin-right: var(--bs-navbar-brand-margin-end);
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    color: var(--bs-navbar-brand-hover-color)
}

.navbar-nav {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: var(--bs-navbar-active-color)
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: var(--bs-navbar-color)
}

.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
    color: var(--bs-navbar-active-color)
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition)
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width)
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media (min-width:576px) {
    .navbar-expand-sm {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: static;
        z-index: auto;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        -webkit-transform: none !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: static;
        z-index: auto;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        -webkit-transform: none !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-md .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: static;
        z-index: auto;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        -webkit-transform: none !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: static;
        z-index: auto;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        -webkit-transform: none !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1400px) {
    .navbar-expand-xxl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: static;
        z-index: auto;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        -webkit-transform: none !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x)
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    -webkit-transform: none !important;
    transform: none !important;
    transition: none
}

.navbar-expand .offcanvas .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas .offcanvas-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-dark {
    --bs-navbar-color: rgba(255, 255, 255, 0.55);
    --bs-navbar-hover-color: #fff;
    --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
    --bs-navbar-active-color: #fff;
    --bs-navbar-brand-color: #fff;
    --bs-navbar-brand-hover-color: #fff;
    --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.card {
    --bs-card-spacer-y: 1.3125rem;
    --bs-card-spacer-x: 1.3125rem;
    --bs-card-title-spacer-y: 0.25rem;
    --bs-card-border-width: 0.0625rem;
    --bs-card-border-color: rgba(231, 234, 243, 0.7);
    --bs-card-border-radius: 0.75rem;
    --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
    --bs-card-inner-border-radius: 0.6875rem;
    --bs-card-cap-padding-y: 1.3125rem;
    --bs-card-cap-padding-x: 1.3125rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius)
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color)
}

.card-title {
    margin-bottom: var(--bs-card-title-spacer-y)
}

.card-subtitle {
    margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: var(--bs-card-spacer-x)
}

.card-header {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color)
}

.card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0
}

.card-footer {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color)
}

.card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)
}

.card-header-tabs {
    margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
    margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
    margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
    border-bottom: 0
}

.card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg)
}

.card-header-pills {
    margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
    margin-left: calc(-.5 * var(--bs-card-cap-padding-x))
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--bs-card-img-overlay-padding);
    border-radius: var(--bs-card-inner-border-radius)
}

.card-img,
.card-img-bottom,
.card-img-top {
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.card-group>.card {
    margin-bottom: var(--bs-card-group-margin)
}

@media (min-width:576px) {
    .card-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group>.card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion {
    --bs-accordion-color: #000;
    --bs-accordion-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: rgba(231, 234, 243, 0.7);
    --bs-accordion-border-width: 0.0625rem;
    --bs-accordion-border-radius: 0.5rem;
    --bs-accordion-inner-border-radius: 0.4375rem;
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 0.75rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23132144'%3e%3cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1rem;
    --bs-accordion-btn-icon-transform: rotate(0deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23377dff'%3e%3cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: rgba(140, 152, 164, 0.25);
    --bs-accordion-btn-focus-box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 0.75rem;
    --bs-accordion-active-color: #3271e6;
    --bs-accordion-active-bg: #fff
}

.accordion-button {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: .875rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color)
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    -webkit-transform: var(--bs-accordion-btn-icon-transform);
    transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-button::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0
}

.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-bg: transparent;
    --bs-breadcrumb-divider-color: #97a4af;
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: #8c98a4;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius)
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: var(--bs-breadcrumb-item-padding-x)
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
    color: var(--bs-breadcrumb-item-active-color)
}

.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-color: #677788;
    --bs-pagination-bg: #fff;
    --bs-pagination-border-width: 0;
    --bs-pagination-border-color: #e7eaf3;
    --bs-pagination-border-radius: 0.3125rem;
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: #f8fafd;
    --bs-pagination-hover-border-color: #e7eaf3;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #f8fafd;
    --bs-pagination-focus-box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #377dff;
    --bs-pagination-active-border-color: #377dff;
    --bs-pagination-disabled-color: #8c98a4;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #e7eaf3;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color)
}

.page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow)
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color)
}

.disabled>.page-link,
.page-link.disabled {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color)
}

.page-item:not(:first-child) .page-link {
    margin-left: 0
}

.page-item:first-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius)
}

.page-item:last-child .page-link {
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius)
}

.pagination-lg {
    --bs-pagination-padding-x: 1.25rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-border-radius: 0.75rem
}

.pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.8125rem;
    --bs-pagination-border-radius: 0.3125rem
}

.badge {
    --bs-badge-padding-x: 0.65em;
    --bs-badge-padding-y: 0.35em;
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 700;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: 0.3125rem;
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius)
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 0.75rem;
    --bs-alert-padding-y: 0.75rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: 0.0625rem solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 0.5rem;
    position: relative;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius)
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 2.25rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: .9375rem .75rem
}

.alert-primary {
    --bs-alert-color: #214b99;
    --bs-alert-bg: #d7e5ff;
    --bs-alert-border-color: #c3d8ff
}

.alert-primary .alert-link {
    color: #1a3c7a
}

.alert-secondary {
    --bs-alert-color: #44505e;
    --bs-alert-bg: #e3e7eb;
    --bs-alert-border-color: #d4dbe2
}

.alert-secondary .alert-link {
    color: #36404b
}

.alert-success {
    --bs-alert-color: #007964;
    --bs-alert-bg: #ccf4ed;
    --bs-alert-border-color: #b3efe5
}

.alert-success .alert-link {
    color: #006150
}

.alert-info {
    --bs-alert-color: #056372;
    --bs-alert-bg: #ceedf2;
    --bs-alert-border-color: #b5e4ec
}

.alert-info .alert-link {
    color: #044f5b
}

.alert-warning {
    --bs-alert-color: #93795c;
    --bs-alert-bg: #fdf4eb;
    --bs-alert-border-color: #fcefe0
}

.alert-warning .alert-link {
    color: #76614a
}

.alert-danger {
    --bs-alert-color: #8e2e48;
    --bs-alert-bg: #fbdbe4;
    --bs-alert-border-color: #fac9d7
}

.alert-danger .alert-link {
    color: #72253a
}

.alert-light {
    --bs-alert-color: #959697;
    --bs-alert-bg: #fefefe;
    --bs-alert-border-color: #fdfefe
}

.alert-light .alert-link {
    color: #777879
}

.alert-dark {
    --bs-alert-color: #0b1429;
    --bs-alert-bg: #d0d3da;
    --bs-alert-border-color: #b8bcc7
}

.alert-dark .alert-link {
    color: #091021
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: .5rem
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: .5rem
    }
}

.progress {
    --bs-progress-height: 0.5rem;
    --bs-progress-font-size: 0.65625rem;
    --bs-progress-bg: #bdc5d1;
    --bs-progress-border-radius: 0.5rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #377dff;
    --bs-progress-bar-transition: width 0.6s ease;
    display: -ms-flexbox;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius)
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition)
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.list-group {
    --bs-list-group-color: #1e2022;
    --bs-list-group-bg: #fff;
    --bs-list-group-border-color: #e7eaf3;
    --bs-list-group-border-width: 0.0625rem;
    --bs-list-group-border-radius: 0.5rem;
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 1rem;
    --bs-list-group-action-color: #677788;
    --bs-list-group-action-hover-color: #677788;
    --bs-list-group-action-hover-bg: #f9fafc;
    --bs-list-group-action-active-color: #677788;
    --bs-list-group-action-active-bg: #f8fafd;
    --bs-list-group-disabled-color: #8c98a4;
    --bs-list-group-disabled-bg: #fff;
    --bs-list-group-active-color: #fff;
    --bs-list-group-active-bg: #377dff;
    --bs-list-group-active-border-color: #377dff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--bs-list-group-border-radius)
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>.list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: var(--bs-list-group-action-color);
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg)
}

.list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg)
}

.list-group-item {
    position: relative;
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg)
}

.list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color)
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: calc(var(--bs-list-group-border-width) * -1);
    border-top-width: var(--bs-list-group-border-width)
}

.list-group-horizontal {
    -ms-flex-direction: row;
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width)
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:1400px) {
    .list-group-horizontal-xxl {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width)
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width)
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #214b99;
    background-color: #377dff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #214b99;
    background-color: #3271e6
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #214b99;
    border-color: #214b99
}

.list-group-item-secondary {
    color: #44505e;
    background-color: #71869d
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #44505e;
    background-color: #66798d
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #44505e;
    border-color: #44505e
}

.list-group-item-success {
    color: #007964;
    background-color: #00c9a7
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #007964;
    background-color: #00b596
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #007964;
    border-color: #007964
}

.list-group-item-info {
    color: #056372;
    background-color: #09a5be
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #056372;
    background-color: #0895ab
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #056372;
    border-color: #056372
}

.list-group-item-warning {
    color: #93795c;
    background-color: #f5ca99
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #93795c;
    background-color: #ddb68a
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #93795c;
    border-color: #93795c
}

.list-group-item-danger {
    color: #8e2e48;
    background-color: #ed4c78
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #8e2e48;
    background-color: #d5446c
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #8e2e48;
    border-color: #8e2e48
}

.list-group-item-light {
    color: #959697;
    background-color: #f9fafc
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #959697;
    background-color: #e0e1e3
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #959697;
    border-color: #959697
}

.list-group-item-dark {
    color: #a1a6b4;
    background-color: #132144
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #a1a6b4;
    background-color: #111e3d
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #a1a6b4;
    border-color: #a1a6b4
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .5rem;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, .25);
    opacity: 1
}

.btn-close.disabled,
.btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    --bs-toast-padding-x: 1rem;
    --bs-toast-padding-y: 1rem;
    --bs-toast-spacing: 1.5rem;
    --bs-toast-max-width: 350px;
    --bs-toast-font-size: 0.875rem;
    --bs-toast-bg: rgba(255, 255, 255, 0.85);
    --bs-toast-border-width: 0.0625rem;
    --bs-toast-border-color: rgba(231, 234, 243, 0.7);
    --bs-toast-border-radius: 0.75rem;
    --bs-toast-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
    --bs-toast-header-color: #8c98a4;
    --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
    --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
    width: var(--bs-toast-max-width);
    max-width: 100%;
    font-size: var(--bs-toast-font-size);
    color: var(--bs-toast-color);
    pointer-events: auto;
    background-color: var(--bs-toast-bg);
    background-clip: padding-box;
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    box-shadow: var(--bs-toast-box-shadow);
    border-radius: var(--bs-toast-border-radius)
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    position: absolute;
    z-index: 1090;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: var(--bs-toast-spacing)
}

.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
    color: var(--bs-toast-header-color);
    background-color: var(--bs-toast-header-bg);
    background-clip: padding-box;
    border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
    border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
    border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width))
}

.toast-header .btn-close {
    margin-right: calc(var(--bs-toast-padding-x) * -.5);
    margin-left: var(--bs-toast-padding-x)
}

.toast-body {
    padding: var(--bs-toast-padding-x);
    word-wrap: break-word
}

.modal {
    --bs-modal-zindex: 101;
    --bs-modal-width: 550px;
    --bs-modal-padding: 2rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-bg: #fff;
    --bs-modal-border-color: transparent;
    --bs-modal-border-width: 0.0625rem;
    --bs-modal-border-radius: 0.75rem;
    --bs-modal-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
    --bs-modal-inner-border-radius: 0.6875rem;
    --bs-modal-header-padding-x: 2rem;
    --bs-modal-header-padding-y: 2rem;
    --bs-modal-header-padding: 2rem 2rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 0;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 0.0625rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2)
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0
}

.modal-backdrop {
    --bs-backdrop-zindex: 100;
    --bs-backdrop-bg: rgba(19, 33, 68, 0.25);
    --bs-backdrop-opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity)
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius)
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(var(--bs-modal-header-padding-y) * -.5) calc(var(--bs-modal-header-padding-x) * -.5) calc(var(--bs-modal-header-padding-y) * -.5) auto
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height)
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding)
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius)
}

.modal-footer>* {
    margin: calc(var(--bs-modal-footer-gap) * .5)
}

@media (min-width:576px) {
    .modal {
        --bs-modal-margin: 1.75rem;
        --bs-modal-box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125)
    }

    .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto
    }

    .modal-sm {
        --bs-modal-width: 400px
    }
}

@media (min-width:992px) {

    .modal-lg,
    .modal-xl {
        --bs-modal-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        --bs-modal-width: 1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-footer,
.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

@media (max-width:575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-footer,
    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-footer,
    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-footer,
    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-footer,
    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-footer,
    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }
}

.tooltip {
    --bs-tooltip-zindex: 1080;
    --bs-tooltip-max-width: 200px;
    --bs-tooltip-padding-x: 0.5rem;
    --bs-tooltip-padding-y: 0.25rem;
    --bs-tooltip-font-size: 0.8125rem;
    --bs-tooltip-color: #fff;
    --bs-tooltip-bg: #132144;
    --bs-tooltip-border-radius: 0.5rem;
    --bs-tooltip-opacity: 1;
    --bs-tooltip-arrow-width: 0.8rem;
    --bs-tooltip-arrow-height: 0.4rem;
    z-index: var(--bs-tooltip-zindex);
    display: block;
    padding: var(--bs-tooltip-arrow-height);
    margin: var(--bs-tooltip-margin);
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--bs-tooltip-font-size);
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: var(--bs-tooltip-opacity)
}

.tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height)
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg)
}

.tooltip-inner {
    max-width: var(--bs-tooltip-max-width);
    padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
    color: var(--bs-tooltip-color);
    text-align: center;
    background-color: var(--bs-tooltip-bg);
    border-radius: var(--bs-tooltip-border-radius)
}

.popover {
    --bs-popover-zindex: 1070;
    --bs-popover-max-width: 276px;
    --bs-popover-font-size: 0.875rem;
    --bs-popover-bg: #fff;
    --bs-popover-border-width: 0.0625rem;
    --bs-popover-border-color: transparent;
    --bs-popover-border-radius: 0.75rem;
    --bs-popover-inner-border-radius: 0.6875rem;
    --bs-popover-box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
    --bs-popover-header-padding-x: 1.5rem;
    --bs-popover-header-padding-y: 1rem;
    --bs-popover-header-font-size: 0.875rem;
    --bs-popover-header-color: var(--bs-heading-color);
    --bs-popover-header-bg: #f8fafd;
    --bs-popover-body-padding-x: 1.5rem;
    --bs-popover-body-padding-y: 1.5rem;
    --bs-popover-body-color: #677788;
    --bs-popover-arrow-width: 1rem;
    --bs-popover-arrow-height: 0.5rem;
    --bs-popover-arrow-border: var(--bs-popover-border-color);
    z-index: var(--bs-popover-zindex);
    display: block;
    max-width: var(--bs-popover-max-width);
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--bs-popover-font-size);
    word-wrap: break-word;
    background-color: var(--bs-popover-bg);
    background-clip: padding-box;
    border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-radius: var(--bs-popover-border-radius)
}

.popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height)
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,
.bs-popover-top>.popover-arrow {
    bottom: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-top>.popover-arrow::before {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
.bs-popover-end>.popover-arrow {
    left: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-end>.popover-arrow::before {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
    top: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::before {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--bs-popover-arrow-width);
    margin-left: calc(var(--bs-popover-arrow-width) * -.5);
    content: "";
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
.bs-popover-start>.popover-arrow {
    right: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-start>.popover-arrow::before {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg)
}

.popover-header {
    padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
    margin-bottom: 0;
    font-size: var(--bs-popover-header-font-size);
    color: var(--bs-popover-header-color);
    background-color: var(--bs-popover-header-bg);
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-top-left-radius: var(--bs-popover-inner-border-radius);
    border-top-right-radius: var(--bs-popover-inner-border-radius)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
    color: var(--bs-popover-body-color)
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

.spinner-border,
.spinner-grow {
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    -webkit-animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid currentcolor;
    border-right-color: transparent
}

.spinner-border-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {

    .spinner-border,
    .spinner-grow {
        --bs-spinner-animation-speed: 1.5s
    }
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 2rem;
    --bs-offcanvas-padding-y: 2rem;
    --bs-offcanvas-bg: #fff;
    --bs-offcanvas-border-width: 0.0625rem;
    --bs-offcanvas-border-color: transparent;
    --bs-offcanvas-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075)
}

@media (max-width:575.98px) {
    .offcanvas-sm {
        position: fixed;
        bottom: 0;
        z-index: 1045;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
    }
}

@media (max-width:575.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-sm {
        transition: none
    }
}

@media (max-width:575.98px) {
    .offcanvas-sm.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width:575.98px) {
    .offcanvas-sm.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width:575.98px) {
    .offcanvas-sm.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width:575.98px) {
    .offcanvas-sm.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width:575.98px) {

    .offcanvas-sm.show:not(.hiding),
    .offcanvas-sm.showing {
        -webkit-transform: none;
        transform: none
    }
}

@media (max-width:575.98px) {

    .offcanvas-sm.hiding,
    .offcanvas-sm.show,
    .offcanvas-sm.showing {
        visibility: visible
    }
}

@media (min-width:576px) {
    .offcanvas-sm {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-sm .offcanvas-header {
        display: none
    }

    .offcanvas-sm .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:767.98px) {
    .offcanvas-md {
        position: fixed;
        bottom: 0;
        z-index: 1045;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
    }
}

@media (max-width:767.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-md {
        transition: none
    }
}

@media (max-width:767.98px) {
    .offcanvas-md.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width:767.98px) {
    .offcanvas-md.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width:767.98px) {
    .offcanvas-md.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width:767.98px) {
    .offcanvas-md.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width:767.98px) {

    .offcanvas-md.show:not(.hiding),
    .offcanvas-md.showing {
        -webkit-transform: none;
        transform: none
    }
}

@media (max-width:767.98px) {

    .offcanvas-md.hiding,
    .offcanvas-md.show,
    .offcanvas-md.showing {
        visibility: visible
    }
}

@media (min-width:768px) {
    .offcanvas-md {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-md .offcanvas-header {
        display: none
    }

    .offcanvas-md .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg {
        position: fixed;
        bottom: 0;
        z-index: 1045;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
    }
}

@media (max-width:991.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-lg {
        transition: none
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width:991.98px) {

    .offcanvas-lg.show:not(.hiding),
    .offcanvas-lg.showing {
        -webkit-transform: none;
        transform: none
    }
}

@media (max-width:991.98px) {

    .offcanvas-lg.hiding,
    .offcanvas-lg.show,
    .offcanvas-lg.showing {
        visibility: visible
    }
}

@media (min-width:992px) {
    .offcanvas-lg {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-lg .offcanvas-header {
        display: none
    }

    .offcanvas-lg .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl {
        position: fixed;
        bottom: 0;
        z-index: 1045;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
    }
}

@media (max-width:1199.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xl {
        transition: none
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width:1199.98px) {

    .offcanvas-xl.show:not(.hiding),
    .offcanvas-xl.showing {
        -webkit-transform: none;
        transform: none
    }
}

@media (max-width:1199.98px) {

    .offcanvas-xl.hiding,
    .offcanvas-xl.show,
    .offcanvas-xl.showing {
        visibility: visible
    }
}

@media (min-width:1200px) {
    .offcanvas-xl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-xl .offcanvas-header {
        display: none
    }

    .offcanvas-xl .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl {
        position: fixed;
        bottom: 0;
        z-index: 1045;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
    }
}

@media (max-width:1399.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xxl {
        transition: none
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width:1399.98px) {

    .offcanvas-xxl.show:not(.hiding),
    .offcanvas-xxl.showing {
        -webkit-transform: none;
        transform: none
    }
}

@media (max-width:1399.98px) {

    .offcanvas-xxl.hiding,
    .offcanvas-xxl.show,
    .offcanvas-xxl.showing {
        visibility: visible
    }
}

@media (min-width:1400px) {
    .offcanvas-xxl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-xxl .offcanvas-header {
        display: none
    }

    .offcanvas-xxl .offcanvas-body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
    -webkit-transform: none;
    transform: none
}

.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
    visibility: visible
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(19, 33, 68, .25)
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: 1
}

.offcanvas-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
}

.offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(var(--bs-offcanvas-padding-y) * -.5);
    margin-right: calc(var(--bs-offcanvas-padding-x) * -.5);
    margin-bottom: calc(var(--bs-offcanvas-padding-y) * -.5)
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5
}

.offcanvas-body {
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    overflow-y: auto
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: .5
}

.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite
}

@-webkit-keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.text-bg-primary {
    color: #fff !important;
    background-color: RGBA(55, 125, 255, var(--bs-bg-opacity, 1)) !important
}

.text-bg-secondary {
    color: #fff !important;
    background-color: RGBA(113, 134, 157, var(--bs-bg-opacity, 1)) !important
}

.text-bg-success {
    color: #fff !important;
    background-color: RGBA(0, 201, 167, var(--bs-bg-opacity, 1)) !important
}

.text-bg-info {
    color: #fff !important;
    background-color: RGBA(9, 165, 190, var(--bs-bg-opacity, 1)) !important
}

.text-bg-warning {
    color: #000 !important;
    background-color: RGBA(245, 202, 153, var(--bs-bg-opacity, 1)) !important
}

.text-bg-danger {
    color: #fff !important;
    background-color: RGBA(237, 76, 120, var(--bs-bg-opacity, 1)) !important
}

.text-bg-light {
    color: #000 !important;
    background-color: RGBA(249, 250, 252, var(--bs-bg-opacity, 1)) !important
}

.text-bg-dark {
    color: #fff !important;
    background-color: RGBA(19, 33, 68, var(--bs-bg-opacity, 1)) !important
}

.link-primary {
    color: #377dff !important
}

.link-primary:focus,
.link-primary:hover {
    color: #2c64cc !important
}

.link-secondary {
    color: #71869d !important
}

.link-secondary:focus,
.link-secondary:hover {
    color: #5a6b7e !important
}

.link-success {
    color: #00c9a7 !important
}

.link-success:focus,
.link-success:hover {
    color: #00a186 !important
}

.link-info {
    color: #09a5be !important
}

.link-info:focus,
.link-info:hover {
    color: #078498 !important
}

.link-warning {
    color: #f5ca99 !important
}

.link-warning:focus,
.link-warning:hover {
    color: #f7d5ad !important
}

.link-danger {
    color: #ed4c78 !important
}

.link-danger:focus,
.link-danger:hover {
    color: #be3d60 !important
}

.link-light {
    color: #f9fafc !important
}

.link-light:focus,
.link-light:hover {
    color: #fafbfd !important
}

.link-dark {
    color: #132144 !important
}

.link-dark:focus,
.link-dark:hover {
    color: #0f1a36 !important
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
}

.sticky-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-sm-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-md-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-lg-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xl-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    .sticky-xxl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xxl-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

.hstack {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    align-self: stretch
}

.vstack {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: stretch;
    align-self: stretch
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: 1
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125) !important
}

.shadow-sm {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075) !important
}

.shadow-lg {
    box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.shadow-xl {
    box-shadow: 0 3.75rem 5rem 0 rgba(140, 152, 164, .3) !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important
}

.translate-middle-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important
}

.border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
}

.border-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
}

.border-success {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
}

.border-info {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
}

.border-warning {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
}

.border-danger {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
}

.border-light {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
}

.border-dark {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
}

.border-white {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
}

.border-white-10 {
    --bs-border-opacity: 1;
    border-color: rgba(255, 255, 255, .1) !important
}

.border-1 {
    --bs-border-width: 1px
}

.border-2 {
    --bs-border-width: 2px
}

.border-3 {
    --bs-border-width: 3px
}

.border-4 {
    --bs-border-width: 4px
}

.border-5 {
    --bs-border-width: 5px
}

.border-opacity-10 {
    --bs-border-opacity: 0.1
}

.border-opacity-25 {
    --bs-border-opacity: 0.25
}

.border-opacity-50 {
    --bs-border-opacity: 0.5
}

.border-opacity-75 {
    --bs-border-opacity: 0.75
}

.border-opacity-100 {
    --bs-border-opacity: 1
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.w-70 {
    width: 70% !important
}

.w-85 {
    width: 85% !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
}

.justify-content-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
}

.order-first {
    -ms-flex-order: -1 !important;
    order: -1 !important
}

.order-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important
}

.order-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important
}

.order-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important
}

.order-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important
}

.order-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important
}

.order-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important
}

.order-last {
    -ms-flex-order: 6 !important;
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 2rem !important
}

.m-6 {
    margin: 2.5rem !important
}

.m-7 {
    margin: 3rem !important
}

.m-8 {
    margin: 3.5rem !important
}

.m-9 {
    margin: 4rem !important
}

.m-10 {
    margin: 4.5rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important
}

.mx-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important
}

.mx-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important
}

.mx-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important
}

.mx-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
}

.my-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important
}

.my-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important
}

.my-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important
}

.my-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 2rem !important
}

.mt-6 {
    margin-top: 2.5rem !important
}

.mt-7 {
    margin-top: 3rem !important
}

.mt-8 {
    margin-top: 3.5rem !important
}

.mt-9 {
    margin-top: 4rem !important
}

.mt-10 {
    margin-top: 4.5rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 2rem !important
}

.me-6 {
    margin-right: 2.5rem !important
}

.me-7 {
    margin-right: 3rem !important
}

.me-8 {
    margin-right: 3.5rem !important
}

.me-9 {
    margin-right: 4rem !important
}

.me-10 {
    margin-right: 4.5rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 2rem !important
}

.mb-6 {
    margin-bottom: 2.5rem !important
}

.mb-7 {
    margin-bottom: 3rem !important
}

.mb-8 {
    margin-bottom: 3.5rem !important
}

.mb-9 {
    margin-bottom: 4rem !important
}

.mb-10 {
    margin-bottom: 4.5rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 2rem !important
}

.ms-6 {
    margin-left: 2.5rem !important
}

.ms-7 {
    margin-left: 3rem !important
}

.ms-8 {
    margin-left: 3.5rem !important
}

.ms-9 {
    margin-left: 4rem !important
}

.ms-10 {
    margin-left: 4.5rem !important
}

.ms-auto {
    margin-left: auto !important
}

.m-n1 {
    margin: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.m-n5 {
    margin: -2rem !important
}

.m-n6 {
    margin: -2.5rem !important
}

.m-n7 {
    margin: -3rem !important
}

.m-n8 {
    margin: -3.5rem !important
}

.m-n9 {
    margin: -4rem !important
}

.m-n10 {
    margin: -4.5rem !important
}

.mx-n1 {
    margin-right: -.25rem !important;
    margin-left: -.25rem !important
}

.mx-n2 {
    margin-right: -.5rem !important;
    margin-left: -.5rem !important
}

.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important
}

.mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
}

.mx-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important
}

.mx-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important
}

.mx-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important
}

.mx-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important
}

.mx-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important
}

.mx-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important
}

.my-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important
}

.my-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important
}

.my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
}

.my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
}

.my-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important
}

.my-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important
}

.my-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important
}

.my-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important
}

.my-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important
}

.my-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important
}

.mt-n1 {
    margin-top: -.25rem !important
}

.mt-n2 {
    margin-top: -.5rem !important
}

.mt-n3 {
    margin-top: -1rem !important
}

.mt-n4 {
    margin-top: -1.5rem !important
}

.mt-n5 {
    margin-top: -2rem !important
}

.mt-n6 {
    margin-top: -2.5rem !important
}

.mt-n7 {
    margin-top: -3rem !important
}

.mt-n8 {
    margin-top: -3.5rem !important
}

.mt-n9 {
    margin-top: -4rem !important
}

.mt-n10 {
    margin-top: -4.5rem !important
}

.me-n1 {
    margin-right: -.25rem !important
}

.me-n2 {
    margin-right: -.5rem !important
}

.me-n3 {
    margin-right: -1rem !important
}

.me-n4 {
    margin-right: -1.5rem !important
}

.me-n5 {
    margin-right: -2rem !important
}

.me-n6 {
    margin-right: -2.5rem !important
}

.me-n7 {
    margin-right: -3rem !important
}

.me-n8 {
    margin-right: -3.5rem !important
}

.me-n9 {
    margin-right: -4rem !important
}

.me-n10 {
    margin-right: -4.5rem !important
}

.mb-n1 {
    margin-bottom: -.25rem !important
}

.mb-n2 {
    margin-bottom: -.5rem !important
}

.mb-n3 {
    margin-bottom: -1rem !important
}

.mb-n4 {
    margin-bottom: -1.5rem !important
}

.mb-n5 {
    margin-bottom: -2rem !important
}

.mb-n6 {
    margin-bottom: -2.5rem !important
}

.mb-n7 {
    margin-bottom: -3rem !important
}

.mb-n8 {
    margin-bottom: -3.5rem !important
}

.mb-n9 {
    margin-bottom: -4rem !important
}

.mb-n10 {
    margin-bottom: -4.5rem !important
}

.ms-n1 {
    margin-left: -.25rem !important
}

.ms-n2 {
    margin-left: -.5rem !important
}

.ms-n3 {
    margin-left: -1rem !important
}

.ms-n4 {
    margin-left: -1.5rem !important
}

.ms-n5 {
    margin-left: -2rem !important
}

.ms-n6 {
    margin-left: -2.5rem !important
}

.ms-n7 {
    margin-left: -3rem !important
}

.ms-n8 {
    margin-left: -3.5rem !important
}

.ms-n9 {
    margin-left: -4rem !important
}

.ms-n10 {
    margin-left: -4.5rem !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 2rem !important
}

.p-6 {
    padding: 2.5rem !important
}

.p-7 {
    padding: 3rem !important
}

.p-8 {
    padding: 3.5rem !important
}

.p-9 {
    padding: 4rem !important
}

.p-10 {
    padding: 4.5rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important
}

.px-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important
}

.px-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.px-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important
}

.px-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important
}

.px-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
}

.py-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important
}

.py-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.py-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important
}

.py-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important
}

.py-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 2rem !important
}

.pt-6 {
    padding-top: 2.5rem !important
}

.pt-7 {
    padding-top: 3rem !important
}

.pt-8 {
    padding-top: 3.5rem !important
}

.pt-9 {
    padding-top: 4rem !important
}

.pt-10 {
    padding-top: 4.5rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 2rem !important
}

.pe-6 {
    padding-right: 2.5rem !important
}

.pe-7 {
    padding-right: 3rem !important
}

.pe-8 {
    padding-right: 3.5rem !important
}

.pe-9 {
    padding-right: 4rem !important
}

.pe-10 {
    padding-right: 4.5rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 2rem !important
}

.pb-6 {
    padding-bottom: 2.5rem !important
}

.pb-7 {
    padding-bottom: 3rem !important
}

.pb-8 {
    padding-bottom: 3.5rem !important
}

.pb-9 {
    padding-bottom: 4rem !important
}

.pb-10 {
    padding-bottom: 4.5rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 2rem !important
}

.ps-6 {
    padding-left: 2.5rem !important
}

.ps-7 {
    padding-left: 3rem !important
}

.ps-8 {
    padding-left: 3.5rem !important
}

.ps-9 {
    padding-left: 4rem !important
}

.ps-10 {
    padding-left: 4.5rem !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 2rem !important
}

.gap-6 {
    gap: 2.5rem !important
}

.gap-7 {
    gap: 3rem !important
}

.gap-8 {
    gap: 3.5rem !important
}

.gap-9 {
    gap: 4rem !important
}

.gap-10 {
    gap: 4.5rem !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: calc(1.26609375rem + .193125vw) !important
}

.fs-2 {
    font-size: calc(1.25625rem + .075vw) !important
}

.fs-3 {
    font-size: 1.1484375rem !important
}

.fs-4 {
    font-size: .984375rem !important
}

.fs-5 {
    font-size: .875rem !important
}

.fs-6 {
    font-size: .8125rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-semibold {
    font-weight: 600 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: #8c98a4 !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-white-70 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .7) !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: var(--bs-border-radius) !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: var(--bs-border-radius-sm) !important
}

.rounded-2 {
    border-radius: var(--bs-border-radius) !important
}

.rounded-3 {
    border-radius: var(--bs-border-radius-lg) !important
}

.rounded-4 {
    border-radius: var(--bs-border-radius-xl) !important
}

.rounded-5 {
    border-radius: var(--bs-border-radius-2xl) !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: var(--bs-border-radius-pill) !important
}

.rounded-top {
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important
}

.rounded-end {
    border-top-right-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important
}

.rounded-bottom {
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-bottom-left-radius: var(--bs-border-radius) !important
}

.rounded-start {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

.content-space-t-0 {
    padding-top: 0 !important
}

.content-space-t-1 {
    padding-top: 3rem !important
}

.content-space-t-2 {
    padding-top: 5rem !important
}

.content-space-t-3 {
    padding-top: 7.5rem !important
}

.content-space-t-4 {
    padding-top: 10rem !important
}

.content-space-t-5 {
    padding-top: 12.5rem !important
}

.content-space-t-auto {
    padding-top: auto !important
}

.content-space-b-0 {
    padding-bottom: 0 !important
}

.content-space-b-1 {
    padding-bottom: 3rem !important
}

.content-space-b-2 {
    padding-bottom: 5rem !important
}

.content-space-b-3 {
    padding-bottom: 7.5rem !important
}

.content-space-b-4 {
    padding-bottom: 10rem !important
}

.content-space-b-5 {
    padding-bottom: 12.5rem !important
}

.content-space-b-auto {
    padding-bottom: auto !important
}

.content-space-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.content-space-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.content-space-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important
}

.content-space-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important
}

.content-space-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important
}

.content-space-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important
}

.content-space-auto {
    padding-top: auto !important;
    padding-bottom: auto !important
}

.zi-n1 {
    z-index: -1 !important
}

.zi-0 {
    z-index: 0 !important
}

.zi-1 {
    z-index: 1 !important
}

.zi-2 {
    z-index: 2 !important
}

.zi-3 {
    z-index: 3 !important
}

.zi-99 {
    z-index: 99 !important
}

.zi-999 {
    z-index: 999 !important
}

.bg-soft-primary {
    background-color: rgba(55, 125, 255, .1) !important
}

.bg-soft-secondary {
    background-color: rgba(113, 134, 157, .1) !important
}

.bg-soft-success {
    background-color: rgba(0, 201, 167, .1) !important
}

.bg-soft-info {
    background-color: rgba(9, 165, 190, .1) !important
}

.bg-soft-warning {
    background-color: rgba(245, 202, 153, .1) !important
}

.bg-soft-danger {
    background-color: rgba(237, 76, 120, .1) !important
}

.bg-soft-light {
    background-color: rgba(249, 250, 252, .1) !important
}

.bg-soft-dark {
    background-color: rgba(19, 33, 68, .1) !important
}

.min-h-100 {
    min-height: 100% !important
}

@media (min-width:576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .position-sm-static {
        position: static !important
    }

    .position-sm-relative {
        position: relative !important
    }

    .position-sm-absolute {
        position: absolute !important
    }

    .position-sm-fixed {
        position: fixed !important
    }

    .position-sm-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-sm-0 {
        top: 0 !important
    }

    .top-sm-50 {
        top: 50% !important
    }

    .top-sm-100 {
        top: 100% !important
    }

    .bottom-sm-0 {
        bottom: 0 !important
    }

    .bottom-sm-50 {
        bottom: 50% !important
    }

    .bottom-sm-100 {
        bottom: 100% !important
    }

    .start-sm-0 {
        left: 0 !important
    }

    .start-sm-50 {
        left: 50% !important
    }

    .start-sm-100 {
        left: 100% !important
    }

    .end-sm-0 {
        right: 0 !important
    }

    .end-sm-50 {
        right: 50% !important
    }

    .end-sm-100 {
        right: 100% !important
    }

    .w-sm-25 {
        width: 25% !important
    }

    .w-sm-50 {
        width: 50% !important
    }

    .w-sm-75 {
        width: 75% !important
    }

    .w-sm-100 {
        width: 100% !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .w-sm-70 {
        width: 70% !important
    }

    .w-sm-85 {
        width: 85% !important
    }

    .h-sm-25 {
        height: 25% !important
    }

    .h-sm-50 {
        height: 50% !important
    }

    .h-sm-75 {
        height: 75% !important
    }

    .h-sm-100 {
        height: 100% !important
    }

    .h-sm-auto {
        height: auto !important
    }

    .min-vh-sm-100 {
        min-height: 100vh !important
    }

    .flex-sm-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-sm-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-sm-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-sm-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-sm-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }

    .order-sm-first {
        -ms-flex-order: -1 !important;
        order: -1 !important
    }

    .order-sm-0 {
        -ms-flex-order: 0 !important;
        order: 0 !important
    }

    .order-sm-1 {
        -ms-flex-order: 1 !important;
        order: 1 !important
    }

    .order-sm-2 {
        -ms-flex-order: 2 !important;
        order: 2 !important
    }

    .order-sm-3 {
        -ms-flex-order: 3 !important;
        order: 3 !important
    }

    .order-sm-4 {
        -ms-flex-order: 4 !important;
        order: 4 !important
    }

    .order-sm-5 {
        -ms-flex-order: 5 !important;
        order: 5 !important
    }

    .order-sm-last {
        -ms-flex-order: 6 !important;
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 2rem !important
    }

    .m-sm-6 {
        margin: 2.5rem !important
    }

    .m-sm-7 {
        margin: 3rem !important
    }

    .m-sm-8 {
        margin: 3.5rem !important
    }

    .m-sm-9 {
        margin: 4rem !important
    }

    .m-sm-10 {
        margin: 4.5rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-sm-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-sm-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important
    }

    .mx-sm-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-sm-8 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-sm-9 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-sm-10 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-sm-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important
    }

    .my-sm-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-sm-8 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-sm-9 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-sm-10 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 2rem !important
    }

    .mt-sm-6 {
        margin-top: 2.5rem !important
    }

    .mt-sm-7 {
        margin-top: 3rem !important
    }

    .mt-sm-8 {
        margin-top: 3.5rem !important
    }

    .mt-sm-9 {
        margin-top: 4rem !important
    }

    .mt-sm-10 {
        margin-top: 4.5rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 2rem !important
    }

    .me-sm-6 {
        margin-right: 2.5rem !important
    }

    .me-sm-7 {
        margin-right: 3rem !important
    }

    .me-sm-8 {
        margin-right: 3.5rem !important
    }

    .me-sm-9 {
        margin-right: 4rem !important
    }

    .me-sm-10 {
        margin-right: 4.5rem !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 2rem !important
    }

    .mb-sm-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-sm-7 {
        margin-bottom: 3rem !important
    }

    .mb-sm-8 {
        margin-bottom: 3.5rem !important
    }

    .mb-sm-9 {
        margin-bottom: 4rem !important
    }

    .mb-sm-10 {
        margin-bottom: 4.5rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 2rem !important
    }

    .ms-sm-6 {
        margin-left: 2.5rem !important
    }

    .ms-sm-7 {
        margin-left: 3rem !important
    }

    .ms-sm-8 {
        margin-left: 3.5rem !important
    }

    .ms-sm-9 {
        margin-left: 4rem !important
    }

    .ms-sm-10 {
        margin-left: 4.5rem !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -2rem !important
    }

    .m-sm-n6 {
        margin: -2.5rem !important
    }

    .m-sm-n7 {
        margin: -3rem !important
    }

    .m-sm-n8 {
        margin: -3.5rem !important
    }

    .m-sm-n9 {
        margin: -4rem !important
    }

    .m-sm-n10 {
        margin: -4.5rem !important
    }

    .mx-sm-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-sm-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-sm-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-sm-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-sm-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-sm-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important
    }

    .mx-sm-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .mx-sm-n8 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-sm-n9 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-sm-n10 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .my-sm-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-sm-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-sm-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-sm-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-sm-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-sm-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important
    }

    .my-sm-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .my-sm-n8 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-sm-n9 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-sm-n10 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .mt-sm-n1 {
        margin-top: -.25rem !important
    }

    .mt-sm-n2 {
        margin-top: -.5rem !important
    }

    .mt-sm-n3 {
        margin-top: -1rem !important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mt-sm-n5 {
        margin-top: -2rem !important
    }

    .mt-sm-n6 {
        margin-top: -2.5rem !important
    }

    .mt-sm-n7 {
        margin-top: -3rem !important
    }

    .mt-sm-n8 {
        margin-top: -3.5rem !important
    }

    .mt-sm-n9 {
        margin-top: -4rem !important
    }

    .mt-sm-n10 {
        margin-top: -4.5rem !important
    }

    .me-sm-n1 {
        margin-right: -.25rem !important
    }

    .me-sm-n2 {
        margin-right: -.5rem !important
    }

    .me-sm-n3 {
        margin-right: -1rem !important
    }

    .me-sm-n4 {
        margin-right: -1.5rem !important
    }

    .me-sm-n5 {
        margin-right: -2rem !important
    }

    .me-sm-n6 {
        margin-right: -2.5rem !important
    }

    .me-sm-n7 {
        margin-right: -3rem !important
    }

    .me-sm-n8 {
        margin-right: -3.5rem !important
    }

    .me-sm-n9 {
        margin-right: -4rem !important
    }

    .me-sm-n10 {
        margin-right: -4.5rem !important
    }

    .mb-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem !important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-sm-n5 {
        margin-bottom: -2rem !important
    }

    .mb-sm-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-sm-n7 {
        margin-bottom: -3rem !important
    }

    .mb-sm-n8 {
        margin-bottom: -3.5rem !important
    }

    .mb-sm-n9 {
        margin-bottom: -4rem !important
    }

    .mb-sm-n10 {
        margin-bottom: -4.5rem !important
    }

    .ms-sm-n1 {
        margin-left: -.25rem !important
    }

    .ms-sm-n2 {
        margin-left: -.5rem !important
    }

    .ms-sm-n3 {
        margin-left: -1rem !important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem !important
    }

    .ms-sm-n5 {
        margin-left: -2rem !important
    }

    .ms-sm-n6 {
        margin-left: -2.5rem !important
    }

    .ms-sm-n7 {
        margin-left: -3rem !important
    }

    .ms-sm-n8 {
        margin-left: -3.5rem !important
    }

    .ms-sm-n9 {
        margin-left: -4rem !important
    }

    .ms-sm-n10 {
        margin-left: -4.5rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 2rem !important
    }

    .p-sm-6 {
        padding: 2.5rem !important
    }

    .p-sm-7 {
        padding: 3rem !important
    }

    .p-sm-8 {
        padding: 3.5rem !important
    }

    .p-sm-9 {
        padding: 4rem !important
    }

    .p-sm-10 {
        padding: 4.5rem !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-sm-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-sm-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important
    }

    .px-sm-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .px-sm-8 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-sm-9 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-sm-10 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-sm-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important
    }

    .py-sm-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .py-sm-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-sm-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-sm-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 2rem !important
    }

    .pt-sm-6 {
        padding-top: 2.5rem !important
    }

    .pt-sm-7 {
        padding-top: 3rem !important
    }

    .pt-sm-8 {
        padding-top: 3.5rem !important
    }

    .pt-sm-9 {
        padding-top: 4rem !important
    }

    .pt-sm-10 {
        padding-top: 4.5rem !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 2rem !important
    }

    .pe-sm-6 {
        padding-right: 2.5rem !important
    }

    .pe-sm-7 {
        padding-right: 3rem !important
    }

    .pe-sm-8 {
        padding-right: 3.5rem !important
    }

    .pe-sm-9 {
        padding-right: 4rem !important
    }

    .pe-sm-10 {
        padding-right: 4.5rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 2rem !important
    }

    .pb-sm-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-sm-7 {
        padding-bottom: 3rem !important
    }

    .pb-sm-8 {
        padding-bottom: 3.5rem !important
    }

    .pb-sm-9 {
        padding-bottom: 4rem !important
    }

    .pb-sm-10 {
        padding-bottom: 4.5rem !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 2rem !important
    }

    .ps-sm-6 {
        padding-left: 2.5rem !important
    }

    .ps-sm-7 {
        padding-left: 3rem !important
    }

    .ps-sm-8 {
        padding-left: 3.5rem !important
    }

    .ps-sm-9 {
        padding-left: 4rem !important
    }

    .ps-sm-10 {
        padding-left: 4.5rem !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: 1rem !important
    }

    .gap-sm-4 {
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        gap: 2rem !important
    }

    .gap-sm-6 {
        gap: 2.5rem !important
    }

    .gap-sm-7 {
        gap: 3rem !important
    }

    .gap-sm-8 {
        gap: 3.5rem !important
    }

    .gap-sm-9 {
        gap: 4rem !important
    }

    .gap-sm-10 {
        gap: 4.5rem !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }

    .content-space-t-sm-0 {
        padding-top: 0 !important
    }

    .content-space-t-sm-1 {
        padding-top: 3rem !important
    }

    .content-space-t-sm-2 {
        padding-top: 5rem !important
    }

    .content-space-t-sm-3 {
        padding-top: 7.5rem !important
    }

    .content-space-t-sm-4 {
        padding-top: 10rem !important
    }

    .content-space-t-sm-5 {
        padding-top: 12.5rem !important
    }

    .content-space-t-sm-auto {
        padding-top: auto !important
    }

    .content-space-b-sm-0 {
        padding-bottom: 0 !important
    }

    .content-space-b-sm-1 {
        padding-bottom: 3rem !important
    }

    .content-space-b-sm-2 {
        padding-bottom: 5rem !important
    }

    .content-space-b-sm-3 {
        padding-bottom: 7.5rem !important
    }

    .content-space-b-sm-4 {
        padding-bottom: 10rem !important
    }

    .content-space-b-sm-5 {
        padding-bottom: 12.5rem !important
    }

    .content-space-b-sm-auto {
        padding-bottom: auto !important
    }

    .content-space-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .content-space-sm-1 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .content-space-sm-2 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .content-space-sm-3 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important
    }

    .content-space-sm-4 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important
    }

    .content-space-sm-5 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important
    }

    .content-space-sm-auto {
        padding-top: auto !important;
        padding-bottom: auto !important
    }
}

@media (min-width:768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .position-md-static {
        position: static !important
    }

    .position-md-relative {
        position: relative !important
    }

    .position-md-absolute {
        position: absolute !important
    }

    .position-md-fixed {
        position: fixed !important
    }

    .position-md-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-md-0 {
        top: 0 !important
    }

    .top-md-50 {
        top: 50% !important
    }

    .top-md-100 {
        top: 100% !important
    }

    .bottom-md-0 {
        bottom: 0 !important
    }

    .bottom-md-50 {
        bottom: 50% !important
    }

    .bottom-md-100 {
        bottom: 100% !important
    }

    .start-md-0 {
        left: 0 !important
    }

    .start-md-50 {
        left: 50% !important
    }

    .start-md-100 {
        left: 100% !important
    }

    .end-md-0 {
        right: 0 !important
    }

    .end-md-50 {
        right: 50% !important
    }

    .end-md-100 {
        right: 100% !important
    }

    .w-md-25 {
        width: 25% !important
    }

    .w-md-50 {
        width: 50% !important
    }
    .w-md-60 {
        width: 60% !important
    }

    .w-md-75 {
        width: 75% !important
    }

    .w-md-100 {
        width: 100% !important
    }

    .w-md-auto {
        width: auto !important
    }

    .w-md-70 {
        width: 70% !important
    }

    .w-md-85 {
        width: 85% !important
    }

    .h-md-25 {
        height: 25% !important
    }

    .h-md-50 {
        height: 50% !important
    }

    .h-md-75 {
        height: 75% !important
    }

    .h-md-100 {
        height: 100% !important
    }

    .h-md-auto {
        height: auto !important
    }

    .min-vh-md-100 {
        min-height: 100vh !important
    }

    .flex-md-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-md-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-md-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-md-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-md-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-md-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-md-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-md-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }

    .order-md-first {
        -ms-flex-order: -1 !important;
        order: -1 !important
    }

    .order-md-0 {
        -ms-flex-order: 0 !important;
        order: 0 !important
    }

    .order-md-1 {
        -ms-flex-order: 1 !important;
        order: 1 !important
    }

    .order-md-2 {
        -ms-flex-order: 2 !important;
        order: 2 !important
    }

    .order-md-3 {
        -ms-flex-order: 3 !important;
        order: 3 !important
    }

    .order-md-4 {
        -ms-flex-order: 4 !important;
        order: 4 !important
    }

    .order-md-5 {
        -ms-flex-order: 5 !important;
        order: 5 !important
    }

    .order-md-last {
        -ms-flex-order: 6 !important;
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 2rem !important
    }

    .m-md-6 {
        margin: 2.5rem !important
    }

    .m-md-7 {
        margin: 3rem !important
    }

    .m-md-8 {
        margin: 3.5rem !important
    }

    .m-md-9 {
        margin: 4rem !important
    }

    .m-md-10 {
        margin: 4.5rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-md-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-md-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important
    }

    .mx-md-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-md-8 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-md-9 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-md-10 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-md-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important
    }

    .my-md-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-md-8 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-md-9 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-md-10 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 2rem !important
    }

    .mt-md-6 {
        margin-top: 2.5rem !important
    }

    .mt-md-7 {
        margin-top: 3rem !important
    }

    .mt-md-8 {
        margin-top: 3.5rem !important
    }

    .mt-md-9 {
        margin-top: 4rem !important
    }

    .mt-md-10 {
        margin-top: 4.5rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 2rem !important
    }

    .me-md-6 {
        margin-right: 2.5rem !important
    }

    .me-md-7 {
        margin-right: 3rem !important
    }

    .me-md-8 {
        margin-right: 3.5rem !important
    }

    .me-md-9 {
        margin-right: 4rem !important
    }

    .me-md-10 {
        margin-right: 4.5rem !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 2rem !important
    }

    .mb-md-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-md-7 {
        margin-bottom: 3rem !important
    }

    .mb-md-8 {
        margin-bottom: 3.5rem !important
    }

    .mb-md-9 {
        margin-bottom: 4rem !important
    }

    .mb-md-10 {
        margin-bottom: 4.5rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 2rem !important
    }

    .ms-md-6 {
        margin-left: 2.5rem !important
    }

    .ms-md-7 {
        margin-left: 3rem !important
    }

    .ms-md-8 {
        margin-left: 3.5rem !important
    }

    .ms-md-9 {
        margin-left: 4rem !important
    }

    .ms-md-10 {
        margin-left: 4.5rem !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .m-md-n5 {
        margin: -2rem !important
    }

    .m-md-n6 {
        margin: -2.5rem !important
    }

    .m-md-n7 {
        margin: -3rem !important
    }

    .m-md-n8 {
        margin: -3.5rem !important
    }

    .m-md-n9 {
        margin: -4rem !important
    }

    .m-md-n10 {
        margin: -4.5rem !important
    }

    .mx-md-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-md-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-md-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-md-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-md-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-md-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important
    }

    .mx-md-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .mx-md-n8 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-md-n9 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-md-n10 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .my-md-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-md-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-md-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-md-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-md-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-md-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important
    }

    .my-md-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .my-md-n8 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-md-n9 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-md-n10 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .mt-md-n1 {
        margin-top: -.25rem !important
    }

    .mt-md-n2 {
        margin-top: -.5rem !important
    }

    .mt-md-n3 {
        margin-top: -1rem !important
    }

    .mt-md-n4 {
        margin-top: -1.5rem !important
    }

    .mt-md-n5 {
        margin-top: -2rem !important
    }

    .mt-md-n6 {
        margin-top: -2.5rem !important
    }

    .mt-md-n7 {
        margin-top: -3rem !important
    }

    .mt-md-n8 {
        margin-top: -3.5rem !important
    }

    .mt-md-n9 {
        margin-top: -4rem !important
    }

    .mt-md-n10 {
        margin-top: -4.5rem !important
    }

    .me-md-n1 {
        margin-right: -.25rem !important
    }

    .me-md-n2 {
        margin-right: -.5rem !important
    }

    .me-md-n3 {
        margin-right: -1rem !important
    }

    .me-md-n4 {
        margin-right: -1.5rem !important
    }

    .me-md-n5 {
        margin-right: -2rem !important
    }

    .me-md-n6 {
        margin-right: -2.5rem !important
    }

    .me-md-n7 {
        margin-right: -3rem !important
    }

    .me-md-n8 {
        margin-right: -3.5rem !important
    }

    .me-md-n9 {
        margin-right: -4rem !important
    }

    .me-md-n10 {
        margin-right: -4.5rem !important
    }

    .mb-md-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-md-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-md-n3 {
        margin-bottom: -1rem !important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-md-n5 {
        margin-bottom: -2rem !important
    }

    .mb-md-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-md-n7 {
        margin-bottom: -3rem !important
    }

    .mb-md-n8 {
        margin-bottom: -3.5rem !important
    }

    .mb-md-n9 {
        margin-bottom: -4rem !important
    }

    .mb-md-n10 {
        margin-bottom: -4.5rem !important
    }

    .ms-md-n1 {
        margin-left: -.25rem !important
    }

    .ms-md-n2 {
        margin-left: -.5rem !important
    }

    .ms-md-n3 {
        margin-left: -1rem !important
    }

    .ms-md-n4 {
        margin-left: -1.5rem !important
    }

    .ms-md-n5 {
        margin-left: -2rem !important
    }

    .ms-md-n6 {
        margin-left: -2.5rem !important
    }

    .ms-md-n7 {
        margin-left: -3rem !important
    }

    .ms-md-n8 {
        margin-left: -3.5rem !important
    }

    .ms-md-n9 {
        margin-left: -4rem !important
    }

    .ms-md-n10 {
        margin-left: -4.5rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 2rem !important
    }

    .p-md-6 {
        padding: 2.5rem !important
    }

    .p-md-7 {
        padding: 3rem !important
    }

    .p-md-8 {
        padding: 3.5rem !important
    }

    .p-md-9 {
        padding: 4rem !important
    }

    .p-md-10 {
        padding: 4.5rem !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-md-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-md-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important
    }

    .px-md-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .px-md-8 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-md-9 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-md-10 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-md-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important
    }

    .py-md-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .py-md-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-md-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-md-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 2rem !important
    }

    .pt-md-6 {
        padding-top: 2.5rem !important
    }

    .pt-md-7 {
        padding-top: 3rem !important
    }

    .pt-md-8 {
        padding-top: 3.5rem !important
    }

    .pt-md-9 {
        padding-top: 4rem !important
    }

    .pt-md-10 {
        padding-top: 4.5rem !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 2rem !important
    }

    .pe-md-6 {
        padding-right: 2.5rem !important
    }

    .pe-md-7 {
        padding-right: 3rem !important
    }

    .pe-md-8 {
        padding-right: 3.5rem !important
    }

    .pe-md-9 {
        padding-right: 4rem !important
    }

    .pe-md-10 {
        padding-right: 4.5rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 2rem !important
    }

    .pb-md-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-md-7 {
        padding-bottom: 3rem !important
    }

    .pb-md-8 {
        padding-bottom: 3.5rem !important
    }

    .pb-md-9 {
        padding-bottom: 4rem !important
    }

    .pb-md-10 {
        padding-bottom: 4.5rem !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 2rem !important
    }

    .ps-md-6 {
        padding-left: 2.5rem !important
    }

    .ps-md-7 {
        padding-left: 3rem !important
    }

    .ps-md-8 {
        padding-left: 3.5rem !important
    }

    .ps-md-9 {
        padding-left: 4rem !important
    }

    .ps-md-10 {
        padding-left: 4.5rem !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: 1rem !important
    }

    .gap-md-4 {
        gap: 1.5rem !important
    }

    .gap-md-5 {
        gap: 2rem !important
    }

    .gap-md-6 {
        gap: 2.5rem !important
    }

    .gap-md-7 {
        gap: 3rem !important
    }

    .gap-md-8 {
        gap: 3.5rem !important
    }

    .gap-md-9 {
        gap: 4rem !important
    }

    .gap-md-10 {
        gap: 4.5rem !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }

    .content-space-t-md-0 {
        padding-top: 0 !important
    }

    .content-space-t-md-1 {
        padding-top: 3rem !important
    }

    .content-space-t-md-2 {
        padding-top: 5rem !important
    }

    .content-space-t-md-3 {
        padding-top: 7.5rem !important
    }

    .content-space-t-md-4 {
        padding-top: 10rem !important
    }

    .content-space-t-md-5 {
        padding-top: 12.5rem !important
    }

    .content-space-t-md-auto {
        padding-top: auto !important
    }

    .content-space-b-md-0 {
        padding-bottom: 0 !important
    }

    .content-space-b-md-1 {
        padding-bottom: 3rem !important
    }

    .content-space-b-md-2 {
        padding-bottom: 5rem !important
    }

    .content-space-b-md-3 {
        padding-bottom: 7.5rem !important
    }

    .content-space-b-md-4 {
        padding-bottom: 10rem !important
    }

    .content-space-b-md-5 {
        padding-bottom: 12.5rem !important
    }

    .content-space-b-md-auto {
        padding-bottom: auto !important
    }

    .content-space-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .content-space-md-1 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .content-space-md-2 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .content-space-md-3 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important
    }

    .content-space-md-4 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important
    }

    .content-space-md-5 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important
    }

    .content-space-md-auto {
        padding-top: auto !important;
        padding-bottom: auto !important
    }
}

@media (min-width:992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .position-lg-static {
        position: static !important
    }

    .position-lg-relative {
        position: relative !important
    }

    .position-lg-absolute {
        position: absolute !important
    }

    .position-lg-fixed {
        position: fixed !important
    }

    .position-lg-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-lg-0 {
        top: 0 !important
    }

    .top-lg-50 {
        top: 50% !important
    }

    .top-lg-100 {
        top: 100% !important
    }

    .bottom-lg-0 {
        bottom: 0 !important
    }

    .bottom-lg-50 {
        bottom: 50% !important
    }

    .bottom-lg-100 {
        bottom: 100% !important
    }

    .start-lg-0 {
        left: 0 !important
    }

    .start-lg-50 {
        left: 50% !important
    }

    .start-lg-100 {
        left: 100% !important
    }

    .end-lg-0 {
        right: 0 !important
    }

    .end-lg-50 {
        right: 50% !important
    }

    .end-lg-100 {
        right: 100% !important
    }

    .w-lg-25 {
        width: 25% !important
    }

    .w-lg-50 {
        width: 50% !important
    }

    .w-lg-75 {
        width: 75% !important
    }

    .w-lg-100 {
        width: 100% !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .w-lg-70 {
        width: 70% !important
    }

    .w-lg-85 {
        width: 85% !important
    }

    .h-lg-25 {
        height: 25% !important
    }

    .h-lg-50 {
        height: 50% !important
    }

    .h-lg-75 {
        height: 75% !important
    }

    .h-lg-100 {
        height: 100% !important
    }

    .h-lg-auto {
        height: auto !important
    }

    .min-vh-lg-100 {
        min-height: 100vh !important
    }

    .flex-lg-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-lg-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-lg-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-lg-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-lg-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-lg-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }

    .order-lg-first {
        -ms-flex-order: -1 !important;
        order: -1 !important
    }

    .order-lg-0 {
        -ms-flex-order: 0 !important;
        order: 0 !important
    }

    .order-lg-1 {
        -ms-flex-order: 1 !important;
        order: 1 !important
    }

    .order-lg-2 {
        -ms-flex-order: 2 !important;
        order: 2 !important
    }

    .order-lg-3 {
        -ms-flex-order: 3 !important;
        order: 3 !important
    }

    .order-lg-4 {
        -ms-flex-order: 4 !important;
        order: 4 !important
    }

    .order-lg-5 {
        -ms-flex-order: 5 !important;
        order: 5 !important
    }

    .order-lg-last {
        -ms-flex-order: 6 !important;
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 2rem !important
    }

    .m-lg-6 {
        margin: 2.5rem !important
    }

    .m-lg-7 {
        margin: 3rem !important
    }

    .m-lg-8 {
        margin: 3.5rem !important
    }

    .m-lg-9 {
        margin: 4rem !important
    }

    .m-lg-10 {
        margin: 4.5rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-lg-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-lg-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important
    }

    .mx-lg-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-lg-8 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-lg-9 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-lg-10 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-lg-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important
    }

    .my-lg-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-lg-8 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-lg-9 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-lg-10 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 2rem !important
    }

    .mt-lg-6 {
        margin-top: 2.5rem !important
    }

    .mt-lg-7 {
        margin-top: 3rem !important
    }

    .mt-lg-8 {
        margin-top: 3.5rem !important
    }

    .mt-lg-9 {
        margin-top: 4rem !important
    }

    .mt-lg-10 {
        margin-top: 4.5rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 2rem !important
    }

    .me-lg-6 {
        margin-right: 2.5rem !important
    }

    .me-lg-7 {
        margin-right: 3rem !important
    }

    .me-lg-8 {
        margin-right: 3.5rem !important
    }

    .me-lg-9 {
        margin-right: 4rem !important
    }

    .me-lg-10 {
        margin-right: 4.5rem !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 2rem !important
    }

    .mb-lg-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-lg-7 {
        margin-bottom: 3rem !important
    }

    .mb-lg-8 {
        margin-bottom: 3.5rem !important
    }

    .mb-lg-9 {
        margin-bottom: 4rem !important
    }

    .mb-lg-10 {
        margin-bottom: 4.5rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 2rem !important
    }

    .ms-lg-6 {
        margin-left: 2.5rem !important
    }

    .ms-lg-7 {
        margin-left: 3rem !important
    }

    .ms-lg-8 {
        margin-left: 3.5rem !important
    }

    .ms-lg-9 {
        margin-left: 4rem !important
    }

    .ms-lg-10 {
        margin-left: 4.5rem !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -2rem !important
    }

    .m-lg-n6 {
        margin: -2.5rem !important
    }

    .m-lg-n7 {
        margin: -3rem !important
    }

    .m-lg-n8 {
        margin: -3.5rem !important
    }

    .m-lg-n9 {
        margin: -4rem !important
    }

    .m-lg-n10 {
        margin: -4.5rem !important
    }

    .mx-lg-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-lg-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-lg-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-lg-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-lg-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-lg-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important
    }

    .mx-lg-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .mx-lg-n8 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-lg-n9 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-lg-n10 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .my-lg-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-lg-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-lg-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-lg-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-lg-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-lg-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important
    }

    .my-lg-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .my-lg-n8 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-lg-n9 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-lg-n10 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .mt-lg-n1 {
        margin-top: -.25rem !important
    }

    .mt-lg-n2 {
        margin-top: -.5rem !important
    }

    .mt-lg-n3 {
        margin-top: -1rem !important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mt-lg-n5 {
        margin-top: -2rem !important
    }

    .mt-lg-n6 {
        margin-top: -2.5rem !important
    }

    .mt-lg-n7 {
        margin-top: -3rem !important
    }

    .mt-lg-n8 {
        margin-top: -3.5rem !important
    }

    .mt-lg-n9 {
        margin-top: -4rem !important
    }

    .mt-lg-n10 {
        margin-top: -4.5rem !important
    }

    .me-lg-n1 {
        margin-right: -.25rem !important
    }

    .me-lg-n2 {
        margin-right: -.5rem !important
    }

    .me-lg-n3 {
        margin-right: -1rem !important
    }

    .me-lg-n4 {
        margin-right: -1.5rem !important
    }

    .me-lg-n5 {
        margin-right: -2rem !important
    }

    .me-lg-n6 {
        margin-right: -2.5rem !important
    }

    .me-lg-n7 {
        margin-right: -3rem !important
    }

    .me-lg-n8 {
        margin-right: -3.5rem !important
    }

    .me-lg-n9 {
        margin-right: -4rem !important
    }

    .me-lg-n10 {
        margin-right: -4.5rem !important
    }

    .mb-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem !important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-lg-n5 {
        margin-bottom: -2rem !important
    }

    .mb-lg-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-lg-n7 {
        margin-bottom: -3rem !important
    }

    .mb-lg-n8 {
        margin-bottom: -3.5rem !important
    }

    .mb-lg-n9 {
        margin-bottom: -4rem !important
    }

    .mb-lg-n10 {
        margin-bottom: -4.5rem !important
    }

    .ms-lg-n1 {
        margin-left: -.25rem !important
    }

    .ms-lg-n2 {
        margin-left: -.5rem !important
    }

    .ms-lg-n3 {
        margin-left: -1rem !important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem !important
    }

    .ms-lg-n5 {
        margin-left: -2rem !important
    }

    .ms-lg-n6 {
        margin-left: -2.5rem !important
    }

    .ms-lg-n7 {
        margin-left: -3rem !important
    }

    .ms-lg-n8 {
        margin-left: -3.5rem !important
    }

    .ms-lg-n9 {
        margin-left: -4rem !important
    }

    .ms-lg-n10 {
        margin-left: -4.5rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 2rem !important
    }

    .p-lg-6 {
        padding: 2.5rem !important
    }

    .p-lg-7 {
        padding: 3rem !important
    }

    .p-lg-8 {
        padding: 3.5rem !important
    }

    .p-lg-9 {
        padding: 4rem !important
    }

    .p-lg-10 {
        padding: 4.5rem !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-lg-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-lg-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important
    }

    .px-lg-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .px-lg-8 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-lg-9 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-lg-10 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-lg-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important
    }

    .py-lg-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .py-lg-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-lg-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-lg-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 2rem !important
    }

    .pt-lg-6 {
        padding-top: 2.5rem !important
    }

    .pt-lg-7 {
        padding-top: 3rem !important
    }

    .pt-lg-8 {
        padding-top: 3.5rem !important
    }

    .pt-lg-9 {
        padding-top: 4rem !important
    }

    .pt-lg-10 {
        padding-top: 4.5rem !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 2rem !important
    }

    .pe-lg-6 {
        padding-right: 2.5rem !important
    }

    .pe-lg-7 {
        padding-right: 3rem !important
    }

    .pe-lg-8 {
        padding-right: 3.5rem !important
    }

    .pe-lg-9 {
        padding-right: 4rem !important
    }

    .pe-lg-10 {
        padding-right: 4.5rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 2rem !important
    }

    .pb-lg-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-lg-7 {
        padding-bottom: 3rem !important
    }

    .pb-lg-8 {
        padding-bottom: 3.5rem !important
    }

    .pb-lg-9 {
        padding-bottom: 4rem !important
    }

    .pb-lg-10 {
        padding-bottom: 4.5rem !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 2rem !important
    }

    .ps-lg-6 {
        padding-left: 2.5rem !important
    }

    .ps-lg-7 {
        padding-left: 3rem !important
    }

    .ps-lg-8 {
        padding-left: 3.5rem !important
    }

    .ps-lg-9 {
        padding-left: 4rem !important
    }

    .ps-lg-10 {
        padding-left: 4.5rem !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: 1rem !important
    }

    .gap-lg-4 {
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        gap: 2rem !important
    }

    .gap-lg-6 {
        gap: 2.5rem !important
    }

    .gap-lg-7 {
        gap: 3rem !important
    }

    .gap-lg-8 {
        gap: 3.5rem !important
    }

    .gap-lg-9 {
        gap: 4rem !important
    }

    .gap-lg-10 {
        gap: 4.5rem !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }

    .content-space-t-lg-0 {
        padding-top: 0 !important
    }

    .content-space-t-lg-1 {
        padding-top: 3rem !important
    }

    .content-space-t-lg-2 {
        padding-top: 5rem !important
    }

    .content-space-t-lg-3 {
        padding-top: 7.5rem !important
    }

    .content-space-t-lg-4 {
        padding-top: 10rem !important
    }

    .content-space-t-lg-5 {
        padding-top: 12.5rem !important
    }

    .content-space-t-lg-auto {
        padding-top: auto !important
    }

    .content-space-b-lg-0 {
        padding-bottom: 0 !important
    }

    .content-space-b-lg-1 {
        padding-bottom: 3rem !important
    }

    .content-space-b-lg-2 {
        padding-bottom: 5rem !important
    }

    .content-space-b-lg-3 {
        padding-bottom: 7.5rem !important
    }

    .content-space-b-lg-4 {
        padding-bottom: 10rem !important
    }

    .content-space-b-lg-5 {
        padding-bottom: 12.5rem !important
    }

    .content-space-b-lg-auto {
        padding-bottom: auto !important
    }

    .content-space-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .content-space-lg-1 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .content-space-lg-2 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .content-space-lg-3 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important
    }

    .content-space-lg-4 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important
    }

    .content-space-lg-5 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important
    }

    .content-space-lg-auto {
        padding-top: auto !important;
        padding-bottom: auto !important
    }
}

@media (min-width:1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .position-xl-static {
        position: static !important
    }

    .position-xl-relative {
        position: relative !important
    }

    .position-xl-absolute {
        position: absolute !important
    }

    .position-xl-fixed {
        position: fixed !important
    }

    .position-xl-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-xl-0 {
        top: 0 !important
    }

    .top-xl-50 {
        top: 50% !important
    }

    .top-xl-100 {
        top: 100% !important
    }

    .bottom-xl-0 {
        bottom: 0 !important
    }

    .bottom-xl-50 {
        bottom: 50% !important
    }

    .bottom-xl-100 {
        bottom: 100% !important
    }

    .start-xl-0 {
        left: 0 !important
    }

    .start-xl-50 {
        left: 50% !important
    }

    .start-xl-100 {
        left: 100% !important
    }

    .end-xl-0 {
        right: 0 !important
    }

    .end-xl-50 {
        right: 50% !important
    }

    .end-xl-100 {
        right: 100% !important
    }

    .w-xl-25 {
        width: 25% !important
    }

    .w-xl-50 {
        width: 50% !important
    }

    .w-xl-75 {
        width: 75% !important
    }

    .w-xl-100 {
        width: 100% !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .w-xl-70 {
        width: 70% !important
    }

    .w-xl-85 {
        width: 85% !important
    }

    .h-xl-25 {
        height: 25% !important
    }

    .h-xl-50 {
        height: 50% !important
    }

    .h-xl-75 {
        height: 75% !important
    }

    .h-xl-100 {
        height: 100% !important
    }

    .h-xl-auto {
        height: auto !important
    }

    .min-vh-xl-100 {
        min-height: 100vh !important
    }

    .flex-xl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xl-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }

    .order-xl-first {
        -ms-flex-order: -1 !important;
        order: -1 !important
    }

    .order-xl-0 {
        -ms-flex-order: 0 !important;
        order: 0 !important
    }

    .order-xl-1 {
        -ms-flex-order: 1 !important;
        order: 1 !important
    }

    .order-xl-2 {
        -ms-flex-order: 2 !important;
        order: 2 !important
    }

    .order-xl-3 {
        -ms-flex-order: 3 !important;
        order: 3 !important
    }

    .order-xl-4 {
        -ms-flex-order: 4 !important;
        order: 4 !important
    }

    .order-xl-5 {
        -ms-flex-order: 5 !important;
        order: 5 !important
    }

    .order-xl-last {
        -ms-flex-order: 6 !important;
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 2rem !important
    }

    .m-xl-6 {
        margin: 2.5rem !important
    }

    .m-xl-7 {
        margin: 3rem !important
    }

    .m-xl-8 {
        margin: 3.5rem !important
    }

    .m-xl-9 {
        margin: 4rem !important
    }

    .m-xl-10 {
        margin: 4.5rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xl-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-xl-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important
    }

    .mx-xl-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xl-8 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-xl-9 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-xl-10 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-xl-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important
    }

    .my-xl-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xl-8 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-xl-9 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-xl-10 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 2rem !important
    }

    .mt-xl-6 {
        margin-top: 2.5rem !important
    }

    .mt-xl-7 {
        margin-top: 3rem !important
    }

    .mt-xl-8 {
        margin-top: 3.5rem !important
    }

    .mt-xl-9 {
        margin-top: 4rem !important
    }

    .mt-xl-10 {
        margin-top: 4.5rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 2rem !important
    }

    .me-xl-6 {
        margin-right: 2.5rem !important
    }

    .me-xl-7 {
        margin-right: 3rem !important
    }

    .me-xl-8 {
        margin-right: 3.5rem !important
    }

    .me-xl-9 {
        margin-right: 4rem !important
    }

    .me-xl-10 {
        margin-right: 4.5rem !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 2rem !important
    }

    .mb-xl-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-xl-7 {
        margin-bottom: 3rem !important
    }

    .mb-xl-8 {
        margin-bottom: 3.5rem !important
    }

    .mb-xl-9 {
        margin-bottom: 4rem !important
    }

    .mb-xl-10 {
        margin-bottom: 4.5rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 2rem !important
    }

    .ms-xl-6 {
        margin-left: 2.5rem !important
    }

    .ms-xl-7 {
        margin-left: 3rem !important
    }

    .ms-xl-8 {
        margin-left: 3.5rem !important
    }

    .ms-xl-9 {
        margin-left: 4rem !important
    }

    .ms-xl-10 {
        margin-left: 4.5rem !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -2rem !important
    }

    .m-xl-n6 {
        margin: -2.5rem !important
    }

    .m-xl-n7 {
        margin: -3rem !important
    }

    .m-xl-n8 {
        margin: -3.5rem !important
    }

    .m-xl-n9 {
        margin: -4rem !important
    }

    .m-xl-n10 {
        margin: -4.5rem !important
    }

    .mx-xl-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-xl-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-xl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xl-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-xl-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important
    }

    .mx-xl-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .mx-xl-n8 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-xl-n9 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-xl-n10 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .my-xl-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-xl-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-xl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xl-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-xl-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important
    }

    .my-xl-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .my-xl-n8 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-xl-n9 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-xl-n10 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .mt-xl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xl-n3 {
        margin-top: -1rem !important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xl-n5 {
        margin-top: -2rem !important
    }

    .mt-xl-n6 {
        margin-top: -2.5rem !important
    }

    .mt-xl-n7 {
        margin-top: -3rem !important
    }

    .mt-xl-n8 {
        margin-top: -3.5rem !important
    }

    .mt-xl-n9 {
        margin-top: -4rem !important
    }

    .mt-xl-n10 {
        margin-top: -4.5rem !important
    }

    .me-xl-n1 {
        margin-right: -.25rem !important
    }

    .me-xl-n2 {
        margin-right: -.5rem !important
    }

    .me-xl-n3 {
        margin-right: -1rem !important
    }

    .me-xl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xl-n5 {
        margin-right: -2rem !important
    }

    .me-xl-n6 {
        margin-right: -2.5rem !important
    }

    .me-xl-n7 {
        margin-right: -3rem !important
    }

    .me-xl-n8 {
        margin-right: -3.5rem !important
    }

    .me-xl-n9 {
        margin-right: -4rem !important
    }

    .me-xl-n10 {
        margin-right: -4.5rem !important
    }

    .mb-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xl-n5 {
        margin-bottom: -2rem !important
    }

    .mb-xl-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-xl-n7 {
        margin-bottom: -3rem !important
    }

    .mb-xl-n8 {
        margin-bottom: -3.5rem !important
    }

    .mb-xl-n9 {
        margin-bottom: -4rem !important
    }

    .mb-xl-n10 {
        margin-bottom: -4.5rem !important
    }

    .ms-xl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xl-n3 {
        margin-left: -1rem !important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xl-n5 {
        margin-left: -2rem !important
    }

    .ms-xl-n6 {
        margin-left: -2.5rem !important
    }

    .ms-xl-n7 {
        margin-left: -3rem !important
    }

    .ms-xl-n8 {
        margin-left: -3.5rem !important
    }

    .ms-xl-n9 {
        margin-left: -4rem !important
    }

    .ms-xl-n10 {
        margin-left: -4.5rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 2rem !important
    }

    .p-xl-6 {
        padding: 2.5rem !important
    }

    .p-xl-7 {
        padding: 3rem !important
    }

    .p-xl-8 {
        padding: 3.5rem !important
    }

    .p-xl-9 {
        padding: 4rem !important
    }

    .p-xl-10 {
        padding: 4.5rem !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xl-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-xl-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important
    }

    .px-xl-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .px-xl-8 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-xl-9 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-xl-10 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-xl-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important
    }

    .py-xl-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .py-xl-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-xl-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-xl-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 2rem !important
    }

    .pt-xl-6 {
        padding-top: 2.5rem !important
    }

    .pt-xl-7 {
        padding-top: 3rem !important
    }

    .pt-xl-8 {
        padding-top: 3.5rem !important
    }

    .pt-xl-9 {
        padding-top: 4rem !important
    }

    .pt-xl-10 {
        padding-top: 4.5rem !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 2rem !important
    }

    .pe-xl-6 {
        padding-right: 2.5rem !important
    }

    .pe-xl-7 {
        padding-right: 3rem !important
    }

    .pe-xl-8 {
        padding-right: 3.5rem !important
    }

    .pe-xl-9 {
        padding-right: 4rem !important
    }

    .pe-xl-10 {
        padding-right: 4.5rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 2rem !important
    }

    .pb-xl-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-xl-7 {
        padding-bottom: 3rem !important
    }

    .pb-xl-8 {
        padding-bottom: 3.5rem !important
    }

    .pb-xl-9 {
        padding-bottom: 4rem !important
    }

    .pb-xl-10 {
        padding-bottom: 4.5rem !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 2rem !important
    }

    .ps-xl-6 {
        padding-left: 2.5rem !important
    }

    .ps-xl-7 {
        padding-left: 3rem !important
    }

    .ps-xl-8 {
        padding-left: 3.5rem !important
    }

    .ps-xl-9 {
        padding-left: 4rem !important
    }

    .ps-xl-10 {
        padding-left: 4.5rem !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: 1rem !important
    }

    .gap-xl-4 {
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        gap: 2rem !important
    }

    .gap-xl-6 {
        gap: 2.5rem !important
    }

    .gap-xl-7 {
        gap: 3rem !important
    }

    .gap-xl-8 {
        gap: 3.5rem !important
    }

    .gap-xl-9 {
        gap: 4rem !important
    }

    .gap-xl-10 {
        gap: 4.5rem !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }

    .content-space-t-xl-0 {
        padding-top: 0 !important
    }

    .content-space-t-xl-1 {
        padding-top: 3rem !important
    }

    .content-space-t-xl-2 {
        padding-top: 5rem !important
    }

    .content-space-t-xl-3 {
        padding-top: 7.5rem !important
    }

    .content-space-t-xl-4 {
        padding-top: 10rem !important
    }

    .content-space-t-xl-5 {
        padding-top: 12.5rem !important
    }

    .content-space-t-xl-auto {
        padding-top: auto !important
    }

    .content-space-b-xl-0 {
        padding-bottom: 0 !important
    }

    .content-space-b-xl-1 {
        padding-bottom: 3rem !important
    }

    .content-space-b-xl-2 {
        padding-bottom: 5rem !important
    }

    .content-space-b-xl-3 {
        padding-bottom: 7.5rem !important
    }

    .content-space-b-xl-4 {
        padding-bottom: 10rem !important
    }

    .content-space-b-xl-5 {
        padding-bottom: 12.5rem !important
    }

    .content-space-b-xl-auto {
        padding-bottom: auto !important
    }

    .content-space-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .content-space-xl-1 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .content-space-xl-2 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .content-space-xl-3 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important
    }

    .content-space-xl-4 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important
    }

    .content-space-xl-5 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important
    }

    .content-space-xl-auto {
        padding-top: auto !important;
        padding-bottom: auto !important
    }
}

@media (min-width:1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .position-xxl-static {
        position: static !important
    }

    .position-xxl-relative {
        position: relative !important
    }

    .position-xxl-absolute {
        position: absolute !important
    }

    .position-xxl-fixed {
        position: fixed !important
    }

    .position-xxl-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-xxl-0 {
        top: 0 !important
    }

    .top-xxl-50 {
        top: 50% !important
    }

    .top-xxl-100 {
        top: 100% !important
    }

    .bottom-xxl-0 {
        bottom: 0 !important
    }

    .bottom-xxl-50 {
        bottom: 50% !important
    }

    .bottom-xxl-100 {
        bottom: 100% !important
    }

    .start-xxl-0 {
        left: 0 !important
    }

    .start-xxl-50 {
        left: 50% !important
    }

    .start-xxl-100 {
        left: 100% !important
    }

    .end-xxl-0 {
        right: 0 !important
    }

    .end-xxl-50 {
        right: 50% !important
    }

    .end-xxl-100 {
        right: 100% !important
    }

    .w-xxl-25 {
        width: 25% !important
    }

    .w-xxl-50 {
        width: 50% !important
    }

    .w-xxl-75 {
        width: 75% !important
    }

    .w-xxl-100 {
        width: 100% !important
    }

    .w-xxl-auto {
        width: auto !important
    }

    .w-xxl-70 {
        width: 70% !important
    }

    .w-xxl-85 {
        width: 85% !important
    }

    .h-xxl-25 {
        height: 25% !important
    }

    .h-xxl-50 {
        height: 50% !important
    }

    .h-xxl-75 {
        height: 75% !important
    }

    .h-xxl-100 {
        height: 100% !important
    }

    .h-xxl-auto {
        height: auto !important
    }

    .min-vh-xxl-100 {
        min-height: 100vh !important
    }

    .flex-xxl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xxl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xxl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xxl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xxl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }

    .order-xxl-first {
        -ms-flex-order: -1 !important;
        order: -1 !important
    }

    .order-xxl-0 {
        -ms-flex-order: 0 !important;
        order: 0 !important
    }

    .order-xxl-1 {
        -ms-flex-order: 1 !important;
        order: 1 !important
    }

    .order-xxl-2 {
        -ms-flex-order: 2 !important;
        order: 2 !important
    }

    .order-xxl-3 {
        -ms-flex-order: 3 !important;
        order: 3 !important
    }

    .order-xxl-4 {
        -ms-flex-order: 4 !important;
        order: 4 !important
    }

    .order-xxl-5 {
        -ms-flex-order: 5 !important;
        order: 5 !important
    }

    .order-xxl-last {
        -ms-flex-order: 6 !important;
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 2rem !important
    }

    .m-xxl-6 {
        margin: 2.5rem !important
    }

    .m-xxl-7 {
        margin: 3rem !important
    }

    .m-xxl-8 {
        margin: 3.5rem !important
    }

    .m-xxl-9 {
        margin: 4rem !important
    }

    .m-xxl-10 {
        margin: 4.5rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-xxl-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important
    }

    .mx-xxl-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xxl-8 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-xxl-9 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-xxl-10 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxl-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-xxl-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important
    }

    .my-xxl-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xxl-8 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-xxl-9 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-xxl-10 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 2rem !important
    }

    .mt-xxl-6 {
        margin-top: 2.5rem !important
    }

    .mt-xxl-7 {
        margin-top: 3rem !important
    }

    .mt-xxl-8 {
        margin-top: 3.5rem !important
    }

    .mt-xxl-9 {
        margin-top: 4rem !important
    }

    .mt-xxl-10 {
        margin-top: 4.5rem !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 2rem !important
    }

    .me-xxl-6 {
        margin-right: 2.5rem !important
    }

    .me-xxl-7 {
        margin-right: 3rem !important
    }

    .me-xxl-8 {
        margin-right: 3.5rem !important
    }

    .me-xxl-9 {
        margin-right: 4rem !important
    }

    .me-xxl-10 {
        margin-right: 4.5rem !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 2rem !important
    }

    .mb-xxl-6 {
        margin-bottom: 2.5rem !important
    }

    .mb-xxl-7 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-8 {
        margin-bottom: 3.5rem !important
    }

    .mb-xxl-9 {
        margin-bottom: 4rem !important
    }

    .mb-xxl-10 {
        margin-bottom: 4.5rem !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 2rem !important
    }

    .ms-xxl-6 {
        margin-left: 2.5rem !important
    }

    .ms-xxl-7 {
        margin-left: 3rem !important
    }

    .ms-xxl-8 {
        margin-left: 3.5rem !important
    }

    .ms-xxl-9 {
        margin-left: 4rem !important
    }

    .ms-xxl-10 {
        margin-left: 4.5rem !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .m-xxl-n1 {
        margin: -.25rem !important
    }

    .m-xxl-n2 {
        margin: -.5rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.5rem !important
    }

    .m-xxl-n5 {
        margin: -2rem !important
    }

    .m-xxl-n6 {
        margin: -2.5rem !important
    }

    .m-xxl-n7 {
        margin: -3rem !important
    }

    .m-xxl-n8 {
        margin: -3.5rem !important
    }

    .m-xxl-n9 {
        margin: -4rem !important
    }

    .m-xxl-n10 {
        margin: -4.5rem !important
    }

    .mx-xxl-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-xxl-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-xxl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xxl-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-xxl-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important
    }

    .mx-xxl-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .mx-xxl-n8 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-xxl-n9 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-xxl-n10 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .my-xxl-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-xxl-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-xxl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xxl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xxl-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-xxl-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important
    }

    .my-xxl-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .my-xxl-n8 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-xxl-n9 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-xxl-n10 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .mt-xxl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xxl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xxl-n3 {
        margin-top: -1rem !important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xxl-n5 {
        margin-top: -2rem !important
    }

    .mt-xxl-n6 {
        margin-top: -2.5rem !important
    }

    .mt-xxl-n7 {
        margin-top: -3rem !important
    }

    .mt-xxl-n8 {
        margin-top: -3.5rem !important
    }

    .mt-xxl-n9 {
        margin-top: -4rem !important
    }

    .mt-xxl-n10 {
        margin-top: -4.5rem !important
    }

    .me-xxl-n1 {
        margin-right: -.25rem !important
    }

    .me-xxl-n2 {
        margin-right: -.5rem !important
    }

    .me-xxl-n3 {
        margin-right: -1rem !important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xxl-n5 {
        margin-right: -2rem !important
    }

    .me-xxl-n6 {
        margin-right: -2.5rem !important
    }

    .me-xxl-n7 {
        margin-right: -3rem !important
    }

    .me-xxl-n8 {
        margin-right: -3.5rem !important
    }

    .me-xxl-n9 {
        margin-right: -4rem !important
    }

    .me-xxl-n10 {
        margin-right: -4.5rem !important
    }

    .mb-xxl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xxl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xxl-n5 {
        margin-bottom: -2rem !important
    }

    .mb-xxl-n6 {
        margin-bottom: -2.5rem !important
    }

    .mb-xxl-n7 {
        margin-bottom: -3rem !important
    }

    .mb-xxl-n8 {
        margin-bottom: -3.5rem !important
    }

    .mb-xxl-n9 {
        margin-bottom: -4rem !important
    }

    .mb-xxl-n10 {
        margin-bottom: -4.5rem !important
    }

    .ms-xxl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xxl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xxl-n3 {
        margin-left: -1rem !important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xxl-n5 {
        margin-left: -2rem !important
    }

    .ms-xxl-n6 {
        margin-left: -2.5rem !important
    }

    .ms-xxl-n7 {
        margin-left: -3rem !important
    }

    .ms-xxl-n8 {
        margin-left: -3.5rem !important
    }

    .ms-xxl-n9 {
        margin-left: -4rem !important
    }

    .ms-xxl-n10 {
        margin-left: -4.5rem !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 2rem !important
    }

    .p-xxl-6 {
        padding: 2.5rem !important
    }

    .p-xxl-7 {
        padding: 3rem !important
    }

    .p-xxl-8 {
        padding: 3.5rem !important
    }

    .p-xxl-9 {
        padding: 4rem !important
    }

    .p-xxl-10 {
        padding: 4.5rem !important
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxl-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-xxl-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important
    }

    .px-xxl-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .px-xxl-8 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-xxl-9 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-xxl-10 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxl-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-xxl-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important
    }

    .py-xxl-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .py-xxl-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-xxl-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-xxl-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 2rem !important
    }

    .pt-xxl-6 {
        padding-top: 2.5rem !important
    }

    .pt-xxl-7 {
        padding-top: 3rem !important
    }

    .pt-xxl-8 {
        padding-top: 3.5rem !important
    }

    .pt-xxl-9 {
        padding-top: 4rem !important
    }

    .pt-xxl-10 {
        padding-top: 4.5rem !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 2rem !important
    }

    .pe-xxl-6 {
        padding-right: 2.5rem !important
    }

    .pe-xxl-7 {
        padding-right: 3rem !important
    }

    .pe-xxl-8 {
        padding-right: 3.5rem !important
    }

    .pe-xxl-9 {
        padding-right: 4rem !important
    }

    .pe-xxl-10 {
        padding-right: 4.5rem !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 2rem !important
    }

    .pb-xxl-6 {
        padding-bottom: 2.5rem !important
    }

    .pb-xxl-7 {
        padding-bottom: 3rem !important
    }

    .pb-xxl-8 {
        padding-bottom: 3.5rem !important
    }

    .pb-xxl-9 {
        padding-bottom: 4rem !important
    }

    .pb-xxl-10 {
        padding-bottom: 4.5rem !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 2rem !important
    }

    .ps-xxl-6 {
        padding-left: 2.5rem !important
    }

    .ps-xxl-7 {
        padding-left: 3rem !important
    }

    .ps-xxl-8 {
        padding-left: 3.5rem !important
    }

    .ps-xxl-9 {
        padding-left: 4rem !important
    }

    .ps-xxl-10 {
        padding-left: 4.5rem !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: 1rem !important
    }

    .gap-xxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        gap: 2rem !important
    }

    .gap-xxl-6 {
        gap: 2.5rem !important
    }

    .gap-xxl-7 {
        gap: 3rem !important
    }

    .gap-xxl-8 {
        gap: 3.5rem !important
    }

    .gap-xxl-9 {
        gap: 4rem !important
    }

    .gap-xxl-10 {
        gap: 4.5rem !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }

    .content-space-t-xxl-0 {
        padding-top: 0 !important
    }

    .content-space-t-xxl-1 {
        padding-top: 3rem !important
    }

    .content-space-t-xxl-2 {
        padding-top: 5rem !important
    }

    .content-space-t-xxl-3 {
        padding-top: 7.5rem !important
    }

    .content-space-t-xxl-4 {
        padding-top: 10rem !important
    }

    .content-space-t-xxl-5 {
        padding-top: 12.5rem !important
    }

    .content-space-t-xxl-auto {
        padding-top: auto !important
    }

    .content-space-b-xxl-0 {
        padding-bottom: 0 !important
    }

    .content-space-b-xxl-1 {
        padding-bottom: 3rem !important
    }

    .content-space-b-xxl-2 {
        padding-bottom: 5rem !important
    }

    .content-space-b-xxl-3 {
        padding-bottom: 7.5rem !important
    }

    .content-space-b-xxl-4 {
        padding-bottom: 10rem !important
    }

    .content-space-b-xxl-5 {
        padding-bottom: 12.5rem !important
    }

    .content-space-b-xxl-auto {
        padding-bottom: auto !important
    }

    .content-space-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .content-space-xxl-1 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .content-space-xxl-2 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .content-space-xxl-3 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important
    }

    .content-space-xxl-4 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important
    }

    .content-space-xxl-5 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important
    }

    .content-space-xxl-auto {
        padding-top: auto !important;
        padding-bottom: auto !important
    }
}

@media (min-width:1200px) {
    .fs-1 {
        font-size: 1.4109375rem !important
    }

    .fs-2 {
        font-size: 1.3125rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-print-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

html {
    position: relative;
    min-height: 100%
}

a {
    text-decoration: none
}

:focus,
a:focus,
button:focus {
    outline-color: rgba(55, 125, 255, .5)
}

figure {
    margin-bottom: 0
}

dl {
    margin-bottom: 0
}

dt {
    color: #677788
}

dd {
    color: #1e2022;
    font-weight: 600;
    margin-bottom: .75rem
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type=number] {
    -moz-appearance: textfield
}

::-moz-selection {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

::selection {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.bg-primary ::-moz-selection {
    color: #fff;
    background-color: rgba(255, 255, 255, .1)
}

.bg-primary ::selection {
    color: #fff;
    background-color: rgba(255, 255, 255, .1)
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 15px, 0);
        transform: translate3d(0, 15px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 15px, 0);
        transform: translate3d(0, 15px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight
}

.accordion-button:focus {
    box-shadow: none
}

.accordion-button:not(.collapsed) {
    box-shadow: none
}

.accordion-button {
    color: #132144;
    font-size: .875rem;
    font-weight: 600;
    padding: .75rem 1.25rem
}

@media (max-width:575.98px) {
    .accordion-button {
        padding: .5rem .8333333333rem
    }
}

.accordion-body {
    padding-top: 0
}

@media (max-width:575.98px) {

    .accordion-body,
    .accordion-button {
        padding: .5rem .8333333333rem
    }
}

.alert-primary {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff
}

.alert-primary .alert-link {
    color: #fff
}

.alert-secondary {
    color: #fff;
    background-color: #71869d;
    border-color: #71869d
}

.alert-secondary .alert-link {
    color: #fff
}

.alert-success {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7
}

.alert-success .alert-link {
    color: #fff
}

.alert-info {
    color: #fff;
    background-color: #09a5be;
    border-color: #09a5be
}

.alert-info .alert-link {
    color: #fff
}

.alert-warning {
    color: #000;
    background-color: #f5ca99;
    border-color: #f5ca99
}

.alert-warning .alert-link {
    color: #000
}

.alert-danger {
    color: #fff;
    background-color: #ed4c78;
    border-color: #ed4c78
}

.alert-danger .alert-link {
    color: #fff
}

.alert-light {
    color: #000;
    background-color: #f9fafc;
    border-color: #f9fafc
}

.alert-light .alert-link {
    color: #000
}

.alert-dark {
    color: #fff;
    background-color: #132144;
    border-color: #132144
}

.alert-dark .alert-link {
    color: #fff
}

.alert-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, .15)
}

.alert-soft-primary .alert-link {
    color: #377dff
}

.alert-soft-primary .alert-link:hover {
    color: #1366ff
}

.alert-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, .15)
}

.alert-soft-secondary .alert-link {
    color: #71869d
}

.alert-soft-secondary .alert-link:hover {
    color: #60748b
}

.alert-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .15)
}

.alert-soft-success .alert-link {
    color: #00c9a7
}

.alert-soft-success .alert-link:hover {
    color: #00a589
}

.alert-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, .15)
}

.alert-soft-info .alert-link {
    color: #09a5be
}

.alert-soft-info .alert-link:hover {
    color: #07879c
}

.alert-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, .15)
}

.alert-soft-warning .alert-link {
    color: #f5ca99
}

.alert-soft-warning .alert-link:hover {
    color: #f2b978
}

.alert-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, .15)
}

.alert-soft-danger .alert-link {
    color: #ed4c78
}

.alert-soft-danger .alert-link:hover {
    color: #ea2c60
}

.alert-soft-light {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, .15)
}

.alert-soft-light .alert-link {
    color: #f9fafc
}

.alert-soft-light .alert-link:hover {
    color: #e1e6f0
}

.alert-soft-dark {
    color: #132144;
    background-color: rgba(19, 33, 68, .15)
}

.alert-soft-dark .alert-link {
    color: #132144
}

.alert-soft-dark .alert-link:hover {
    color: #0b1328
}

.avatar {
    position: relative;
    display: inline-block;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: .5rem
}

.avatar:not(img) {
    background-color: #fff
}

.avatar-img {
    max-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: .5rem
}

.avatar-initials {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 2.625rem;
    height: 2.625rem;
    font-size: .875rem;
    font-weight: 600;
    pointer-events: none;
    text-transform: uppercase;
    border-radius: .5rem
}

.avatar-circle {
    border-radius: 50%
}

.avatar-circle .avatar,
.avatar-circle .avatar-img,
.avatar-circle .avatar-initials {
    border-radius: 50%
}

.avatar-centered {
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto
}

.avatar-group {
    position: relative;
    z-index: 2;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center
}

.avatar-group .avatar:hover {
    z-index: 2
}

.avatar-group .avatar-circle .avatar-initials {
    border-radius: 50%
}

.avatar-group .avatar .avatar-img,
.avatar-group .avatar .avatar-initials,
.avatar-group .avatar-sm .avatar-img,
.avatar-group .avatar-sm .avatar-initials,
.avatar-group .avatar-xs .avatar-img,
.avatar-group .avatar-xs .avatar-initials {
    border: 2px solid #fff
}

.avatar-group .avatar-lg .avatar-img,
.avatar-group .avatar-lg .avatar-initials {
    border: 5px solid #fff
}

.avatar-group .avatar-xl .avatar-img,
.avatar-group .avatar-xl .avatar-initials {
    border: 7px solid #fff
}

.avatar-group .avatar+.avatar {
    margin-left: -1rem
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -.6125rem
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -.875rem
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -1.5rem
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -2rem
}

.avatar-group .avatar,
.avatar-group .avatar-initials {
    width: 2.625rem;
    height: 2.625rem
}

.avatar-group .avatar-initials {
    font-size: .875rem
}

.avatar-group .avatar+.avatar {
    margin-left: -1rem
}

.avatar-group-xs .avatar,
.avatar-group-xs .avatar-initials {
    width: 1.53125rem;
    height: 1.53125rem
}

.avatar-group-xs .avatar-initials {
    font-size: .65625rem
}

.avatar-group-xs .avatar+.avatar {
    margin-left: -.6125rem
}

.avatar-group-sm .avatar,
.avatar-group-sm .avatar-initials {
    width: 2.40625rem;
    height: 2.40625rem
}

.avatar-group-sm .avatar-initials {
    font-size: .875rem
}

.avatar-group-sm .avatar+.avatar {
    margin-left: -.875rem
}

.avatar-group-lg .avatar,
.avatar-group-lg .avatar-initials {
    width: 3.36875rem;
    height: 3.36875rem
}

.avatar-group-lg .avatar-initials {
    font-size: 1rem
}

.avatar-group-lg .avatar+.avatar {
    margin-left: -1.5rem
}

.avatar-group-xl .avatar,
.avatar-group-xl .avatar-initials {
    width: 4.921875rem;
    height: 4.921875rem
}

.avatar-group-xl .avatar-initials {
    font-size: 1.75rem
}

.avatar-group-xl .avatar+.avatar {
    margin-left: -1.5rem
}

.avatar-group-xxl .avatar,
.avatar-group-xxl .avatar-initials {
    width: 7.875rem;
    height: 7.875rem
}

.avatar-group-xxl .avatar-initials {
    font-size: 2.625rem
}

.avatar-group-xxl .avatar+.avatar {
    margin-left: -2rem
}

.avatar.avatar-circle .avatar-status {
    bottom: -.19140625rem;
    right: -.19140625rem
}

.avatar.avatar-circle .avatar-sm-status {
    bottom: 0;
    right: 0
}

.avatar.avatar-circle .avatar-lg-status {
    bottom: -.284375rem;
    right: -.284375rem
}

.avatar-xss,
.avatar-xss .avatar-initials {
    width: 1rem;
    height: 1rem
}

.avatar-xss .avatar-img {
    width: 1rem
}

.avatar-xss .avatar-initials {
    font-size: .65625rem
}

.avatar-xs,
.avatar-xs .avatar-initials {
    width: 1.53125rem;
    height: 1.53125rem
}

.avatar-xs .avatar-img {
    width: 1.53125rem
}

.avatar-xs .avatar-initials {
    font-size: .65625rem
}

.avatar-xs.avatar-circle .avatar-status {
    bottom: -.19140625rem;
    right: -.19140625rem
}

.avatar-xs.avatar-circle .avatar-sm-status {
    bottom: -.1025390625rem;
    right: -.1025390625rem
}

.avatar-xs.avatar-circle .avatar-lg-status {
    bottom: -.284375rem;
    right: -.284375rem
}

.avatar-xs,
.avatar-xs .avatar-initials {
    width: 1.53125rem;
    height: 1.53125rem
}

.avatar-xs .avatar-img {
    width: 1.53125rem
}

.avatar-xs .avatar-initials {
    font-size: .65625rem
}

.avatar-xs.avatar-circle .avatar-status {
    bottom: -.19140625rem;
    right: -.19140625rem
}

.avatar-xs.avatar-circle .avatar-sm-status {
    bottom: -.1025390625rem;
    right: -.1025390625rem
}

.avatar-xs.avatar-circle .avatar-lg-status {
    bottom: -.284375rem;
    right: -.284375rem
}

.avatar-sm,
.avatar-sm .avatar-initials {
    width: 2.40625rem;
    height: 2.40625rem
}

.avatar-sm .avatar-img {
    width: 2.40625rem
}

.avatar-sm .avatar-initials {
    font-size: .875rem
}

.avatar-sm.avatar-circle .avatar-status {
    bottom: -.19140625rem;
    right: -.19140625rem
}

.avatar-sm.avatar-circle .avatar-sm-status {
    bottom: -.08203125rem;
    right: -.08203125rem
}

.avatar-sm.avatar-circle .avatar-lg-status {
    bottom: -.35546875rem;
    right: -.35546875rem
}

.avatar-lg,
.avatar-lg .avatar-initials {
    width: 3.36875rem;
    height: 3.36875rem
}

.avatar-lg .avatar-img {
    width: 3.36875rem
}

.avatar-lg .avatar-initials {
    font-size: 1rem
}

.avatar-lg.avatar-circle .avatar-status {
    bottom: -.11484375rem;
    right: -.11484375rem
}

.avatar-lg.avatar-circle .avatar-sm-status {
    bottom: .08203125rem;
    right: .08203125rem
}

.avatar-lg.avatar-circle .avatar-lg-status {
    bottom: -.177734375rem;
    right: -.177734375rem
}

.avatar-xl,
.avatar-xl .avatar-initials {
    width: 4.921875rem;
    height: 4.921875rem
}

.avatar-xl .avatar-img {
    width: 4.921875rem
}

.avatar-xl .avatar-initials {
    font-size: 1.75rem
}

.avatar-xl.avatar-circle .avatar-status {
    bottom: .1435546875rem;
    right: .1435546875rem
}

.avatar-xl.avatar-circle .avatar-sm-status {
    bottom: .205078125rem;
    right: .205078125rem
}

.avatar-xl.avatar-circle .avatar-lg-status {
    bottom: .1184895833rem;
    right: .1184895833rem
}

.avatar-xxl,
.avatar-xxl .avatar-initials {
    width: 7.875rem;
    height: 7.875rem
}

.avatar-xxl .avatar-img {
    width: 7.875rem
}

.avatar-xxl .avatar-initials {
    font-size: 2.625rem
}

.avatar-xxl.avatar-circle .avatar-status {
    bottom: .65625rem;
    right: .65625rem
}

.avatar-xxl.avatar-circle .avatar-sm-status {
    bottom: .765625rem;
    right: .765625rem
}

.avatar-xxl.avatar-circle .avatar-lg-status {
    bottom: .56875rem;
    right: .56875rem
}

.avatar-status {
    position: absolute;
    bottom: -.459375rem;
    right: -.459375rem;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    vertical-align: baseline;
    border: .125rem solid #fff;
    width: 1.1484375rem;
    height: 1.1484375rem;
    line-height: 1;
    font-size: .65625rem;
    border-radius: 50%
}

.avatar-sm-status {
    bottom: -.2296875rem;
    right: -.2296875rem;
    width: .8203125rem;
    height: .8203125rem;
    font-size: .4375rem
}

.avatar-lg-status {
    width: 1.421875rem;
    height: 1.421875rem;
    font-size: .65625rem
}

.avatar-primary .avatar-initials {
    color: #fff;
    background-color: #377dff
}

.avatar-status-primary {
    color: #fff;
    background-color: #377dff
}

.avatar-secondary .avatar-initials {
    color: #fff;
    background-color: #71869d
}

.avatar-status-secondary {
    color: #fff;
    background-color: #71869d
}

.avatar-success .avatar-initials {
    color: #fff;
    background-color: #00c9a7
}

.avatar-status-success {
    color: #fff;
    background-color: #00c9a7
}

.avatar-info .avatar-initials {
    color: #fff;
    background-color: #09a5be
}

.avatar-status-info {
    color: #fff;
    background-color: #09a5be
}

.avatar-warning .avatar-initials {
    color: #000;
    background-color: #f5ca99
}

.avatar-status-warning {
    color: #000;
    background-color: #f5ca99
}

.avatar-danger .avatar-initials {
    color: #fff;
    background-color: #ed4c78
}

.avatar-status-danger {
    color: #fff;
    background-color: #ed4c78
}

.avatar-light .avatar-initials {
    color: #000;
    background-color: #f9fafc
}

.avatar-status-light {
    color: #000;
    background-color: #f9fafc
}

.avatar-dark .avatar-initials {
    color: #fff;
    background-color: #132144
}

.avatar-status-dark {
    color: #fff;
    background-color: #132144
}

.avatar-soft-primary .avatar-initials {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.avatar-status-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.avatar-soft-secondary .avatar-initials {
    color: #71869d;
    background-color: rgba(113, 134, 157, .1)
}

.avatar-status-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, .1)
}

.avatar-soft-success .avatar-initials {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .1)
}

.avatar-status-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .1)
}

.avatar-soft-info .avatar-initials {
    color: #09a5be;
    background-color: rgba(9, 165, 190, .1)
}

.avatar-status-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, .1)
}

.avatar-soft-warning .avatar-initials {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, .1)
}

.avatar-status-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, .1)
}

.avatar-soft-danger .avatar-initials {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, .1)
}

.avatar-status-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, .1)
}

.avatar-soft-light .avatar-initials {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, .1)
}

.avatar-status-soft-light {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, .1)
}

.avatar-soft-dark .avatar-initials {
    color: #132144;
    background-color: rgba(19, 33, 68, .1)
}

.avatar-status-soft-dark {
    color: #132144;
    background-color: rgba(19, 33, 68, .1)
}

.avatar.avatar-4x3 {
    width: 3.5rem;
    height: auto;
    border-radius: 0
}

.avatar.avatar-4x3 .avatar-img {
    width: 3.5rem;
    height: inherit
}

.avatar-xss.avatar-4x3 {
    width: 1.3333333333rem;
    height: auto;
    border-radius: 0
}

.avatar-xss.avatar-4x3 .avatar-img {
    width: 1.3333333333rem;
    height: inherit
}

.avatar-xs.avatar-4x3 {
    width: 2.0416666667rem;
    height: auto;
    border-radius: 0
}

.avatar-xs.avatar-4x3 .avatar-img {
    width: 2.0416666667rem;
    height: inherit
}

.avatar-sm.avatar-4x3 {
    width: 3.2083333333rem;
    height: auto;
    border-radius: 0
}

.avatar-sm.avatar-4x3 .avatar-img {
    width: 3.2083333333rem;
    height: inherit
}

.avatar-lg.avatar-4x3 {
    width: 4.4916666667rem;
    height: auto;
    border-radius: 0
}

.avatar-lg.avatar-4x3 .avatar-img {
    width: 4.4916666667rem;
    height: inherit
}

.avatar-xl.avatar-4x3 {
    width: 6.5625rem;
    height: auto;
    border-radius: 0
}

.avatar-xl.avatar-4x3 .avatar-img {
    width: 6.5625rem;
    height: inherit
}

.avatar-xxl.avatar-4x3 {
    width: 10.5rem;
    height: auto;
    border-radius: 0
}

.avatar-xxl.avatar-4x3 .avatar-img {
    width: 10.5rem;
    height: inherit
}

.avatar-uploader {
    cursor: pointer;
    display: inline-block;
    transition: .2s;
    margin-bottom: 0
}

.avatar-uploader-input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 33, 68, .25);
    border-radius: 50%;
    transition: .2s
}

.avatar-uploader-trigger {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 50%
}

.avatar-uploader-icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: #677788;
    background-color: #fff;
    border-radius: 50%;
    transition: .2s
}

.avatar-uploader:hover .avatar-uploader-icon {
    color: #fff;
    background-color: #377dff
}

.avatar-lg .avatar-uploader-icon {
    font-size: .65625rem;
    width: 1.3125rem;
    height: 1.3125rem
}

.avatar-xl .avatar-uploader-icon {
    font-size: .8125rem;
    width: 1.53125rem;
    height: 1.53125rem
}

.avatar-xxl .avatar-uploader-icon {
    width: 2.40625rem;
    height: 2.40625rem
}

.badge {
    line-height: normal
}

.badge .legend-indicator {
    margin-right: .3125rem
}

.blockquote {
    color: #677788;
    font-size: 1.25rem;
    border-left: .1875rem solid rgba(231, 234, 243, .7);
    padding-left: .75rem;
    margin-bottom: 0
}

.blockquote-sm {
    font-size: .875rem;
    padding-left: .6125rem
}

.blockquote-footer {
    margin-top: 1.5rem
}

.breadcrumb .breadcrumb-item {
    color: #1e2022
}

.breadcrumb .breadcrumb-link {
    color: #8c98a4
}

.breadcrumb .breadcrumb-link:hover {
    color: #1366ff
}

.btn:hover {
    border-color: transparent
}

.btn-link {
    font-weight: 600
}

.btn-link:focus {
    box-shadow: none
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
    border-color: transparent
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    border-color: transparent
}

.btn.dropdown-toggle {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
    box-shadow: 0 4px 11px rgba(55, 125, 255, .35)
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
    box-shadow: 0 4px 11px rgba(113, 134, 157, .35)
}

.btn-check:focus+.btn-success,
.btn-success:focus,
.btn-success:hover {
    box-shadow: 0 4px 11px rgba(0, 201, 167, .35)
}

.btn-check:focus+.btn-info,
.btn-info:focus,
.btn-info:hover {
    box-shadow: 0 4px 11px rgba(9, 165, 190, .35)
}

.btn-check:focus+.btn-warning,
.btn-warning:focus,
.btn-warning:hover {
    box-shadow: 0 4px 11px rgba(245, 202, 153, .35)
}

.btn-check:focus+.btn-danger,
.btn-danger:focus,
.btn-danger:hover {
    box-shadow: 0 4px 11px rgba(237, 76, 120, .35)
}

.btn-check:focus+.btn-light,
.btn-light:focus,
.btn-light:hover {
    box-shadow: 0 4px 11px rgba(249, 250, 252, .35)
}

.btn-check:focus+.btn-dark,
.btn-dark:focus,
.btn-dark:hover {
    box-shadow: 0 4px 11px rgba(19, 33, 68, .35)
}

.btn-toggle .btn-toggle-default {
    display: inline-block
}

.btn-toggle .btn-toggle-toggled {
    display: none
}

.btn-toggle.toggled .btn-toggle-default {
    display: none
}

.btn-toggle.toggled .btn-toggle-toggled {
    display: inline-block
}

.btn-white {
    background-color: #fff;
    border: .0625rem solid rgba(231, 234, 243, .7)
}

.btn-white.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-white,
.btn-white:focus,
.btn-white:hover {
    color: #1366ff;
    border-color: rgba(231, 234, 243, .7);
    background-color: #fff;
    box-shadow: 0 3px 6px -2px rgba(140, 152, 164, .25)
}

.btn-check:focus+.btn-white.dropdown-toggle::after,
.btn-white:focus.dropdown-toggle::after,
.btn-white:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-white.disabled,
.btn-white:disabled {
    color: #bdc5d1;
    background-color: rgba(231, 234, 243, .5)
}

.btn-primary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-secondary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-success.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-info.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-warning.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-danger.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-light.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-dark.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-ghost-primary {
    color: #377dff;
    background-color: transparent
}

.btn-check:active+.btn-ghost-primary,
.btn-check:checked+.btn-ghost-primary,
.btn-check:focus+.btn-ghost-primary,
.btn-ghost-primary.active,
.btn-ghost-primary:active,
.btn-ghost-primary:focus,
.btn-ghost-primary:hover,
.show>.btn-ghost-primary.dropdown-toggle {
    color: #377dff;
    border-color: transparent;
    background-color: rgba(55, 125, 255, .1)
}

.btn-ghost-primary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-ghost-primary.btn-no-focus,
.btn-ghost-primary.btn-no-focus.focus,
.btn-ghost-primary.btn-no-focus:focus,
.btn-ghost-primary.btn-no-focus:hover {
    color: #377dff;
    background-color: unset;
    box-shadow: none
}

.btn-check:focus+.btn-ghost-primary.btn-no-focus:hover,
.btn-ghost-primary.btn-no-focus.focus:hover,
.btn-ghost-primary.btn-no-focus:focus:hover,
.btn-ghost-primary.btn-no-focus:hover:hover {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.btn-check:focus+.btn-ghost-primary.btn-no-focus.btn-transition,
.btn-ghost-primary.btn-no-focus.btn-transition.focus,
.btn-ghost-primary.btn-no-focus.btn-transition:focus,
.btn-ghost-primary.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-ghost-secondary {
    color: #71869d;
    background-color: transparent
}

.btn-check:active+.btn-ghost-secondary,
.btn-check:checked+.btn-ghost-secondary,
.btn-check:focus+.btn-ghost-secondary,
.btn-ghost-secondary.active,
.btn-ghost-secondary:active,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover,
.show>.btn-ghost-secondary.dropdown-toggle {
    color: #71869d;
    border-color: transparent;
    background-color: rgba(55, 125, 255, .1)
}

.btn-ghost-secondary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-ghost-secondary.btn-no-focus,
.btn-ghost-secondary.btn-no-focus.focus,
.btn-ghost-secondary.btn-no-focus:focus,
.btn-ghost-secondary.btn-no-focus:hover {
    color: #71869d;
    background-color: unset;
    box-shadow: none
}

.btn-check:focus+.btn-ghost-secondary.btn-no-focus:hover,
.btn-ghost-secondary.btn-no-focus.focus:hover,
.btn-ghost-secondary.btn-no-focus:focus:hover,
.btn-ghost-secondary.btn-no-focus:hover:hover {
    color: #71869d;
    background-color: rgba(113, 134, 157, .1)
}

.btn-check:focus+.btn-ghost-secondary.btn-no-focus.btn-transition,
.btn-ghost-secondary.btn-no-focus.btn-transition.focus,
.btn-ghost-secondary.btn-no-focus.btn-transition:focus,
.btn-ghost-secondary.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-ghost-success {
    color: #00c9a7;
    background-color: transparent
}

.btn-check:active+.btn-ghost-success,
.btn-check:checked+.btn-ghost-success,
.btn-check:focus+.btn-ghost-success,
.btn-ghost-success.active,
.btn-ghost-success:active,
.btn-ghost-success:focus,
.btn-ghost-success:hover,
.show>.btn-ghost-success.dropdown-toggle {
    color: #00c9a7;
    border-color: transparent;
    background-color: rgba(0, 201, 167, .1)
}

.btn-ghost-success.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-ghost-success.btn-no-focus,
.btn-ghost-success.btn-no-focus.focus,
.btn-ghost-success.btn-no-focus:focus,
.btn-ghost-success.btn-no-focus:hover {
    color: #00c9a7;
    background-color: unset;
    box-shadow: none
}

.btn-check:focus+.btn-ghost-success.btn-no-focus:hover,
.btn-ghost-success.btn-no-focus.focus:hover,
.btn-ghost-success.btn-no-focus:focus:hover,
.btn-ghost-success.btn-no-focus:hover:hover {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .1)
}

.btn-check:focus+.btn-ghost-success.btn-no-focus.btn-transition,
.btn-ghost-success.btn-no-focus.btn-transition.focus,
.btn-ghost-success.btn-no-focus.btn-transition:focus,
.btn-ghost-success.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-ghost-info {
    color: #09a5be;
    background-color: transparent
}

.btn-check:active+.btn-ghost-info,
.btn-check:checked+.btn-ghost-info,
.btn-check:focus+.btn-ghost-info,
.btn-ghost-info.active,
.btn-ghost-info:active,
.btn-ghost-info:focus,
.btn-ghost-info:hover,
.show>.btn-ghost-info.dropdown-toggle {
    color: #09a5be;
    border-color: transparent;
    background-color: rgba(9, 165, 190, .1)
}

.btn-ghost-info.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-ghost-info.btn-no-focus,
.btn-ghost-info.btn-no-focus.focus,
.btn-ghost-info.btn-no-focus:focus,
.btn-ghost-info.btn-no-focus:hover {
    color: #09a5be;
    background-color: unset;
    box-shadow: none
}

.btn-check:focus+.btn-ghost-info.btn-no-focus:hover,
.btn-ghost-info.btn-no-focus.focus:hover,
.btn-ghost-info.btn-no-focus:focus:hover,
.btn-ghost-info.btn-no-focus:hover:hover {
    color: #09a5be;
    background-color: rgba(9, 165, 190, .1)
}

.btn-check:focus+.btn-ghost-info.btn-no-focus.btn-transition,
.btn-ghost-info.btn-no-focus.btn-transition.focus,
.btn-ghost-info.btn-no-focus.btn-transition:focus,
.btn-ghost-info.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-ghost-warning {
    color: #f5ca99;
    background-color: transparent
}

.btn-check:active+.btn-ghost-warning,
.btn-check:checked+.btn-ghost-warning,
.btn-check:focus+.btn-ghost-warning,
.btn-ghost-warning.active,
.btn-ghost-warning:active,
.btn-ghost-warning:focus,
.btn-ghost-warning:hover,
.show>.btn-ghost-warning.dropdown-toggle {
    color: #f5ca99;
    border-color: transparent;
    background-color: rgba(245, 202, 153, .1)
}

.btn-ghost-warning.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-ghost-warning.btn-no-focus,
.btn-ghost-warning.btn-no-focus.focus,
.btn-ghost-warning.btn-no-focus:focus,
.btn-ghost-warning.btn-no-focus:hover {
    color: #f5ca99;
    background-color: unset;
    box-shadow: none
}

.btn-check:focus+.btn-ghost-warning.btn-no-focus:hover,
.btn-ghost-warning.btn-no-focus.focus:hover,
.btn-ghost-warning.btn-no-focus:focus:hover,
.btn-ghost-warning.btn-no-focus:hover:hover {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, .1)
}

.btn-check:focus+.btn-ghost-warning.btn-no-focus.btn-transition,
.btn-ghost-warning.btn-no-focus.btn-transition.focus,
.btn-ghost-warning.btn-no-focus.btn-transition:focus,
.btn-ghost-warning.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-ghost-danger {
    color: #ed4c78;
    background-color: transparent
}

.btn-check:active+.btn-ghost-danger,
.btn-check:checked+.btn-ghost-danger,
.btn-check:focus+.btn-ghost-danger,
.btn-ghost-danger.active,
.btn-ghost-danger:active,
.btn-ghost-danger:focus,
.btn-ghost-danger:hover,
.show>.btn-ghost-danger.dropdown-toggle {
    color: #ed4c78;
    border-color: transparent;
    background-color: rgba(237, 76, 120, .1)
}

.btn-ghost-danger.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-ghost-danger.btn-no-focus,
.btn-ghost-danger.btn-no-focus.focus,
.btn-ghost-danger.btn-no-focus:focus,
.btn-ghost-danger.btn-no-focus:hover {
    color: #ed4c78;
    background-color: unset;
    box-shadow: none
}

.btn-check:focus+.btn-ghost-danger.btn-no-focus:hover,
.btn-ghost-danger.btn-no-focus.focus:hover,
.btn-ghost-danger.btn-no-focus:focus:hover,
.btn-ghost-danger.btn-no-focus:hover:hover {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, .1)
}

.btn-check:focus+.btn-ghost-danger.btn-no-focus.btn-transition,
.btn-ghost-danger.btn-no-focus.btn-transition.focus,
.btn-ghost-danger.btn-no-focus.btn-transition:focus,
.btn-ghost-danger.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-ghost-light {
    color: #f9fafc;
    background-color: transparent
}

.btn-check:active+.btn-ghost-light,
.btn-check:checked+.btn-ghost-light,
.btn-check:focus+.btn-ghost-light,
.btn-ghost-light.active,
.btn-ghost-light:active,
.btn-ghost-light:focus,
.btn-ghost-light:hover,
.show>.btn-ghost-light.dropdown-toggle {
    color: #f9fafc;
    border-color: transparent;
    background-color: rgba(249, 250, 252, .1)
}

.btn-ghost-light.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-ghost-light.btn-no-focus,
.btn-ghost-light.btn-no-focus.focus,
.btn-ghost-light.btn-no-focus:focus,
.btn-ghost-light.btn-no-focus:hover {
    color: #f9fafc;
    background-color: unset;
    box-shadow: none
}

.btn-check:focus+.btn-ghost-light.btn-no-focus:hover,
.btn-ghost-light.btn-no-focus.focus:hover,
.btn-ghost-light.btn-no-focus:focus:hover,
.btn-ghost-light.btn-no-focus:hover:hover {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, .1)
}

.btn-check:focus+.btn-ghost-light.btn-no-focus.btn-transition,
.btn-ghost-light.btn-no-focus.btn-transition.focus,
.btn-ghost-light.btn-no-focus.btn-transition:focus,
.btn-ghost-light.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-ghost-dark {
    color: #132144;
    background-color: transparent
}

.btn-check:active+.btn-ghost-dark,
.btn-check:checked+.btn-ghost-dark,
.btn-check:focus+.btn-ghost-dark,
.btn-ghost-dark.active,
.btn-ghost-dark:active,
.btn-ghost-dark:focus,
.btn-ghost-dark:hover,
.show>.btn-ghost-dark.dropdown-toggle {
    color: #132144;
    border-color: transparent;
    background-color: rgba(19, 33, 68, .1)
}

.btn-ghost-dark.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-ghost-dark.btn-no-focus,
.btn-ghost-dark.btn-no-focus.focus,
.btn-ghost-dark.btn-no-focus:focus,
.btn-ghost-dark.btn-no-focus:hover {
    color: #132144;
    background-color: unset;
    box-shadow: none
}

.btn-check:focus+.btn-ghost-dark.btn-no-focus:hover,
.btn-ghost-dark.btn-no-focus.focus:hover,
.btn-ghost-dark.btn-no-focus:focus:hover,
.btn-ghost-dark.btn-no-focus:hover:hover {
    color: #132144;
    background-color: rgba(19, 33, 68, .1)
}

.btn-check:focus+.btn-ghost-dark.btn-no-focus.btn-transition,
.btn-ghost-dark.btn-no-focus.btn-transition.focus,
.btn-ghost-dark.btn-no-focus.btn-transition:focus,
.btn-ghost-dark.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-check:focus+.btn-ghost-secondary,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
    color: #377dff
}

.btn-check:focus+.btn-ghost-secondary.dropdown-toggle::after,
.btn-ghost-secondary:focus.dropdown-toggle::after,
.btn-ghost-secondary:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:focus+.btn-ghost-secondary.btn-no-focus:hover,
.btn-ghost-secondary.btn-no-focus.focus:hover,
.btn-ghost-secondary.btn-no-focus:focus:hover,
.btn-ghost-secondary.btn-no-focus:hover:hover {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.btn-check:focus+.btn-ghost-secondary.btn-no-focus.btn-transition,
.btn-ghost-secondary.btn-no-focus.btn-transition.focus,
.btn-ghost-secondary.btn-no-focus.btn-transition:focus,
.btn-ghost-secondary.btn-no-focus.btn-transition:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.btn-group-segment {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #f8fafd;
    border-radius: .5rem;
    padding: .25rem .25rem
}

.btn-group-segment .btn {
    color: #677788
}

.btn-group-segment .btn:not(:last-child) {
    margin-right: .25rem
}

.btn-group-segment .btn:hover {
    color: #1366ff
}

.btn-group-segment>.btn-group:not(:first-child)>.btn,
.btn-group-segment>.btn-group:not(:last-child)>.btn,
.btn-group-segment>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment>.btn:nth-child(n+3),
.btn-group-segment>:not(.btn-check)+.btn {
    border-radius: .5rem
}

.btn-group-segment>.btn-check:checked+.btn,
.btn-group-segment>.btn-check:focus+.btn {
    color: #1e2022;
    background-color: #fff;
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075)
}

.btn-group-segment.btn-group-pills {
    border-radius: 50rem
}

.btn-group-segment.btn-group-pills>.btn-group:not(:first-child)>.btn,
.btn-group-segment.btn-group-pills>.btn-group:not(:last-child)>.btn,
.btn-group-segment.btn-group-pills>.btn:not(:first-child),
.btn-group-segment.btn-group-pills>.btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 50rem
}

.btn-group-fill {
    display: -ms-flexbox;
    display: flex
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.btn-group-vertical>.btn-group>.btn {
    width: 100%;
    display: block
}

.btn-group-vertical>.btn-group>.btn-check:not(:first-child)+.btn {
    margin-top: -.0625rem
}

.btn-group-vertical .btn-group>.btn-group:not(:first-child),
.btn-group-vertical .btn-group>.btn:not(:first-child) {
    margin-left: 0
}

.btn-group-vertical>.btn-group>.btn-check+.btn {
    border-radius: 0
}

.btn-group-vertical>.btn-group>.btn-check:first-child+.btn {
    border-top-left-radius: .3125rem;
    border-top-right-radius: .3125rem
}

.btn-group-vertical>.btn-group>.btn:last-child {
    border-bottom-right-radius: .3125rem;
    border-bottom-left-radius: .3125rem
}

.btn-group-vertical>.btn-group-segment>.btn-check+.btn {
    border-radius: .3125rem
}

@media (max-width:575.98px) {

    .btn-group-sm-vertical>.btn,
    .btn-group-sm-vertical>.btn-group,
    .btn-group-sm-vertical>.btn-group>.btn {
        width: 100%;
        display: block
    }

    .btn-group-sm-vertical>.btn-group>.btn-check:not(:first-child)+.btn {
        margin-top: -.0625rem
    }

    .btn-group-sm-vertical .btn-group>.btn-group:not(:first-child),
    .btn-group-sm-vertical .btn-group>.btn:not(:first-child) {
        margin-left: 0
    }

    .btn-group-sm-vertical>.btn-group>.btn-check+.btn {
        border-radius: 0
    }

    .btn-group-sm-vertical>.btn-group>.btn-check:first-child+.btn {
        border-top-left-radius: .3125rem;
        border-top-right-radius: .3125rem
    }

    .btn-group-sm-vertical>.btn-group>.btn:last-child {
        border-bottom-right-radius: .3125rem;
        border-bottom-left-radius: .3125rem
    }

    .btn-group-sm-vertical>.btn-group-segment>.btn-check+.btn {
        border-radius: .3125rem
    }
}

@media (max-width:767.98px) {

    .btn-group-md-vertical>.btn,
    .btn-group-md-vertical>.btn-group,
    .btn-group-md-vertical>.btn-group>.btn {
        width: 100%;
        display: block
    }

    .btn-group-md-vertical>.btn-group>.btn-check:not(:first-child)+.btn {
        margin-top: -.0625rem
    }

    .btn-group-md-vertical .btn-group>.btn-group:not(:first-child),
    .btn-group-md-vertical .btn-group>.btn:not(:first-child) {
        margin-left: 0
    }

    .btn-group-md-vertical>.btn-group>.btn-check+.btn {
        border-radius: 0
    }

    .btn-group-md-vertical>.btn-group>.btn-check:first-child+.btn {
        border-top-left-radius: .3125rem;
        border-top-right-radius: .3125rem
    }

    .btn-group-md-vertical>.btn-group>.btn:last-child {
        border-bottom-right-radius: .3125rem;
        border-bottom-left-radius: .3125rem
    }

    .btn-group-md-vertical>.btn-group-segment>.btn-check+.btn {
        border-radius: .3125rem
    }
}

@media (max-width:991.98px) {

    .btn-group-lg-vertical>.btn,
    .btn-group-lg-vertical>.btn-group,
    .btn-group-lg-vertical>.btn-group>.btn {
        width: 100%;
        display: block
    }

    .btn-group-lg-vertical>.btn-group>.btn-check:not(:first-child)+.btn {
        margin-top: -.0625rem
    }

    .btn-group-lg-vertical .btn-group>.btn-group:not(:first-child),
    .btn-group-lg-vertical .btn-group>.btn:not(:first-child) {
        margin-left: 0
    }

    .btn-group-lg-vertical>.btn-group>.btn-check+.btn {
        border-radius: 0
    }

    .btn-group-lg-vertical>.btn-group>.btn-check:first-child+.btn {
        border-top-left-radius: .3125rem;
        border-top-right-radius: .3125rem
    }

    .btn-group-lg-vertical>.btn-group>.btn:last-child {
        border-bottom-right-radius: .3125rem;
        border-bottom-left-radius: .3125rem
    }

    .btn-group-lg-vertical>.btn-group-segment>.btn-check+.btn {
        border-radius: .3125rem
    }
}

@media (max-width:1199.98px) {

    .btn-group-xl-vertical>.btn,
    .btn-group-xl-vertical>.btn-group,
    .btn-group-xl-vertical>.btn-group>.btn {
        width: 100%;
        display: block
    }

    .btn-group-xl-vertical>.btn-group>.btn-check:not(:first-child)+.btn {
        margin-top: -.0625rem
    }

    .btn-group-xl-vertical .btn-group>.btn-group:not(:first-child),
    .btn-group-xl-vertical .btn-group>.btn:not(:first-child) {
        margin-left: 0
    }

    .btn-group-xl-vertical>.btn-group>.btn-check+.btn {
        border-radius: 0
    }

    .btn-group-xl-vertical>.btn-group>.btn-check:first-child+.btn {
        border-top-left-radius: .3125rem;
        border-top-right-radius: .3125rem
    }

    .btn-group-xl-vertical>.btn-group>.btn:last-child {
        border-bottom-right-radius: .3125rem;
        border-bottom-left-radius: .3125rem
    }

    .btn-group-xl-vertical>.btn-group-segment>.btn-check+.btn {
        border-radius: .3125rem
    }
}

@media (max-width:1399.98px) {

    .btn-group-xxl-vertical>.btn,
    .btn-group-xxl-vertical>.btn-group,
    .btn-group-xxl-vertical>.btn-group>.btn {
        width: 100%;
        display: block
    }

    .btn-group-xxl-vertical>.btn-group>.btn-check:not(:first-child)+.btn {
        margin-top: -.0625rem
    }

    .btn-group-xxl-vertical .btn-group>.btn-group:not(:first-child),
    .btn-group-xxl-vertical .btn-group>.btn:not(:first-child) {
        margin-left: 0
    }

    .btn-group-xxl-vertical>.btn-group>.btn-check+.btn {
        border-radius: 0
    }

    .btn-group-xxl-vertical>.btn-group>.btn-check:first-child+.btn {
        border-top-left-radius: .3125rem;
        border-top-right-radius: .3125rem
    }

    .btn-group-xxl-vertical>.btn-group>.btn:last-child {
        border-bottom-right-radius: .3125rem;
        border-bottom-left-radius: .3125rem
    }

    .btn-group-xxl-vertical>.btn-group-segment>.btn-check+.btn {
        border-radius: .3125rem
    }
}

.btn-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1);
    border-color: transparent
}

.btn-check:focus+.btn-soft-primary,
.btn-soft-primary:focus,
.btn-soft-primary:hover {
    color: #fff;
    background-color: #377dff;
    border-color: transparent
}

.btn-check:active+.btn-soft-primary,
.btn-check:checked+.btn-soft-primary,
.btn-soft-primary.active,
.btn-soft-primary:active,
.show>.btn-soft-primary.dropdown-toggle {
    color: #fff;
    background-color: #377dff;
    border-color: transparent
}

.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
    color: #377dff;
    background-color: #377dff;
    border-color: transparent
}

.btn-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, .1);
    border-color: transparent
}

.btn-check:focus+.btn-soft-secondary,
.btn-soft-secondary:focus,
.btn-soft-secondary:hover {
    color: #fff;
    background-color: #71869d;
    border-color: transparent
}

.btn-check:active+.btn-soft-secondary,
.btn-check:checked+.btn-soft-secondary,
.btn-soft-secondary.active,
.btn-soft-secondary:active,
.show>.btn-soft-secondary.dropdown-toggle {
    color: #fff;
    background-color: #71869d;
    border-color: transparent
}

.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled {
    color: #71869d;
    background-color: #71869d;
    border-color: transparent
}

.btn-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .1);
    border-color: transparent
}

.btn-check:focus+.btn-soft-success,
.btn-soft-success:focus,
.btn-soft-success:hover {
    color: #fff;
    background-color: #00c9a7;
    border-color: transparent
}

.btn-check:active+.btn-soft-success,
.btn-check:checked+.btn-soft-success,
.btn-soft-success.active,
.btn-soft-success:active,
.show>.btn-soft-success.dropdown-toggle {
    color: #fff;
    background-color: #00c9a7;
    border-color: transparent
}

.btn-soft-success.disabled,
.btn-soft-success:disabled {
    color: #00c9a7;
    background-color: #00c9a7;
    border-color: transparent
}

.btn-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, .1);
    border-color: transparent
}

.btn-check:focus+.btn-soft-info,
.btn-soft-info:focus,
.btn-soft-info:hover {
    color: #fff;
    background-color: #09a5be;
    border-color: transparent
}

.btn-check:active+.btn-soft-info,
.btn-check:checked+.btn-soft-info,
.btn-soft-info.active,
.btn-soft-info:active,
.show>.btn-soft-info.dropdown-toggle {
    color: #fff;
    background-color: #09a5be;
    border-color: transparent
}

.btn-soft-info.disabled,
.btn-soft-info:disabled {
    color: #09a5be;
    background-color: #09a5be;
    border-color: transparent
}

.btn-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, .1);
    border-color: transparent
}

.btn-check:focus+.btn-soft-warning,
.btn-soft-warning:focus,
.btn-soft-warning:hover {
    color: #000;
    background-color: #f5ca99;
    border-color: transparent
}

.btn-check:active+.btn-soft-warning,
.btn-check:checked+.btn-soft-warning,
.btn-soft-warning.active,
.btn-soft-warning:active,
.show>.btn-soft-warning.dropdown-toggle {
    color: #000;
    background-color: #f5ca99;
    border-color: transparent
}

.btn-soft-warning.disabled,
.btn-soft-warning:disabled {
    color: #f5ca99;
    background-color: #f5ca99;
    border-color: transparent
}

.btn-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, .1);
    border-color: transparent
}

.btn-check:focus+.btn-soft-danger,
.btn-soft-danger:focus,
.btn-soft-danger:hover {
    color: #fff;
    background-color: #ed4c78;
    border-color: transparent
}

.btn-check:active+.btn-soft-danger,
.btn-check:checked+.btn-soft-danger,
.btn-soft-danger.active,
.btn-soft-danger:active,
.show>.btn-soft-danger.dropdown-toggle {
    color: #fff;
    background-color: #ed4c78;
    border-color: transparent
}

.btn-soft-danger.disabled,
.btn-soft-danger:disabled {
    color: #ed4c78;
    background-color: #ed4c78;
    border-color: transparent
}

.btn-soft-light {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, .1);
    border-color: transparent
}

.btn-check:focus+.btn-soft-light,
.btn-soft-light:focus,
.btn-soft-light:hover {
    color: #000;
    background-color: #f9fafc;
    border-color: transparent
}

.btn-check:active+.btn-soft-light,
.btn-check:checked+.btn-soft-light,
.btn-soft-light.active,
.btn-soft-light:active,
.show>.btn-soft-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafc;
    border-color: transparent
}

.btn-soft-light.disabled,
.btn-soft-light:disabled {
    color: #f9fafc;
    background-color: #f9fafc;
    border-color: transparent
}

.btn-soft-dark {
    color: #132144;
    background-color: rgba(19, 33, 68, .1);
    border-color: transparent
}

.btn-check:focus+.btn-soft-dark,
.btn-soft-dark:focus,
.btn-soft-dark:hover {
    color: #fff;
    background-color: #132144;
    border-color: transparent
}

.btn-check:active+.btn-soft-dark,
.btn-check:checked+.btn-soft-dark,
.btn-soft-dark.active,
.btn-soft-dark:active,
.show>.btn-soft-dark.dropdown-toggle {
    color: #fff;
    background-color: #132144;
    border-color: transparent
}

.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
    color: #132144;
    background-color: #132144;
    border-color: transparent
}

.btn-soft-primary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-soft-primary.dropdown-toggle::after,
.btn-soft-primary:focus.dropdown-toggle::after,
.btn-soft-primary:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-soft-secondary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-soft-secondary.dropdown-toggle::after,
.btn-soft-secondary:focus.dropdown-toggle::after,
.btn-soft-secondary:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-soft-success.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-soft-success.dropdown-toggle::after,
.btn-soft-success:focus.dropdown-toggle::after,
.btn-soft-success:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-soft-info.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-soft-info.dropdown-toggle::after,
.btn-soft-info:focus.dropdown-toggle::after,
.btn-soft-info:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-soft-warning.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-soft-warning.dropdown-toggle::after,
.btn-soft-warning:focus.dropdown-toggle::after,
.btn-soft-warning:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-soft-danger.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-soft-danger.dropdown-toggle::after,
.btn-soft-danger:focus.dropdown-toggle::after,
.btn-soft-danger:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-soft-light.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-soft-light.dropdown-toggle::after,
.btn-soft-light:focus.dropdown-toggle::after,
.btn-soft-light:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-soft-dark.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-check:focus+.btn-soft-dark.dropdown-toggle::after,
.btn-soft-dark:focus.dropdown-toggle::after,
.btn-soft-dark:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:focus+.btn-soft-secondary,
.btn-soft-secondary:focus,
.btn-soft-secondary:hover {
    color: #fff
}

.btn-check:focus+.btn-soft-secondary.dropdown-toggle::after,
.btn-soft-secondary:focus.dropdown-toggle::after,
.btn-soft-secondary:hover.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-icon {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: .875rem;
    font-weight: 600;
    width: 2.625rem;
    height: 2.625rem;
    padding: 0
}

.btn-icon>svg {
    width: .875rem;
    height: auto
}

.btn-outline-primary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-outline-primary:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:active+.btn-outline-primary.dropdown-toggle::after,
.btn-check:checked+.btn-outline-primary.dropdown-toggle::after,
.btn-outline-primary.active.dropdown-toggle::after,
.btn-outline-primary.dropdown-toggle.show.dropdown-toggle::after,
.btn-outline-primary:active.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-outline-secondary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-outline-secondary:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:active+.btn-outline-secondary.dropdown-toggle::after,
.btn-check:checked+.btn-outline-secondary.dropdown-toggle::after,
.btn-outline-secondary.active.dropdown-toggle::after,
.btn-outline-secondary.dropdown-toggle.show.dropdown-toggle::after,
.btn-outline-secondary:active.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-outline-success.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-outline-success:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:active+.btn-outline-success.dropdown-toggle::after,
.btn-check:checked+.btn-outline-success.dropdown-toggle::after,
.btn-outline-success.active.dropdown-toggle::after,
.btn-outline-success.dropdown-toggle.show.dropdown-toggle::after,
.btn-outline-success:active.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-outline-info.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-outline-info:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:active+.btn-outline-info.dropdown-toggle::after,
.btn-check:checked+.btn-outline-info.dropdown-toggle::after,
.btn-outline-info.active.dropdown-toggle::after,
.btn-outline-info.dropdown-toggle.show.dropdown-toggle::after,
.btn-outline-info:active.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-outline-warning.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-outline-warning:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:active+.btn-outline-warning.dropdown-toggle::after,
.btn-check:checked+.btn-outline-warning.dropdown-toggle::after,
.btn-outline-warning.active.dropdown-toggle::after,
.btn-outline-warning.dropdown-toggle.show.dropdown-toggle::after,
.btn-outline-warning:active.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-outline-danger.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-outline-danger:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:active+.btn-outline-danger.dropdown-toggle::after,
.btn-check:checked+.btn-outline-danger.dropdown-toggle::after,
.btn-outline-danger.active.dropdown-toggle::after,
.btn-outline-danger.dropdown-toggle.show.dropdown-toggle::after,
.btn-outline-danger:active.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-outline-light.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-outline-light:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:active+.btn-outline-light.dropdown-toggle::after,
.btn-check:checked+.btn-outline-light.dropdown-toggle::after,
.btn-outline-light.active.dropdown-toggle::after,
.btn-outline-light.dropdown-toggle.show.dropdown-toggle::after,
.btn-outline-light:active.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-outline-dark.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-outline-dark:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-check:active+.btn-outline-dark.dropdown-toggle::after,
.btn-check:checked+.btn-outline-dark.dropdown-toggle::after,
.btn-outline-dark.active.dropdown-toggle::after,
.btn-outline-dark.dropdown-toggle.show.dropdown-toggle::after,
.btn-outline-dark:active.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e")
}

.btn-outline-secondary {
    border-color: rgba(231, 234, 243, .7)
}

.btn-dashed-outline {
    border-style: dashed
}

.btn-xs:not(.btn-icon) {
    font-size: .65625rem;
    padding: .3125rem .75rem
}

.btn-icon.btn-xs {
    font-size: .65625rem;
    width: 1.53125rem;
    height: 1.53125rem
}

.btn-icon.btn-xs>svg {
    width: .65625rem;
    height: auto
}

.btn-group-sm>.btn-icon.btn,
.btn-icon.btn-sm {
    font-size: .875rem;
    width: 2.40625rem;
    height: 2.40625rem
}

.btn-group-sm>.btn-icon.btn>svg,
.btn-icon.btn-sm>svg {
    width: .875rem;
    height: auto
}

.btn-group-lg>.btn-icon.btn,
.btn-icon.btn-lg {
    font-size: 1rem;
    width: 3.36875rem;
    height: 3.36875rem
}

.btn-group-lg>.btn-icon.btn>svg,
.btn-icon.btn-lg>svg {
    width: 1rem;
    height: auto
}

.btn-status {
    position: absolute;
    top: -.4375rem;
    right: -.4375rem;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: .65625rem;
    font-weight: 600;
    width: 1.1484375rem;
    height: 1.1484375rem;
    background-color: #bdc5d1;
    border: .125rem solid #fff;
    padding: 0;
    border-radius: 50%
}

.btn.rounded-circle .btn-status {
    top: -.2916666667rem;
    right: -.2916666667rem
}

.btn-xs-status {
    top: -.3125rem;
    right: -.3125rem;
    font-size: .5359375rem;
    width: 10px;
    height: 10px;
    border-width: .1rem
}

.btn.rounded-circle .btn-xs-status {
    top: -.078125rem;
    right: -.078125rem
}

.btn-sm-status {
    top: -.3125rem;
    right: -.3125rem;
    font-size: .65625rem;
    width: 12px;
    height: 12px
}

.btn.rounded-circle .btn-sm-status {
    top: -.15625rem;
    right: -.15625rem
}

.btn-lg-status {
    top: -.6125rem;
    right: -.6125rem;
    font-size: .875rem;
    width: 24px;
    height: 24px
}

.btn.rounded-circle .btn-lg-status {
    top: -.49rem;
    right: -.49rem
}

.btn-status-primary {
    color: color-yiq(#377dff);
    background-color: #377dff
}

.btn-status-secondary {
    color: color-yiq(#71869d);
    background-color: #71869d
}

.btn-status-success {
    color: color-yiq(#00c9a7);
    background-color: #00c9a7
}

.btn-status-info {
    color: color-yiq(#09a5be);
    background-color: #09a5be
}

.btn-status-warning {
    color: color-yiq(#f5ca99);
    background-color: #f5ca99
}

.btn-status-danger {
    color: color-yiq(#ed4c78);
    background-color: #ed4c78
}

.btn-status-light {
    color: color-yiq(#f9fafc);
    background-color: #f9fafc
}

.btn-status-dark {
    color: color-yiq(#132144);
    background-color: #132144
}

.card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075)
}

.card table tr th {
    font-weight: 400
}

.card-footer {
    display: block
}

.card-subtitle {
    display: block;
    text-transform: uppercase;
    color: #8c98a4;
    font-size: .7109375rem;
    margin-bottom: .25rem
}

.card-title {
    margin-bottom: 0
}

.card-title:not(:last-child) {
    margin-bottom: .5rem
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.card-header-title {
    margin-bottom: 0
}

.card-header-title+.card-text {
    margin-top: .25rem
}

.card-link {
    font-weight: 600;
    white-space: nowrap
}

.card-text {
    color: #677788
}

.card-transition {
    transition: all .2s ease-in-out
}

.card-transition:focus,
.card-transition:hover {
    -webkit-transform: translateY(-.1875rem) !important;
    transform: translateY(-.1875rem) !important
}

.card-ghost {
    background-color: transparent;
    box-shadow: none
}

.card-dashed {
    border: .0625rem dashed rgba(231, 234, 243, .7)
}

.card-centered .card-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column
}

.card-group .card {
    box-shadow: none
}

.card-group .card+.card {
    border-left: .0625rem solid rgba(231, 234, 243, .7)
}

.card .card-header+.table-responsive {
    margin-top: -1px
}

.card .table {
    margin-bottom: 0
}

.card .card-table>tbody>tr:first-child>td {
    padding-top: 1.3125rem
}

.card .card-table>tbody>tr:last-child>td {
    padding-bottom: 1.3125rem
}

.card .card-table>:not(caption)>*>* {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem
}

.card .card-table>.popover>*>* {
    padding: 0
}

.card .card-header+.table .thead-light th:first-child,
.card .card-header+.table-responsive .thead-light th:first-child {
    border-top-left-radius: 0
}

.card .card-header+.table .thead-light th:last-child,
.card .card-header+.table-responsive .thead-light th:last-child {
    border-top-right-radius: 0
}

.card .card-header+.table .table-thead-bordered>:not(:last-child)>:last-child>*,
.card .card-header+.table-responsive .table-thead-bordered>:not(:last-child)>:last-child>* {
    border-top: .0625rem solid rgba(231, 234, 243, .7)
}

.card .table-thead-bordered>:not(:last-child)>:last-child>* {
    border-top-width: 0
}

.card-sm>.card-footer,
.card-sm>.card-header {
    padding: 1rem 1rem
}

.card-sm>.card-body,
.card-sm>.card-img-overlay,
.card-sm>.collapse .card-body {
    padding: 1rem 1rem
}

.card-sm .card-table>tbody>tr:first-child>td {
    padding-top: 1rem
}

.card-sm .card-table>tbody>tr:last-child>td {
    padding-bottom: 1rem
}

.card-sm .card-table>:not(caption)>*>* {
    padding-right: 1rem;
    padding-left: 1rem
}

.card-lg>.card-footer,
.card-lg>.card-header {
    padding-right: 2.5rem;
    padding-left: 2.5rem
}

.card-lg>.card-body,
.card-lg>.card-img-overlay,
.card-lg>.collapse .card-body {
    padding: 2.5rem 2.5rem
}

.card-lg .card-table>tbody>tr:first-child>td {
    padding-top: 2.5rem
}

.card-lg .card-table>tbody>tr:last-child>td {
    padding-bottom: 2.5rem
}

.card-lg .card-table>:not(caption)>*>* {
    padding-right: 2.5rem;
    padding-left: 2.5rem
}

.card-pinned {
    position: relative;
    display: block
}

.card-pinned-top-start {
    position: absolute;
    top: 1rem;
    left: 1rem
}

.card-pinned-top-end {
    position: absolute;
    top: 1rem;
    right: 1rem
}

.card-pinned-bottom-start {
    position: absolute;
    bottom: 1rem;
    left: 1rem
}

.card-pinned-bottom-end {
    position: absolute;
    bottom: 1rem;
    right: 1rem
}

.card-body-height {
    height: 21.25rem;
    overflow: hidden;
    overflow-y: auto
}

.card-body-height::-webkit-scrollbar {
    width: .6125rem
}

.card-body-height::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, .6)
}

.card-hover-shadow {
    transition: .3s
}

.card-hover-shadow:hover {
    box-shadow: 0 .1875rem .75rem rgba(140, 152, 164, .25) !important
}

.card-alert {
    border-radius: 0;
    margin-bottom: 0
}

.card-navbar-nav {
    width: 100%;
    padding: 1.5rem .5rem
}

.card-navbar-nav.nav-tabs .dropdown-header,
.card-navbar-nav.nav-tabs .nav-link {
    margin-left: -.5rem
}

@media (min-width:576px) {
    .card-header-content-sm-between {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: .5rem
    }
}

@media (min-width:768px) {
    .card-header-content-md-between {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: .5rem
    }
}

@media (min-width:992px) {
    .card-header-content-lg-between {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: .5rem
    }
}

@media (min-width:1200px) {
    .card-header-content-xl-between {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: .5rem
    }
}

@media (min-width:1400px) {
    .card-header-content-xxl-between {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: .5rem
    }
}

.card-header-content-between {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: .5rem
}

@media (max-width:575.98px) {
    .card-dropdown-filter-centered {
        left: -8rem;
        min-width: 18rem !important
    }
}

.card-nav-vertical {
    -ms-flex-direction: column;
    flex-direction: column
}

.card-nav-vertical.nav .nav-link {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.card-nav-vertical.card-nav {
    margin-left: -.65625rem;
    margin-right: -.65625rem
}

.card-dropdown-btn {
    z-index: 3
}

.card-avatar-group {
    z-index: 2
}

.card-progress-wrap {
    overflow: hidden;
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem
}

.card-progress {
    height: .25rem
}

.card-progress:first-child .progress-bar {
    border-top-left-radius: .75rem
}

.card-progress:first-child .progress-bar[aria-valuenow="100"] {
    border-top-right-radius: .75rem
}

.card>.profile-cover,
.card>.profile-cover .profile-cover-img,
.card>.profile-cover .profile-cover-img-wrapper {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.card>.card-header~.profile-cover,
.card>.card-header~.profile-cover .profile-cover-img,
.card>.card-header~.profile-cover .profile-cover-img-wrapper {
    border-radius: 0
}

.card>.tab-content .tab-pane[class*=bg] {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem
}

.circles-chart {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto
}

.circles-chart-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.btn-close:focus {
    box-shadow: none
}

.btn-close-light {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center
}

@media (min-width:576px) {
    .col-sm-divider>:not(:first-child) {
        position: relative
    }

    .col-sm-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 0;
        width: .0625rem;
        height: 100%;
        background-color: rgba(231, 234, 243, .7);
        content: ""
    }
}

.col-divider-rotated>:not(:first-child)::before {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg)
}

@media (min-width:768px) {
    .col-md-divider>:not(:first-child) {
        position: relative
    }

    .col-md-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 0;
        width: .0625rem;
        height: 100%;
        background-color: rgba(231, 234, 243, .7);
        content: ""
    }
}

.col-divider-rotated>:not(:first-child)::before {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg)
}

@media (min-width:992px) {
    .col-lg-divider>:not(:first-child) {
        position: relative
    }

    .col-lg-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 0;
        width: .0625rem;
        height: 100%;
        background-color: rgba(231, 234, 243, .7);
        content: ""
    }
}

.col-divider-rotated>:not(:first-child)::before {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg)
}

@media (min-width:1200px) {
    .col-xl-divider>:not(:first-child) {
        position: relative
    }

    .col-xl-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 0;
        width: .0625rem;
        height: 100%;
        background-color: rgba(231, 234, 243, .7);
        content: ""
    }
}

.col-divider-rotated>:not(:first-child)::before {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg)
}

@media (min-width:1400px) {
    .col-xxl-divider>:not(:first-child) {
        position: relative
    }

    .col-xxl-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 0;
        width: .0625rem;
        height: 100%;
        background-color: rgba(231, 234, 243, .7);
        content: ""
    }
}

.col-divider-rotated>:not(:first-child)::before {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg)
}

.col-divider>:not(:first-child) {
    position: relative
}

.col-divider>:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: .0625rem;
    height: 100%;
    background-color: rgba(231, 234, 243, .7);
    content: ""
}

.col-divider-rotated>:not(:first-child)::before {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg)
}

.col-divider>:not(:first-child) {
    position: relative
}

.col-divider>:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: .0625rem;
    background-color: rgba(231, 234, 243, .7);
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.col-divider>* {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.col-divider>:first-child {
    padding-top: 0
}

.col-divider>:last-child {
    padding-bottom: 0
}

@media (max-width:575.98px) {
    .col-sm-divider>:not(:first-child) {
        position: relative
    }

    .col-sm-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: .0625rem;
        background-color: rgba(231, 234, 243, .7);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .col-sm-divider>* {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .col-sm-divider>:first-child {
        padding-top: 0
    }

    .col-sm-divider>:last-child {
        padding-bottom: 0
    }
}

@media (max-width:767.98px) {
    .col-md-divider>:not(:first-child) {
        position: relative
    }

    .col-md-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: .0625rem;
        background-color: rgba(231, 234, 243, .7);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .col-md-divider>* {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .col-md-divider>:first-child {
        padding-top: 0
    }

    .col-md-divider>:last-child {
        padding-bottom: 0
    }
}

@media (max-width:991.98px) {
    .col-lg-divider>:not(:first-child) {
        position: relative
    }

    .col-lg-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: .0625rem;
        background-color: rgba(231, 234, 243, .7);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .col-lg-divider>* {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .col-lg-divider>:first-child {
        padding-top: 0
    }

    .col-lg-divider>:last-child {
        padding-bottom: 0
    }
}

@media (max-width:1199.98px) {
    .col-xl-divider>:not(:first-child) {
        position: relative
    }

    .col-xl-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: .0625rem;
        background-color: rgba(231, 234, 243, .7);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .col-xl-divider>* {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .col-xl-divider>:first-child {
        padding-top: 0
    }

    .col-xl-divider>:last-child {
        padding-bottom: 0
    }
}

@media (max-width:1399.98px) {
    .col-xxl-divider>:not(:first-child) {
        position: relative
    }

    .col-xxl-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: .0625rem;
        background-color: rgba(231, 234, 243, .7);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .col-xxl-divider>* {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .col-xxl-divider>:first-child {
        padding-top: 0
    }

    .col-xxl-divider>:last-child {
        padding-bottom: 0
    }
}

.col-divider>:not(:first-child) {
    position: relative
}

.col-divider>:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: .0625rem;
    height: 100%;
    background-color: rgba(231, 234, 243, .7);
    content: ""
}

.col-divider>*,
.col-divider>:first-child,
.col-divider>:last-child {
    padding-top: 0;
    padding-bottom: 0
}

.col-divider-light>:not(:first-child)::before {
    background-color: rgba(255, 255, 255, .1)
}

.content-space {
    padding: 2rem
}

@media (max-width:575.98px) {
    .content-space {
        padding: 1rem
    }
}

.navbar-expand .navbar-vertical-aside.navbar-vertical-fixed~.main {
    padding-left: 16.25rem
}

@media (min-width:576px) {
    .navbar-expand-sm .navbar-vertical-aside.navbar-vertical-fixed~.main {
        padding-left: 16.25rem
    }
}

@media (min-width:768px) {
    .navbar-expand-md .navbar-vertical-aside.navbar-vertical-fixed~.main {
        padding-left: 16.25rem
    }
}

@media (min-width:992px) {
    .navbar-expand-lg .navbar-vertical-aside.navbar-vertical-fixed~.main {
        padding-left: 16.25rem
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl .navbar-vertical-aside.navbar-vertical-fixed~.main {
        padding-left: 16.25rem
    }
}

@media (min-width:1400px) {
    .navbar-expand-xxl .navbar-vertical-aside.navbar-vertical-fixed~.main {
        padding-left: 16.25rem
    }
}

.footer-offset {
    margin-bottom: 3.5rem
}

.main {
    -ms-flex-positive: 1;
    flex-grow: 1
}

.main .content {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    padding-right: var(--bs-gutter-x, 2rem);
    padding-left: var(--bs-gutter-x, 2rem)
}

@media (max-width:575.98px) {

    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        padding-right: var(--bs-gutter-x, 1.25rem);
        padding-left: var(--bs-gutter-x, 1.25rem)
    }
}

.kanban-board {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.kanban-board::-webkit-scrollbar {
    height: .6125rem
}

.kanban-board::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, .6)
}

.kanban-board .kanban-board-row {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.kanban-board .kanban-board-row>[class*=col] {
    max-width: 350px
}

.sidebar-detached-content {
    margin-left: 18.25rem
}

.splitted-content-main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.navbar-vertical.splitted-content-navbar {
    width: 22rem
}

.navbar-vertical.splitted-content-navbar .navbar-vertical-content {
    height: 100%
}

.navbar-vertical.splitted-content-navbar .navbar-nav {
    margin-left: 5.75rem
}

.navbar-vertical.splitted-content-navbar .navbar-vertical-footer {
    margin-left: 5.75rem
}

.splitted-content-bordered:not(:last-child) {
    border-right: .0625rem solid rgba(231, 234, 243, .7)
}

.splitted-content-bordered:not(:first-child) {
    border-left: .0625rem solid rgba(231, 234, 243, .7)
}

.navbar-vertical-aside~.splitted-content-small.splitted-content-bordered:not(:last-child) {
    margin-left: -.0625rem
}

.splitted-content-mini,
.splitted-content-small {
    background-color: #fff
}

.splitted-content-mini.offcanvas,
.splitted-content-small.offcanvas {
    z-index: 1041
}

.splitted-content-mini {
    width: 5.75rem;
    min-height: 100vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.splitted-content-mini .navbar-brand {
    margin-right: 0
}

.splitted-content-mini .offcanvas-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0
}

.splitted-content-fluid,
.splitted-content-small {
    min-height: 100vh
}

.splitted-content-small {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20rem;
    max-width: 100%
}

.splitted-content-fluid {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%
}

.header~.main .splitted-content-fluid,
.header~.main .splitted-content-mini,
.header~.main .splitted-content-small {
    min-height: calc(100vh - 3.875rem)
}

@media (min-width:1200px) {
    .splitted-content-toggle {
        display: none
    }
}

@media (min-width:992px) {
    .splitted-content-mini {
        position: static;
        visibility: visible;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.dropdown-menu {
    box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175);
    margin-top: .3125rem
}

.dropdown-menu .dropdown-item {
    font-size: .8125rem
}

.dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.dropdown-menu .card {
    border-color: transparent;
    box-shadow: none
}

.dropdown-item {
    border-radius: .3125rem
}

.dropdown-item:not(:last-child) {
    margin-bottom: .25rem
}

.dropdown-item:active {
    color: #1e2022;
    background-color: rgba(189, 197, 209, .2)
}

.dropdown-item.active:active:focus,
.dropdown-item.active:not(:focus):not(:active) {
    color: #1e2022;
    background-color: rgba(189, 197, 209, .2)
}

.dropdown-header {
    text-transform: uppercase;
    letter-spacing: .03125rem;
    font-size: .65625rem;
    font-weight: 600;
    padding: .5rem 1rem
}

.dropdown-item-icon {
    display: inline-block;
    opacity: .7;
    width: 1.5rem;
    color: #677788
}

.dropdown-toggle {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden
}

.dropdown-toggle::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1rem 1rem;
    content: "";
    transition: .3s
}

.dropdown-toggle:not(.dropdown-toggle-empty)::after {
    padding-left: 1.25rem;
    margin-left: auto
}

.dropdown-toggle .dropdown-item-icon {
    width: 1.75rem
}

.nav-item>.nav-link[aria-expanded=true]::after,
.show>.nav-link.dropdown-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.dropup .dropdown-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.dropright .dropdown-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin-top: .25rem
}

.dropleft .dropdown-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-top: -.25rem
}

.dropdown-card {
    padding: 0
}

.dropdown-card .card {
    box-shadow: none
}

.dropdown-card-shopping-cart .card>.card-footer {
    border-top-width: .0625rem
}

.dropdown-menu-form-search {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden
}

.dropdown-menu-form-search .card-body-height {
    padding: .5rem
}

.dropdown-menu-form-search .card-body-height .dropdown-divider {
    width: calc(100% + 1rem);
    margin-left: -.5rem
}

.dropupend .dropdown-menu[data-bs-popper] {
    top: auto;
    right: auto;
    left: 100%;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .625rem
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3.5rem
}

.footer:not([class*=container]) {
    padding-right: 2rem;
    padding-left: 2rem
}

@media (max-width:575.98px) {
    .footer:not([class*=container]) {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

.footer-height-offset {
    height: calc(100vh - 3.5rem)
}

.form-control-plaintext::-webkit-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control-plaintext::-moz-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control-plaintext:-ms-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control-plaintext::-ms-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control-plaintext::placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control-title {
    font-size: 1.75rem;
    line-height: normal;
    font-weight: 600;
    border: none;
    padding: 0
}

.form-control-title:focus {
    box-shadow: none
}

.form-control-single-number {
    font-size: 1.75rem;
    text-align: center
}

.form-check-label {
    margin-top: .125rem
}

.form-check .form-check-label {
    margin-top: 0
}

.form-check-input {
    margin-top: .1875rem
}

.form-check-text {
    display: block
}

.form-check-img {
    max-width: 100%;
    height: auto
}

.form-check-input,
.form-check-label,
label.form-control {
    cursor: pointer
}

.form-check-input:focus {
    box-shadow: none
}

.form-check-input~.form-check-label .form-check-default {
    display: block
}

.form-check-input~.form-check-label .form-check-active {
    display: none
}

.form-check-input:checked~.form-check-label .form-check-default {
    display: none
}

.form-check-input:checked~.form-check-label .form-check-active {
    display: block
}

.form-check-reverse {
    padding-left: 0;
    padding-right: 1.5rem
}

.form-check-reverse .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: -1.5rem
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border: 1px solid rgba(231, 234, 243, .7)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #677788
}

.form-check-primary .form-check-input:checked {
    background-color: #377dff;
    border-color: #377dff
}

.form-check-secondary .form-check-input:checked {
    background-color: #71869d;
    border-color: #71869d
}

.form-check-success .form-check-input:checked {
    background-color: #00c9a7;
    border-color: #00c9a7
}

.form-check-info .form-check-input:checked {
    background-color: #09a5be;
    border-color: #09a5be
}

.form-check-warning .form-check-input:checked {
    background-color: #f5ca99;
    border-color: #f5ca99
}

.form-check-danger .form-check-input:checked {
    background-color: #ed4c78;
    border-color: #ed4c78
}

.form-check-light .form-check-input:checked {
    background-color: #f9fafc;
    border-color: #f9fafc
}

.form-check-dark .form-check-input:checked {
    background-color: #132144;
    border-color: #132144
}

.form-check-dashed {
    text-align: center;
    padding: 2rem 2rem;
    border: .125rem dashed rgba(231, 234, 243, .7);
    border-radius: .5rem
}

.form-check-dashed:hover {
    cursor: pointer;
    background-color: #f9fafc
}

.form-check-dashed .form-check-input {
    display: none
}

.form-check-label-highlighter {
    position: relative;
    padding-left: 0
}

.form-check-label-highlighter .form-check-img {
    border-radius: .5rem
}

.form-check-label-highlighter .form-check-input {
    width: 100%;
    height: 100%;
    background: 0 0;
    border-width: 0;
    margin-left: 0
}

.form-check-label-highlighter .form-check-label {
    border: .125rem solid rgba(231, 234, 243, .7);
    border-radius: .5rem
}

.form-check-label-highlighter .form-check-label::before {
    position: absolute;
    top: .75rem;
    right: .75rem;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23e7eaf3'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23fff'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.25rem 1.25rem;
    opacity: 0;
    content: ""
}

.form-check-label-highlighter .form-check-input:checked[type=checkbox],
.form-check-label-highlighter .form-check-input:checked[type=radio] {
    background-image: none;
    background-color: transparent;
    border-width: 0
}

.form-check-label-highlighter .form-check-input:checked[type=checkbox]~.form-check-label,
.form-check-label-highlighter .form-check-input:checked[type=radio]~.form-check-label {
    border-color: #377dff
}

.form-check-label-highlighter .form-check-input:checked[type=checkbox]~.form-check-label::before,
.form-check-label-highlighter .form-check-input:checked[type=radio]~.form-check-label::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23fff'/%3e%3c/svg%3e");
    opacity: 1
}

.form-check-select {
    cursor: pointer
}

.form-check-select .form-check-input {
    position: relative;
    z-index: 2;
    width: 12px;
    height: 12px;
    background-color: #e7eaf3;
    border-width: 0;
    border-radius: 50%
}

.form-check-select .form-check-input:hover {
    background-color: rgba(55, 125, 255, .3125)
}

.form-check-select .form-check-input:checked[type=checkbox] {
    background-image: none
}

.form-check-select .form-check-input:checked[type=checkbox]~.form-check-stretched-bg::before {
    background-color: rgba(55, 125, 255, .035)
}

.form-check-select .form-check-input:checked[type=checkbox]:hover {
    background-color: #377dff
}

.form-check-select .form-check-stretched-bg::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: auto;
    content: "";
    z-index: 1;
    background-color: rgba(0, 0, 0, 0)
}

.form-check-select:hover .form-check-stretched-bg::before {
    background-color: rgba(55, 125, 255, .035)
}

.form-check-select-stretched {
    padding-left: 0;
    margin-bottom: 0
}

.form-check-select-stretched .form-check-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    float: none;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;
    border: .0625rem solid rgba(231, 234, 243, .7);
    border-radius: .75rem
}

.form-check-select-stretched .form-check-label::before {
    position: absolute;
    top: .75rem;
    right: .75rem;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23e7eaf3'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23fff'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.25rem 1.25rem;
    content: ""
}

.form-check-select-stretched .form-check-input[type=checkbox],
.form-check-select-stretched .form-check-input[type=radio] {
    background-color: transparent;
    border-width: 0;
    background-image: none
}

.form-check-select-stretched .form-check-input:checked[type=checkbox]~.form-check-label,
.form-check-select-stretched .form-check-input:checked[type=radio]~.form-check-label {
    border-color: #377dff
}

.form-check-select-stretched .form-check-input:checked[type=checkbox]~.form-check-label::before,
.form-check-select-stretched .form-check-input:checked[type=radio]~.form-check-label::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-select-stretched.checked .form-check-select-stretched-btn {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff
}

.form-check-switch {
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0
}

.form-check-switch .form-check-input {
    display: none
}

.form-check-switch .form-check-label {
    cursor: pointer;
    color: #377dff;
    background-color: #fff;
    border: .0625rem solid #377dff
}

.form-check-switch .form-check-input:checked~.form-check-label {
    color: #fff;
    border-style: solid;
    background-color: #377dff
}

.form-check-switch .form-check-input:disabled~.form-check-label {
    color: #bdc5d1;
    background-color: rgba(231, 234, 243, .5)
}

.form-select {
    cursor: pointer
}

.form-select-borderless {
    border-width: 0
}

.form-switch {
    position: relative
}

.form-switch.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0
}

.form-switch.row .form-check-input {
    float: none;
    margin-left: auto;
    margin-right: 0
}

.form-switch .form-check-input {
    width: 2.8125em;
    height: 1.8125em;
    border-width: 0;
    background-color: rgba(231, 234, 243, .7);
    margin-top: -.25rem;
    margin-right: .75rem
}

.form-switch .form-check-input:active {
    -webkit-filter: 100%;
    filter: 100%
}

.form-check-input:checked {
    background-color: #377dff
}

.form-switch-between {
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0
}

.form-switch-between .form-check-input {
    float: none;
    -ms-flex-align: center;
    align-items: center;
    margin-left: .5rem
}

.form-switch-between .form-check-label {
    cursor: inherit
}

.form-switch-promotion {
    position: relative;
    min-width: 5rem
}

.form-switch-promotion-container {
    position: absolute;
    top: -1.25rem;
    left: 1.25rem
}

.form-switch-promotion-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: -1rem
}

.form-switch-promotion-arrow {
    margin-right: -1.75rem
}

.form-switch-promotion-text {
    display: block;
    margin-top: .3125rem
}

.form-switch-darkmode-with-icons {
    margin-bottom: 0
}

.form-switch-darkmode-with-icons .form-check-input {
    position: relative;
    width: 4em;
    height: 2.25em;
    border-width: 0;
    background-color: rgba(231, 234, 243, .7);
    cursor: pointer;
    margin-top: -.1rem;
    margin-right: .75rem
}

.form-switch-darkmode-with-icons .form-check-input:active {
    -webkit-filter: 100%;
    filter: 100%
}

.form-switch-darkmode-with-icons .form-check-input::after,
.form-switch-darkmode-with-icons .form-check-input::before {
    position: absolute;
    top: calc(50% - .40625rem);
    width: .8125rem;
    height: .8125rem;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: .8125rem .8125rem;
    content: "";
    transition: .3s
}

.form-switch-darkmode-with-icons .form-check-input::before {
    left: .5rem;
    color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z'/%3e%3c/svg%3e");
    -webkit-transform: scale(0);
    transform: scale(0)
}

.form-switch-darkmode-with-icons .form-check-input::after {
    right: .5rem;
    opacity: 1;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23677788' viewBox='0 0 16 16'%3e%3cpath d='M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z'/%3e%3cpath d='M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z'/%3e%3c/svg%3e");
    -webkit-transform: scale(1);
    transform: scale(1)
}

.form-switch-darkmode-with-icons .form-check-input:checked {
    background-color: #377dff
}

.form-switch-darkmode-with-icons .form-check-input:checked::before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.form-switch-darkmode-with-icons .form-check-input:checked::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0)
}

.form-attachment-btn {
    position: relative;
    overflow: hidden;
    margin-bottom: 0
}

.form-attachment-btn-label {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    opacity: 0
}

.form-text {
    margin-bottom: 0
}

.form-link {
    display: inline-block;
    font-weight: 600;
    margin-top: 1rem
}

.input-group-merge {
    position: relative;
    overflow: hidden;
    border: .0625rem solid rgba(231, 234, 243, .7);
    border-radius: .3125rem
}

.input-group-merge .input-group-append,
.input-group-merge .input-group-prepend {
    position: absolute;
    top: 1px;
    bottom: 1px;
    z-index: 4;
    background-color: transparent
}

.input-group-merge .input-group-append.input-group-text,
.input-group-merge .input-group-prepend.input-group-text {
    border-width: 0
}

.input-group-merge .input-group-prepend {
    left: 1px
}

.input-group-merge .input-group-append {
    right: 1px
}

.input-group-merge .form-control,
.input-group-merge .form-select {
    border-width: 0
}

.input-group-merge .form-control:not(:first-child),
.input-group-merge .form-select:not(:first-child) {
    padding-left: 3.25rem;
    border-top-left-radius: .3125rem;
    border-bottom-left-radius: .3125rem
}

.input-group-merge .form-control:not(:last-child),
.input-group-merge .form-select:not(:last-child) {
    padding-right: 3.25rem;
    border-top-right-radius: .3125rem;
    border-bottom-right-radius: .3125rem
}

.input-group-merge .form-control-sm,
.input-group-merge .form-select-sm {
    min-height: calc(1.5em + .875rem)
}

.input-group-merge .form-control-sm:not(:first-child),
.input-group-merge .form-select-sm:not(:first-child) {
    padding-left: 3.25rem
}

.input-group-merge .form-control-sm:not(:last-child),
.input-group-merge .form-select-sm:not(:last-child) {
    padding-right: 3.25rem
}

.input-group-merge .form-control-lg,
.input-group-merge .form-select-lg {
    min-height: calc(1.5em + 1.375rem)
}

.was-validated .input-group-merge.is-valid {
    border-color: #00c9a7
}

.was-validated .input-group-merge.is-valid.focus {
    box-shadow: 0 0 1rem 0 rgba(0, 201, 167, .25)
}

.was-validated .input-group-merge.is-invalid {
    border-color: #ed4c78
}

.was-validated .input-group-merge.is-invalid.focus {
    box-shadow: 0 0 1rem 0 rgba(237, 76, 120, .25)
}

.input-group-append,
.input-group-prepend {
    display: -ms-flexbox;
    display: flex
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.input-group-append:last-child>.btn:not(:last-child),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

@media (min-width:576px) {
    .input-group-append-last-sm-down-none .tom-select-custom .form-select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
}

@media (max-width:575.98px) {
    .input-group-append-last-sm-down-none.input-group-append:last-child .form-select:not(:last-child) {
        border-top-right-radius: .3125rem;
        border-bottom-right-radius: .3125rem
    }
}

.input-group-vertical {
    display: block
}

.input-group-vertical>.form-control,
.input-group-vertical>.form-select {
    width: 100%
}

.input-group-vertical>.form-control:first-child,
.input-group-vertical>.form-select:first-child {
    border-top-left-radius: .3125rem !important;
    border-top-right-radius: .3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-vertical>.form-control:not(:first-child):not(:last-child),
.input-group-vertical>.form-select:not(:first-child):not(:last-child) {
    border-radius: 0
}

.input-group-vertical>.form-control:last-child,
.input-group-vertical>.form-select:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: .3125rem !important;
    border-bottom-left-radius: .3125rem !important
}

.input-group-vertical .form-control+.form-control,
.input-group-vertical .form-control+.form-select,
.input-group-vertical .form-control-plaintext+.form-control,
.input-group-vertical .form-control-plaintext+.form-select,
.input-group-vertical .form-select+.form-control,
.input-group-vertical .form-select+.form-select {
    margin-left: 0 !important;
    margin-top: -.0625rem
}

.input-group-vertical.input-group .tom-select-custom {
    margin-left: 0 !important
}

.input-group-vertical.input-group .tom-select-custom .form-select:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem
}

@media (max-width:575.98px) {
    .input-group-sm-vertical {
        display: block
    }

    .input-group-sm-vertical>.form-control,
    .input-group-sm-vertical>.form-select {
        width: 100%
    }

    .input-group-sm-vertical>.form-control:first-child,
    .input-group-sm-vertical>.form-select:first-child {
        border-top-left-radius: .3125rem !important;
        border-top-right-radius: .3125rem !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .input-group-sm-vertical>.form-control:not(:first-child):not(:last-child),
    .input-group-sm-vertical>.form-select:not(:first-child):not(:last-child) {
        border-radius: 0
    }

    .input-group-sm-vertical>.form-control:last-child,
    .input-group-sm-vertical>.form-select:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: .3125rem !important;
        border-bottom-left-radius: .3125rem !important
    }

    .input-group-sm-vertical .form-control+.form-control,
    .input-group-sm-vertical .form-control+.form-select,
    .input-group-sm-vertical .form-control-plaintext+.form-control,
    .input-group-sm-vertical .form-control-plaintext+.form-select,
    .input-group-sm-vertical .form-select+.form-control,
    .input-group-sm-vertical .form-select+.form-select {
        margin-left: 0 !important;
        margin-top: -.0625rem
    }

    .input-group-sm-vertical.input-group .tom-select-custom {
        margin-left: 0 !important
    }

    .input-group-sm-vertical.input-group .tom-select-custom .form-select:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: .3125rem
    }
}

@media (max-width:767.98px) {
    .input-group-md-vertical {
        display: block
    }

    .input-group-md-vertical>.form-control,
    .input-group-md-vertical>.form-select {
        width: 100%
    }

    .input-group-md-vertical>.form-control:first-child,
    .input-group-md-vertical>.form-select:first-child {
        border-top-left-radius: .3125rem !important;
        border-top-right-radius: .3125rem !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .input-group-md-vertical>.form-control:not(:first-child):not(:last-child),
    .input-group-md-vertical>.form-select:not(:first-child):not(:last-child) {
        border-radius: 0
    }

    .input-group-md-vertical>.form-control:last-child,
    .input-group-md-vertical>.form-select:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: .3125rem !important;
        border-bottom-left-radius: .3125rem !important
    }

    .input-group-md-vertical .form-control+.form-control,
    .input-group-md-vertical .form-control+.form-select,
    .input-group-md-vertical .form-control-plaintext+.form-control,
    .input-group-md-vertical .form-control-plaintext+.form-select,
    .input-group-md-vertical .form-select+.form-control,
    .input-group-md-vertical .form-select+.form-select {
        margin-left: 0 !important;
        margin-top: -.0625rem
    }

    .input-group-md-vertical.input-group .tom-select-custom {
        margin-left: 0 !important
    }

    .input-group-md-vertical.input-group .tom-select-custom .form-select:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: .3125rem
    }
}

@media (max-width:991.98px) {
    .input-group-lg-vertical {
        display: block
    }

    .input-group-lg-vertical>.form-control,
    .input-group-lg-vertical>.form-select {
        width: 100%
    }

    .input-group-lg-vertical>.form-control:first-child,
    .input-group-lg-vertical>.form-select:first-child {
        border-top-left-radius: .3125rem !important;
        border-top-right-radius: .3125rem !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .input-group-lg-vertical>.form-control:not(:first-child):not(:last-child),
    .input-group-lg-vertical>.form-select:not(:first-child):not(:last-child) {
        border-radius: 0
    }

    .input-group-lg-vertical>.form-control:last-child,
    .input-group-lg-vertical>.form-select:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: .3125rem !important;
        border-bottom-left-radius: .3125rem !important
    }

    .input-group-lg-vertical .form-control+.form-control,
    .input-group-lg-vertical .form-control+.form-select,
    .input-group-lg-vertical .form-control-plaintext+.form-control,
    .input-group-lg-vertical .form-control-plaintext+.form-select,
    .input-group-lg-vertical .form-select+.form-control,
    .input-group-lg-vertical .form-select+.form-select {
        margin-left: 0 !important;
        margin-top: -.0625rem
    }

    .input-group-lg-vertical.input-group .tom-select-custom {
        margin-left: 0 !important
    }

    .input-group-lg-vertical.input-group .tom-select-custom .form-select:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: .3125rem
    }
}

@media (max-width:1199.98px) {
    .input-group-xl-vertical {
        display: block
    }

    .input-group-xl-vertical>.form-control,
    .input-group-xl-vertical>.form-select {
        width: 100%
    }

    .input-group-xl-vertical>.form-control:first-child,
    .input-group-xl-vertical>.form-select:first-child {
        border-top-left-radius: .3125rem !important;
        border-top-right-radius: .3125rem !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .input-group-xl-vertical>.form-control:not(:first-child):not(:last-child),
    .input-group-xl-vertical>.form-select:not(:first-child):not(:last-child) {
        border-radius: 0
    }

    .input-group-xl-vertical>.form-control:last-child,
    .input-group-xl-vertical>.form-select:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: .3125rem !important;
        border-bottom-left-radius: .3125rem !important
    }

    .input-group-xl-vertical .form-control+.form-control,
    .input-group-xl-vertical .form-control+.form-select,
    .input-group-xl-vertical .form-control-plaintext+.form-control,
    .input-group-xl-vertical .form-control-plaintext+.form-select,
    .input-group-xl-vertical .form-select+.form-control,
    .input-group-xl-vertical .form-select+.form-select {
        margin-left: 0 !important;
        margin-top: -.0625rem
    }

    .input-group-xl-vertical.input-group .tom-select-custom {
        margin-left: 0 !important
    }

    .input-group-xl-vertical.input-group .tom-select-custom .form-select:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: .3125rem
    }
}

@media (max-width:1399.98px) {
    .input-group-xxl-vertical {
        display: block
    }

    .input-group-xxl-vertical>.form-control,
    .input-group-xxl-vertical>.form-select {
        width: 100%
    }

    .input-group-xxl-vertical>.form-control:first-child,
    .input-group-xxl-vertical>.form-select:first-child {
        border-top-left-radius: .3125rem !important;
        border-top-right-radius: .3125rem !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .input-group-xxl-vertical>.form-control:not(:first-child):not(:last-child),
    .input-group-xxl-vertical>.form-select:not(:first-child):not(:last-child) {
        border-radius: 0
    }

    .input-group-xxl-vertical>.form-control:last-child,
    .input-group-xxl-vertical>.form-select:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: .3125rem !important;
        border-bottom-left-radius: .3125rem !important
    }

    .input-group-xxl-vertical .form-control+.form-control,
    .input-group-xxl-vertical .form-control+.form-select,
    .input-group-xxl-vertical .form-control-plaintext+.form-control,
    .input-group-xxl-vertical .form-control-plaintext+.form-select,
    .input-group-xxl-vertical .form-select+.form-control,
    .input-group-xxl-vertical .form-select+.form-select {
        margin-left: 0 !important;
        margin-top: -.0625rem
    }

    .input-group-xxl-vertical.input-group .tom-select-custom {
        margin-left: 0 !important
    }

    .input-group-xxl-vertical.input-group .tom-select-custom .form-select:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: .3125rem
    }
}

.form-control-borderless,
.input-group-borderless .form-control,
.input-group-borderless .input-group-text,
.input-group-borderless.input-group-merge {
    border: none
}

.form-control-borderless:focus,
.input-group-borderless .form-control:focus,
.input-group-borderless .input-group-text:focus,
.input-group-borderless.input-group-merge:focus {
    box-shadow: none
}

.form-select-borderless {
    border-color: transparent
}

.form-select-borderless:focus {
    box-shadow: none;
    border-color: transparent
}

.form-control-light,
.form-select-light,
.input-group-light .form-control,
.input-group-light .form-select {
    background-color: #f6f7fa;
    box-shadow: none
}

.form-control-light:disabled,
.form-control-light[readonly],
.form-select-light:disabled,
.form-select-light[readonly],
.input-group-light .form-control:disabled,
.input-group-light .form-control[readonly],
.input-group-light .form-select:disabled,
.input-group-light .form-select[readonly] {
    background-color: transparent
}

.form-control-hover-light.focus,
.form-control-hover-light:focus,
.form-control-hover-light:hover,
.form-select-hover-light.focus,
.form-select-hover-light:focus,
.form-select-hover-light:hover,
.input-group-hover-light .form-control.focus,
.input-group-hover-light .form-control:focus,
.input-group-hover-light .form-control:hover,
.input-group-hover-light .form-select.focus,
.input-group-hover-light .form-select:focus,
.input-group-hover-light .form-select:hover {
    background-color: #fff;
    box-shadow: none
}

.form-control-hover-light:disabled,
.form-control-hover-light[readonly],
.form-select-hover-light:disabled,
.form-select-hover-light[readonly],
.input-group-hover-light .form-control:disabled,
.input-group-hover-light .form-control[readonly],
.input-group-hover-light .form-select:disabled,
.input-group-hover-light .form-select[readonly] {
    background-color: transparent
}

.form-control-hover-light,
.form-select-hover-light,
.input-group-hover-light .form-control,
.input-group-hover-light .form-select {
    background-color: #fff
}

.form-control-hover-light.focus,
.form-control-hover-light:focus,
.form-control-hover-light:hover,
.form-select-hover-light.focus,
.form-select-hover-light:focus,
.form-select-hover-light:hover,
.input-group-hover-light .form-control.focus,
.input-group-hover-light .form-control:focus,
.input-group-hover-light .form-control:hover,
.input-group-hover-light .form-select.focus,
.input-group-hover-light .form-select:focus,
.input-group-hover-light .form-select:hover {
    background-color: #f6f7fa;
    box-shadow: none
}

.form-control-hover-light:disabled,
.form-control-hover-light[readonly],
.form-select-hover-light:disabled,
.form-select-hover-light[readonly],
.input-group-hover-light .form-control:disabled,
.input-group-hover-light .form-control[readonly],
.input-group-hover-light .form-select:disabled,
.input-group-hover-light .form-select[readonly] {
    background-color: transparent
}

.form-select-flush,
.input-group-flush {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.form-select-flush.is-invalid,
.form-select-flush.is-invalid:focus,
.form-select-flush.is-valid,
.form-select-flush.is-valid:focus,
.input-group-flush.is-invalid,
.input-group-flush.is-invalid:focus,
.input-group-flush.is-valid,
.input-group-flush.is-valid:focus {
    box-shadow: none
}

.form-control-flush:focus {
    box-shadow: none
}

.input-group-flush .form-control,
.input-group-flush .form-select {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.input-group-flush .form-control.is-invalid,
.input-group-flush .form-control.is-invalid:focus,
.input-group-flush .form-control.is-valid,
.input-group-flush .form-control.is-valid:focus,
.input-group-flush .form-select.is-invalid,
.input-group-flush .form-select.is-invalid:focus,
.input-group-flush .form-select.is-valid,
.input-group-flush .form-select.is-valid:focus {
    box-shadow: none
}

.input-group-flush .input-group-append.input-group-text,
.input-group-flush .input-group-prepend.input-group-text {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0
}

.input-group-flush .input-group-prepend.input-group-text {
    padding-left: 0
}

.input-group-flush .input-group-append.input-group-text {
    padding-left: 0
}

.input-group-flush.input-group-merge .input-group-append.input-group-text,
.input-group-flush.input-group-merge .input-group-prepend.input-group-text {
    padding: 0
}

.input-group-flush.input-group-merge .form-control:not(:first-child),
.input-group-flush.input-group-merge .form-select:not(:first-child) {
    padding-left: 2rem
}

.input-group-flush.input-group-merge .form-control:not(:last-child),
.input-group-flush.input-group-merge .form-select:not(:last-child) {
    padding-right: 2rem
}

.input-group-flush.input-group .form-control:not(:first-child),
.input-group-flush.input-group .form-control:not(:last-child),
.input-group-flush.input-group .form-select:not(:first-child),
.input-group-flush.input-group .form-select:not(:last-child),
.input-group-flush.input-group .input-group-append.input-group-text:first-child,
.input-group-flush.input-group .input-group-append.input-group-text:last-child,
.input-group-flush.input-group-merge .input-group-append.input-group-text,
.input-group-flush.input-group-merge .input-group-prepend.input-group-text {
    border-radius: 0
}

.input-group-add-field {
    position: relative;
    margin-top: 1rem
}

.input-group-add-field-delete {
    position: absolute;
    top: .75rem;
    right: -1.25rem;
    color: #ed4c78
}

.input-group-add-field-delete:hover {
    color: #ea2c60
}

.form-label-secondary {
    font-size: .8125rem;
    color: #8c98a4
}

.form-label-link {
    font-weight: 600;
    font-size: .875rem;
    margin-bottom: .5rem
}

.go-to {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    width: 2.625rem;
    height: 2.625rem;
    background-color: rgba(113, 134, 157, .1);
    color: #677788;
    font-size: .875rem;
    opacity: .5;
    border-radius: 50%;
    transition: .3s ease-out
}

.go-to:focus:hover,
.go-to:hover {
    color: #fff;
    background-color: #377dff;
    opacity: 1
}

.icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: .875rem;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: .3125rem
}

.icon-circle {
    border-radius: 50%
}

.icon-centered {
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto
}

.icon.icon-xs {
    font-size: .65625rem;
    width: 1.53125rem;
    height: 1.53125rem
}

.icon.icon-sm {
    font-size: .875rem;
    width: 2.40625rem;
    height: 2.40625rem
}

.icon.icon-lg {
    font-size: 1rem;
    width: 3.36875rem;
    height: 3.36875rem
}

.icon-primary {
    color: #fff;
    border-color: #377dff;
    background-color: #377dff
}

.icon-secondary {
    color: #fff;
    border-color: #71869d;
    background-color: #71869d
}

.icon-success {
    color: #fff;
    border-color: #00c9a7;
    background-color: #00c9a7
}

.icon-info {
    color: #fff;
    border-color: #09a5be;
    background-color: #09a5be
}

.icon-warning {
    color: #000;
    border-color: #f5ca99;
    background-color: #f5ca99
}

.icon-danger {
    color: #fff;
    border-color: #ed4c78;
    background-color: #ed4c78
}

.icon-light {
    color: #000;
    border-color: #f9fafc;
    background-color: #f9fafc
}

.icon-dark {
    color: #fff;
    border-color: #132144;
    background-color: #132144
}

.icon-soft-primary {
    color: #377dff;
    background: rgba(55, 125, 255, .1)
}

.icon-soft-secondary {
    color: #71869d;
    background: rgba(113, 134, 157, .1)
}

.icon-soft-success {
    color: #00c9a7;
    background: rgba(0, 201, 167, .1)
}

.icon-soft-info {
    color: #09a5be;
    background: rgba(9, 165, 190, .1)
}

.icon-soft-warning {
    color: #f5ca99;
    background: rgba(245, 202, 153, .1)
}

.icon-soft-danger {
    color: #ed4c78;
    background: rgba(237, 76, 120, .1)
}

.icon-soft-light {
    color: #f9fafc;
    background: rgba(249, 250, 252, .1)
}

.icon-soft-dark {
    color: #132144;
    background: rgba(19, 33, 68, .1)
}

.legend-indicator {
    display: inline-block;
    width: .5rem;
    height: .5rem;
    background-color: #bdc5d1;
    border-radius: 50%;
    margin-right: .4375rem
}

.list-comment {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0
}

.list-comment:first-child {
    margin-top: -2.5rem
}

.list-comment-item {
    margin-top: 2.5rem
}

.list-comment-item .list-comment .list-comment-item {
    padding-left: 1rem;
    border-left: .1875rem solid rgba(231, 234, 243, .7)
}

.list-comment-divider .list-comment-item:not(:last-child) {
    border-bottom: .0625rem solid rgba(231, 234, 243, .7);
    padding-bottom: 2.5rem
}

.list-py-1>li:not(:first-child) {
    padding-top: .3125rem
}

.list-py-1>li:not(:last-child) {
    padding-bottom: .3125rem
}

.list-py-2>li:not(:first-child) {
    padding-top: .5rem
}

.list-py-2>li:not(:last-child) {
    padding-bottom: .5rem
}

.list-py-3>li:not(:first-child) {
    padding-top: 1rem
}

.list-py-3>li:not(:last-child) {
    padding-bottom: 1rem
}

.list-px-1>li:not(:first-child),
.list-px-1>li:not(:last-child) {
    padding-right: .25rem
}

.list-px-2>li:not(:first-child),
.list-px-2>li:not(:last-child) {
    padding-right: .5rem
}

.list-px-3>li:not(:first-child),
.list-px-3>li:not(:last-child) {
    padding-right: 1rem
}

.list-separator {
    margin-bottom: 0
}

.list-separator .list-inline-item {
    position: relative;
    margin-left: 0;
    margin-right: -.25rem
}

.list-separator .list-inline-item:not(:last-child) {
    padding-right: 2rem
}

.list-separator .list-inline-item:not(:last-child)::after {
    position: absolute;
    top: 50%;
    right: .8rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "/";
    opacity: .4
}

.list-separator .list-separator-link {
    color: #677788
}

.list-separator .list-separator-link:hover {
    color: #1366ff
}

.list-separator-light .list-inline-item::after {
    color: #fff
}

.list-separator-light .list-separator-link,
.list-separator-light .list-separator-link:hover {
    color: #fff
}

.list-separator-light .list-separator-link:hover {
    text-decoration: underline
}

.list-group-item:first-child {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.list-group-item:last-child {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.list-group-item.active .list-group-icon {
    color: #fff
}

.list-group-icon {
    display: inline-block;
    color: #677788;
    width: 2rem;
    text-align: center;
    margin-right: .5rem
}

.list-group-striped>li:nth-of-type(odd) {
    background-color: rgba(55, 125, 255, .1)
}

.list-group-sm .list-group-item {
    font-size: .8125rem;
    padding: .5rem .5rem
}

.list-group-lg .list-group-item {
    padding: 1.5rem 1.5rem
}

.list-group-lg .list-group-icon {
    font-size: 1.3125rem;
    width: 2.5rem
}

.list-group-no-gutters .list-group-item {
    padding-right: 0;
    padding-left: 0
}

.list-group-start-bordered .list-group-item {
    padding-left: 0;
    padding-right: 0
}

.list-group-start-bordered .list-group-item-action {
    display: block;
    border-width: 0 0 0 .125rem;
    border-style: solid;
    border-color: transparent;
    padding-left: 1rem
}

.list-group-start-bordered .list-group-item-action:focus,
.list-group-start-bordered .list-group-item-action:hover {
    background-color: transparent
}

.list-checked {
    padding-left: 0;
    list-style: none
}

.list-checked-item {
    position: relative;
    color: #677788;
    padding-left: 2rem
}

.list-checked-item:not(:last-child) {
    margin-bottom: .5rem
}

.list-checked-item::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2371869d'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.25rem 1.25rem;
    content: "";
    margin-top: .125rem
}

.list-checked-item[hover]:hover {
    color: #377dff
}

[class*=list-checked-bg-] .list-checked-item::before,
[class*=list-checked-soft-bg-] .list-checked-item::before {
    margin-top: .25rem
}

.list-checked-primary .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23377dff'/%3e%3c/svg%3e")
}

.list-checked-bg-primary .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e")
}

.list-checked-soft-bg-primary .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23377dff'/%3e%3c/svg%3e")
}

.list-checked-secondary .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2371869d'/%3e%3c/svg%3e")
}

.list-checked-bg-secondary .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2371869d'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e")
}

.list-checked-soft-bg-secondary .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2371869d' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2371869d'/%3e%3c/svg%3e")
}

.list-checked-success .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2300c9a7'/%3e%3c/svg%3e")
}

.list-checked-bg-success .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300c9a7'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e")
}

.list-checked-soft-bg-success .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300c9a7' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2300c9a7'/%3e%3c/svg%3e")
}

.list-checked-info .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2309a5be'/%3e%3c/svg%3e")
}

.list-checked-bg-info .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2309a5be'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e")
}

.list-checked-soft-bg-info .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2309a5be' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2309a5be'/%3e%3c/svg%3e")
}

.list-checked-warning .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23f5ca99'/%3e%3c/svg%3e")
}

.list-checked-bg-warning .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f5ca99'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e")
}

.list-checked-soft-bg-warning .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f5ca99' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f5ca99'/%3e%3c/svg%3e")
}

.list-checked-danger .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23ed4c78'/%3e%3c/svg%3e")
}

.list-checked-bg-danger .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23ed4c78'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e")
}

.list-checked-soft-bg-danger .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23ed4c78' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23ed4c78'/%3e%3c/svg%3e")
}

.list-checked-light .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23f9fafc'/%3e%3c/svg%3e")
}

.list-checked-bg-light .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f9fafc'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e")
}

.list-checked-soft-bg-light .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f9fafc' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f9fafc'/%3e%3c/svg%3e")
}

.list-checked-dark .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23132144'/%3e%3c/svg%3e")
}

.list-checked-bg-dark .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23132144'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e")
}

.list-checked-soft-bg-dark .list-checked-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23132144' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23132144'/%3e%3c/svg%3e")
}

.list-checked-sm .list-checked-item {
    padding-left: 1.5rem
}

.list-checked-sm .list-checked-item::before {
    width: 1rem;
    height: 1rem;
    background-size: 1rem 1rem;
    margin-top: .3125rem
}

.list-checked-sm[class*=list-checked-bg-] .list-checked-item::before,
.list-checked-sm[class*=list-checked-soft-bg-] .list-checked-item::before {
    margin-top: .25rem
}

.list-checked-lg .list-checked-item {
    padding-left: 2.75rem
}

.list-checked-lg .list-checked-item:not(:last-child) {
    margin-bottom: 1.25rem
}

.list-checked-lg .list-checked-item::before {
    width: 1.75rem;
    height: 1.75rem;
    background-size: 1.75rem 1.75rem;
    margin-top: 0
}

.list-checked-lg[class*=list-checked-bg-] .list-checked-item::before,
.list-checked-lg[class*=list-checked-soft-bg-] .list-checked-item::before {
    margin-top: -.0625rem
}

.list-pointer {
    padding-left: 0;
    list-style: none
}

.list-pointer-item {
    position: relative;
    color: #677788;
    padding-left: 1.75rem
}

.list-pointer-item:not(:last-child) {
    margin-bottom: .5rem
}

.list-pointer-item::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2371869d' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.25rem 1.25rem;
    content: "";
    margin-top: .1875rem
}

.list-pointer-item[hover]:hover {
    color: #377dff
}

[class*=list-pointer-bg-] .list-pointer-item::before,
[class*=list-pointer-soft-bg-] .list-pointer-item::before {
    margin-top: .25rem
}

.list-pointer-primary .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23377dff' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e")
}

.list-pointer-bg-primary .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23377dff'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e")
}

.list-pointer-soft-bg-primary .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23377dff' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23377dff'/%3e%3c/svg%3e")
}

.list-pointer-secondary .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2371869d' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e")
}

.list-pointer-bg-secondary .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2371869d'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e")
}

.list-pointer-soft-bg-secondary .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2371869d' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2371869d'/%3e%3c/svg%3e")
}

.list-pointer-success .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2300c9a7' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e")
}

.list-pointer-bg-success .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2300c9a7'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e")
}

.list-pointer-soft-bg-success .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2300c9a7' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2300c9a7'/%3e%3c/svg%3e")
}

.list-pointer-info .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2309a5be' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e")
}

.list-pointer-bg-info .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2309a5be'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e")
}

.list-pointer-soft-bg-info .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2309a5be' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2309a5be'/%3e%3c/svg%3e")
}

.list-pointer-warning .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23f5ca99' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e")
}

.list-pointer-bg-warning .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f5ca99'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23000'/%3e%3c/svg%3e")
}

.list-pointer-soft-bg-warning .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f5ca99' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23f5ca99'/%3e%3c/svg%3e")
}

.list-pointer-danger .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23ed4c78' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e")
}

.list-pointer-bg-danger .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23ed4c78'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e")
}

.list-pointer-soft-bg-danger .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23ed4c78' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23ed4c78'/%3e%3c/svg%3e")
}

.list-pointer-light .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23f9fafc' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e")
}

.list-pointer-bg-light .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f9fafc'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23000'/%3e%3c/svg%3e")
}

.list-pointer-soft-bg-light .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f9fafc' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23f9fafc'/%3e%3c/svg%3e")
}

.list-pointer-dark .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23132144' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e")
}

.list-pointer-bg-dark .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23132144'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e")
}

.list-pointer-soft-bg-dark .list-pointer-item::before {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23132144' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23132144'/%3e%3c/svg%3e")
}

.list-pointer-sm .list-pointer-item {
    padding-left: 1.5rem
}

.list-pointer-sm .list-pointer-item::before {
    width: 1rem;
    height: 1rem;
    background-size: 1rem 1rem;
    margin-top: .3125rem
}

.list-pointer-sm[class*=list-pointer-bg-] .list-pointer-item::before,
.list-pointer-sm[class*=list-pointer-soft-bg-] .list-pointer-item::before {
    margin-top: .25rem
}

.list-pointer-lg .list-pointer-item {
    padding-left: 2.75rem
}

.list-pointer-lg .list-pointer-item:not(:last-child) {
    margin-bottom: 1.25rem
}

.list-pointer-lg .list-pointer-item::before {
    width: 1.75rem;
    height: 1.75rem;
    background-size: 1.75rem 1.75rem;
    margin-top: 0
}

.list-pointer-lg[class*=list-pointer-bg-] .list-pointer-item::before,
.list-pointer-lg[class*=list-pointer-soft-bg-] .list-pointer-item::before {
    margin-top: -.0625rem
}

.modal-header {
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 0
}

.modal-header .close {
    padding: .25rem .25rem;
    margin: 0 0 0 auto
}

.modal-footer {
    border-top-width: .0625rem
}

.modal-footer>* {
    margin-top: 0;
    margin-bottom: 0
}

.modal-footer-text:last-child {
    font-size: .875rem;
    margin-bottom: 0
}

.modal-close {
    position: absolute;
    top: .75rem;
    right: .75rem;
    z-index: 2
}

.modal-top-cover {
    position: relative;
    overflow: hidden;
    min-height: 8rem;
    border-top-right-radius: .6875rem;
    border-top-left-radius: .6875rem
}

.modal-top-cover-avatar,
.modal-top-cover-icon {
    position: relative;
    z-index: 2;
    margin-top: -3rem
}

.nav-subtitle {
    display: block;
    color: #8c98a4;
    font-size: .65625rem;
    text-transform: uppercase;
    letter-spacing: .03125rem
}

.nav-link.dropdown-toggle {
    -ms-flex-align: center;
    align-items: center
}

.nav-link.active,
.navbar-nav .nav-link.active {
    color: #377dff
}

.nav-item.show .nav-link-svg path,
.nav-link.active .nav-link-svg path {
    fill: #377dff
}

.nav-text:last-child,
.nav-title:last-child {
    margin-bottom: 0
}

.nav-icon {
    opacity: .7;
    -ms-flex: 0 0 1.9375rem;
    flex: 0 0 1.9375rem
}

.nav-tabs .nav-link {
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom: .1875rem solid transparent;
    margin-bottom: -.125rem
}

.nav-tabs .nav-link .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent
}

.nav-tabs .nav-link .badge {
    line-height: 1
}

.nav-tabs .nav-item.show>.nav-link,
.nav-tabs .nav-link.active {
    font-weight: 600
}

.nav-tabs .nav-item.show .nav-link {
    color: #132144;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link:hover {
    color: #377dff
}

.nav-tabs .nav-item.show .nav-link.active {
    color: #377dff
}

.nav-tabs:not(.nav-vertical) .nav-link {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}

.nav-tabs .nav-scroller-dropdown .dropdown-menu {
    border-radius: .5rem
}

.nav-tabs-light .nav-item.show .nav-link,
.nav-tabs-light .nav-link.active {
    color: #fff
}

.nav-vertical {
    -ms-flex-direction: column;
    flex-direction: column
}

.nav-vertical.nav .nav-item:not(:last-child) {
    margin-right: 0
}

.nav-vertical.nav .nav-link {
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap
}

.nav-vertical .nav-subtitle {
    padding-left: 1rem
}

.nav-vertical.nav-tabs {
    border-bottom-width: 0
}

.nav-vertical.nav-tabs .dropdown-header {
    padding-left: 1rem !important;
    padding-right: 1rem !important
}

.nav-vertical.nav-tabs .dropdown-header {
    padding-left: 2rem !important
}

.nav-vertical.nav-tabs .nav-link {
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left: .1875rem solid transparent;
    margin-bottom: 0
}

.nav-vertical.nav-tabs .nav-subtitle {
    border-left: .0625rem solid rgba(231, 234, 243, .7)
}

.nav-vertical.nav-tabs .nav-item.show>.nav-link,
.nav-vertical.nav-tabs .nav-link.active {
    border-color: #377dff
}

.nav-vertical.nav-tabs .nav-collapse .nav-link,
.nav-vertical.nav-tabs .nav-item.show .nav-item.show .nav-link {
    border-color: transparent
}

.nav-vertical.nav-lg .nav-link {
    font-size: .875rem;
    margin-top: .25rem;
    margin-bottom: .25rem
}

.nav-vertical.nav.nav-tabs .nav-collapse .nav-link {
    padding-left: 3rem
}

.nav-vertical.nav.nav-tabs .nav-collapse .nav-collapse .nav-link {
    padding-left: 3.91875rem
}

.nav-vertical.nav.nav-tabs .nav-collapse .nav-collapse .nav-link::before {
    display: block;
    color: #97a4af;
    content: "•";
    padding-right: .5rem
}

.nav-vertical.nav.nav-tabs .nav-collapse .nav-item.show>.nav-link::before,
.nav-vertical.nav.nav-tabs .nav-collapse .nav-link.active::before {
    color: #377dff
}

.nav-vertical.nav-pills {
    width: 100%
}

.nav-vertical.nav-pills .nav-item {
    margin: 0
}

.nav-vertical.nav-pills .nav-link {
    padding: .5rem .875rem !important;
    margin-bottom: .25rem
}

.nav-vertical.nav-pills .nav-link.active[aria-expanded=false],
.nav-vertical.nav-pills .nav-link.active[aria-expanded=true] {
    color: #377dff
}

.nav-vertical.nav-pills .nav-link.active[aria-expanded=false]:hover,
.nav-vertical.nav-pills .nav-link.active[aria-expanded=true]:hover {
    background-color: transparent
}

.nav-vertical.nav-pills .nav-item .nav-link:hover,
.nav-vertical.nav-pills .nav-item .nav-link[aria-expanded]:hover {
    color: #132144
}

.nav-vertical.nav-pills .nav-item.show .nav-link[aria-expanded]:hover {
    color: #1366ff
}

.nav-vertical.nav-pills .nav-link {
    background-color: transparent
}

.nav-vertical.nav-pills .nav-link.active {
    background-color: rgba(189, 197, 209, .2)
}

.nav-vertical.nav-pills .nav-link.active[aria-expanded=false],
.nav-vertical.nav-pills .nav-link.active[aria-expanded=true] {
    background-color: transparent
}

.nav-vertical.nav-pills .nav-item .nav-collapse.show .nav-link.active {
    color: #132144;
    background-color: rgba(189, 197, 209, .2)
}

.navbar-dark .nav-vertical.nav-pills .nav-item .nav-collapse.show .nav-link.active {
    color: #fff
}

.nav-vertical.nav-pills .nav-collapse .nav-item .nav-link[aria-expanded]:hover,
.nav-vertical.nav-pills .nav-collapse .nav-link:hover {
    color: #132144;
    background-color: rgba(189, 197, 209, .2)
}

.nav-vertical.nav-pills .nav-collapse .nav-item.show .nav-link[aria-expanded]:hover {
    color: #132144
}

.nav-vertical.nav-pills .nav-collapse {
    position: relative;
    padding-left: 1.9375rem
}

.nav-vertical.nav-pills .nav-collapse::before {
    position: absolute;
    top: 0;
    left: 1.3125rem;
    width: .125rem;
    height: calc(100% - .25rem);
    content: "";
    background-color: rgba(231, 234, 243, .7)
}

.nav-vertical.nav-pills .nav-link.dropdown-toggle {
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.nav-pills .nav-item {
    margin: .25rem .25rem
}

.nav-pills .nav-link {
    padding: 1rem 1rem
}

.nav-pills .nav-link:hover {
    color: #132144;
    background-color: rgba(189, 197, 209, .2)
}

.nav-pills .nav-link.active:hover {
    background-color: rgba(189, 197, 209, .2)
}

.nav-pills .nav-link.active:hover,
.nav-pills .show>.nav-link:hover {
    border-color: transparent
}

.nav-segment {
    position: relative;
    background-color: #f8fafd;
    padding: .25rem .25rem;
    border-radius: .5rem
}

.nav-segment:not(.nav-fill) {
    display: -ms-inline-flexbox;
    display: inline-flex
}

.nav-segment .nav-link {
    color: #677788;
    font-size: .8125rem;
    padding: .5rem .8125rem;
    border-radius: .5rem
}

.nav-segment .nav-link:hover {
    color: #377dff
}

.nav-segment .nav-link.active {
    color: #1e2022;
    background-color: #fff;
    box-shadow: 0 .1875rem .375rem 0 rgba(140, 152, 164, .25)
}

.nav-segment .nav-link.disabled {
    color: #bdc5d1
}

.nav-segment.nav-pills {
    border-radius: 50rem
}

.nav-segment.nav-pills .nav-link {
    border-radius: 50rem
}

.nav-light .nav-link {
    color: rgba(255, 255, 255, .7)
}

.nav-light .nav-link.active,
.nav-light .nav-link:hover {
    color: #fff
}

.nav-light.nav-pills .nav-link.active,
.nav-light.nav-pills .nav-link:hover {
    color: #132144
}

.nav-sm .nav-link {
    font-size: .9375rem;
    padding-right: .75rem;
    padding-left: .75rem
}

.nav-sm.nav-pills .nav-link {
    padding: .75rem .75rem
}

.nav-lg .nav-link {
    font-size: .875rem
}

.nav-lg.nav-pills .nav-link {
    padding: 1.5rem 1.5rem
}

.nav-link-badge {
    margin-left: auto
}

.nav-compact {
    text-align: center;
    -ms-flex-align: center;
    align-items: center
}

.nav-compact .card-navbar-nav {
    padding-top: 0
}

.nav-compact .nav-item>.nav-link {
    border-radius: .5rem;
    margin: .75rem 0
}

.nav-compact .nav-item>.nav-link.active,
.nav-compact .nav-item>.nav-link:hover {
    color: #1366ff;
    background-color: rgba(55, 125, 255, .1)
}

.nav-compact .nav-tabs .nav-collapse .nav-collapse {
    position: static;
    box-shadow: none
}

.nav-compact .nav-collapse .nav-item>.nav-link {
    display: -ms-flexbox;
    display: flex;
    margin: .375rem 0
}

.nav-compact .nav-collapse .nav-item>.nav-link.active,
.nav-compact .nav-collapse .nav-item>.nav-link:hover {
    background-color: transparent
}

.nav-compact .nav-item {
    margin: 0
}

.nav-compact .nav-icon {
    font-size: 1.25rem
}

.nav-compact .nav-link-title {
    display: block
}

.nav-compact-icon {
    text-align: center;
    -ms-flex-align: center;
    align-items: center
}

.nav-compact-icon>.nav-item {
    margin: .75rem 0
}

.nav-compact-icon>.nav-item:not(:last-child) {
    margin-right: 0
}

.nav-compact-icon .nav-icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 2.625rem;
    height: 2.625rem;
    font-size: .875rem;
    color: #677788;
    background-color: transparent;
    opacity: 1;
    margin-right: 0;
    border-radius: .5rem;
    text-align: center
}

.nav-compact-icon .nav-icon.active,
.nav-compact-icon .nav-icon:hover {
    color: #1366ff;
    background-color: rgba(55, 125, 255, .1)
}

.nav-compact-icon .nav-link.active .nav-icon {
    color: #1366ff;
    background-color: rgba(55, 125, 255, .1)
}

.nav-compact-icon-circle .nav-icon {
    border-radius: 50%
}

.navbar {
    z-index: 99
}

.navbar-collapse {
    -ms-flex-align: start;
    align-items: flex-start
}

.navbar-nav {
    -ms-flex-positive: 1;
    flex-grow: 1
}

.navbar-bordered {
    border-width: 0 0 .0625rem 0;
    border-style: solid;
    border-color: rgba(231, 234, 243, .7)
}

.navbar-shadow {
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125)
}

.navbar-height {
    height: 3.875rem
}

.navbar.navbar-scrolled.navbar-light {
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125)
}

.navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99
}

.navbar-fixed~.main {
    padding-top: 3.875rem
}

@media (min-width:576px) {
    .navbar-fixed-sm {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99
    }

    .navbar-fixed-sm~.main {
        padding-top: 3.875rem
    }
}

@media (min-width:768px) {
    .navbar-fixed-md {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99
    }

    .navbar-fixed-md~.main {
        padding-top: 3.875rem
    }
}

@media (min-width:992px) {
    .navbar-fixed-lg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99
    }

    .navbar-fixed-lg~.main {
        padding-top: 3.875rem
    }
}

@media (min-width:1200px) {
    .navbar-fixed-xl {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99
    }

    .navbar-fixed-xl~.main {
        padding-top: 3.875rem
    }
}

@media (min-width:1400px) {
    .navbar-fixed-xxl {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99
    }

    .navbar-fixed-xxl~.main {
        padding-top: 3.875rem
    }
}

.navbar.navbar-container {
    padding-right: 2rem;
    padding-left: 2rem
}

@media (max-width:575.98px) {
    .navbar.navbar-container {
        padding-right: var(--bs-gutter-x, 1.25rem);
        padding-left: var(--bs-gutter-x, 1.25rem)
    }
}

.navbar.navbar-container .navbar-nav-wrap-content-start {
    padding-left: 0
}

.navbar-spacer-y-0 {
    padding-top: 0;
    padding-bottom: 0
}

.navbar.navbar-scrolled.navbar-light {
    background-color: #fff;
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125)
}

.navbar.navbar-scrolled.navbar-dark {
    background-color: #132144
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0
}

.navbar-brand-logo {
    width: 100%;
    min-width: 6.5rem;
    max-width: 6.5rem
}

.navbar-brand-logo-short {
    width: 100%;
    min-width: 2rem;
    max-width: 2rem
}

.navbar-brand-collapsed,
.navbar-brand-on-scroll {
    display: none
}

.navbar-scrolled .navbar-brand-default {
    display: none
}

.navbar-scrolled .navbar-brand-on-scroll {
    display: inline-block
}

.navbar-nav-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-align: center;
    align-items: center
}

.navbar-nav-wrap .navbar-toggler {
    margin-left: auto
}

.navbar-nav-wrap-secondary-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    gap: .5rem
}

.navbar-nav-wrap-content-start {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 2rem
}

.navbar-nav-wrap-content-end {
    margin-left: auto
}

.navbar .navbar-nav-wrap-content-end .navbar-nav,
.navbar .navbar-nav-wrap-secondary-content .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    padding: 0
}

.navbar-toggler {
    color: #677788;
    padding: .5rem .5rem
}

.navbar-toggler:focus {
    box-shadow: none
}

.navbar-toggler .navbar-toggler-text {
    color: #132144
}

.navbar-toggler .navbar-toggler-default {
    display: -ms-flexbox;
    display: flex
}

.navbar-toggler .navbar-toggler-toggled {
    display: none
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-default {
    display: none
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-toggled {
    display: -ms-flexbox;
    display: flex
}

.navbar-topbar .navbar-toggler {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 0;
    font-size: .8125rem
}

.navbar-topbar .js-mega-menu {
    z-index: 11
}

.navbar .nav-item {
    margin-right: 0
}

.navbar .nav-item:not(:last-child) {
    margin-right: .5rem
}

.navbar .dropdown-menu .nav-item,
.navbar .nav-compact-icon .nav-item {
    margin-right: 0
}

.navbar .dropdown-menu .nav-item:not(:last-child),
.navbar .nav-compact-icon .nav-item:not(:last-child) {
    margin-right: 0
}

.navbar .dropdown-menu .dropdown-toggle::after,
.navbar .nav-compact-icon .dropdown-toggle::after {
    margin-right: -.625rem
}

.navbar:not(.splitted-content-navbar) .navbar-nav .nav-link {
    padding: 1rem .75rem
}

.navbar .navbar-collapse .nav-link:first-child {
    padding-left: 0
}

.navbar .nav-item:hover>.nav-link {
    color: #1366ff
}

.navbar .dropdown-menu {
    border-top: .1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.navbar .dropdown-menu::before {
    position: absolute;
    top: -.625rem;
    display: block;
    left: 0;
    width: 100%;
    height: 1.75rem;
    content: ""
}

.dropupend .navbar-dropdown-menu .dropdown .dropdown-menu {
    top: 0;
    bottom: auto;
    margin-left: .625rem
}

.navbar .navbar-dropdown-menu-borderless {
    border-top-width: 0;
    border-radius: .5rem
}

.navbar-dropdown-menu-inner {
    padding: .75rem .75rem
}

.navbar-dropdown-menu-media-link {
    display: block;
    text-decoration: none;
    padding: 1rem 1rem;
    border-radius: .5rem
}

.navbar-dropdown-menu-media-link:hover:not(.disabled):not(:disabled) {
    background-color: rgba(189, 197, 209, .2)
}

.navbar-dropdown-menu-media-link:hover:not(.disabled):not(:disabled) .navbar-dropdown-menu-media-title {
    color: #377dff
}

.navbar-dropdown-menu-media-link.disabled {
    pointer-events: none
}

.navbar-dropdown-menu-media-link+.navbar-dropdown-menu-media-link {
    margin-top: .5rem
}

.navbar-dropdown-menu-media-title {
    color: #1e2022;
    font-weight: 600
}

.navbar-dropdown-menu-media-desc {
    color: #677788;
    font-size: .765625rem;
    margin-bottom: 0
}

.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item {
    position: relative
}

.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    top: -.75rem;
    right: 0;
    border-top: .0625rem solid rgba(231, 234, 243, .7);
    width: 100%;
    content: ""
}

.navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    margin: .75rem .75rem
}

.navbar-dropdown-menu-promo-item~.navbar-dropdown-menu-promo-item {
    border-top: .0625rem solid rgba(231, 234, 243, .7)
}

.navbar-dropdown-menu-promo .navbar-dropdown-menu-promo-item {
    border-top: none
}

.navbar-dropdown-menu-promo-link {
    display: block;
    height: 100%;
    padding: 1rem 1rem;
    border-radius: .5rem
}

.navbar-dropdown-menu-promo-link.active {
    background-color: rgba(189, 197, 209, .2)
}

.navbar-dropdown-menu-promo-link.active .navbar-dropdown-menu-media-title {
    color: #377dff
}

.navbar-dropdown-menu-promo-link.disabled {
    opacity: .7;
    pointer-events: none
}

.navbar-dropdown-menu-promo-link:hover:not(.disabled):not(:disabled) {
    background-color: rgba(189, 197, 209, .2)
}

.navbar-dropdown-menu-promo-link:hover:not(.disabled):not(:disabled) .navbar-dropdown-menu-media-title {
    color: #377dff
}

.navbar-dropdown-menu-banner {
    position: relative;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: .5rem
}

.navbar-dropdown-menu-banner-content {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 100%;
    text-align: center;
    padding: 2rem 2rem;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.navbar-dropdown-menu-shop-banner {
    position: relative;
    background-color: #f9fafc;
    padding-left: 10rem;
    border-radius: .5rem
}

.navbar-dropdown-menu-shop-banner-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 10rem
}

.navbar.navbar-vertical:not(.splitted-content-navbar) .navbar-nav .nav-link,
.navbar.navbar-vertical:not(.splitted-content-navbar) .navbar-nav .nav-subtitle {
    padding: .3125rem 1.5rem
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical {
    padding-right: 0;
    padding-left: 0
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-link,
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-subtitle {
    border-left-color: transparent
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-item.show .nav-link,
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-link.active {
    border-color: #377dff
}

@media (min-width:576px) {
    .navbar-input-group {
        min-width: 21rem
    }
}

.navbar-input-group input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0
}

.navbar-input-group input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0
}

.navbar-input-group input[type=search]::-webkit-search-cancel-button,
.navbar-input-group input[type=search]::-webkit-search-decoration,
.navbar-input-group input[type=search]::-webkit-search-results-button,
.navbar-input-group input[type=search]::-webkit-search-results-decoration {
    display: none
}

.navbar-vertical .card .nav-tabs .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 1.25rem;
    margin-left: -1.3125rem
}

.navbar-vertical .card .nav-tabs .nav-link:not(.active) {
    border-color: transparent
}

.navbar-dropdown-sub-menu[data-bs-popper] {
    top: 0;
    right: calc(100% + 1rem)
}

.navbar-dropdown-account-wrapper {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    color: #1e2022;
    background-color: transparent;
    padding: .25rem;
    border-radius: 50rem;
    transition: .3s
}

.navbar-dropdown-account-wrapper:focus,
.navbar-dropdown-account-wrapper:hover {
    background-color: rgba(55, 125, 255, .1)
}

.navbar-card-list-group {
    margin: .5rem 0 .5rem .5rem
}

.navbar-card-list-group .list-group-item {
    padding-top: 1rem;
    padding-bottom: 1rem
}

@media (min-width:576px) {
    .navbar-expand-sm .navbar-toggler {
        -ms-flex-order: 1;
        order: 1
    }

    .navbar-expand-sm .navbar-collapse {
        width: auto;
        -ms-flex-order: 2;
        order: 2
    }

    .navbar-expand-sm .navbar-nav-wrap-secondary-content {
        -ms-flex-order: 3;
        order: 3
    }

    .navbar-expand-sm:not(.navbar-vertical) .navbar-nav {
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-sm .navbar-nav-wrap .navbar-aside-toggler {
        display: none
    }

    .navbar-expand-sm .hs-sub-menu.dropdown-menu,
    .navbar-expand-sm .hs-sub-menu.dropdown-menu[data-bs-popper] {
        margin-top: 0
    }

    .navbar-expand-sm .dropdown-menu {
        margin-top: .75rem
    }

    .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu {
        margin-top: -.6875rem;
        margin-left: .5rem
    }

    .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
        top: 0;
        left: 100%
    }

    .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
        top: 0;
        left: -1rem;
        width: 1rem;
        height: 100%
    }

    .navbar-expand-sm.navbar-sidebar-detached .navbar-brand {
        width: 16.25rem;
        margin-right: 0
    }

    .navbar-expand-sm .navbar-topbar .navbar-toggler {
        margin-left: 0
    }

    .navbar-expand-sm .navbar-topbar .nav-link {
        font-size: .9375rem;
        padding: .5rem .75rem
    }

    .navbar-expand-sm .navbar-topbar .nav-item:first-child .nav-link {
        padding-left: 0
    }

    .navbar-expand-sm .navbar-topbar .nav-item:last-child .nav-link {
        padding-right: 0
    }

    .navbar-expand-sm.navbar-center .navbar-nav {
        -ms-flex-pack: center;
        justify-content: center
    }

    .navbar-expand-sm.navbar-end .navbar-topbar .navbar-nav {
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 0
    }

    .navbar-expand-sm.navbar-end .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto
    }

    .navbar-expand-sm.navbar-end .navbar-absolute-top-scroller,
    .navbar-expand-sm.navbar-end .navbar-sticky-top-scroller {
        margin-left: auto
    }

    .navbar-expand-sm.navbar-floating {
        position: absolute;
        right: 0;
        left: 0;
        margin-top: 2.5rem
    }

    .navbar-expand-sm.navbar-floating.navbar-scrolled {
        position: fixed;
        background-color: #fff;
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        margin-top: 0
    }

    .navbar-expand-sm.navbar-floating.navbar-scrolled .navbar-floating-nav {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-sm.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
        box-shadow: none
    }

    .navbar-expand-sm.navbar-floating .navbar-floating-nav {
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-sm.navbar-floating .dropdown-menu,
    .navbar-expand-sm.navbar-floating .dropdown-menu[data-bs-popper] {
        margin-top: .5rem
    }

    .navbar-expand-sm.navbar-floating .dropdown-menu .hs-mega-menu,
    .navbar-expand-sm.navbar-floating .dropdown-menu:not(.hs-sub-menu) {
        margin-top: 0
    }

    .navbar-expand-sm .navbar-floating-nav {
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem
    }

    .navbar-expand-sm.navbar-vertical.navbar-sidebar {
        -ms-flex-flow: column;
        flex-flow: column;
        overflow-y: scroll;
        height: 100%;
        max-height: 100vh
    }

    .navbar-expand-sm.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-expand-sm.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6)
    }

    .navbar-expand-sm.navbar-vertical.navbar-sidebar .navbar-collapse {
        width: 100%;
        display: block !important
    }

    .navbar-expand-sm.navbar-vertical.navbar-sidebar .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .navbar-expand-sm .navbar-vertical.navbar-collapse {
        display: block !important
    }

    .navbar-expand-sm .navbar-vertical.navbar-vertical-fixed {
        height: 100%
    }

    .navbar-expand-sm .navbar-dropdown-menu-promo {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-sm .navbar-dropdown-menu-promo-item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-sm .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative
    }

    .navbar-expand-sm .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -.75rem;
        border-right: .0625rem solid rgba(231, 234, 243, .7);
        height: 100%;
        content: ""
    }
}

@media (min-width:768px) {
    .navbar-expand-md .navbar-toggler {
        -ms-flex-order: 1;
        order: 1
    }

    .navbar-expand-md .navbar-collapse {
        width: auto;
        -ms-flex-order: 2;
        order: 2
    }

    .navbar-expand-md .navbar-nav-wrap-secondary-content {
        -ms-flex-order: 3;
        order: 3
    }

    .navbar-expand-md:not(.navbar-vertical) .navbar-nav {
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-md .navbar-nav-wrap .navbar-aside-toggler {
        display: none
    }

    .navbar-expand-md .hs-sub-menu.dropdown-menu,
    .navbar-expand-md .hs-sub-menu.dropdown-menu[data-bs-popper] {
        margin-top: 0
    }

    .navbar-expand-md .dropdown-menu {
        margin-top: .75rem
    }

    .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu {
        margin-top: -.6875rem;
        margin-left: .5rem
    }

    .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
        top: 0;
        left: 100%
    }

    .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
        top: 0;
        left: -1rem;
        width: 1rem;
        height: 100%
    }

    .navbar-expand-md.navbar-sidebar-detached .navbar-brand {
        width: 16.25rem;
        margin-right: 0
    }

    .navbar-expand-md .navbar-topbar .navbar-toggler {
        margin-left: 0
    }

    .navbar-expand-md .navbar-topbar .nav-link {
        font-size: .9375rem;
        padding: .5rem .75rem
    }

    .navbar-expand-md .navbar-topbar .nav-item:first-child .nav-link {
        padding-left: 0
    }

    .navbar-expand-md .navbar-topbar .nav-item:last-child .nav-link {
        padding-right: 0
    }

    .navbar-expand-md.navbar-center .navbar-nav {
        -ms-flex-pack: center;
        justify-content: center
    }

    .navbar-expand-md.navbar-end .navbar-topbar .navbar-nav {
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 0
    }

    .navbar-expand-md.navbar-end .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto
    }

    .navbar-expand-md.navbar-end .navbar-absolute-top-scroller,
    .navbar-expand-md.navbar-end .navbar-sticky-top-scroller {
        margin-left: auto
    }

    .navbar-expand-md.navbar-floating {
        position: absolute;
        right: 0;
        left: 0;
        margin-top: 2.5rem
    }

    .navbar-expand-md.navbar-floating.navbar-scrolled {
        position: fixed;
        background-color: #fff;
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        margin-top: 0
    }

    .navbar-expand-md.navbar-floating.navbar-scrolled .navbar-floating-nav {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-md.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
        box-shadow: none
    }

    .navbar-expand-md.navbar-floating .navbar-floating-nav {
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-md.navbar-floating .dropdown-menu,
    .navbar-expand-md.navbar-floating .dropdown-menu[data-bs-popper] {
        margin-top: .5rem
    }

    .navbar-expand-md.navbar-floating .dropdown-menu .hs-mega-menu,
    .navbar-expand-md.navbar-floating .dropdown-menu:not(.hs-sub-menu) {
        margin-top: 0
    }

    .navbar-expand-md .navbar-floating-nav {
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem
    }

    .navbar-expand-md.navbar-vertical.navbar-sidebar {
        -ms-flex-flow: column;
        flex-flow: column;
        overflow-y: scroll;
        height: 100%;
        max-height: 100vh
    }

    .navbar-expand-md.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-expand-md.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6)
    }

    .navbar-expand-md.navbar-vertical.navbar-sidebar .navbar-collapse {
        width: 100%;
        display: block !important
    }

    .navbar-expand-md.navbar-vertical.navbar-sidebar .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .navbar-expand-md .navbar-vertical.navbar-collapse {
        display: block !important
    }

    .navbar-expand-md .navbar-vertical.navbar-vertical-fixed {
        height: 100%
    }

    .navbar-expand-md .navbar-dropdown-menu-promo {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-md .navbar-dropdown-menu-promo-item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-md .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative
    }

    .navbar-expand-md .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -.75rem;
        border-right: .0625rem solid rgba(231, 234, 243, .7);
        height: 100%;
        content: ""
    }
}

@media (min-width:992px) {
    .navbar-expand-lg .navbar-toggler {
        -ms-flex-order: 1;
        order: 1
    }

    .navbar-expand-lg .navbar-collapse {
        width: auto;
        -ms-flex-order: 2;
        order: 2
    }

    .navbar-expand-lg .navbar-nav-wrap-secondary-content {
        -ms-flex-order: 3;
        order: 3
    }

    .navbar-expand-lg:not(.navbar-vertical) .navbar-nav {
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-lg .navbar-nav-wrap .navbar-aside-toggler {
        display: none
    }

    .navbar-expand-lg .hs-sub-menu.dropdown-menu,
    .navbar-expand-lg .hs-sub-menu.dropdown-menu[data-bs-popper] {
        margin-top: 0
    }

    .navbar-expand-lg .dropdown-menu {
        margin-top: .75rem
    }

    .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu {
        margin-top: -.6875rem;
        margin-left: .5rem
    }

    .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
        top: 0;
        left: 100%
    }

    .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
        top: 0;
        left: -1rem;
        width: 1rem;
        height: 100%
    }

    .navbar-expand-lg.navbar-sidebar-detached .navbar-brand {
        width: 16.25rem;
        margin-right: 0
    }

    .navbar-expand-lg .navbar-topbar .navbar-toggler {
        margin-left: 0
    }

    .navbar-expand-lg .navbar-topbar .nav-link {
        font-size: .9375rem;
        padding: .5rem .75rem
    }

    .navbar-expand-lg .navbar-topbar .nav-item:first-child .nav-link {
        padding-left: 0
    }

    .navbar-expand-lg .navbar-topbar .nav-item:last-child .nav-link {
        padding-right: 0
    }

    .navbar-expand-lg.navbar-center .navbar-nav {
        -ms-flex-pack: center;
        justify-content: center
    }

    .navbar-expand-lg.navbar-end .navbar-topbar .navbar-nav {
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 0
    }

    .navbar-expand-lg.navbar-end .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto
    }

    .navbar-expand-lg.navbar-end .navbar-absolute-top-scroller,
    .navbar-expand-lg.navbar-end .navbar-sticky-top-scroller {
        margin-left: auto
    }

    .navbar-expand-lg.navbar-floating {
        position: absolute;
        right: 0;
        left: 0;
        margin-top: 2.5rem
    }

    .navbar-expand-lg.navbar-floating.navbar-scrolled {
        position: fixed;
        background-color: #fff;
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        margin-top: 0
    }

    .navbar-expand-lg.navbar-floating.navbar-scrolled .navbar-floating-nav {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-lg.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
        box-shadow: none
    }

    .navbar-expand-lg.navbar-floating .navbar-floating-nav {
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-lg.navbar-floating .dropdown-menu,
    .navbar-expand-lg.navbar-floating .dropdown-menu[data-bs-popper] {
        margin-top: .5rem
    }

    .navbar-expand-lg.navbar-floating .dropdown-menu .hs-mega-menu,
    .navbar-expand-lg.navbar-floating .dropdown-menu:not(.hs-sub-menu) {
        margin-top: 0
    }

    .navbar-expand-lg .navbar-floating-nav {
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem
    }

    .navbar-expand-lg.navbar-vertical.navbar-sidebar {
        -ms-flex-flow: column;
        flex-flow: column;
        overflow-y: scroll;
        height: 100%;
        max-height: 100vh
    }

    .navbar-expand-lg.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-expand-lg.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6)
    }

    .navbar-expand-lg.navbar-vertical.navbar-sidebar .navbar-collapse {
        width: 100%;
        display: block !important
    }

    .navbar-expand-lg.navbar-vertical.navbar-sidebar .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .navbar-expand-lg .navbar-vertical.navbar-collapse {
        display: block !important
    }

    .navbar-expand-lg .navbar-vertical.navbar-vertical-fixed {
        height: 100%
    }

    .navbar-expand-lg .navbar-dropdown-menu-promo {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-lg .navbar-dropdown-menu-promo-item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-lg .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative
    }

    .navbar-expand-lg .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -.75rem;
        border-right: .0625rem solid rgba(231, 234, 243, .7);
        height: 100%;
        content: ""
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl .navbar-toggler {
        -ms-flex-order: 1;
        order: 1
    }

    .navbar-expand-xl .navbar-collapse {
        width: auto;
        -ms-flex-order: 2;
        order: 2
    }

    .navbar-expand-xl .navbar-nav-wrap-secondary-content {
        -ms-flex-order: 3;
        order: 3
    }

    .navbar-expand-xl:not(.navbar-vertical) .navbar-nav {
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-xl .navbar-nav-wrap .navbar-aside-toggler {
        display: none
    }

    .navbar-expand-xl .hs-sub-menu.dropdown-menu,
    .navbar-expand-xl .hs-sub-menu.dropdown-menu[data-bs-popper] {
        margin-top: 0
    }

    .navbar-expand-xl .dropdown-menu {
        margin-top: .75rem
    }

    .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
        margin-top: -.6875rem;
        margin-left: .5rem
    }

    .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
        top: 0;
        left: 100%
    }

    .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
        top: 0;
        left: -1rem;
        width: 1rem;
        height: 100%
    }

    .navbar-expand-xl.navbar-sidebar-detached .navbar-brand {
        width: 16.25rem;
        margin-right: 0
    }

    .navbar-expand-xl .navbar-topbar .navbar-toggler {
        margin-left: 0
    }

    .navbar-expand-xl .navbar-topbar .nav-link {
        font-size: .9375rem;
        padding: .5rem .75rem
    }

    .navbar-expand-xl .navbar-topbar .nav-item:first-child .nav-link {
        padding-left: 0
    }

    .navbar-expand-xl .navbar-topbar .nav-item:last-child .nav-link {
        padding-right: 0
    }

    .navbar-expand-xl.navbar-center .navbar-nav {
        -ms-flex-pack: center;
        justify-content: center
    }

    .navbar-expand-xl.navbar-end .navbar-topbar .navbar-nav {
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 0
    }

    .navbar-expand-xl.navbar-end .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto
    }

    .navbar-expand-xl.navbar-end .navbar-absolute-top-scroller,
    .navbar-expand-xl.navbar-end .navbar-sticky-top-scroller {
        margin-left: auto
    }

    .navbar-expand-xl.navbar-floating {
        position: absolute;
        right: 0;
        left: 0;
        margin-top: 2.5rem
    }

    .navbar-expand-xl.navbar-floating.navbar-scrolled {
        position: fixed;
        background-color: #fff;
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        margin-top: 0
    }

    .navbar-expand-xl.navbar-floating.navbar-scrolled .navbar-floating-nav {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-xl.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
        box-shadow: none
    }

    .navbar-expand-xl.navbar-floating .navbar-floating-nav {
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-xl.navbar-floating .dropdown-menu,
    .navbar-expand-xl.navbar-floating .dropdown-menu[data-bs-popper] {
        margin-top: .5rem
    }

    .navbar-expand-xl.navbar-floating .dropdown-menu .hs-mega-menu,
    .navbar-expand-xl.navbar-floating .dropdown-menu:not(.hs-sub-menu) {
        margin-top: 0
    }

    .navbar-expand-xl .navbar-floating-nav {
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem
    }

    .navbar-expand-xl.navbar-vertical.navbar-sidebar {
        -ms-flex-flow: column;
        flex-flow: column;
        overflow-y: scroll;
        height: 100%;
        max-height: 100vh
    }

    .navbar-expand-xl.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-expand-xl.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6)
    }

    .navbar-expand-xl.navbar-vertical.navbar-sidebar .navbar-collapse {
        width: 100%;
        display: block !important
    }

    .navbar-expand-xl.navbar-vertical.navbar-sidebar .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .navbar-expand-xl .navbar-vertical.navbar-collapse {
        display: block !important
    }

    .navbar-expand-xl .navbar-vertical.navbar-vertical-fixed {
        height: 100%
    }

    .navbar-expand-xl .navbar-dropdown-menu-promo {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-xl .navbar-dropdown-menu-promo-item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-xl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative
    }

    .navbar-expand-xl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -.75rem;
        border-right: .0625rem solid rgba(231, 234, 243, .7);
        height: 100%;
        content: ""
    }
}

@media (min-width:1400px) {
    .navbar-expand-xxl .navbar-toggler {
        -ms-flex-order: 1;
        order: 1
    }

    .navbar-expand-xxl .navbar-collapse {
        width: auto;
        -ms-flex-order: 2;
        order: 2
    }

    .navbar-expand-xxl .navbar-nav-wrap-secondary-content {
        -ms-flex-order: 3;
        order: 3
    }

    .navbar-expand-xxl:not(.navbar-vertical) .navbar-nav {
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-xxl .navbar-nav-wrap .navbar-aside-toggler {
        display: none
    }

    .navbar-expand-xxl .hs-sub-menu.dropdown-menu,
    .navbar-expand-xxl .hs-sub-menu.dropdown-menu[data-bs-popper] {
        margin-top: 0
    }

    .navbar-expand-xxl .dropdown-menu {
        margin-top: .75rem
    }

    .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
        margin-top: -.6875rem;
        margin-left: .5rem
    }

    .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
        top: 0;
        left: 100%
    }

    .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
        top: 0;
        left: -1rem;
        width: 1rem;
        height: 100%
    }

    .navbar-expand-xxl.navbar-sidebar-detached .navbar-brand {
        width: 16.25rem;
        margin-right: 0
    }

    .navbar-expand-xxl .navbar-topbar .navbar-toggler {
        margin-left: 0
    }

    .navbar-expand-xxl .navbar-topbar .nav-link {
        font-size: .9375rem;
        padding: .5rem .75rem
    }

    .navbar-expand-xxl .navbar-topbar .nav-item:first-child .nav-link {
        padding-left: 0
    }

    .navbar-expand-xxl .navbar-topbar .nav-item:last-child .nav-link {
        padding-right: 0
    }

    .navbar-expand-xxl.navbar-center .navbar-nav {
        -ms-flex-pack: center;
        justify-content: center
    }

    .navbar-expand-xxl.navbar-end .navbar-topbar .navbar-nav {
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 0
    }

    .navbar-expand-xxl.navbar-end .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto
    }

    .navbar-expand-xxl.navbar-end .navbar-absolute-top-scroller,
    .navbar-expand-xxl.navbar-end .navbar-sticky-top-scroller {
        margin-left: auto
    }

    .navbar-expand-xxl.navbar-floating {
        position: absolute;
        right: 0;
        left: 0;
        margin-top: 2.5rem
    }

    .navbar-expand-xxl.navbar-floating.navbar-scrolled {
        position: fixed;
        background-color: #fff;
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        margin-top: 0
    }

    .navbar-expand-xxl.navbar-floating.navbar-scrolled .navbar-floating-nav {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-xxl.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
        box-shadow: none
    }

    .navbar-expand-xxl.navbar-floating .navbar-floating-nav {
        box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-xxl.navbar-floating .dropdown-menu,
    .navbar-expand-xxl.navbar-floating .dropdown-menu[data-bs-popper] {
        margin-top: .5rem
    }

    .navbar-expand-xxl.navbar-floating .dropdown-menu .hs-mega-menu,
    .navbar-expand-xxl.navbar-floating .dropdown-menu:not(.hs-sub-menu) {
        margin-top: 0
    }

    .navbar-expand-xxl .navbar-floating-nav {
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem
    }

    .navbar-expand-xxl.navbar-vertical.navbar-sidebar {
        -ms-flex-flow: column;
        flex-flow: column;
        overflow-y: scroll;
        height: 100%;
        max-height: 100vh
    }

    .navbar-expand-xxl.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-expand-xxl.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6)
    }

    .navbar-expand-xxl.navbar-vertical.navbar-sidebar .navbar-collapse {
        width: 100%;
        display: block !important
    }

    .navbar-expand-xxl.navbar-vertical.navbar-sidebar .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .navbar-expand-xxl .navbar-vertical.navbar-collapse {
        display: block !important
    }

    .navbar-expand-xxl .navbar-vertical.navbar-vertical-fixed {
        height: 100%
    }

    .navbar-expand-xxl .navbar-dropdown-menu-promo {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-xxl .navbar-dropdown-menu-promo-item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-xxl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative
    }

    .navbar-expand-xxl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -.75rem;
        border-right: .0625rem solid rgba(231, 234, 243, .7);
        height: 100%;
        content: ""
    }
}

.navbar-expand .navbar-toggler {
    -ms-flex-order: 1;
    order: 1
}

.navbar-expand .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
    order: 2
}

.navbar-expand .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
    order: 3
}

.navbar-expand:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
    align-items: center
}

.navbar-expand .navbar-nav-wrap .navbar-aside-toggler {
    display: none
}

.navbar-expand .hs-sub-menu.dropdown-menu,
.navbar-expand .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0
}

.navbar-expand .dropdown-menu {
    margin-top: .75rem
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -.6875rem;
    margin-left: .5rem
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0;
    left: 100%
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%
}

.navbar-expand.navbar-sidebar-detached .navbar-brand {
    width: 16.25rem;
    margin-right: 0
}

.navbar-expand .navbar-topbar .navbar-toggler {
    margin-left: 0
}

.navbar-expand .navbar-topbar .nav-link {
    font-size: .9375rem;
    padding: .5rem .75rem
}

.navbar-expand .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0
}

.navbar-expand .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0
}

.navbar-expand.navbar-center .navbar-nav {
    -ms-flex-pack: center;
    justify-content: center
}

.navbar-expand.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 0
}

.navbar-expand.navbar-end .navbar-nav {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: auto
}

.navbar-expand.navbar-end .navbar-absolute-top-scroller,
.navbar-expand.navbar-end .navbar-sticky-top-scroller {
    margin-left: auto
}

.navbar-expand.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem
}

.navbar-expand.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
    margin-top: 0
}

.navbar-expand.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0
}

.navbar-expand.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none
}

.navbar-expand.navbar-floating .navbar-floating-nav {
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-expand.navbar-floating .dropdown-menu,
.navbar-expand.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: .5rem
}

.navbar-expand.navbar-floating .dropdown-menu .hs-mega-menu,
.navbar-expand.navbar-floating .dropdown-menu:not(.hs-sub-menu) {
    margin-top: 0
}

.navbar-expand .navbar-floating-nav {
    border-radius: .5rem;
    padding-left: 1rem;
    padding-right: 1rem
}

.navbar-expand.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh
}

.navbar-expand.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: .6125rem
}

.navbar-expand.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, .6)
}

.navbar-expand.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important
}

.navbar-expand.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start
}

.navbar-expand .navbar-vertical.navbar-collapse {
    display: block !important
}

.navbar-expand .navbar-vertical.navbar-vertical-fixed {
    height: 100%
}

.navbar-expand .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

.navbar-expand .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column
}

.navbar-expand .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative
}

.navbar-expand .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -.75rem;
    border-right: .0625rem solid rgba(231, 234, 243, .7);
    height: 100%;
    content: ""
}

.navbar-expand .navbar-nav-wrap-secondary-content {
    margin-left: auto
}

.navbar-expand .navbar-nav-wrap-secondary-content~.navbar-toggler {
    margin-left: .5rem
}

.navbar-expand .navbar-collapse {
    background-color: #fff
}

.navbar-expand .navbar-nav {
    padding: 1rem 1rem
}

.navbar-expand .navbar-nav-wrap .navbar-aside-toggler {
    position: static
}

.navbar-expand .navbar-nav .nav-link,
.navbar-expand .navbar-nav .nav-subtitle {
    padding: .5rem 0
}

.navbar-expand .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important
}

.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem
}

.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: .4375rem;
    width: .125rem;
    height: calc(100% - .25rem);
    content: "";
    background-color: rgba(231, 234, 243, .7)
}

.navbar-expand .navbar-dropdown-menu,
.navbar-expand .navbar-dropdown-submenu {
    position: absolute
}

.navbar-expand .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0
}

.navbar-expand.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0
}

.navbar-expand .navbar-btn {
    padding: .35rem .5rem
}

.navbar-expand .hs-sub-menu-opened>.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.navbar-expand .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0);
    transform: rotate(0)
}

.navbar-expand .dropdown-menu .hs-sub-menu-opened>.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.navbar-expand .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.navbar-expand .hs-mega-menu .hs-mega-menu,
.navbar-expand .hs-mega-menu .hs-sub-menu,
.navbar-expand .hs-menu-vertical .hs-mega-menu,
.navbar-expand .hs-menu-vertical .hs-sub-menu,
.navbar-expand .hs-sub-menu .hs-mega-menu,
.navbar-expand .hs-sub-menu .hs-sub-menu {
    left: 0
}

.navbar-expand .navbar-topbar {
    margin-bottom: .4375rem
}

.navbar-expand.navbar-dark .navbar-nav {
    background-color: #132144
}

.navbar-expand.navbar-vertical .navbar-nav {
    padding: 0
}

.navbar-expand .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -.375rem
}

.navbar-expand .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative
}

.navbar-expand .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -.375rem;
    right: 0;
    border-bottom: .0625rem solid rgba(231, 234, 243, .7);
    width: 100%;
    content: ""
}

.navbar-expand .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0
}

.navbar-expand .navbar-absolute-top-inner {
    background-color: #fff
}

.navbar-expand .navbar.navbar-vertical-detached {
    position: relative;
    width: auto
}

.navbar-expand .navbar.navbar-vertical-detached .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none
}

.navbar-expand~.sidebar-detached-content {
    margin-left: 0
}

.navbar-expand .dropdown-menu-form-search {
    position: fixed;
    top: 0 !important;
    left: 0;
    height: 100%;
    margin-top: 0
}

.navbar-expand .dropdown-menu-form-search .card-body-height {
    height: 100%
}

@media (max-width:575.98px) {
    .navbar-expand-sm .navbar-nav-wrap-secondary-content {
        margin-left: auto
    }

    .navbar-expand-sm .navbar-nav-wrap-secondary-content~.navbar-toggler {
        margin-left: .5rem
    }

    .navbar-expand-sm .navbar-collapse {
        background-color: #fff
    }

    .navbar-expand-sm .navbar-nav {
        padding: 1rem 1rem
    }

    .navbar-expand-sm .navbar-nav-wrap .navbar-aside-toggler {
        position: static
    }

    .navbar-expand-sm .navbar-nav .nav-link,
    .navbar-expand-sm .navbar-nav .nav-subtitle {
        padding: .5rem 0
    }

    .navbar-expand-sm .hs-mega-menu {
        max-width: 100% !important;
        min-width: 100% !important
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
        position: relative;
        box-shadow: none;
        border-top-width: 0;
        margin-top: 0;
        border-radius: 0;
        padding-left: 1rem
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
        position: absolute;
        top: 0;
        left: .4375rem;
        width: .125rem;
        height: calc(100% - .25rem);
        content: "";
        background-color: rgba(231, 234, 243, .7)
    }

    .navbar-expand-sm .navbar-dropdown-menu,
    .navbar-expand-sm .navbar-dropdown-submenu {
        position: absolute
    }

    .navbar-expand-sm .navbar-dropdown-sub-menu {
        position: static;
        box-shadow: none;
        padding: 0;
        margin-top: 0
    }

    .navbar-expand-sm.navbar-dark .navbar-nav .dropdown-menu {
        border-left-width: 0
    }

    .navbar-expand-sm .navbar-btn {
        padding: .35rem .5rem
    }

    .navbar-expand-sm .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-sm .dropdown-menu .dropdown-item.dropdown-toggle::after {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    .navbar-expand-sm .dropdown-menu .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-sm .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-sm .hs-mega-menu .hs-mega-menu,
    .navbar-expand-sm .hs-mega-menu .hs-sub-menu,
    .navbar-expand-sm .hs-menu-vertical .hs-mega-menu,
    .navbar-expand-sm .hs-menu-vertical .hs-sub-menu,
    .navbar-expand-sm .hs-sub-menu .hs-mega-menu,
    .navbar-expand-sm .hs-sub-menu .hs-sub-menu {
        left: 0
    }

    .navbar-expand-sm .navbar-topbar {
        margin-bottom: .4375rem
    }

    .navbar-expand-sm.navbar-dark .navbar-nav {
        background-color: #132144
    }

    .navbar-expand-sm.navbar-vertical .navbar-nav {
        padding: 0
    }

    .navbar-expand-sm .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -.375rem
    }

    .navbar-expand-sm .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
        position: relative
    }

    .navbar-expand-sm .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
        position: absolute;
        bottom: -.375rem;
        right: 0;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7);
        width: 100%;
        content: ""
    }

    .navbar-expand-sm .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0
    }

    .navbar-expand-sm .navbar-absolute-top-inner {
        background-color: #fff
    }

    .navbar-expand-sm .navbar.navbar-vertical-detached {
        position: relative;
        width: auto
    }

    .navbar-expand-sm .navbar.navbar-vertical-detached .navbar-vertical-container {
        height: auto;
        min-height: auto;
        max-height: none
    }

    .navbar-expand-sm~.sidebar-detached-content {
        margin-left: 0
    }

    .navbar-expand-sm .dropdown-menu-form-search {
        position: fixed;
        top: 0 !important;
        left: 0;
        height: 100%;
        margin-top: 0
    }

    .navbar-expand-sm .dropdown-menu-form-search .card-body-height {
        height: 100%
    }
}

@media (max-width:767.98px) {
    .navbar-expand-md .navbar-nav-wrap-secondary-content {
        margin-left: auto
    }

    .navbar-expand-md .navbar-nav-wrap-secondary-content~.navbar-toggler {
        margin-left: .5rem
    }

    .navbar-expand-md .navbar-collapse {
        background-color: #fff
    }

    .navbar-expand-md .navbar-nav {
        padding: 1rem 1rem
    }

    .navbar-expand-md .navbar-nav-wrap .navbar-aside-toggler {
        position: static
    }

    .navbar-expand-md .navbar-nav .nav-link,
    .navbar-expand-md .navbar-nav .nav-subtitle {
        padding: .5rem 0
    }

    .navbar-expand-md .hs-mega-menu {
        max-width: 100% !important;
        min-width: 100% !important
    }

    .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
        position: relative;
        box-shadow: none;
        border-top-width: 0;
        margin-top: 0;
        border-radius: 0;
        padding-left: 1rem
    }

    .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
        position: absolute;
        top: 0;
        left: .4375rem;
        width: .125rem;
        height: calc(100% - .25rem);
        content: "";
        background-color: rgba(231, 234, 243, .7)
    }

    .navbar-expand-md .navbar-dropdown-menu,
    .navbar-expand-md .navbar-dropdown-submenu {
        position: absolute
    }

    .navbar-expand-md .navbar-dropdown-sub-menu {
        position: static;
        box-shadow: none;
        padding: 0;
        margin-top: 0
    }

    .navbar-expand-md.navbar-dark .navbar-nav .dropdown-menu {
        border-left-width: 0
    }

    .navbar-expand-md .navbar-btn {
        padding: .35rem .5rem
    }

    .navbar-expand-md .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-md .dropdown-menu .dropdown-item.dropdown-toggle::after {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    .navbar-expand-md .dropdown-menu .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-md .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-md .hs-mega-menu .hs-mega-menu,
    .navbar-expand-md .hs-mega-menu .hs-sub-menu,
    .navbar-expand-md .hs-menu-vertical .hs-mega-menu,
    .navbar-expand-md .hs-menu-vertical .hs-sub-menu,
    .navbar-expand-md .hs-sub-menu .hs-mega-menu,
    .navbar-expand-md .hs-sub-menu .hs-sub-menu {
        left: 0
    }

    .navbar-expand-md .navbar-topbar {
        margin-bottom: .4375rem
    }

    .navbar-expand-md.navbar-dark .navbar-nav {
        background-color: #132144
    }

    .navbar-expand-md.navbar-vertical .navbar-nav {
        padding: 0
    }

    .navbar-expand-md .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -.375rem
    }

    .navbar-expand-md .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
        position: relative
    }

    .navbar-expand-md .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
        position: absolute;
        bottom: -.375rem;
        right: 0;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7);
        width: 100%;
        content: ""
    }

    .navbar-expand-md .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0
    }

    .navbar-expand-md .navbar-absolute-top-inner {
        background-color: #fff
    }

    .navbar-expand-md .navbar.navbar-vertical-detached {
        position: relative;
        width: auto
    }

    .navbar-expand-md .navbar.navbar-vertical-detached .navbar-vertical-container {
        height: auto;
        min-height: auto;
        max-height: none
    }

    .navbar-expand-md~.sidebar-detached-content {
        margin-left: 0
    }

    .navbar-expand-md .dropdown-menu-form-search {
        position: fixed;
        top: 0 !important;
        left: 0;
        height: 100%;
        margin-top: 0
    }

    .navbar-expand-md .dropdown-menu-form-search .card-body-height {
        height: 100%
    }
}

@media (max-width:991.98px) {
    .navbar-expand-lg .navbar-nav-wrap-secondary-content {
        margin-left: auto
    }

    .navbar-expand-lg .navbar-nav-wrap-secondary-content~.navbar-toggler {
        margin-left: .5rem
    }

    .navbar-expand-lg .navbar-collapse {
        background-color: #fff
    }

    .navbar-expand-lg .navbar-nav {
        padding: 1rem 1rem
    }

    .navbar-expand-lg .navbar-nav-wrap .navbar-aside-toggler {
        position: static
    }

    .navbar-expand-lg .navbar-nav .nav-link,
    .navbar-expand-lg .navbar-nav .nav-subtitle {
        padding: .5rem 0
    }

    .navbar-expand-lg .hs-mega-menu {
        max-width: 100% !important;
        min-width: 100% !important
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
        position: relative;
        box-shadow: none;
        border-top-width: 0;
        margin-top: 0;
        border-radius: 0;
        padding-left: 1rem
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
        position: absolute;
        top: 0;
        left: .4375rem;
        width: .125rem;
        height: calc(100% - .25rem);
        content: "";
        background-color: rgba(231, 234, 243, .7)
    }

    .navbar-expand-lg .navbar-dropdown-menu,
    .navbar-expand-lg .navbar-dropdown-submenu {
        position: absolute
    }

    .navbar-expand-lg .navbar-dropdown-sub-menu {
        position: static;
        box-shadow: none;
        padding: 0;
        margin-top: 0
    }

    .navbar-expand-lg.navbar-dark .navbar-nav .dropdown-menu {
        border-left-width: 0
    }

    .navbar-expand-lg .navbar-btn {
        padding: .35rem .5rem
    }

    .navbar-expand-lg .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-lg .dropdown-menu .dropdown-item.dropdown-toggle::after {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    .navbar-expand-lg .dropdown-menu .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-lg .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-lg .hs-mega-menu .hs-mega-menu,
    .navbar-expand-lg .hs-mega-menu .hs-sub-menu,
    .navbar-expand-lg .hs-menu-vertical .hs-mega-menu,
    .navbar-expand-lg .hs-menu-vertical .hs-sub-menu,
    .navbar-expand-lg .hs-sub-menu .hs-mega-menu,
    .navbar-expand-lg .hs-sub-menu .hs-sub-menu {
        left: 0
    }

    .navbar-expand-lg .navbar-topbar {
        margin-bottom: .4375rem
    }

    .navbar-expand-lg.navbar-dark .navbar-nav {
        background-color: #132144
    }

    .navbar-expand-lg.navbar-vertical .navbar-nav {
        padding: 0
    }

    .navbar-expand-lg .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -.375rem
    }

    .navbar-expand-lg .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
        position: relative
    }

    .navbar-expand-lg .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
        position: absolute;
        bottom: -.375rem;
        right: 0;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7);
        width: 100%;
        content: ""
    }

    .navbar-expand-lg .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0
    }

    .navbar-expand-lg .navbar-absolute-top-inner {
        background-color: #fff
    }

    .navbar-expand-lg .navbar.navbar-vertical-detached {
        position: relative;
        width: auto
    }

    .navbar-expand-lg .navbar.navbar-vertical-detached .navbar-vertical-container {
        height: auto;
        min-height: auto;
        max-height: none
    }

    .navbar-expand-lg~.sidebar-detached-content {
        margin-left: 0
    }

    .navbar-expand-lg .dropdown-menu-form-search {
        position: fixed;
        top: 0 !important;
        left: 0;
        height: 100%;
        margin-top: 0
    }

    .navbar-expand-lg .dropdown-menu-form-search .card-body-height {
        height: 100%
    }
}

@media (max-width:1199.98px) {
    .navbar-expand-xl .navbar-nav-wrap-secondary-content {
        margin-left: auto
    }

    .navbar-expand-xl .navbar-nav-wrap-secondary-content~.navbar-toggler {
        margin-left: .5rem
    }

    .navbar-expand-xl .navbar-collapse {
        background-color: #fff
    }

    .navbar-expand-xl .navbar-nav {
        padding: 1rem 1rem
    }

    .navbar-expand-xl .navbar-nav-wrap .navbar-aside-toggler {
        position: static
    }

    .navbar-expand-xl .navbar-nav .nav-link,
    .navbar-expand-xl .navbar-nav .nav-subtitle {
        padding: .5rem 0
    }

    .navbar-expand-xl .hs-mega-menu {
        max-width: 100% !important;
        min-width: 100% !important
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
        position: relative;
        box-shadow: none;
        border-top-width: 0;
        margin-top: 0;
        border-radius: 0;
        padding-left: 1rem
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
        position: absolute;
        top: 0;
        left: .4375rem;
        width: .125rem;
        height: calc(100% - .25rem);
        content: "";
        background-color: rgba(231, 234, 243, .7)
    }

    .navbar-expand-xl .navbar-dropdown-menu,
    .navbar-expand-xl .navbar-dropdown-submenu {
        position: absolute
    }

    .navbar-expand-xl .navbar-dropdown-sub-menu {
        position: static;
        box-shadow: none;
        padding: 0;
        margin-top: 0
    }

    .navbar-expand-xl.navbar-dark .navbar-nav .dropdown-menu {
        border-left-width: 0
    }

    .navbar-expand-xl .navbar-btn {
        padding: .35rem .5rem
    }

    .navbar-expand-xl .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-xl .dropdown-menu .dropdown-item.dropdown-toggle::after {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    .navbar-expand-xl .dropdown-menu .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-xl .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-xl .hs-mega-menu .hs-mega-menu,
    .navbar-expand-xl .hs-mega-menu .hs-sub-menu,
    .navbar-expand-xl .hs-menu-vertical .hs-mega-menu,
    .navbar-expand-xl .hs-menu-vertical .hs-sub-menu,
    .navbar-expand-xl .hs-sub-menu .hs-mega-menu,
    .navbar-expand-xl .hs-sub-menu .hs-sub-menu {
        left: 0
    }

    .navbar-expand-xl .navbar-topbar {
        margin-bottom: .4375rem
    }

    .navbar-expand-xl.navbar-dark .navbar-nav {
        background-color: #132144
    }

    .navbar-expand-xl.navbar-vertical .navbar-nav {
        padding: 0
    }

    .navbar-expand-xl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -.375rem
    }

    .navbar-expand-xl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
        position: relative
    }

    .navbar-expand-xl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
        position: absolute;
        bottom: -.375rem;
        right: 0;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7);
        width: 100%;
        content: ""
    }

    .navbar-expand-xl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0
    }

    .navbar-expand-xl .navbar-absolute-top-inner {
        background-color: #fff
    }

    .navbar-expand-xl .navbar.navbar-vertical-detached {
        position: relative;
        width: auto
    }

    .navbar-expand-xl .navbar.navbar-vertical-detached .navbar-vertical-container {
        height: auto;
        min-height: auto;
        max-height: none
    }

    .navbar-expand-xl~.sidebar-detached-content {
        margin-left: 0
    }

    .navbar-expand-xl .dropdown-menu-form-search {
        position: fixed;
        top: 0 !important;
        left: 0;
        height: 100%;
        margin-top: 0
    }

    .navbar-expand-xl .dropdown-menu-form-search .card-body-height {
        height: 100%
    }
}

@media (max-width:1399.98px) {
    .navbar-expand-xxl .navbar-nav-wrap-secondary-content {
        margin-left: auto
    }

    .navbar-expand-xxl .navbar-nav-wrap-secondary-content~.navbar-toggler {
        margin-left: .5rem
    }

    .navbar-expand-xxl .navbar-collapse {
        background-color: #fff
    }

    .navbar-expand-xxl .navbar-nav {
        padding: 1rem 1rem
    }

    .navbar-expand-xxl .navbar-nav-wrap .navbar-aside-toggler {
        position: static
    }

    .navbar-expand-xxl .navbar-nav .nav-link,
    .navbar-expand-xxl .navbar-nav .nav-subtitle {
        padding: .5rem 0
    }

    .navbar-expand-xxl .hs-mega-menu {
        max-width: 100% !important;
        min-width: 100% !important
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
        position: relative;
        box-shadow: none;
        border-top-width: 0;
        margin-top: 0;
        border-radius: 0;
        padding-left: 1rem
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
        position: absolute;
        top: 0;
        left: .4375rem;
        width: .125rem;
        height: calc(100% - .25rem);
        content: "";
        background-color: rgba(231, 234, 243, .7)
    }

    .navbar-expand-xxl .navbar-dropdown-menu,
    .navbar-expand-xxl .navbar-dropdown-submenu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-dropdown-sub-menu {
        position: static;
        box-shadow: none;
        padding: 0;
        margin-top: 0
    }

    .navbar-expand-xxl.navbar-dark .navbar-nav .dropdown-menu {
        border-left-width: 0
    }

    .navbar-expand-xxl .navbar-btn {
        padding: .35rem .5rem
    }

    .navbar-expand-xxl .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-xxl .dropdown-menu .dropdown-item.dropdown-toggle::after {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    .navbar-expand-xxl .dropdown-menu .hs-sub-menu-opened>.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-xxl .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .navbar-expand-xxl .hs-mega-menu .hs-mega-menu,
    .navbar-expand-xxl .hs-mega-menu .hs-sub-menu,
    .navbar-expand-xxl .hs-menu-vertical .hs-mega-menu,
    .navbar-expand-xxl .hs-menu-vertical .hs-sub-menu,
    .navbar-expand-xxl .hs-sub-menu .hs-mega-menu,
    .navbar-expand-xxl .hs-sub-menu .hs-sub-menu {
        left: 0
    }

    .navbar-expand-xxl .navbar-topbar {
        margin-bottom: .4375rem
    }

    .navbar-expand-xxl.navbar-dark .navbar-nav {
        background-color: #132144
    }

    .navbar-expand-xxl.navbar-vertical .navbar-nav {
        padding: 0
    }

    .navbar-expand-xxl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -.375rem
    }

    .navbar-expand-xxl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
        position: relative
    }

    .navbar-expand-xxl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
        position: absolute;
        bottom: -.375rem;
        right: 0;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7);
        width: 100%;
        content: ""
    }

    .navbar-expand-xxl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0
    }

    .navbar-expand-xxl .navbar-absolute-top-inner {
        background-color: #fff
    }

    .navbar-expand-xxl .navbar.navbar-vertical-detached {
        position: relative;
        width: auto
    }

    .navbar-expand-xxl .navbar.navbar-vertical-detached .navbar-vertical-container {
        height: auto;
        min-height: auto;
        max-height: none
    }

    .navbar-expand-xxl~.sidebar-detached-content {
        margin-left: 0
    }

    .navbar-expand-xxl .dropdown-menu-form-search {
        position: fixed;
        top: 0 !important;
        left: 0;
        height: 100%;
        margin-top: 0
    }

    .navbar-expand-xxl .dropdown-menu-form-search .card-body-height {
        height: 100%
    }
}

.navbar-expand .navbar-nav .nav-link,
.navbar-expand .navbar-nav .nav-subtitle {
    padding-right: 1rem;
    padding-left: 1rem
}

@media (min-width:992px) and (max-width:1199.98px) {
    [class*=navbar-expand] .navbar-nav-wrap .navbar-aside-toggler {
        display: block
    }
}

.navbar-vertical.navbar {
    display: block;
    padding: 0
}

.navbar-vertical.navbar.collapse:not(.show) {
    display: none
}

.navbar-vertical.navbar .nav-subtitle-replacer {
    display: none;
    color: #8c98a4;
    text-align: center;
    font-size: 1.3125rem;
    padding: .5rem 0
}

.navbar-vertical-container {
    position: relative;
    height: 100%;
    min-height: 100%
}

.navbar-vertical-fixed .navbar-vertical-container {
    max-height: 100%
}

.navbar-vertical-content {
    overflow: hidden
}

.navbar-vertical .nav-compact,
.navbar-vertical .nav-compact-icon {
    -ms-flex-direction: column;
    flex-direction: column
}

.navbar-vertical .nav-compact .nav-link-title,
.navbar-vertical .nav-compact-icon .nav-link-title {
    max-width: 8.5rem
}

.navbar-vertical .nav-compact .nav-link,
.navbar-vertical .nav-compact-icon .nav-link {
    display: block
}

.navbar-vertical .nav-compact .nav-tabs .nav-collapse .nav-item .nav-link,
.navbar-vertical .nav-compact-icon .nav-tabs .nav-collapse .nav-item .nav-link {
    display: -ms-flexbox;
    display: flex
}

.navbar-vertical-absolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 16.25rem;
    margin-left: -16.25rem
}

.navbar-vertical-fixed {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    width: 16.25rem;
    margin-left: -16.25rem
}

.navbar-vertical-fixed .navbar-vertical-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0
}

.navbar-vertical-fixed .navbar-vertical-footer-offset {
    height: 100%;
    padding-bottom: 4.25rem
}

.navbar-vertical.navbar-bordered {
    border-width: 0 .0625rem 0 0
}

.navbar-vertical-footer {
    border-top: .0625rem solid rgba(231, 234, 243, .7);
    padding: .75rem 2rem
}

.navbar-vertical-footer-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0
}

.navbar-vertical-footer-list .navbar-vertical-footer-list-item {
    position: relative;
    margin: 0 .5rem
}

.navbar-vertical-footer-dropdown {
    margin-bottom: 1.5rem
}

.navbar-vertical-detached.navbar {
    top: auto;
    left: auto;
    width: 16.25rem;
    z-index: 98;
    margin-left: 0;
    bottom: auto
}

.navbar-vertical-detached.navbar .navbar-vertical-footer {
    position: static
}

.navbar-vertical-detached .card-navbar-nav .navbar-nav {
    padding: 0
}

.navbar-vertical-detached .card-navbar-nav .nav-tabs {
    margin-left: -.5rem
}

.navbar-vertical-detached .card-navbar-nav .nav-link {
    padding-left: 1.5rem
}

.navbar-vertical-detached.navbar-vertical .nav-tabs .nav-collapse .nav-link {
    padding-left: 3.5rem
}

.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav-link,
.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav-subtitle {
    padding-right: 0;
    padding-left: 0
}

.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav .nav-link {
    padding-right: 0;
    padding-left: 2.1875rem
}

.navbar-vertical-aside:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .nav-link {
    padding-left: 2rem;
    padding-right: 2rem
}

.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .dropdown-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .nav-collapse .nav-link {
    padding-right: 2rem;
    padding-left: 3.91875rem
}

.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .nav-collapse .nav-collapse .nav-link {
    padding-left: 4.66875rem
}

.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .navbar-nav .nav-collapse .nav-link {
    padding-right: 4.66875rem;
    padding-left: 3rem
}

.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .nav-item.show>.nav-link {
    color: #377dff
}

.navbar-vertical-aside-mini-mode .navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .show>.nav-link[aria-expanded=true] {
    border-color: #377dff
}

.navbar-vertical-aside-mini-mode .navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .show>.nav-link[aria-expanded=true].dropdown-toggle::before {
    background-color: #377dff
}

.navbar-vertical-aside-mini-mode .navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .show .nav-item.show>.nav-link {
    border-color: transparent
}

.navbar-vertical-aside-mini-mode .navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .nav-collapse .nav-link {
    padding-left: 2rem;
    padding-right: 2rem
}

.navbar-vertical-aside-mini-mode .navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-vertical.nav-tabs .nav-collapse .nav-collapse .nav-link {
    padding-left: 2rem
}

.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-subtitle,
.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-subtitle-replacer,
.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .nav-title {
    padding-right: 0;
    padding-left: 0
}

.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .navbar-nav.nav-compact .nav-link,
.navbar-vertical:not(.splitted-content-navbar):not([class*=container]) .navbar-nav.nav-compact-icon .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-vertical-aside-transition-on .footer,
.navbar-vertical-aside-transition-on .main,
.navbar-vertical-aside-transition-on .navbar,
.navbar-vertical-aside-transition-on .navbar-vertical-aside {
    transition: .2s ease-in-out
}

.navbar-vertical-aside-transition-on .navbar-vertical-aside .navbar-vertical-footer {
    opacity: 0
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode .main {
    padding-left: 0
}

.navbar-fixed~.main .navbar-vertical-aside {
    top: 0
}

[class*=navbar-vertical-aside-show] .navbar.splitted-content-navbar .navbar-brand {
    display: block
}

[class*=navbar-vertical-aside-show] .navbar-vertical.splitted-content-navbar .navbar-brand {
    display: -ms-flexbox;
    display: flex
}

[class*=navbar-vertical-aside-show] .navbar-nav-wrap-content-left {
    padding-left: 0
}

.navbar-vertical-aside-mini-mode-hidden-elements {
    -ms-flex: 1;
    flex: 1
}

.navbar-vertical-aside .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 3.875rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem
}

.navbar-vertical-aside .navbar-brand-logo {
    display: block
}

.navbar-vertical-aside .navbar-brand-logo-mini {
    width: 1.9125rem;
    display: none
}

.navbar-aside-toggler {
    opacity: 0;
    z-index: 1000;
    width: 2.40625rem;
    height: 2.40625rem;
    font-size: .875rem;
    color: #677788;
    background-color: #fff;
    border: .0625rem solid rgba(231, 234, 243, .7);
    border-radius: 50%;
    transition: .4s
}

.navbar-vertical-aside .navbar-aside-toggler {
    position: absolute;
    top: .734375rem;
    right: -1.203125rem
}

.navbar-vertical-content {
    height: calc(100% - 3.875rem);
    overflow-y: auto
}

.navbar-vertical-content::-webkit-scrollbar {
    width: .6125rem
}

.navbar-vertical-content::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, .6)
}

.nav-footer-item {
    display: none
}

.nav-footer-item:last-child {
    margin-bottom: 2rem
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
    position: fixed
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-content {
    height: 100%
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-footer {
    position: static;
    margin-top: -.0625rem
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-footer .navbar-vertical-footer-list-item {
    position: static
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-footer .navbar-vertical-footer-list-item .hs-unfold {
    position: static
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-footer-offset {
    padding-bottom: 0
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-footer-list-item {
    margin: .5rem 0
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-footer-dropdown {
    top: 0;
    left: calc(100% + 1rem);
    margin: 0
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-footer-dropdown.hs-unfold-reverse-y {
    top: auto;
    bottom: 0
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-vertical-footer-dropdown::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - .0625rem);
    width: 1.75rem;
    height: 100%;
    content: ""
}

.navbar-vertical-aside.navbar-vertical-aside-initialized {
    margin-left: 0
}

.navbar-vertical-aside-closed-mode .navbar-vertical-aside.navbar-vertical-aside-initialized {
    margin-left: -16.25rem
}

.navbar-vertical-aside-closed-mode .navbar-vertical-aside.splitted-content-navbar.navbar-vertical-aside-initialized {
    margin-left: -22rem
}

.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
    margin-left: -16.25rem
}

.navbar-vertical-aside .nav.nav-pills {
    padding-right: 1.25rem;
    padding-left: 1.25rem
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside {
    width: 9rem !important
}

.navbar-vertical-aside-compact-mode .navbar-vertical .navbar-brand {
    margin-right: 0
}

.navbar-vertical-aside-compact-mode .nav-item>.nav-collapse {
    position: absolute;
    top: 0;
    left: calc(100% + .9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding: .5rem .5rem;
    box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175);
    border-radius: .5rem
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside .nav-vertical.nav-pills .nav-collapse {
    text-align: left;
    padding-left: 1.9375rem
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside .nav-vertical.nav-pills .nav-collapse::before {
    height: 100%
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside .nav-vertical.nav-pills .nav-collapse .nav-collapse {
    position: static;
    box-shadow: none
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside .nav-collapse.collapsing {
    transition: none
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside .nav.nav-pills.nav-vertical .nav-collapse .nav-link {
    padding: .5rem 1rem
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside .nav.nav-vertical.nav-tabs .nav-collapse {
    text-align: left
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside .nav.nav-vertical.nav-tabs .nav-collapse .nav-link {
    padding-left: 2rem
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside .nav.nav-vertical.nav-tabs .nav-collapse .nav-collapse .nav-link {
    padding-left: 2.6125rem
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside .dropdown-header {
    display: none
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
    display: block
}

.navbar-vertical-aside-mini-mode .nav-collapse {
    position: absolute !important;
    top: 0;
    left: calc(100% + .9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175);
    border-radius: .3125rem
}

.navbar-vertical-aside-mini-mode .nav-collapse::before {
    position: absolute;
    top: 0;
    left: -1.0625rem;
    width: 1.125rem;
    height: calc(100% - .25rem);
    content: "";
    background: 0 0
}

.navbar-vertical-aside-mini-mode .nav-collapse .nav-link.dropdown-toggle::before {
    display: none
}

.navbar-vertical-aside-mini-mode .nav-vertical.nav-pills .nav-collapse::before {
    display: none
}

.navbar-vertical-aside-mini-mode .nav .nav-collapse {
    padding: .5rem .5rem
}

.navbar-vertical-aside-mini-mode .nav-tabs .nav-collapse .nav-collapse {
    padding-left: 0
}

.navbar-vertical-aside-mini-mode .nav-tabs .nav-item.show .nav-item.show .nav-link.active {
    border-color: #377dff
}

.navbar-vertical-aside .nav-link.dropdown-toggle {
    position: relative
}

.navbar-vertical-aside .nav-link.dropdown-toggle::before {
    position: absolute;
    top: 50%;
    left: .6125rem;
    content: "";
    width: 5px;
    height: 5px;
    background-color: #bdc5d1;
    opacity: 0;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: opacity .3s
}

.navbar-vertical-aside .nav-pills .nav-link.dropdown-toggle::before {
    left: 5px
}

.navbar-vertical-aside .nav-item.show>.nav-link.dropdown-toggle::before,
.navbar-vertical-aside .nav-link.dropdown-toggle.active::before,
.navbar-vertical-aside .nav-link.dropdown-toggle:hover::before {
    background-color: #377dff
}

.navbar-vertical-aside-mini-mode .nav-link.dropdown-toggle::before {
    opacity: 1
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand {
    margin-right: 0
}

.navbar-vertical-aside-mini-mode .nav {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.navbar-vertical-aside-mini-mode .nav-link.dropdown-toggle::after {
    display: none
}

.navbar-vertical-aside-mini-mode .nav-collapse .nav-link.dropdown-toggle::after {
    display: block
}

.navbar-vertical-aside-mini-mode .nav-collapse .nav-collapse {
    position: static !important;
    box-shadow: none;
    min-width: 100%
}

.navbar-vertical-aside-mini-mode .nav-link-title {
    display: none
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-container,
.navbar-vertical-aside-mini-mode .navbar-vertical-container {
    position: static
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-container::-webkit-scrollbar,
.navbar-vertical-aside-mini-mode .navbar-vertical-container::-webkit-scrollbar {
    width: .6125rem
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-container::-webkit-scrollbar-thumb,
.navbar-vertical-aside-mini-mode .navbar-vertical-container::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, .6)
}

.navbar-vertical-aside .splitted-content-mini {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    z-index: 1
}

.navbar-vertical-aside-mobile-overlay,
.navbar-vertical-aside-sub-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease
}

.navbar-vertical-aside-mobile-overlay {
    background-color: rgba(19, 33, 68, .25)
}

.navbar-vertical-aside-mobile-overlay-transparent {
    background-color: transparent
}

.has-navbar-vertical-aside.navbar-vertical-aside-sub-menu-opened .navbar-vertical-aside-sub-menu-overlay,
.has-navbar-vertical-aside:not(.navbar-vertical-aside-closed-mode) .navbar-vertical-aside-mobile-overlay {
    z-index: 99;
    opacity: 1;
    width: 100%;
    height: 100%
}

@media (min-width:576px) {
    .navbar-vertical.navbar-expand-sm {
        -ms-flex-flow: column;
        flex-flow: column
    }

    .navbar-vertical.navbar-expand-sm .navbar-nav {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-vertical-aside-show-sm .main {
        padding-left: 16.25rem
    }

    .navbar-vertical-aside-show-sm .footer {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-sm .navbar:not(.navbar-vertical) {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-sm .splitted-content-navbar {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .main {
        padding-left: 9rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .footer {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .navbar:not(.navbar-vertical) {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-sm.splitted-content .main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-sm.splitted-content .footer {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-sm.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-sm .navbar-vertical-aside {
        margin-left: 0
    }

    .navbar-vertical-aside-show-sm .navbar:not(.navbar-vertical-aside) .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-sm .navbar-vertical.splitted-content-navbar .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .navbar:not(.navbar-vertical) {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .main {
        padding-left: 5.75rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .footer {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
        width: 5.75rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-navbar {
        width: 22rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content .footer,
    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical),
    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 2rem
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .navbar-vertical-aside-mini-mode-hidden-elements {
        display: none
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo-mini {
        display: block
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo {
        display: none
    }

    .navbar-vertical-aside-show-sm:not(.navbar-vertical-closed-mode) .navbar-vertical-aside-mobile-overlay {
        display: none
    }

    .navbar-vertical-aside-show-sm .splitted-content-navbar .navbar-toggler-full-align,
    .navbar-vertical-aside-show-sm .splitted-content-navbar .navbar-toggler-short-align {
        display: none
    }

    .navbar-vertical-aside-show-sm .navbar-toggler,
    .navbar-vertical-aside-show-sm .navbar-vertical .navbar-toggler {
        display: none
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-initialized .navbar-vertical-content {
        overflow-y: scroll;
        overflow-y: overlay
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .navbar-vertical-aside .dropdown-header {
        display: none
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
        display: block
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .nav-footer-item {
        display: block
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .navbar-vertical-footer {
        display: none
    }

    .navbar-vertical-aside-show-sm .splitted-content-small {
        position: static;
        visibility: visible;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@media (min-width:768px) {
    .navbar-vertical.navbar-expand-md {
        -ms-flex-flow: column;
        flex-flow: column
    }

    .navbar-vertical.navbar-expand-md .navbar-nav {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-vertical-aside-show-md .main {
        padding-left: 16.25rem
    }

    .navbar-vertical-aside-show-md .footer {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-md .navbar:not(.navbar-vertical) {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-md .splitted-content-navbar {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .main {
        padding-left: 9rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .footer {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .navbar:not(.navbar-vertical) {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-md.splitted-content .main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-md.splitted-content .footer {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-md.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-md .navbar-vertical-aside {
        margin-left: 0
    }

    .navbar-vertical-aside-show-md .navbar:not(.navbar-vertical-aside) .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-md .navbar-vertical.splitted-content-navbar .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .navbar:not(.navbar-vertical) {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .main {
        padding-left: 5.75rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .footer {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
        width: 5.75rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-navbar {
        width: 22rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content .footer,
    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical),
    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 2rem
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .navbar-vertical-aside-mini-mode-hidden-elements {
        display: none
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo-mini {
        display: block
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo {
        display: none
    }

    .navbar-vertical-aside-show-md:not(.navbar-vertical-closed-mode) .navbar-vertical-aside-mobile-overlay {
        display: none
    }

    .navbar-vertical-aside-show-md .splitted-content-navbar .navbar-toggler-full-align,
    .navbar-vertical-aside-show-md .splitted-content-navbar .navbar-toggler-short-align {
        display: none
    }

    .navbar-vertical-aside-show-md .navbar-toggler,
    .navbar-vertical-aside-show-md .navbar-vertical .navbar-toggler {
        display: none
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-initialized .navbar-vertical-content {
        overflow-y: scroll;
        overflow-y: overlay
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .navbar-vertical-aside .dropdown-header {
        display: none
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
        display: block
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .nav-footer-item {
        display: block
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .navbar-vertical-footer {
        display: none
    }

    .navbar-vertical-aside-show-md .splitted-content-small {
        position: static;
        visibility: visible;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@media (min-width:992px) {
    .navbar-vertical.navbar-expand-lg {
        -ms-flex-flow: column;
        flex-flow: column
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-vertical-aside-show-lg .main {
        padding-left: 16.25rem
    }

    .navbar-vertical-aside-show-lg .footer {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-lg .navbar:not(.navbar-vertical) {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-lg .splitted-content-navbar {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .main {
        padding-left: 9rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .footer {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .navbar:not(.navbar-vertical) {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-lg.splitted-content .main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-lg.splitted-content .footer {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-lg.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-lg .navbar-vertical-aside {
        margin-left: 0
    }

    .navbar-vertical-aside-show-lg .navbar:not(.navbar-vertical-aside) .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-lg .navbar-vertical.splitted-content-navbar .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .navbar:not(.navbar-vertical) {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .main {
        padding-left: 5.75rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .footer {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
        width: 5.75rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-navbar {
        width: 22rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content .footer,
    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical),
    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 2rem
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .navbar-vertical-aside-mini-mode-hidden-elements {
        display: none
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo-mini {
        display: block
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo {
        display: none
    }

    .navbar-vertical-aside-show-lg:not(.navbar-vertical-closed-mode) .navbar-vertical-aside-mobile-overlay {
        display: none
    }

    .navbar-vertical-aside-show-lg .splitted-content-navbar .navbar-toggler-full-align,
    .navbar-vertical-aside-show-lg .splitted-content-navbar .navbar-toggler-short-align {
        display: none
    }

    .navbar-vertical-aside-show-lg .navbar-toggler,
    .navbar-vertical-aside-show-lg .navbar-vertical .navbar-toggler {
        display: none
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-initialized .navbar-vertical-content {
        overflow-y: scroll;
        overflow-y: overlay
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .navbar-vertical-aside .dropdown-header {
        display: none
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
        display: block
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .nav-footer-item {
        display: block
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .navbar-vertical-footer {
        display: none
    }

    .navbar-vertical-aside-show-lg .splitted-content-small {
        position: static;
        visibility: visible;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@media (min-width:1200px) {
    .navbar-vertical.navbar-expand-xl {
        -ms-flex-flow: column;
        flex-flow: column
    }

    .navbar-vertical.navbar-expand-xl .navbar-nav {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-vertical-aside-show-xl .main {
        padding-left: 16.25rem
    }

    .navbar-vertical-aside-show-xl .footer {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical) {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-xl .splitted-content-navbar {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .main {
        padding-left: 9rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .footer {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .navbar:not(.navbar-vertical) {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-xl.splitted-content .main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-xl.splitted-content .footer {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-xl.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-xl .navbar-vertical-aside {
        margin-left: 0
    }

    .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical-aside) .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-xl .navbar-vertical.splitted-content-navbar .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar:not(.navbar-vertical) {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .main {
        padding-left: 5.75rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .footer {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
        width: 5.75rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-navbar {
        width: 22rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content .footer,
    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical),
    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 2rem
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar-vertical-aside-mini-mode-hidden-elements {
        display: none
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo-mini {
        display: block
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo {
        display: none
    }

    .navbar-vertical-aside-show-xl:not(.navbar-vertical-closed-mode) .navbar-vertical-aside-mobile-overlay {
        display: none
    }

    .navbar-vertical-aside-show-xl .splitted-content-navbar .navbar-toggler-full-align,
    .navbar-vertical-aside-show-xl .splitted-content-navbar .navbar-toggler-short-align {
        display: none
    }

    .navbar-vertical-aside-show-xl .navbar-toggler,
    .navbar-vertical-aside-show-xl .navbar-vertical .navbar-toggler {
        display: none
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-initialized .navbar-vertical-content {
        overflow-y: scroll;
        overflow-y: overlay
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .dropdown-header {
        display: none
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
        display: block
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .nav-footer-item {
        display: block
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar-vertical-footer {
        display: none
    }

    .navbar-vertical-aside-show-xl .splitted-content-small {
        position: static;
        visibility: visible;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@media (min-width:1400px) {
    .navbar-vertical.navbar-expand-xxl {
        -ms-flex-flow: column;
        flex-flow: column
    }

    .navbar-vertical.navbar-expand-xxl .navbar-nav {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-vertical-aside-show-xxl .main {
        padding-left: 16.25rem
    }

    .navbar-vertical-aside-show-xxl .footer {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-xxl .navbar:not(.navbar-vertical) {
        margin-left: 16.25rem
    }

    .navbar-vertical-aside-show-xxl .splitted-content-navbar {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .main {
        padding-left: 9rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .footer {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .navbar:not(.navbar-vertical) {
        margin-left: 9rem
    }

    .navbar-vertical-aside-show-xxl.splitted-content .main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-xxl.splitted-content .footer {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-xxl.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 22rem
    }

    .navbar-vertical-aside-show-xxl .navbar-vertical-aside {
        margin-left: 0
    }

    .navbar-vertical-aside-show-xxl .navbar:not(.navbar-vertical-aside) .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-xxl .navbar-vertical.splitted-content-navbar .navbar-brand {
        display: none
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .navbar:not(.navbar-vertical) {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .main {
        padding-left: 5.75rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .footer {
        margin-left: 5.75rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
        width: 5.75rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-navbar {
        width: 22rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content .footer,
    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical),
    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-main {
        padding-left: 22rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical) {
        margin-left: 2rem
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .navbar-vertical-aside-mini-mode-hidden-elements {
        display: none
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo-mini {
        display: block
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo {
        display: none
    }

    .navbar-vertical-aside-show-xxl:not(.navbar-vertical-closed-mode) .navbar-vertical-aside-mobile-overlay {
        display: none
    }

    .navbar-vertical-aside-show-xxl .splitted-content-navbar .navbar-toggler-full-align,
    .navbar-vertical-aside-show-xxl .splitted-content-navbar .navbar-toggler-short-align {
        display: none
    }

    .navbar-vertical-aside-show-xxl .navbar-toggler,
    .navbar-vertical-aside-show-xxl .navbar-vertical .navbar-toggler {
        display: none
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-initialized .navbar-vertical-content {
        overflow-y: scroll;
        overflow-y: overlay
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .dropdown-header {
        display: none
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
        display: block
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .nav-footer-item {
        display: block
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .navbar-vertical-footer {
        display: none
    }

    .navbar-vertical-aside-show-xxl .splitted-content-small {
        position: static;
        visibility: visible;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.navbar-vertical.navbar-expand {
    -ms-flex-flow: column;
    flex-flow: column
}

.navbar-vertical.navbar-expand .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column
}

.navbar-vertical-aside-show .main {
    padding-left: 16.25rem
}

.navbar-vertical-aside-show .footer {
    margin-left: 16.25rem
}

.navbar-vertical-aside-show .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem
}

.navbar-vertical-aside-show .splitted-content-navbar {
    margin-left: 22rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .navbar:not(.navbar-vertical) {
    margin-left: 9rem
}

.navbar-vertical-aside-show.splitted-content .main {
    padding-left: 22rem
}

.navbar-vertical-aside-show.splitted-content .footer {
    margin-left: 22rem
}

.navbar-vertical-aside-show.splitted-content .navbar:not(.navbar-vertical) {
    margin-left: 22rem
}

.navbar-vertical-aside-show .navbar-vertical-aside {
    margin-left: 0
}

.navbar-vertical-aside-show .navbar:not(.navbar-vertical-aside) .navbar-brand {
    display: none
}

.navbar-vertical-aside-show .navbar-vertical.splitted-content-navbar .navbar-brand {
    display: none
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .navbar:not(.navbar-vertical) {
    margin-left: 5.75rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.75rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.75rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
    width: 5.75rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-navbar {
    width: 22rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content .footer,
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical),
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-main {
    padding-left: 22rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content .navbar:not(.navbar-vertical) {
    margin-left: 2rem
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo-mini {
    display: block
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo {
    display: none
}

.navbar-vertical-aside-show:not(.navbar-vertical-closed-mode) .navbar-vertical-aside-mobile-overlay {
    display: none
}

.navbar-vertical-aside-show .splitted-content-navbar .navbar-toggler-full-align,
.navbar-vertical-aside-show .splitted-content-navbar .navbar-toggler-short-align {
    display: none
}

.navbar-vertical-aside-show .navbar-toggler,
.navbar-vertical-aside-show .navbar-vertical .navbar-toggler {
    display: none
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-initialized .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .navbar-vertical-aside .dropdown-header {
    display: none
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
    display: block
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .nav-footer-item {
    display: block
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .navbar-vertical-footer {
    display: none
}

.navbar-vertical-aside-show .splitted-content-small {
    position: static;
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.navbar-vertical-aside-show .navbar-brand-logo-mini {
    display: none
}

.navbar-vertical-aside-show.has-navbar-vertical-aside .navbar-height {
    height: 3.875rem
}

.navbar-vertical-aside-show.has-navbar-vertical-aside .navbar-nav {
    display: -ms-flexbox;
    display: flex
}

.navbar-vertical-aside-show.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
    margin-left: -17.25rem
}

@media (max-width:575.98px) {
    .navbar-vertical-aside-show-sm .navbar-brand-logo-mini {
        display: none
    }

    .navbar-vertical-aside-show-sm.has-navbar-vertical-aside .navbar-height {
        height: 3.875rem
    }

    .navbar-vertical-aside-show-sm.has-navbar-vertical-aside .navbar-nav {
        display: -ms-flexbox;
        display: flex
    }

    .navbar-vertical-aside-show-sm.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
        margin-left: -17.25rem
    }
}

@media (max-width:767.98px) {
    .navbar-vertical-aside-show-md .navbar-brand-logo-mini {
        display: none
    }

    .navbar-vertical-aside-show-md.has-navbar-vertical-aside .navbar-height {
        height: 3.875rem
    }

    .navbar-vertical-aside-show-md.has-navbar-vertical-aside .navbar-nav {
        display: -ms-flexbox;
        display: flex
    }

    .navbar-vertical-aside-show-md.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
        margin-left: -17.25rem
    }
}

@media (max-width:991.98px) {
    .navbar-vertical-aside-show-lg .navbar-brand-logo-mini {
        display: none
    }

    .navbar-vertical-aside-show-lg.has-navbar-vertical-aside .navbar-height {
        height: 3.875rem
    }

    .navbar-vertical-aside-show-lg.has-navbar-vertical-aside .navbar-nav {
        display: -ms-flexbox;
        display: flex
    }

    .navbar-vertical-aside-show-lg.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
        margin-left: -17.25rem
    }
}

@media (max-width:1199.98px) {
    .navbar-vertical-aside-show-xl .navbar-brand-logo-mini {
        display: none
    }

    .navbar-vertical-aside-show-xl.has-navbar-vertical-aside .navbar-height {
        height: 3.875rem
    }

    .navbar-vertical-aside-show-xl.has-navbar-vertical-aside .navbar-nav {
        display: -ms-flexbox;
        display: flex
    }

    .navbar-vertical-aside-show-xl.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
        margin-left: -17.25rem
    }
}

@media (max-width:1399.98px) {
    .navbar-vertical-aside-show-xxl .navbar-brand-logo-mini {
        display: none
    }

    .navbar-vertical-aside-show-xxl.has-navbar-vertical-aside .navbar-height {
        height: 3.875rem
    }

    .navbar-vertical-aside-show-xxl.has-navbar-vertical-aside .navbar-nav {
        display: -ms-flexbox;
        display: flex
    }

    .navbar-vertical-aside-show-xxl.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
        margin-left: -17.25rem
    }
}

.has-navbar-vertical-aside .navbar-toggler-short-align {
    display: block
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode .navbar-toggler-short-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode .navbar-toggler-short-align {
    display: none
}

.has-navbar-vertical-aside .navbar-toggler-full-align {
    display: none
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode .navbar-toggler-full-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode .navbar-toggler-full-align {
    display: block
}

.splitted-content-navbar .navbar-toggler-short-align {
    display: block
}

.navbar-vertical-aside-closed-mode .splitted-content-navbar .navbar-toggler-short-align,
.navbar-vertical-aside-mini-mode .splitted-content-navbar .navbar-toggler-short-align {
    display: none
}

.splitted-content-navbar .navbar-toggler-full-align {
    display: none
}

.navbar-vertical-aside-closed-mode .splitted-content-navbar .navbar-toggler-full-align,
.navbar-vertical-aside-mini-mode .splitted-content-navbar .navbar-toggler-full-align {
    display: block
}

@-webkit-keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@media (min-width:576px) {
    .navbar-absolute-sm-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width:768px) {
    .navbar-absolute-md-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width:992px) {
    .navbar-absolute-lg-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width:1200px) {
    .navbar-absolute-xl-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width:1400px) {
    .navbar-absolute-xxl-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0
    }
}

.navbar-absolute-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

@media (max-width:575.98px) {
    .navbar-absolute-md-top .navbar-absolute-top-scroller {
        max-height: 75vh;
        background-color: #fff;
        overflow: hidden;
        overflow-y: auto
    }

    .navbar-absolute-md-top .navbar-absolute-top-scroller::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-absolute-md-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6);
        visibility: hidden
    }

    .navbar-absolute-md-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
        visibility: visible
    }
}

@media (max-width:767.98px) {

    .navbar-absolute-lg-top .navbar-absolute-top-scroller,
    .navbar-absolute-sm-top .navbar-absolute-top-scroller,
    .navbar-absolute-top .navbar-absolute-top-scroller {
        max-height: 75vh;
        background-color: #fff;
        overflow: hidden;
        overflow-y: auto
    }

    .navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar,
    .navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar,
    .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
    .navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
    .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6);
        visibility: hidden
    }

    .navbar-absolute-lg-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
    .navbar-absolute-sm-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
    .navbar-absolute-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
        visibility: visible
    }
}

@media (max-width:991.98px) {
    .navbar-absolute-xl-top .navbar-absolute-top-scroller {
        max-height: 75vh;
        background-color: #fff;
        overflow: hidden;
        overflow-y: auto
    }

    .navbar-absolute-xl-top .navbar-absolute-top-scroller::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-absolute-xl-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6);
        visibility: hidden
    }

    .navbar-absolute-xl-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
        visibility: visible
    }
}

@media (min-width:576px) {
    .navbar-sticky-sm-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width:768px) {
    .navbar-sticky-md-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width:992px) {
    .navbar-sticky-lg-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width:1200px) {
    .navbar-sticky-xl-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width:1400px) {
    .navbar-sticky-xxl-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0
    }
}

.navbar-sticky-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0
}

@media (max-width:575.98px) {
    .navbar-sticky-md-top .navbar-sticky-top-scroller {
        max-height: 75vh;
        overflow: hidden;
        overflow-y: auto
    }

    .navbar-sticky-md-top .navbar-sticky-top-scroller::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-sticky-md-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6);
        visibility: hidden
    }

    .navbar-sticky-md-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
        visibility: visible
    }
}

@media (max-width:767.98px) {

    .navbar-sticky-lg-top .navbar-sticky-top-scroller,
    .navbar-sticky-sm-top .navbar-sticky-top-scroller,
    .navbar-sticky-top .navbar-sticky-top-scroller {
        max-height: 75vh;
        overflow: hidden;
        overflow-y: auto
    }

    .navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar,
    .navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar,
    .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
    .navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
    .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6);
        visibility: hidden
    }

    .navbar-sticky-lg-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
    .navbar-sticky-sm-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
    .navbar-sticky-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
        visibility: visible
    }
}

@media (max-width:991.98px) {
    .navbar-sticky-xl-top .navbar-sticky-top-scroller {
        max-height: 75vh;
        overflow: hidden;
        overflow-y: auto
    }

    .navbar-sticky-xl-top .navbar-sticky-top-scroller::-webkit-scrollbar {
        width: .6125rem
    }

    .navbar-sticky-xl-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, .6);
        visibility: hidden
    }

    .navbar-sticky-xl-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
        visibility: visible
    }
}

.navbar-sidebar-aside-content {
    padding-left: .75rem;
    padding-right: .75rem
}

.navbar-vertical-aside-mini-mode .nav-collapse {
    opacity: 0
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside-initialized .nav-collapse {
    opacity: 1
}

@media (min-width:576px) {
    .navbar-sidebar-aside-sm .navbar-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 17rem;
        padding: 0
    }

    .navbar-sidebar-aside-sm .navbar-sidebar .navbar-collapse {
        width: 100%
    }

    .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7)
    }

    .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0
    }

    .navbar-sidebar-aside-sm .navbar-sidebar .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem
    }

    .navbar-sidebar-aside-sm .navbar-sidebar .navbar-sidebar-aside-body {
        padding-top: 6.25rem
    }

    .navbar-sidebar-aside-sm .navbar-sidebar-aside-content {
        margin-left: 17rem
    }
}

@media (min-width:768px) {
    .navbar-sidebar-aside-md .navbar-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 17rem;
        padding: 0
    }

    .navbar-sidebar-aside-md .navbar-sidebar .navbar-collapse {
        width: 100%
    }

    .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7)
    }

    .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0
    }

    .navbar-sidebar-aside-md .navbar-sidebar .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem
    }

    .navbar-sidebar-aside-md .navbar-sidebar .navbar-sidebar-aside-body {
        padding-top: 6.25rem
    }

    .navbar-sidebar-aside-md .navbar-sidebar-aside-content {
        margin-left: 17rem
    }
}

@media (min-width:992px) {
    .navbar-sidebar-aside-lg .navbar-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 17rem;
        padding: 0
    }

    .navbar-sidebar-aside-lg .navbar-sidebar .navbar-collapse {
        width: 100%
    }

    .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7)
    }

    .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0
    }

    .navbar-sidebar-aside-lg .navbar-sidebar .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem
    }

    .navbar-sidebar-aside-lg .navbar-sidebar .navbar-sidebar-aside-body {
        padding-top: 6.25rem
    }

    .navbar-sidebar-aside-lg .navbar-sidebar-aside-content {
        margin-left: 17rem
    }
}

@media (min-width:1200px) {
    .navbar-sidebar-aside-xl .navbar-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 17rem;
        padding: 0
    }

    .navbar-sidebar-aside-xl .navbar-sidebar .navbar-collapse {
        width: 100%
    }

    .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7)
    }

    .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0
    }

    .navbar-sidebar-aside-xl .navbar-sidebar .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem
    }

    .navbar-sidebar-aside-xl .navbar-sidebar .navbar-sidebar-aside-body {
        padding-top: 6.25rem
    }

    .navbar-sidebar-aside-xl .navbar-sidebar-aside-content {
        margin-left: 17rem
    }
}

@media (min-width:1400px) {
    .navbar-sidebar-aside-xxl .navbar-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 17rem;
        padding: 0
    }

    .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-collapse {
        width: 100%
    }

    .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: .0625rem solid rgba(231, 234, 243, .7)
    }

    .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0
    }

    .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem
    }

    .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-sidebar-aside-body {
        padding-top: 6.25rem
    }

    .navbar-sidebar-aside-xxl .navbar-sidebar-aside-content {
        margin-left: 17rem
    }
}

.navbar-sidebar-aside .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0
}

.navbar-sidebar-aside .navbar-sidebar .navbar-collapse {
    width: 100%
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: .0625rem solid rgba(231, 234, 243, .7)
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 0
}

.navbar-sidebar-aside .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}

.navbar-sidebar-aside .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem
}

.navbar-sidebar-aside .navbar-sidebar-aside-content {
    margin-left: 17rem
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside .navbar-sidebar .navbar-brand-badge {
    display: none
}

.navbar-sidebar-aside .nav-segment {
    margin: 1.25rem 1.5rem
}

@media (max-width:575.98px) {

    .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand,
    .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand-badge {
        display: none
    }

    .navbar-sidebar-aside-sm .nav-segment {
        margin: 1.25rem 1.5rem
    }
}

@media (max-width:767.98px) {

    .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand,
    .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand-badge {
        display: none
    }

    .navbar-sidebar-aside-md .nav-segment {
        margin: 1.25rem 1.5rem
    }
}

@media (max-width:991.98px) {

    .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand,
    .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand-badge {
        display: none
    }

    .navbar-sidebar-aside-lg .nav-segment {
        margin: 1.25rem 1.5rem
    }
}

@media (max-width:1199.98px) {

    .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand,
    .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand-badge {
        display: none
    }

    .navbar-sidebar-aside-xl .nav-segment {
        margin: 1.25rem 1.5rem
    }
}

@media (max-width:1399.98px) {

    .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand,
    .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand-badge {
        display: none
    }

    .navbar-sidebar-aside-xxl .nav-segment {
        margin: 1.25rem 1.5rem
    }
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: #677788
}

.navbar-light .navbar-toggler {
    color: #677788;
    border-color: rgba(231, 234, 243, .7)
}

.navbar-light .navbar-nav .nav-link {
    color: #677788
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #377dff
}

.navbar-light .navbar-nav .nav-link.active .dropdown-item-icon {
    color: #377dff;
    opacity: 1
}

.navbar-dark .navbar-nav .nav-item:hover .nav-link,
.navbar-dark .navbar-nav .nav-item:hover .nav-link:focus,
.navbar-dark .navbar-nav .nav-item:hover .nav-link:hover {
    color: #fff
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link.active,
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link:focus,
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link:hover {
    color: #fff
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-tabs .nav-link,
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-tabs .nav-link:focus,
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-tabs .nav-link:hover {
    color: #677788
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-tabs .nav-link.active {
    color: #377dff
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-tabs .nav-item .nav-link:hover {
    color: #377dff
}

.navbar-dark .navbar-nav .nav-item:hover .navbar-dropdown-menu .nav-link {
    color: #677788
}

.navbar-dark .navbar-nav .nav-item:hover .navbar-dropdown-menu .nav-link.active {
    color: #377dff
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .dropdown-item-icon {
    color: #677788
}

.navbar-dark .navbar-toggler-text {
    color: #fff
}

.navbar-dark .nav-link.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.55)' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>")
}

.navbar-dark .input-group-hover-light .form-control,
.navbar-dark .input-group-hover-light .form-control:hover {
    background-color: rgba(255, 255, 255, .1)
}

.navbar-dark .input-group-hover-light .form-control:focus {
    background-color: #fff
}

.navbar-dark .navbar-aside-toggler {
    color: #fff;
    background-color: #132144;
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .nav-tabs .nav-item .nav-link:hover {
    color: #fff
}

.navbar-dark .nav-tabs .nav-item.show .nav-link,
.navbar-dark .nav-tabs .nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .nav-tabs .nav-collapse .nav-link:hover {
    color: #377dff
}

.navbar-dark .nav-pills .nav-link.active {
    color: #fff
}

.navbar-dark .nav-tabs>.nav-item>.nav-link:hover,
.navbar-dark.navbar .nav-tabs .nav-item:hover>.nav-link:hover {
    color: #fff
}

.navbar-dark.navbar .nav-tabs .nav-collapse .nav-.nav-link,
.navbar-dark.navbar .nav-tabs .nav-collapse .nav-item:hover>.nav-link:hover {
    color: #377dff
}

.navbar-dark .nav-pills .nav-link,
.navbar-dark .nav-vertical.nav-pills .nav-collapse .nav-item .nav-link[aria-expanded]:hover,
.navbar-dark .nav-vertical.nav-pills .nav-collapse .nav-link:hover,
.navbar-dark .nav-vertical.nav-pills .nav-item .nav-link:hover,
.navbar-dark .nav-vertical.nav-pills .nav-item .nav-link[aria-expanded]:hover,
.navbar-dark .nav-vertical.nav-pills .nav-item.show .nav-collapse .nav-link.active,
.navbar-dark.navbar .nav-pills .nav-item:hover>.nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-vertical-footer {
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .dropdown-menu:not(.navbar-dropdown-menu-borderless) .dropdown-item-icon {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark.splitted-content-mini .nav-compact-icon .nav-icon {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark.splitted-content-mini .nav-compact-icon .nav-icon.active,
.navbar-dark.splitted-content-mini .nav-compact-icon .nav-icon:hover {
    background-color: rgba(255, 255, 255, .1)
}

.navbar-vertical-aside.navbar-dark .nav-link {
    color: #fff
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-tabs .nav-item .nav-item>.nav-link {
    color: #132144
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-tabs .nav-collapse .nav-link {
    color: #677788
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-tabs .nav-collapse .nav-link.active {
    color: #377dff
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-tabs .nav-collapse .nav-link.active:hover {
    color: #377dff
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-tabs .nav-collapse .nav-link:hover {
    color: #377dff
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-pills .nav-link,
.navbar-vertical-aside-mini-mode .navbar-dark .nav-vertical.nav-pills .nav-collapse .nav-item .nav-link[aria-expanded]:hover,
.navbar-vertical-aside-mini-mode .navbar-dark .nav-vertical.nav-pills .nav-collapse .nav-link:hover,
.navbar-vertical-aside-mini-mode .navbar-dark .nav-vertical.nav-pills .nav-item .nav-link:hover,
.navbar-vertical-aside-mini-mode .navbar-dark .nav-vertical.nav-pills .nav-item .nav-link[aria-expanded]:hover,
.navbar-vertical-aside-mini-mode .navbar-dark .nav-vertical.nav-pills .nav-item.show .nav-collapse .nav-link.active,
.navbar-vertical-aside-mini-mode .navbar-dark.navbar .nav-pills .nav-item:hover>.nav-link {
    color: #677788
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-pills>*>.nav-item>.nav-link,
.navbar-vertical-aside-mini-mode .navbar-dark .nav-pills>.nav-item>.nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-vertical.nav-pills .nav-item .nav-link[aria-expanded]:hover {
    color: #fff
}

.navbar-vertical-aside-mini-mode .navbar-dark .nav-vertical.nav-pills .nav-link.active:hover {
    background-color: rgba(189, 197, 209, .2)
}

.navbar-invisible {
    display: none
}

.navbar-moved-up {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

.navbar-faded {
    opacity: 0;
    visibility: hidden
}

.navbar-section-hidden {
    position: relative
}

.navbar[data-hs-header-options*=fixMoment] {
    transition: .3s ease
}

.navbar.navbar-untransitioned {
    transition: none
}

.navbar.navbar-scrolled {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto
}

.navbar.navbar-scrolled .navbar-topbar {
    display: none
}

.navbar-fix-top {
    position: fixed
}

.navbar.navbar-fix-top[data-hs-header-options*=effectCompensation] {
    transition: none
}

.offcanvas-header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: .0625rem solid rgba(231, 234, 243, .7)
}

.offcanvas-navbar-search,
.offcanvas.offcanvas-navbar-search {
    bottom: auto;
    height: auto
}

.offcanvas-footer {
    padding: 1.5rem 2rem;
    border-top: .0625rem solid rgba(231, 234, 243, .7)
}

.page-header {
    border-bottom: .0625rem solid rgba(231, 234, 243, .7);
    padding-bottom: 2rem;
    margin-bottom: 2.25rem
}

.page-header .breadcrumb {
    margin-bottom: .25rem
}

.page-header-title {
    margin-bottom: .5rem
}

.page-header-text {
    margin-bottom: .25rem
}

.page-header-tabs {
    margin-bottom: -2rem;
    border-bottom-width: 0
}

.hs-nav-scroller-horizontal .page-header-tabs {
    margin-bottom: -2rem
}

.page-header-reset {
    border-bottom-width: 0;
    padding-bottom: 0
}

.page-header-light {
    border-bottom-color: rgba(255, 255, 255, .1)
}

.page-header-light .page-header-title {
    color: #fff
}

.page-header-light .page-header-text {
    color: rgba(255, 255, 255, .7)
}

.page-item:not(:last-child) {
    margin-right: .25rem
}

.page-link {
    min-width: 2.25rem;
    text-align: center;
    cursor: pointer;
    border-radius: .3125rem
}

.popover {
    box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175)
}

.popover-header {
    font-size: 1rem;
    font-weight: 600
}

.popover-dark {
    background-color: #132144
}

.popover-dark.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-dark.bs-popover-top>.popover-arrow::after {
    border-top-color: #132144
}

.popover-dark.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-dark.bs-popover-end>.popover-arrow::after {
    border-right-color: #132144
}

.popover-dark.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-dark.bs-popover-bottom>.popover-arrow::after {
    border-bottom-color: #132144
}

.popover-dark.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-dark.bs-popover-start>.popover-arrow::after {
    border-left-color: #132144
}

.popover-dark .popover-header {
    color: #fff;
    background-color: #132144;
    border-bottom-color: rgba(255, 255, 255, .1)
}

.popover-dark .popover-body {
    color: #bdc5d1
}

.profile-cover {
    position: relative;
    height: 7.5rem;
    padding: 1rem 1rem;
    border-radius: .75rem
}

.profile-cover-content {
    position: relative;
    z-index: 1
}

.profile-cover-img-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 7.5rem;
    background-color: #e7eaf3;
    border-radius: .75rem
}

.profile-cover-img {
    width: 100%;
    height: 7.5rem;
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: top;
    border-radius: .75rem
}

.profile-cover-avatar {
    display: -ms-flexbox;
    display: flex;
    background-color: #fff;
    border: .25rem solid #fff;
    margin: -6.3rem auto .5rem auto
}

.profile-cover-content {
    padding: 1rem 1rem
}

.profile-cover-uploader {
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin-bottom: 0
}

.profile-cover-uploader-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0
}

.profile-cover-uploader-label {
    margin-bottom: 0
}

@media (min-width:992px) {
    .profile-cover {
        height: 10rem
    }

    .profile-cover-img-wrapper {
        height: 10rem
    }

    .profile-cover-img {
        height: 10rem
    }
}

.progress-vertical {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: #bdc5d1;
    width: .5rem;
    height: 12.5rem
}

.shape-container {
    position: relative
}

.shape[class*=text-]>svg [fill]:not([fill=none]) {
    fill: currentColor !important
}

.shape {
    position: absolute;
    z-index: -1
}

.shape-top {
    top: 0;
    left: 0;
    right: 0
}

.shape-top>svg {
    width: 100%;
    height: auto;
    margin-bottom: -1px;
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center
}

.shape-bottom {
    bottom: 0;
    left: 0;
    right: 0
}

.shape-bottom>svg {
    width: 100%;
    height: auto;
    margin-bottom: -1px;
    -webkit-transform-origin: top center;
    transform-origin: top center
}

.shape-start {
    top: 0;
    left: 0;
    bottom: 0
}

.shape-start>svg {
    width: auto;
    height: 100%;
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.shape-end {
    top: 0;
    right: 0;
    bottom: 0
}

.shape-end>svg {
    width: auto;
    height: 100%;
    -webkit-transform-origin: right center;
    transform-origin: right center
}

.sliding-img {
    width: 100%;
    height: 281px;
    overflow: hidden
}

.sliding-img-frame-to-end,
.sliding-img-frame-to-start {
    width: 2880px;
    height: 281px;
    background-size: 2880px 281px
}

.sliding-img-frame-to-start {
    -webkit-animation: sliding-img-frame-to-start 60s linear infinite;
    animation: sliding-img-frame-to-start 60s linear infinite
}

.sliding-img-frame-to-end {
    -webkit-animation: sliding-img-frame-to-end 60s linear infinite;
    animation: sliding-img-frame-to-end 60s linear infinite
}

@-webkit-keyframes sliding-img-frame-to-start {
    from {
        background-position-x: 0
    }

    to {
        background-position-x: -2880px
    }
}

@keyframes sliding-img-frame-to-start {
    from {
        background-position-x: 0
    }

    to {
        background-position-x: -2880px
    }
}

@-webkit-keyframes sliding-img-frame-to-end {
    from {
        background-position-x: 0
    }

    to {
        background-position-x: 2880px
    }
}

@keyframes sliding-img-frame-to-end {
    from {
        background-position-x: 0
    }

    to {
        background-position-x: 2880px
    }
}

.step {
    position: relative;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-right: calc(1.5rem / -2);
    margin-left: calc(1.5rem / -2)
}

.step.step-dashed .step-icon::after {
    border-left-style: dashed
}

.step-icon-border {
    border: .125rem solid rgba(231, 234, 243, .7)
}

.step-title {
    display: block;
    color: #1e2022;
    font-weight: 600
}

.step-text:last-child {
    color: #677788;
    margin-bottom: 0
}

.step-border-last-0 .step-item:last-child .step-icon::after {
    display: none
}

.step .step-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-bottom: 2.25rem
}

.step-item-between .step-item:last-child {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.step .step-content-wrapper {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.step .step-content {
    -ms-flex: 1;
    flex: 1
}

.step-item.collapse:not(.show) {
    display: none
}

.step-item .step-title-description {
    display: none
}

.step-item.focus .step-title-description {
    display: block
}

.step .step-avatar {
    font-size: .875rem;
    font-weight: 600;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    margin-right: 1rem
}

.step .step-avatar-img {
    max-width: 100%;
    height: auto;
    border-radius: 50%
}

.step .step-avatar::after {
    position: absolute;
    top: 3.375rem;
    left: 1.3125rem;
    height: calc(100% - 1.875rem);
    border-left: .125rem solid rgba(231, 234, 243, .7);
    content: ""
}

.step-avatar-xs .step-avatar,
.step-avatar-xs.step-avatar {
    font-size: .65625rem;
    width: 1.53125rem;
    height: 1.53125rem
}

.step-avatar-xs .step-avatar::after,
.step-avatar-xs.step-avatar::after {
    top: 2.28125rem;
    left: .703125rem;
    width: 1.625rem;
    height: calc(100% - .78125rem)
}

.step-avatar-xs .step-divider::after {
    left: .765625rem
}

.step-avatar-sm .step-avatar,
.step-avatar-sm.step-avatar {
    font-size: .875rem;
    width: 2.40625rem;
    height: 2.40625rem
}

.step-avatar-sm .step-avatar::after,
.step-avatar-sm.step-avatar::after {
    top: 3.15625rem;
    left: 1.140625rem;
    width: 1.625rem;
    height: calc(100% - 1.65625rem)
}

.step-avatar-sm .step-divider::after {
    left: 1.203125rem
}

.step-avatar-lg .step-avatar,
.step-avatar-lg.step-avatar {
    font-size: 1rem;
    width: 4.8125rem;
    height: 4.8125rem
}

.step-avatar-lg .step-avatar::after,
.step-avatar-lg.step-avatar::after {
    top: 5.5625rem;
    left: 2.34375rem;
    width: 1.625rem;
    height: calc(100% - 4.0625rem)
}

.step-avatar-lg .step-divider::after {
    left: 2.40625rem
}

.step-divider {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    height: 1rem;
    font-size: .7109375rem;
    font-weight: 600
}

.step-divider::after {
    position: absolute;
    top: 1.75rem;
    left: 1.3125rem;
    height: calc(100% - .25rem);
    border-left: .125rem solid rgba(231, 234, 243, .7);
    content: ""
}

.step .step-icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: .875rem;
    font-weight: 600;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    margin-right: 1rem
}

.step .step-icon::after {
    position: absolute;
    top: 3.375rem;
    left: 1.3125rem;
    height: calc(100% - 1.875rem);
    border-left: .125rem solid rgba(231, 234, 243, .7);
    content: ""
}

.step .step-icon-pseudo::before {
    display: block;
    width: .25rem;
    height: .25rem;
    background-color: #97a4af;
    border-radius: 50%;
    content: ""
}

.step-icon-xs .step-icon,
.step-icon-xs.step-icon {
    font-size: .65625rem;
    width: 1.53125rem;
    height: 1.53125rem
}

.step-icon-xs .step-icon::after,
.step-icon-xs.step-icon::after {
    top: 2.28125rem;
    left: .703125rem;
    width: 1.625rem;
    height: calc(100% - .78125rem)
}

.step-icon-xs .step-divider::after {
    left: .765625rem
}

.step-icon-sm .step-icon,
.step-icon-sm.step-icon {
    font-size: .875rem;
    width: 2.40625rem;
    height: 2.40625rem
}

.step-icon-sm .step-icon::after,
.step-icon-sm.step-icon::after {
    top: 3.15625rem;
    left: 1.140625rem;
    width: 1.625rem;
    height: calc(100% - 1.65625rem)
}

.step-icon-sm .step-divider::after {
    left: 1.203125rem
}

.step-icon-lg .step-icon,
.step-icon-lg.step-icon {
    font-size: 1rem;
    width: 4.8125rem;
    height: 4.8125rem
}

.step-icon-lg .step-icon::after,
.step-icon-lg.step-icon::after {
    top: 5.5625rem;
    left: 2.34375rem;
    width: 1.625rem;
    height: calc(100% - 4.0625rem)
}

.step-icon-lg .step-divider::after {
    left: 2.40625rem
}

@media (min-width:576px) {
    .step-sm.step-dashed .step-icon::after {
        border-left: none;
        border-top-style: dashed
    }

    .step-sm .step-item {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }

    .step-sm:not(.step-inline) .step-content-wrapper {
        display: block
    }

    .step-sm .step-icon {
        margin-bottom: 1rem
    }

    .step-sm .step-icon::after {
        top: 1.3125rem;
        left: 4.125rem;
        width: calc(100% - 4.125rem);
        height: 1.625rem;
        border-top: .125rem solid rgba(231, 234, 243, .7);
        border-left: none
    }

    .step-sm .step-icon.step-icon-xs::after,
    .step-sm.step-icon-xs .step-icon::after {
        top: .765625rem;
        left: 3.03125rem;
        width: calc(100% - 3.03125rem)
    }

    .step-sm .step-icon.step-icon-sm::after,
    .step-sm.step-icon-sm .step-icon::after {
        top: 1.203125rem;
        left: 3.90625rem;
        width: calc(100% - 3.90625rem)
    }

    .step-sm .step-icon.step-icon-lg::after,
    .step-sm.step-icon-lg .step-icon::after {
        top: 2.40625rem;
        left: 6.3125rem;
        width: calc(100% - 6.3125rem)
    }
}

@media (min-width:768px) {
    .step-md.step-dashed .step-icon::after {
        border-left: none;
        border-top-style: dashed
    }

    .step-md .step-item {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }

    .step-md:not(.step-inline) .step-content-wrapper {
        display: block
    }

    .step-md .step-icon {
        margin-bottom: 1rem
    }

    .step-md .step-icon::after {
        top: 1.3125rem;
        left: 4.125rem;
        width: calc(100% - 4.125rem);
        height: 1.625rem;
        border-top: .125rem solid rgba(231, 234, 243, .7);
        border-left: none
    }

    .step-md .step-icon.step-icon-xs::after,
    .step-md.step-icon-xs .step-icon::after {
        top: .765625rem;
        left: 3.03125rem;
        width: calc(100% - 3.03125rem)
    }

    .step-md .step-icon.step-icon-sm::after,
    .step-md.step-icon-sm .step-icon::after {
        top: 1.203125rem;
        left: 3.90625rem;
        width: calc(100% - 3.90625rem)
    }

    .step-md .step-icon.step-icon-lg::after,
    .step-md.step-icon-lg .step-icon::after {
        top: 2.40625rem;
        left: 6.3125rem;
        width: calc(100% - 6.3125rem)
    }
}

@media (min-width:992px) {
    .step-lg.step-dashed .step-icon::after {
        border-left: none;
        border-top-style: dashed
    }

    .step-lg .step-item {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }

    .step-lg:not(.step-inline) .step-content-wrapper {
        display: block
    }

    .step-lg .step-icon {
        margin-bottom: 1rem
    }

    .step-lg .step-icon::after {
        top: 1.3125rem;
        left: 4.125rem;
        width: calc(100% - 4.125rem);
        height: 1.625rem;
        border-top: .125rem solid rgba(231, 234, 243, .7);
        border-left: none
    }

    .step-lg .step-icon.step-icon-xs::after,
    .step-lg.step-icon-xs .step-icon::after {
        top: .765625rem;
        left: 3.03125rem;
        width: calc(100% - 3.03125rem)
    }

    .step-lg .step-icon.step-icon-sm::after,
    .step-lg.step-icon-sm .step-icon::after {
        top: 1.203125rem;
        left: 3.90625rem;
        width: calc(100% - 3.90625rem)
    }

    .step-lg .step-icon.step-icon-lg::after,
    .step-lg.step-icon-lg .step-icon::after {
        top: 2.40625rem;
        left: 6.3125rem;
        width: calc(100% - 6.3125rem)
    }
}

@media (min-width:1200px) {
    .step-xl.step-dashed .step-icon::after {
        border-left: none;
        border-top-style: dashed
    }

    .step-xl .step-item {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }

    .step-xl:not(.step-inline) .step-content-wrapper {
        display: block
    }

    .step-xl .step-icon {
        margin-bottom: 1rem
    }

    .step-xl .step-icon::after {
        top: 1.3125rem;
        left: 4.125rem;
        width: calc(100% - 4.125rem);
        height: 1.625rem;
        border-top: .125rem solid rgba(231, 234, 243, .7);
        border-left: none
    }

    .step-xl .step-icon.step-icon-xs::after,
    .step-xl.step-icon-xs .step-icon::after {
        top: .765625rem;
        left: 3.03125rem;
        width: calc(100% - 3.03125rem)
    }

    .step-xl .step-icon.step-icon-sm::after,
    .step-xl.step-icon-sm .step-icon::after {
        top: 1.203125rem;
        left: 3.90625rem;
        width: calc(100% - 3.90625rem)
    }

    .step-xl .step-icon.step-icon-lg::after,
    .step-xl.step-icon-lg .step-icon::after {
        top: 2.40625rem;
        left: 6.3125rem;
        width: calc(100% - 6.3125rem)
    }
}

@media (min-width:576px) {
    .step-sm.step-centered {
        text-align: center
    }

    .step-sm.step-centered .step-item:last-child .step-icon::after {
        display: none
    }

    .step-sm.step-centered .step-icon {
        margin-left: auto;
        margin-right: auto
    }

    .step-sm.step-centered .step-icon::after {
        width: calc(100% - 4.125rem);
        left: calc(50% + 2.8125rem)
    }

    .step-sm.step-centered .step-icon.step-icon-xs::after,
    .step-sm.step-centered.step-icon-xs .step-icon::after {
        width: calc(100% - 3.03125rem);
        left: calc(50% + 2.265625rem)
    }

    .step-sm.step-centered .step-icon.step-icon-sm::after,
    .step-sm.step-centered.step-icon-sm .step-icon::after {
        width: calc(100% - 3.90625rem);
        left: calc(50% + 2.703125rem)
    }

    .step-sm.step-centered .step-icon.step-icon-lg::after,
    .step-sm.step-centered.step-icon-lg .step-icon::after {
        width: calc(100% - 6.3125rem);
        left: calc(50% + 3.90625rem)
    }
}

@media (min-width:768px) {
    .step-md.step-centered {
        text-align: center
    }

    .step-md.step-centered .step-item:last-child .step-icon::after {
        display: none
    }

    .step-md.step-centered .step-icon {
        margin-left: auto;
        margin-right: auto
    }

    .step-md.step-centered .step-icon::after {
        width: calc(100% - 4.125rem);
        left: calc(50% + 2.8125rem)
    }

    .step-md.step-centered .step-icon.step-icon-xs::after,
    .step-md.step-centered.step-icon-xs .step-icon::after {
        width: calc(100% - 3.03125rem);
        left: calc(50% + 2.265625rem)
    }

    .step-md.step-centered .step-icon.step-icon-sm::after,
    .step-md.step-centered.step-icon-sm .step-icon::after {
        width: calc(100% - 3.90625rem);
        left: calc(50% + 2.703125rem)
    }

    .step-md.step-centered .step-icon.step-icon-lg::after,
    .step-md.step-centered.step-icon-lg .step-icon::after {
        width: calc(100% - 6.3125rem);
        left: calc(50% + 3.90625rem)
    }
}

@media (min-width:992px) {
    .step-lg.step-centered {
        text-align: center
    }

    .step-lg.step-centered .step-item:last-child .step-icon::after {
        display: none
    }

    .step-lg.step-centered .step-icon {
        margin-left: auto;
        margin-right: auto
    }

    .step-lg.step-centered .step-icon::after {
        width: calc(100% - 4.125rem);
        left: calc(50% + 2.8125rem)
    }

    .step-lg.step-centered .step-icon.step-icon-xs::after,
    .step-lg.step-centered.step-icon-xs .step-icon::after {
        width: calc(100% - 3.03125rem);
        left: calc(50% + 2.265625rem)
    }

    .step-lg.step-centered .step-icon.step-icon-sm::after,
    .step-lg.step-centered.step-icon-sm .step-icon::after {
        width: calc(100% - 3.90625rem);
        left: calc(50% + 2.703125rem)
    }

    .step-lg.step-centered .step-icon.step-icon-lg::after,
    .step-lg.step-centered.step-icon-lg .step-icon::after {
        width: calc(100% - 6.3125rem);
        left: calc(50% + 3.90625rem)
    }
}

@media (min-width:992px) {
    .step-lg.step-centered {
        text-align: center
    }

    .step-lg.step-centered .step-item:last-child .step-icon::after {
        display: none
    }

    .step-lg.step-centered .step-icon {
        margin-left: auto;
        margin-right: auto
    }

    .step-lg.step-centered .step-icon::after {
        width: calc(100% - 4.125rem);
        left: calc(50% + 2.8125rem)
    }

    .step-lg.step-centered .step-icon.step-icon-xs::after,
    .step-lg.step-centered.step-icon-xs .step-icon::after {
        width: calc(100% - 3.03125rem);
        left: calc(50% + 2.265625rem)
    }

    .step-lg.step-centered .step-icon.step-icon-sm::after,
    .step-lg.step-centered.step-icon-sm .step-icon::after {
        width: calc(100% - 3.90625rem);
        left: calc(50% + 2.703125rem)
    }

    .step-lg.step-centered .step-icon.step-icon-lg::after,
    .step-lg.step-centered.step-icon-lg .step-icon::after {
        width: calc(100% - 6.3125rem);
        left: calc(50% + 3.90625rem)
    }
}

.step .step-is-invalid-icon,
.step .step-is-valid-icon {
    display: none
}

.step .active .step-icon,
.step .active.is-valid .step-icon,
.step .visited .step-icon {
    color: #fff;
    background-color: #377dff
}

.step .active .step-title,
.step .active.is-valid .step-title,
.step .visited .step-title {
    color: #377dff
}

.step .is-valid .step-icon {
    color: #fff;
    background-color: #377dff
}

.step .is-valid .step-title {
    color: #377dff
}

.step .is-valid .step-is-valid-icon {
    display: -ms-inline-flexbox;
    display: inline-flex
}

.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
    display: none
}

.step .is-invalid .step-icon {
    color: #fff;
    background-color: #ed4c78
}

.step .is-invalid .step-title {
    color: #ed4c78
}

.step .is-invalid .step-is-invalid-icon {
    display: -ms-inline-flexbox;
    display: inline-flex
}

.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
    display: none
}

.step-icon-primary {
    color: #fff;
    background-color: #377dff
}

.step-icon-primary.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-secondary {
    color: #fff;
    background-color: #71869d
}

.step-icon-secondary.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-success {
    color: #fff;
    background-color: #00c9a7
}

.step-icon-success.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-info {
    color: #fff;
    background-color: #09a5be
}

.step-icon-info.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-warning {
    color: #000;
    background-color: #f5ca99
}

.step-icon-warning.step-icon-pseudo::before {
    background-color: #000
}

.step-icon-danger {
    color: #fff;
    background-color: #ed4c78
}

.step-icon-danger.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-light {
    color: #000;
    background-color: #f9fafc
}

.step-icon-light.step-icon-pseudo::before {
    background-color: #000
}

.step-icon-dark {
    color: #fff;
    background-color: #132144
}

.step-icon-dark.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-soft-primary {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.step-icon-soft-primary.step-icon-pseudo::before {
    background-color: #377dff
}

.step-icon-soft-secondary {
    color: #71869d;
    background-color: rgba(113, 134, 157, .1)
}

.step-icon-soft-secondary.step-icon-pseudo::before {
    background-color: #71869d
}

.step-icon-soft-success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .1)
}

.step-icon-soft-success.step-icon-pseudo::before {
    background-color: #00c9a7
}

.step-icon-soft-info {
    color: #09a5be;
    background-color: rgba(9, 165, 190, .1)
}

.step-icon-soft-info.step-icon-pseudo::before {
    background-color: #09a5be
}

.step-icon-soft-warning {
    color: #f5ca99;
    background-color: rgba(245, 202, 153, .1)
}

.step-icon-soft-warning.step-icon-pseudo::before {
    background-color: #f5ca99
}

.step-icon-soft-danger {
    color: #ed4c78;
    background-color: rgba(237, 76, 120, .1)
}

.step-icon-soft-danger.step-icon-pseudo::before {
    background-color: #ed4c78
}

.step-icon-soft-light {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, .1)
}

.step-icon-soft-light.step-icon-pseudo::before {
    background-color: #f9fafc
}

.step-icon-soft-dark {
    color: #132144;
    background-color: rgba(19, 33, 68, .1)
}

.step-icon-soft-dark.step-icon-pseudo::before {
    background-color: #132144
}

.step-inline .step-content-wrapper {
    -ms-flex-align: center;
    align-items: center
}

.step-inline .step-item:last-child .step-title::after {
    display: none
}

.step-inline .step-title {
    display: inline-block
}

@media (min-width:576px) {
    .step-sm.step-inline.step-dashed .step-title::after {
        border-top-style: dashed
    }

    .step-sm.step-inline .step-item {
        overflow: hidden
    }

    .step-sm.step-inline .step-icon {
        margin-bottom: 0
    }

    .step-sm.step-inline .step-icon::after {
        display: none
    }

    .step-sm.step-inline .step-title::after {
        position: absolute;
        top: 1.3125rem;
        width: 100%;
        height: 1.625rem;
        border-top: .125rem solid rgba(231, 234, 243, .7);
        margin-left: 1.5rem;
        content: ""
    }

    .step-sm.step-inline .step-icon-xs+.step-content .step-title::after,
    .step-sm.step-inline.step-icon-xs .step-content .step-title::after {
        top: .765625rem
    }

    .step-sm.step-inline .step-icon-sm+.step-content .step-title::after,
    .step-sm.step-inline.step-icon-sm .step-content .step-title::after {
        top: 1.203125rem
    }

    .step-sm.step-inline .step-icon-lg+.step-content .step-title::after,
    .step-sm.step-inline.step-icon-lg .step-content .step-title::after {
        top: 2.40625rem
    }
}

@media (min-width:768px) {
    .step-md.step-inline.step-dashed .step-title::after {
        border-top-style: dashed
    }

    .step-md.step-inline .step-item {
        overflow: hidden
    }

    .step-md.step-inline .step-icon {
        margin-bottom: 0
    }

    .step-md.step-inline .step-icon::after {
        display: none
    }

    .step-md.step-inline .step-title::after {
        position: absolute;
        top: 1.3125rem;
        width: 100%;
        height: 1.625rem;
        border-top: .125rem solid rgba(231, 234, 243, .7);
        margin-left: 1.5rem;
        content: ""
    }

    .step-md.step-inline .step-icon-xs+.step-content .step-title::after,
    .step-md.step-inline.step-icon-xs .step-content .step-title::after {
        top: .765625rem
    }

    .step-md.step-inline .step-icon-sm+.step-content .step-title::after,
    .step-md.step-inline.step-icon-sm .step-content .step-title::after {
        top: 1.203125rem
    }

    .step-md.step-inline .step-icon-lg+.step-content .step-title::after,
    .step-md.step-inline.step-icon-lg .step-content .step-title::after {
        top: 2.40625rem
    }
}

@media (min-width:992px) {
    .step-lg.step-inline.step-dashed .step-title::after {
        border-top-style: dashed
    }

    .step-lg.step-inline .step-item {
        overflow: hidden
    }

    .step-lg.step-inline .step-icon {
        margin-bottom: 0
    }

    .step-lg.step-inline .step-icon::after {
        display: none
    }

    .step-lg.step-inline .step-title::after {
        position: absolute;
        top: 1.3125rem;
        width: 100%;
        height: 1.625rem;
        border-top: .125rem solid rgba(231, 234, 243, .7);
        margin-left: 1.5rem;
        content: ""
    }

    .step-lg.step-inline .step-icon-xs+.step-content .step-title::after,
    .step-lg.step-inline.step-icon-xs .step-content .step-title::after {
        top: .765625rem
    }

    .step-lg.step-inline .step-icon-sm+.step-content .step-title::after,
    .step-lg.step-inline.step-icon-sm .step-content .step-title::after {
        top: 1.203125rem
    }

    .step-lg.step-inline .step-icon-lg+.step-content .step-title::after,
    .step-lg.step-inline.step-icon-lg .step-content .step-title::after {
        top: 2.40625rem
    }
}

@media (min-width:1200px) {
    .step-xl.step-inline.step-dashed .step-title::after {
        border-top-style: dashed
    }

    .step-xl.step-inline .step-item {
        overflow: hidden
    }

    .step-xl.step-inline .step-icon {
        margin-bottom: 0
    }

    .step-xl.step-inline .step-icon::after {
        display: none
    }

    .step-xl.step-inline .step-title::after {
        position: absolute;
        top: 1.3125rem;
        width: 100%;
        height: 1.625rem;
        border-top: .125rem solid rgba(231, 234, 243, .7);
        margin-left: 1.5rem;
        content: ""
    }

    .step-xl.step-inline .step-icon-xs+.step-content .step-title::after,
    .step-xl.step-inline.step-icon-xs .step-content .step-title::after {
        top: .765625rem
    }

    .step-xl.step-inline .step-icon-sm+.step-content .step-title::after,
    .step-xl.step-inline.step-icon-sm .step-content .step-title::after {
        top: 1.203125rem
    }

    .step-xl.step-inline .step-icon-lg+.step-content .step-title::after,
    .step-xl.step-inline.step-icon-lg .step-content .step-title::after {
        top: 2.40625rem
    }
}

@media (min-width:576px) {
    .step-timeline-sm {
        margin-left: 0;
        margin-right: 0
    }

    .step-timeline-sm .step-item {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 0;
        padding-right: 0;
        margin-left: 50%
    }

    .step-timeline-sm .step-item:nth-child(even) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        margin-left: auto;
        margin-right: 50%
    }

    .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .step-timeline-sm .step-item:nth-child(even) .step-icon {
        margin-left: 0;
        margin-right: -1.3125rem
    }

    .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
        margin-right: -.765625rem
    }

    .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
        margin-right: -1.203125rem
    }

    .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
        margin-right: -2.40625rem
    }

    .step-timeline-sm .step-item:nth-child(even) .step-content {
        margin-right: 1.5rem
    }

    .step-timeline-sm .step-icon {
        margin-left: -1.3125rem
    }

    .step-timeline-sm .step-icon::after {
        left: auto;
        width: auto
    }

    .step-timeline-sm .step-icon-xs {
        margin-left: -.765625rem
    }

    .step-timeline-sm .step-icon-sm {
        margin-left: -1.203125rem
    }

    .step-timeline-sm .step-icon-lg {
        margin-left: -2.40625rem
    }
}

@media (min-width:768px) {
    .step-timeline-md {
        margin-left: 0;
        margin-right: 0
    }

    .step-timeline-md .step-item {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 0;
        padding-right: 0;
        margin-left: 50%
    }

    .step-timeline-md .step-item:nth-child(even) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        margin-left: auto;
        margin-right: 50%
    }

    .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .step-timeline-md .step-item:nth-child(even) .step-icon {
        margin-left: 0;
        margin-right: -1.3125rem
    }

    .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
        margin-right: -.765625rem
    }

    .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
        margin-right: -1.203125rem
    }

    .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
        margin-right: -2.40625rem
    }

    .step-timeline-md .step-item:nth-child(even) .step-content {
        margin-right: 1.5rem
    }

    .step-timeline-md .step-icon {
        margin-left: -1.3125rem
    }

    .step-timeline-md .step-icon::after {
        left: auto;
        width: auto
    }

    .step-timeline-md .step-icon-xs {
        margin-left: -.765625rem
    }

    .step-timeline-md .step-icon-sm {
        margin-left: -1.203125rem
    }

    .step-timeline-md .step-icon-lg {
        margin-left: -2.40625rem
    }
}

@media (min-width:992px) {
    .step-timeline-lg {
        margin-left: 0;
        margin-right: 0
    }

    .step-timeline-lg .step-item {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 0;
        padding-right: 0;
        margin-left: 50%
    }

    .step-timeline-lg .step-item:nth-child(even) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        margin-left: auto;
        margin-right: 50%
    }

    .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .step-timeline-lg .step-item:nth-child(even) .step-icon {
        margin-left: 0;
        margin-right: -1.3125rem
    }

    .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
        margin-right: -.765625rem
    }

    .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
        margin-right: -1.203125rem
    }

    .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
        margin-right: -2.40625rem
    }

    .step-timeline-lg .step-item:nth-child(even) .step-content {
        margin-right: 1.5rem
    }

    .step-timeline-lg .step-icon {
        margin-left: -1.3125rem
    }

    .step-timeline-lg .step-icon::after {
        left: auto;
        width: auto
    }

    .step-timeline-lg .step-icon-xs {
        margin-left: -.765625rem
    }

    .step-timeline-lg .step-icon-sm {
        margin-left: -1.203125rem
    }

    .step-timeline-lg .step-icon-lg {
        margin-left: -2.40625rem
    }
}

@media (min-width:1200px) {
    .step-timeline-xl {
        margin-left: 0;
        margin-right: 0
    }

    .step-timeline-xl .step-item {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 0;
        padding-right: 0;
        margin-left: 50%
    }

    .step-timeline-xl .step-item:nth-child(even) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        margin-left: auto;
        margin-right: 50%
    }

    .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .step-timeline-xl .step-item:nth-child(even) .step-icon {
        margin-left: 0;
        margin-right: -1.3125rem
    }

    .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
        margin-right: -.765625rem
    }

    .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
        margin-right: -1.203125rem
    }

    .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
        margin-right: -2.40625rem
    }

    .step-timeline-xl .step-item:nth-child(even) .step-content {
        margin-right: 1.5rem
    }

    .step-timeline-xl .step-icon {
        margin-left: -1.3125rem
    }

    .step-timeline-xl .step-icon::after {
        left: auto;
        width: auto
    }

    .step-timeline-xl .step-icon-xs {
        margin-left: -.765625rem
    }

    .step-timeline-xl .step-icon-sm {
        margin-left: -1.203125rem
    }

    .step-timeline-xl .step-icon-lg {
        margin-left: -2.40625rem
    }
}

.svg-icon {
    display: block;
    margin-top: .125rem
}

.svg-icon[class*=text-]>svg [fill]:not([fill=none]) {
    fill: currentColor !important
}

.svg-inline {
    display: inline-block
}

.svg-icon>svg {
    width: 2.75rem;
    height: 2.75rem
}

.svg-icon-xs>svg {
    width: 1.25rem;
    height: 1.25rem
}

.svg-icon-sm>svg {
    width: 1.75rem;
    height: 1.75rem
}

.svg-icon-lg>svg {
    width: 3.5rem;
    height: 3.5rem
}

.toast-header .btn-close {
    margin-right: 0
}

.table tr {
    color: #677788
}

.table thead th {
    color: #677788;
    font-size: .74375rem;
    font-weight: 600;
    text-transform: uppercase
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: rgba(231, 234, 243, .7)
}

.table>:not(caption)>:last-child>* {
    border-bottom-width: 0
}

.table .btn {
    white-space: nowrap
}

.table-nowrap td,
.table-nowrap th {
    white-space: nowrap
}

.table-align-middle tbody tr,
.table-align-middle td,
.table-align-middle th {
    vertical-align: middle
}

.table-text-center,
.table-text-center td,
.table-text-center th {
    text-align: center
}

.table-text-start,
.table-text-start td,
.table-text-start th {
    text-align: left
}

.table-text-end,
.table-text-end td,
.table-text-end th {
    text-align: right
}

.thead-light th {
    background-color: #f8fafd
}

.table-lg>:not(caption)>*>* {
    padding: 1rem 1.5rem
}

.table-thead-bordered>:not(:last-child)>:last-child>* {
    border-top: .0625rem solid rgba(231, 234, 243, .7);
    border-bottom: .0625rem solid rgba(231, 234, 243, .7)
}

.table-vertical-border-striped>tbody>tr>td:not(:first-child):not(:last-child),
.table-vertical-border-striped>thead>tr>th:not(:first-child):not(:last-child) {
    border-right: .0625rem solid rgba(231, 234, 243, .7)
}

.table-column-pe-0 {
    padding-right: 0 !important
}

.table-column-ps-0 {
    padding-left: 0 !important
}

.table-progress {
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 5rem;
    height: .25rem
}

.table-input-group {
    min-width: 14rem
}

[class*=text-highlight-] {
    background: left 1em/1em .2em;
    background-repeat: repeat-x
}

.text-highlight-primary {
    background-image: linear-gradient(to bottom, rgba(55, 125, 255, .5), rgba(55, 125, 255, .5))
}

.text-highlight-secondary {
    background-image: linear-gradient(to bottom, rgba(113, 134, 157, .5), rgba(113, 134, 157, .5))
}

.text-highlight-success {
    background-image: linear-gradient(to bottom, rgba(0, 201, 167, .5), rgba(0, 201, 167, .5))
}

.text-highlight-info {
    background-image: linear-gradient(to bottom, rgba(9, 165, 190, .5), rgba(9, 165, 190, .5))
}

.text-highlight-warning {
    background-image: linear-gradient(to bottom, rgba(245, 202, 153, .5), rgba(245, 202, 153, .5))
}

.text-highlight-danger {
    background-image: linear-gradient(to bottom, rgba(237, 76, 120, .5), rgba(237, 76, 120, .5))
}

.text-highlight-light {
    background-image: linear-gradient(to bottom, rgba(249, 250, 252, .5), rgba(249, 250, 252, .5))
}

.text-highlight-dark {
    background-image: linear-gradient(to bottom, rgba(19, 33, 68, .5), rgba(19, 33, 68, .5))
}

.bg-img-start {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center
}

.bg-img-end {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center
}

.bg-img-center {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center
}

.gradient-radial-sm-primary {
    background-image: radial-gradient(50% 50% at 50% 50%, rgba(55, 125, 255, .1) 0, rgba(255, 255, 255, 0) 100%)
}

.gradient-y-lg-dark {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #132144)
}

.link {
    font-weight: 600
}

.link-primary:focus,
.link-primary:hover {
    color: #1366ff
}

.link-secondary:focus,
.link-secondary:hover {
    color: #1366ff
}

.link-success:focus,
.link-success:hover {
    color: #1366ff
}

.link-info:focus,
.link-info:hover {
    color: #1366ff
}

.link-warning:focus,
.link-warning:hover {
    color: #1366ff
}

.link-danger:focus,
.link-danger:hover {
    color: #1366ff
}

.link-light:focus,
.link-light:hover {
    color: rgba(255, 255, 255, .7)
}

.link-dark:focus,
.link-dark:hover {
    color: #1366ff
}

.link-collapse[aria-expanded=false] .link-collapse-default {
    display: inline-block
}

.link-collapse[aria-expanded=false] .link-collapse-active {
    display: none
}

.link-collapse[aria-expanded=true] .link-collapse-default {
    display: none
}

.link-collapse[aria-expanded=true] .link-collapse-active {
    display: inline-block
}

.text-cap {
    display: block;
    color: #1e2022;
    font-size: .7109375rem;
    font-weight: 600;
    letter-spacing: .03125rem;
    text-transform: uppercase;
    margin-bottom: 1rem
}

.text-dark {
    color: #1e2022 !important
}

.text-dark[href]:hover {
    color: #1366ff !important
}

.link-secondary[href]:hover,
.text-body[href]:hover,
.text-muted[href]:hover,
.text-secondary[href]:hover {
    color: #1366ff !important
}

a:hover .text-inherit {
    color: #1366ff !important
}

.divider-start {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #8c98a4
}

.divider-start::after {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    border-top: .0625rem solid rgba(231, 234, 243, .7);
    margin-top: .0625rem;
    content: ""
}

.divider-start::after {
    margin-left: 1.5rem
}

.divider-end {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #8c98a4
}

.divider-end::before {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    border-top: .0625rem solid rgba(231, 234, 243, .7);
    margin-top: .0625rem;
    content: ""
}

.divider-end::before {
    margin-right: 1.5rem
}

.divider-center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #8c98a4
}

.divider-center::after,
.divider-center::before {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    border-top: .0625rem solid rgba(231, 234, 243, .7);
    margin-top: .0625rem;
    content: ""
}

.divider-center::before {
    margin-right: 1.5rem
}

.divider-center::after {
    margin-left: 1.5rem
}

.devices {
    max-width: 1140px;
    position: relative;
    overflow: hidden;
    padding: 0 .75rem 7rem;
    margin-right: -.75rem;
    margin-left: -.75rem
}

.devices .device-browser {
    margin-left: auto;
    margin-right: 5rem
}

@media (max-width:991.98px) {
    .devices .device-browser {
        margin-right: auto
    }
}

.device-browser {
    position: relative;
    z-index: 1;
    display: block;
    max-width: 100%;
    width: 50rem;
    height: auto;
    margin-left: auto;
    margin-right: auto
}

.device-browser-lg {
    width: 60rem
}

.device-browser-frame {
    background: #fff;
    box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, .5), 0 2rem 4rem -2rem rgba(19, 33, 68, .3);
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.device-browser-img {
    max-width: 100%;
    height: auto;
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.device-browser .hs-img-comp {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.device-browser-header {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    max-width: 50rem;
    background: #fff;
    border-bottom: .0625rem solid #f1f3f8;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    padding: .5rem 6.5rem
}

.device-browser-header-btn-list {
    display: -ms-flexbox;
    display: flex;
    grid-gap: .25rem;
    position: absolute;
    top: calc(50% - .25rem);
    left: 1rem
}

.device-browser-header-btn-list-btn {
    width: .5rem;
    height: .5rem;
    background-color: #e7eaf3;
    border-radius: 50%
}

.device-browser-header-browser-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #677788;
    background-color: #e7eaf3;
    font-size: .4375rem;
    border-radius: .125rem
}

.device-browser-lg {
    width: 60rem
}

.device-browser-lg .device-browser-header {
    max-width: 60rem
}

.hs-nav-scroller-horizontal {
    position: relative
}

.hs-nav-scroller-horizontal .nav {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    white-space: nowrap;
    scroll-behavior: smooth
}

.hs-nav-scroller-horizontal .nav .nav-item {
    white-space: nowrap
}

.hs-nav-scroller-horizontal .nav .nav-link {
    white-space: nowrap
}

.hs-nav-scroller-horizontal .nav::-webkit-scrollbar {
    display: none
}

.hs-nav-scroller-horizontal .nav-tabs {
    padding-bottom: .125rem
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next,
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
    position: absolute;
    height: 100%;
    z-index: 1;
    font-size: 1.3125rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link {
    width: 2.625rem;
    color: #677788
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link:hover {
    color: #1366ff
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
    left: 0;
    margin-left: -.125rem;
    background-image: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, 0) 100%)
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link {
    padding: .5rem 0
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
    right: 0;
    margin-right: -.125rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 50%)
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link {
    padding: .5rem 0;
    text-align: right
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-dark-next .hs-nav-scroller-arrow-link,
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-dark-prev .hs-nav-scroller-arrow-link {
    color: rgba(255, 255, 255, .7)
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-dark-next .hs-nav-scroller-arrow-link:hover,
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-dark-prev .hs-nav-scroller-arrow-link:hover {
    color: #fff
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-dark-prev {
    background: linear-gradient(to left, rgba(19, 33, 68, .53), #132144 61.34%)
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-dark-next {
    background: linear-gradient(to right, rgba(19, 33, 68, .53), #132144 61.34%)
}

.hs-nav-scroller-vertical {
    height: 100%;
    overflow: hidden;
    overflow-y: auto
}

.hs-nav-scroller-vertical::-webkit-scrollbar {
    width: .6125rem
}

.hs-nav-scroller-vertical::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, .6)
}

.nav-scroller-dropdown {
    position: static
}

.hs-form-search-menu-content {
    position: absolute;
    display: block !important;
    opacity: 0;
    pointer-events: none
}

.hs-form-search-menu-hidden {
    display: none !important;
    opacity: 0;
    visibility: hidden
}

.hs-form-search-menu-initialized {
    opacity: 1;
    visibility: visible;
    pointer-events: auto
}

.hs-loader-wrapper {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    display: none;
    background-color: #fff;
    border-radius: .5rem
}

.hs-loader {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
}

.hs-fullscreen {
    position: fixed !important;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh !important;
    max-height: 100vh !important;
    width: 100vw !important;
    max-width: 100vw !important;
    border-radius: 0
}

.hs-fullscreen .hs-fullscreen-icon-default {
    display: none
}

.hs-fullscreen .hs-fullscreen-icon-active {
    display: block
}

.hs-fullscreen-on {
    overflow: hidden
}

.hs-fullscreen-icon-default {
    display: block
}

.hs-fullscreen-icon-active {
    display: none
}

.chartjs-custom {
    position: relative;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto
}

.hs-chartjs-tooltip-wrap {
    position: absolute;
    z-index: 3;
    transition: opacity .2s ease-in-out, left .2s ease, top .2s ease
}

.hs-chartjs-tooltip {
    position: relative;
    font-size: .8125rem;
    background-color: #132144;
    border-radius: .5rem;
    padding: .6125rem 1rem;
    transition: opacity .2s ease-in-out, left .2s ease, top .2s ease, top 0s
}

.hs-chartjs-tooltip::before {
    position: absolute;
    left: calc(50% - .5rem);
    bottom: -.4375rem;
    width: 1rem;
    height: .5rem;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='1rem' height='0.5rem' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 50 22.49'%3E%3Cpath fill='%23132144' d='M0,0h50L31.87,19.65c-3.45,3.73-9.33,3.79-12.85,0.13L0,0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem .5rem
}

.hs-chartjs-tooltip-left {
    left: -130%
}

.hs-chartjs-tooltip-left::before {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -.6875rem;
    left: auto;
    -webkit-transform: translateY(-50%) rotate(270deg);
    transform: translateY(-50%) rotate(270deg)
}

.hs-chartjs-tooltip-right {
    left: 30%
}

.hs-chartjs-tooltip-right::before {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -.6875rem;
    right: auto;
    -webkit-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg)
}

.hs-chartjs-tooltip-header {
    color: rgba(255, 255, 255, .7);
    font-weight: 600;
    white-space: nowrap
}

.hs-chartjs-tooltip-body {
    color: #fff
}

.chartjs-doughnut-custom {
    position: relative
}

.chartjs-doughnut-custom-stat {
    position: absolute;
    top: 8rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.chartjs-matrix-custom {
    position: relative
}

.hs-chartjs-matrix-legend {
    display: inline-block;
    position: relative;
    height: 2.5rem;
    list-style: none;
    padding-left: 0
}

.hs-chartjs-matrix-legend-item {
    width: .625rem;
    height: .625rem;
    display: inline-block
}

.hs-chartjs-matrix-legend-min {
    position: absolute;
    left: 0;
    bottom: 0
}

.hs-chartjs-matrix-legend-max {
    position: absolute;
    right: 0;
    bottom: 0
}

.datatable-custom .dataTables_filter,
.datatable-custom .dataTables_info,
.datatable-custom .dataTables_length,
.datatable-custom .dataTables_paginate {
    display: none
}

.datatable-custom .sorting,
.datatable-custom .sorting_asc,
.datatable-custom .sorting_desc {
    position: relative;
    cursor: pointer;
    overflow: hidden
}

.datatable-custom .sorting::after,
.datatable-custom .sorting_asc::after,
.datatable-custom .sorting_desc::after {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    width: .7rem;
    height: .7rem;
    background-image: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: .7rem .7rem;
    content: "";
    margin-left: .5rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.datatable-custom .sorting_asc::after {
    background-image: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23377dff'/%3E%3C/svg%3E%0A");
    content: ""
}

.datatable-custom .sorting_desc::after {
    background-image: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23377dff'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E%0A");
    content: ""
}

.datatable-custom-pagination {
    margin-bottom: 0
}

.datatable-custom-content-box.dataTable,
.datatable-custom-content-box.dataTable td,
.datatable-custom-content-box.dataTable th {
    box-sizing: content-box
}

.datatable-custom-centered .dataTable {
    margin: 0 auto
}

.datatable-custom-collapsible td.details-control {
    position: relative
}

.datatable-custom-collapsible td.details-control::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3E%3C/svg%3E") no-repeat right center/1rem 1rem;
    content: "";
    cursor: pointer;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.datatable-custom-collapsible tr.shown td.details-control::before {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3E%3C/svg%3E") no-repeat right center/1rem 1rem
}

.daterangepicker-custom-input {
    cursor: pointer
}

.daterangepicker-wrapper {
    border: none;
    padding: 0;
    cursor: pointer
}

.daterangepicker {
    border: none;
    font-family: Inter, sans-serif;
    z-index: 98;
    background-color: #fff;
    box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175);
    margin-top: 0;
    border-radius: .75rem
}

.daterangepicker.opensright::after,
.daterangepicker.opensright::before {
    left: 1rem
}

.daterangepicker::after,
.daterangepicker::before {
    top: 0;
    border-bottom-color: #fff;
    border-bottom-width: 0
}

.daterangepicker.drop-up::before {
    border-top-color: #fff
}

.daterangepicker .drp-calendar {
    max-width: 18.75rem
}

.daterangepicker .drp-calendar.left {
    padding: 1rem 1rem
}

.daterangepicker .drp-calendar.right {
    padding: 1rem 1rem
}

.daterangepicker th.next,
.daterangepicker th.prev {
    min-width: auto;
    width: 2.40625rem;
    height: 2.40625rem;
    color: #377dff;
    font-size: .875rem;
    border-radius: 50%
}

.daterangepicker th.next:hover,
.daterangepicker th.prev:hover {
    background-color: rgba(55, 125, 255, .1)
}

.daterangepicker th.next:hover .daterangepicker-custom-arrow,
.daterangepicker th.prev:hover .daterangepicker-custom-arrow {
    color: #377dff
}

.daterangepicker .calendar-table {
    background-color: #fff;
    border-width: 0
}

.daterangepicker .calendar-table table {
    border-collapse: separate;
    border-spacing: 0 .25rem
}

.daterangepicker .calendar-table th:not(.month) {
    color: #97a4af;
    font-weight: 600;
    text-transform: uppercase
}

.daterangepicker .calendar-table th.month {
    font-size: .875rem;
    font-weight: 600
}

.daterangepicker .calendar-table td {
    min-width: 2.40625rem;
    width: 2.40625rem;
    height: 2.40625rem;
    font-size: .875rem;
    line-height: 2.15625rem
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
    border-color: #677788
}

.daterangepicker .calendar-table .next:hover span,
.daterangepicker .calendar-table .prev:hover span {
    border-color: #377dff
}

.daterangepicker td.available:not(.in-range) {
    border-radius: 50%
}

.daterangepicker td.available:hover:not(.active) {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.daterangepicker td.in-range {
    color: #677788;
    background-color: rgba(231, 234, 243, .5)
}

.daterangepicker td.active.start-date.end-date,
.daterangepicker td.today.start-date.end-date {
    border-radius: 50%
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    color: #fff;
    background-color: #377dff
}

.daterangepicker td.active.start-date {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem
}

.daterangepicker td.active.end-date {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
    color: #bdc5d1;
    background-color: transparent
}

.daterangepicker .drp-buttons,
.daterangepicker.show-ranges.ltr .drp-calendar.left {
    border-color: rgba(231, 234, 243, .7)
}

.daterangepicker .drp-buttons {
    padding: 1rem 1rem
}

.daterangepicker .drp-buttons .btn {
    font-size: .875rem;
    font-weight: 400;
    padding: .6125rem 1rem
}

.daterangepicker .cancelBtn {
    background-color: #fff;
    border-color: rgba(231, 234, 243, .7)
}

.daterangepicker .cancelBtn.active,
.daterangepicker .cancelBtn:active,
.daterangepicker .cancelBtn:focus,
.daterangepicker .cancelBtn:hover {
    color: #1366ff;
    box-shadow: 0 3px 6px -2px rgba(140, 152, 164, .25)
}

.daterangepicker .drp-selected {
    color: #71869d
}

.daterangepicker .ranges ul {
    min-width: 10rem;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.daterangepicker .ranges li {
    color: #677788;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .3125rem;
    padding: .5rem 1rem;
    margin-left: .625rem;
    margin-right: .625rem
}

.daterangepicker .ranges li:hover {
    color: #1366ff;
    background-color: transparent
}

.daterangepicker .ranges li.active {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
    cursor: pointer;
    width: 3.5rem;
    font-size: .8125rem;
    color: #1e2022;
    background-color: transparent;
    border-color: rgba(231, 234, 243, .7);
    padding: .25rem .25rem;
    border-radius: .5rem
}

.daterangepicker select.ampmselect:hover,
.daterangepicker select.hourselect:hover,
.daterangepicker select.minuteselect:hover,
.daterangepicker select.secondselect:hover {
    color: #1366ff
}

@media (max-width:776.98px) {
    .daterangepicker {
        width: auto;
        max-width: 19rem
    }

    .daterangepicker .drp-calendar {
        max-width: 100%
    }

    .daterangepicker .drp-calendar,
    .daterangepicker .ranges {
        float: none
    }

    .daterangepicker .ranges ul {
        width: 100%;
        margin: 0
    }

    .daterangepicker .drp-selected {
        display: block;
        margin-bottom: 1rem
    }
}

.dz-dropzone {
    cursor: pointer
}

.dz-dropzone .dz-message {
    width: 100%;
    text-align: center
}

.dz-dropzone .dz-details {
    margin-bottom: 1rem
}

.dz-dropzone .dz-file-preview {
    background-color: #fff;
    border-radius: .5rem;
    padding: 1rem 1rem;
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075)
}

.dz-dropzone .dz-file-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.dz-dropzone .dz-filename {
    margin-bottom: .25rem
}

.dz-dropzone .dz-img {
    max-width: 2.625rem;
    border-radius: .5rem;
    margin-right: .75rem
}

.dz-dropzone .dz-img-inner {
    border-radius: .5rem
}

.dz-dropzone .dz-close-icon,
.dz-dropzone .dz-size {
    color: #677788
}

.dz-dropzone .dz-title {
    font-size: .65625rem;
    font-weight: 600
}

.dz-dropzone .dz-size {
    font-size: 80%
}

.dz-dropzone .dz-file-initials {
    display: inline-block;
    width: 2.625rem;
    height: 2.625rem;
    line-height: 2.625rem;
    font-weight: 600;
    font-size: .875rem;
    color: #377dff;
    text-align: center;
    background-color: rgba(55, 125, 255, .1);
    border-radius: .5rem;
    margin-right: .75rem
}

.dz-dropzone [data-dz-thumbnail]:not([src]) {
    display: none;
    margin-bottom: 0
}

.dz-dropzone .dz-progress {
    margin-bottom: 1rem
}

.dz-dropzone .dz-processing .dz-error-mark,
.dz-dropzone .dz-processing .dz-success-mark {
    display: none
}

.dz-dropzone .dz-processing.dz-error .dz-error-mark,
.dz-dropzone .dz-processing.dz-success .dz-success-mark {
    display: block
}

.dz-dropzone .dz-processing .dz-error-mark {
    color: #ed4c78
}

.dz-dropzone .dz-processing .dz-success-mark {
    color: #00c9a7
}

.dz-dropzone-card {
    width: 100%;
    background-color: #f8fafd;
    border: .125rem dashed rgba(231, 234, 243, .7);
    border-radius: .5rem;
    padding: 3rem 3rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.fancybox-custom .fancybox-slide.animated {
    display: block;
    opacity: 0;
    z-index: 0
}

.fancybox-custom .fancybox-slide.animated.fancybox-slide--current {
    opacity: 1;
    z-index: 1
}

.fancybox-custom .fancybox-content {
    background-color: transparent
}

.fancybox-custom .fancybox-bg {
    background-color: #1e2022
}

.fancybox-custom .fancybox-button svg {
    margin-bottom: 0
}

.fancybox-custom .fancybox-progress {
    background-color: #377dff
}

.fancybox-blur aside,
.fancybox-blur footer,
.fancybox-blur header,
.fancybox-blur main {
    -webkit-filter: blur(30px);
    filter: blur(30px)
}

.flatpickr-custom {
    position: relative;
    width: 100% !important
}

.flatpickr-custom .flatpickr-calendar {
    top: calc(1.5em + 1.35rem) !important;
    left: 0 !important;
    width: auto
}

.flatpickr-custom-form-control[readonly],
.form-control[readonly] {
    background-color: transparent
}

.flatpickr-calendar {
    width: 21.125rem;
    padding: 1rem 1rem;
    box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175);
    background-color: #fff;
    border-radius: .75rem;
    margin-top: .5rem
}

.flatpickr-calendar::before {
    left: 1rem;
    border-width: .5rem;
    margin: 0 .5rem
}

.flatpickr-calendar.arrowTop::before {
    border-bottom-color: #fff
}

.flatpickr-calendar::after {
    display: none
}

.flatpickr-calendar.animate.open {
    -webkit-animation: fadeInUp .3s cubic-bezier(.23, 1, .32, 1);
    animation: fadeInUp .3s cubic-bezier(.23, 1, .32, 1)
}

.flatpickr-calendar .flatpickr-months {
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .75rem
}

.flatpickr-calendar .flatpickr-current-month,
.flatpickr-calendar .flatpickr-next-month,
.flatpickr-calendar .flatpickr-prev-month {
    padding: 0
}

.flatpickr-calendar .flatpickr-current-month {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: auto
}

.flatpickr-calendar .flatpickr-next-month,
.flatpickr-calendar .flatpickr-prev-month {
    position: static;
    color: #677788;
    width: 2.40625rem;
    height: 2.40625rem;
    line-height: 2.40625rem;
    background-color: transparent;
    border-radius: 50%
}

.flatpickr-calendar .flatpickr-next-month:hover,
.flatpickr-calendar .flatpickr-prev-month:hover {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.flatpickr-calendar .flatpickr-weekday {
    color: #97a4af;
    text-transform: uppercase;
    font-weight: 600
}

.flatpickr-calendar .rangeMode .flatpickr-day {
    margin-top: .25rem
}

.flatpickr-calendar .flatpickr-monthDropdown-months {
    color: #677788;
    font-size: .875rem;
    font-weight: 600
}

.flatpickr-calendar .flatpickr-monthDropdown-months:hover {
    background-color: transparent
}

.flatpickr-calendar .flatpickr-current-month input.cur-year {
    color: #677788;
    font-size: .875rem;
    font-weight: 600
}

.flatpickr-calendar .flatpickr-day {
    color: #1e2022;
    border: none
}

.flatpickr-calendar .flatpickr-day:focus,
.flatpickr-calendar .flatpickr-day:hover {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1)
}

.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.endRange.inRange,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.endRange:focus,
.flatpickr-calendar .flatpickr-day.endRange:hover,
.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.selected.inRange,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected:focus,
.flatpickr-calendar .flatpickr-day.selected:hover,
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.startRange.inRange,
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.startRange:focus,
.flatpickr-calendar .flatpickr-day.startRange:hover {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff
}

.flatpickr-calendar .flatpickr-day.inRange {
    color: #fff;
    background-color: #377dff;
    box-shadow: -.35rem 0 0 #377dff, .35rem 0 0 #377dff
}

.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay:hover {
    color: #fff;
    border-color: #377dff;
    background-color: #377dff
}

.flatpickr-calendar .flatpickr-day.today {
    color: #fff;
    border-color: transparent;
    background-color: #377dff
}

.flatpickr-calendar .flatpickr-day.nextMonthDay,
.flatpickr-calendar .flatpickr-day.prevMonthDay {
    color: #e7eaf3
}

.flatpickr-calendar .flatpickr-day.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.prevMonthDay:hover {
    color: #bdc5d1;
    background-color: rgba(231, 234, 243, .7);
    border-color: rgba(231, 234, 243, .7)
}

.flatpickr-calendar .flatpickr-day.disabled {
    color: #bdc5d1
}

.flatpickr-calendar .flatpickr-day.disabled:hover {
    color: #bdc5d1;
    background-color: rgba(231, 234, 243, .7);
    border-color: rgba(231, 234, 243, .7)
}

.flatpickr-calendar .flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-calendar .flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-calendar .flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)) {
    box-shadow: -.35rem 0 0 #377dff, .35rem 0 0 #377dff
}

.flatpickr-calendar .numInputWrapper span.arrowDown,
.flatpickr-calendar .numInputWrapper span.arrowUp {
    display: none
}

.flatpickr-calendar .numInputWrapper:hover {
    background-color: transparent
}

.flatpickr-calendar .flatpickr-time input {
    color: #1e2022
}

.flatpickr-calendar .flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-calendar .flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-calendar .flatpickr-time input:focus,
.flatpickr-calendar .flatpickr-time input:hover {
    background-color: rgba(231, 234, 243, .5)
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback).flatpickr-calendar {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem
}

.flatpickr-custom-borderless {
    width: 0 !important;
    min-width: 7rem !important
}

.flatpickr-custom-borderless .input-group-text {
    border: 0;
    padding-right: 0 !important
}

.flatpickr-custom-borderless .flatpickr-custom-form-control {
    border: none;
    cursor: pointer;
    box-shadow: none;
    padding-right: 0;
    color: #377dff
}

.flatpickr-custom-position-fix-sm-down .flatpickr-calendar {
    left: -100% !important
}

.fullcalendar-custom .fc-list-empty {
    background-color: #f8fafd
}

.fullcalendar-custom .fc-daygrid-dot-event.fc-event-mirror,
.fullcalendar-custom .fc-daygrid-dot-event:hover {
    color: #377dff
}

.fullcalendar-custom .fc-daygrid-dot-event {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #1e2022;
    background-color: rgba(55, 125, 255, .1);
    border-radius: .5rem
}

.fullcalendar-custom .fc-v-event .fc-event-main {
    color: #1e2022;
    font-weight: 600
}

.fullcalendar-custom .fc-h-event,
.fullcalendar-custom .fc-v-event {
    cursor: pointer;
    border: none
}

.fullcalendar-custom .fc-h-event {
    background-color: rgba(55, 125, 255, .1)
}

.fullcalendar-custom .fc-listWeek-view .fc-list-event {
    background-color: #fff
}

.fullcalendar-custom .fullcalendar-custom-event-hs-team {
    background-color: #eaf1ff
}

.fullcalendar-custom .fullcalendar-custom-event-hs-team .fc-list-event-dot {
    border-color: #b7d0ff
}

.fullcalendar-custom .fullcalendar-custom-event-reminders {
    background-color: #fdeef2
}

.fullcalendar-custom .fullcalendar-custom-event-reminders .fc-list-event-dot {
    border-color: #f9c0cf
}

.fullcalendar-custom .fullcalendar-custom-event-tasks {
    background-color: #fdf3e8
}

.fullcalendar-custom .fullcalendar-custom-event-tasks .fc-list-event-dot {
    border-color: #f8dbba
}

.fullcalendar-custom .fullcalendar-custom-event-holidays {
    background-color: #b1f1fb
}

.fullcalendar-custom .fullcalendar-custom-event-holidays .fc-list-event-dot {
    border-color: #80e8f9
}

.fullcalendar-custom .fc-daygrid-inline-block-event {
    display: inline-block
}

.fullcalendar-custom .fc-daygrid-dot-event .fc-event-title,
.fullcalendar-custom .fc-daygrid-event {
    font-size: .8125rem;
    font-weight: 600
}

.fullcalendar-custom .fc-event-resizable,
.fullcalendar-custom .fc-timegrid-event-harness .fc-timegrid-event {
    max-width: 15rem
}

.fullcalendar-custom .fc-daygrid-event,
.fullcalendar-custom .fc-timegrid-event .fc-event-main {
    padding: .3125rem .3125rem
}

.fullcalendar-custom .fc-daygrid-block-event .fc-event-time,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title {
    color: #1e2022;
    padding: 0
}

.fullcalendar-custom .fc-daygrid-block-event .fc-event-time:hover,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title:hover {
    color: #377dff
}

.fullcalendar-custom .fc-daygrid-block-event .fc-event-time.fc-sticky,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title.fc-sticky {
    padding-right: 0;
    padding-left: 0
}

.fullcalendar-custom .fc-daygrid-event .fc-event-title {
    padding: .125rem 0
}

.fullcalendar-custom .fc-event-time {
    font-size: .65625rem
}

.fullcalendar-custom .fc-event-title.fc-sticky {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: .8125rem
}

.fullcalendar-custom .fc-daygrid-event,
.fullcalendar-custom.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fullcalendar-custom.fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin: .125rem 0
}

.fullcalendar-custom.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
.fullcalendar-custom.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
    border-radius: .5rem
}

.fullcalendar-custom.fc table,
.fullcalendar-custom.fc td,
.fullcalendar-custom.fc th {
    border-color: rgba(231, 234, 243, .7)
}

.fullcalendar-custom.fc .fc-view:not(.fc-timeGridDay-view) .fc-daygrid-day {
    height: 10rem
}

.fullcalendar-custom.fc .fc-daygrid-day-frame {
    padding: .25rem
}

.fullcalendar-custom.fc .fc-col-header-cell-cushion,
.fullcalendar-custom.fc .fc-timegrid-slot-label-cushion {
    font-size: .765625rem;
    color: #97a4af;
    text-transform: uppercase
}

.fullcalendar-custom.fc .fc-col-header-cell-cushion {
    display: block;
    background-color: transparent;
    padding: .5rem .5rem
}

.fullcalendar-custom.fc .fc-day-today .fc-col-header-cell-cushion {
    color: #377dff;
    background-color: rgba(55, 125, 255, .1);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.fullcalendar-custom.fc .fc-daygrid-day-top {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: .125rem
}

.fullcalendar-custom.fc .fc-day-other .fc-daygrid-day-top {
    opacity: 1
}

.fullcalendar-custom.fc .fc-day-other .fc-daygrid-day-top .fc-daygrid-day-number {
    color: #e7eaf3
}

.fullcalendar-custom.fc .fc-daygrid-day-number {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 2.40625rem;
    height: 2.40625rem;
    color: #132144;
    border-radius: 50%
}

.fullcalendar-custom.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent
}

.fullcalendar-custom.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    color: #fff;
    background-color: #377dff
}

.fullcalendar-custom.fc .fc-highlight,
.fullcalendar-custom.fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(55, 125, 255, .1)
}

.fullcalendar-custom.fc .fc-cell-shaded,
.fullcalendar-custom.fc .fc-day-disabled {
    background-color: rgba(231, 234, 243, .5)
}

.fullcalendar-custom.fc .fc-button {
    font-size: .875rem;
    color: #677788;
    background-color: transparent;
    border-color: rgba(231, 234, 243, .7);
    text-transform: capitalize;
    padding: .5rem 1rem
}

.fullcalendar-custom.fc .fc-button .fc-icon {
    font-size: .875rem;
    vertical-align: baseline
}

.fullcalendar-custom.fc .fc-button.active,
.fullcalendar-custom.fc .fc-button.focus,
.fullcalendar-custom.fc .fc-button:active,
.fullcalendar-custom.fc .fc-button:focus,
.fullcalendar-custom.fc .fc-button:hover {
    color: #1366ff;
    box-shadow: 0 3px 6px -2px rgba(140, 152, 164, .25)
}

.fullcalendar-custom.fc .fc-button.fc-button-active {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff
}

.fullcalendar-custom.fc .fc-button.fc-button-primary:focus,
.fullcalendar-custom.fc .fc-button.fc-button-primary:not(:disabled).fc-button-active:focus,
.fullcalendar-custom.fc .fc-button.fc-button-primary:not(:disabled):active:focus {
    box-shadow: none
}

.fullcalendar-custom.fc .fc-button.fc-button-primary:not(:disabled).fc-button-active,
.fullcalendar-custom.fc .fc-button.fc-button-primary:not(:disabled):active {
    color: #fff;
    background-color: #377dff;
    border-color: #377dff
}

.fullcalendar-custom.fc .fc-toolbar-title {
    font-size: 1.1484375rem
}

.fullcalendar-custom-timegrid.fc-theme-standard .fc-scrollgrid td {
    border-bottom: none
}

.fullcalendar-custom-timegrid.fc-theme-standard .fc-scrollgrid td .fc-timegrid-slot-minor {
    border-top: none
}

.fullcalendar-custom-timegrid .fc-col-header-cell-cushion {
    cursor: pointer
}

.fullcalendar-custom-timegrid .fc-col-header-cell-cushion .day-view {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.fullcalendar-custom-timegrid .fc-col-header-cell-cushion:hover {
    color: #1366ff
}

.fullcalendar-custom-timegrid .fc-timegrid-event {
    min-height: 5rem;
    padding: .3125rem
}

.fullcalendar-custom-timegrid .fc-timegrid-now-indicator-line {
    border-color: #ed4c78
}

.fullcalendar-custom-timegrid .fc-timegrid-now-indicator-arrow {
    left: auto;
    right: 0;
    border: none
}

.fullcalendar-custom-timegrid .fc-timegrid-now-indicator-arrow::before {
    position: absolute;
    top: 1px;
    right: 0;
    width: .5rem;
    height: .5rem;
    content: "";
    background-color: #ed4c78;
    border-radius: 50%
}

.fc-theme-standard .fc-list {
    border-color: rgba(231, 234, 243, .7)
}

.fc-theme-standard .fc-list .fc-list-event:hover td {
    background-color: transparent
}

.fc-theme-standard .fc-list .fc-list-day-text {
    color: #1e2022
}

.fc-theme-standard .fc-list .fc-list-day-side-text {
    color: #677788;
    font-weight: 400
}

.fullcalendar-custom.fc.fc-theme-standard .fc-popover {
    background-color: #fff;
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075)
}

.fullcalendar-custom.fc .fc-popover {
    border-color: rgba(231, 234, 243, .7);
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075);
    border-radius: .75rem
}

.fullcalendar-custom.fc .fc-popover-header {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
    background-color: #f8fafd;
    padding: .5rem .75rem
}

.fullcalendar-custom.fc .fc-more-popover .fc-popover-body {
    padding: .5rem .75rem
}

.fullcalendar-custom.fc .fc-more-popover .fc-popover-body .fc-sticky {
    position: static
}

.fullcalendar-custom.fc .fc-popover-title {
    margin: 0
}

.fullcalendar-custom.fc .fc-popover-close:hover {
    color: #1366ff
}

.fullcalendar-event-popover {
    width: 25rem;
    max-width: 25rem
}

.quantity-counter {
    min-width: 9rem;
    padding: .5rem 1rem;
    border: .0625rem solid rgba(231, 234, 243, .7);
    border-radius: .3125rem
}

.quantity-counter-btn.btn>svg {
    width: 1rem
}

.form-control-quantity-counter {
    border-width: 0;
    height: auto;
    padding: 0
}

.form-control-quantity-counter:focus {
    box-shadow: none
}

.quill-custom {
    width: 100%
}

.quill-custom .ql-container {
    position: static
}

.quill-custom .ql-container.ql-snow,
.quill-custom .ql-toolbar.ql-snow {
    border-color: rgba(231, 234, 243, .7)
}

.quill-custom .ql-toolbar.ql-snow {
    padding: .75rem 1rem;
    border-top-left-radius: .3125rem;
    border-top-right-radius: .3125rem
}

.quill-custom .ql-container.ql-snow {
    border-bottom-right-radius: .3125rem;
    border-bottom-left-radius: .3125rem
}

.quill-custom .ql-formats:first-child {
    padding-left: 0
}

.quill-custom .ql-formats:first-child button {
    margin-right: .25rem
}

.quill-custom .ql-formats:first-child button:first-child {
    margin-left: -.25rem
}

.quill-custom .ql-editor {
    position: relative;
    padding: .6125rem 1rem
}

.quill-custom .ql-editor p {
    font-size: .875rem;
    font-family: Inter, sans-serif;
    color: #1e2022
}

.quill-custom .ql-editor.ql-blank::before {
    left: 1rem;
    color: #8c98a4;
    font-family: Inter, sans-serif;
    font-style: normal
}

.quill-custom .ql-snow.ql-toolbar .ql-fill {
    fill: #677788
}

.quill-custom .ql-snow.ql-toolbar .ql-stroke {
    stroke: #677788
}

.quill-custom .ql-snow.ql-toolbar button:hover {
    color: #377dff
}

.quill-custom .ql-snow.ql-toolbar button:hover .ql-fill {
    fill: #377dff
}

.quill-custom .ql-snow.ql-toolbar button:hover .ql-stroke {
    stroke: #377dff
}

.quill-custom .ql-snow .ql-toolbar button,
.quill-custom .ql-snow.ql-toolbar button {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 2.1875rem;
    height: 2.1875rem;
    padding: .25rem .25rem;
    border-radius: .3125rem
}

.quill-custom .ql-snow .ql-toolbar button.ql-active,
.quill-custom .ql-snow .ql-toolbar button:hover,
.quill-custom .ql-snow.ql-toolbar button.ql-active,
.quill-custom .ql-snow.ql-toolbar button:hover {
    background-color: #f8fafd
}

.quill-custom .ql-snow .ql-toolbar button svg,
.quill-custom .ql-snow.ql-toolbar button svg {
    width: 1rem;
    margin-bottom: 0
}

.quill-custom .ql-snow .ql-tooltip {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    z-index: 1;
    min-width: 20rem;
    border-width: 0;
    text-align: center;
    box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175);
    padding: 1.5rem 1.5rem;
    border-radius: .3125rem;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.quill-custom .ql-snow .ql-tooltip::before {
    display: block;
    text-align: center;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: .875rem;
    border-bottom: .0625rem solid rgba(231, 234, 243, .7);
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    margin-right: 0
}

.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-remove {
    display: none
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text] {
    min-width: 20rem;
    font-size: .875rem;
    line-height: normal;
    border: .0625rem solid rgba(231, 234, 243, .7);
    padding: .6125rem 1rem;
    margin-bottom: 1.5rem;
    border-radius: .3125rem;
    transition: .3s
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview {
    display: block
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview:hover {
    border-color: #377dff
}

.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text] {
    height: auto;
    display: block;
    font-family: Inter, sans-serif
}

.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text]:focus {
    border-color: rgba(140, 152, 164, .25);
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125)
}

.quill-custom .ql-snow .ql-action {
    display: inline-block;
    color: #fff;
    background-color: #377dff;
    font-size: .875rem;
    line-height: normal;
    padding: .6125rem 1rem;
    border-radius: .3125rem;
    transition: .3s
}

.quill-custom .ql-snow .ql-action:hover {
    background-color: #1366ff
}

.quill-custom .ql-snow .ql-tooltip a.ql-action::after,
.quill-custom .ql-snow .ql-tooltip a.ql-remove::before {
    padding-right: 0;
    margin-left: 0;
    border-right: none
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove {
    border: .0625rem solid rgba(231, 234, 243, .7);
    font-size: .875rem;
    padding: .6125rem 1rem;
    border-radius: .3125rem;
    margin-left: .5rem;
    transition: .3s
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove:hover {
    color: #1366ff;
    box-shadow: 0 3px 6px -2px rgba(140, 152, 164, .25)
}

.quill-custom .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.quill-custom .ql-snow .ql-toolbar .ql-picker-item:hover,
.quill-custom .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.quill-custom .ql-snow .ql-toolbar .ql-picker-label:hover,
.quill-custom .ql-snow .ql-toolbar button.ql-active,
.quill-custom .ql-snow .ql-toolbar button:focus,
.quill-custom .ql-snow .ql-toolbar button:hover,
.quill-custom .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.quill-custom .ql-snow.ql-toolbar .ql-picker-item:hover,
.quill-custom .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.quill-custom .ql-snow.ql-toolbar .ql-picker-label:hover,
.quill-custom .ql-snow.ql-toolbar button.ql-active,
.quill-custom .ql-snow.ql-toolbar button:focus,
.quill-custom .ql-snow.ql-toolbar button:hover {
    color: #377dff
}

.sortablejs-custom.sortable-ghost {
    opacity: .3
}

.sortablejs-custom-rotate.sortable-fallback {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1 !important
}

.sortablejs-custom-handle {
    cursor: pointer
}

.tom-select-custom .input-group-sm>.ts-wrapper .ts-control.has-items,
.tom-select-custom .ts-wrapper.form-control-sm .ts-control.has-items,
.tom-select-custom .ts-wrapper.form-select-sm .ts-control.has-items {
    padding: .5rem 2.25rem .5rem 1rem
}

.tom-select-custom .input-group-lg>.ts-wrapper>.ts-control,
.tom-select-custom .ts-wrapper.form-control-lg .ts-control,
.tom-select-custom .ts-wrapper.form-select-lg .ts-control {
    padding: .75rem 2.25rem .75rem 1rem
}

.tom-select-custom .hs-select-single-multiple .item {
    display: none !important
}

.tom-select-custom .ts-wrapper.form-select .ts-control,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple {
    padding: .6125rem 2.25rem .6125rem 1rem
}

.tom-select-custom .ts-wrapper.form-select .ts-control .tom-select-custom-hide,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple .tom-select-custom-hide {
    display: none
}

.tom-select-custom .ts-wrapper.form-select .ts-control .focus,
.tom-select-custom .ts-wrapper.form-select .ts-control:focus,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple .focus,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple:focus {
    box-shadow: none
}

.tom-select-custom .ts-control,
.tom-select-custom .ts-control input,
.tom-select-custom .ts-dropdown {
    color: #677788
}

.tom-select-custom .ts-dropdown,
.tom-select-custom .ts-dropdown.form-control,
.tom-select-custom .ts-dropdown.form-select {
    background-color: #fff;
    border: .0625rem solid rgba(231, 234, 243, .7);
    border-radius: .3125rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075)
}

.tom-select-custom .ts-dropdown .active,
.tom-select-custom .ts-dropdown .create:hover,
.tom-select-custom .ts-dropdown .option:hover {
    color: #1e2022;
    background-color: rgba(189, 197, 209, .3)
}

.tom-select-custom .ts-dropdown .create,
.tom-select-custom .ts-dropdown .no-results,
.tom-select-custom .ts-dropdown .optgroup-header,
.tom-select-custom .ts-dropdown .option {
    padding: .5rem 2.5rem .5rem 1rem
}

.tom-select-custom .ts-dropdown .option {
    position: relative
}

.tom-select-custom .ts-dropdown .option::after {
    display: none;
    position: absolute;
    top: 50%;
    right: .5rem;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 110' fill='%23377dff'%3e%3cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1rem 1rem;
    content: "";
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.tom-select-custom .ts-dropdown .option.selected::after {
    display: block
}

.tom-select-custom .ts-control,
.tom-select-custom .ts-wrapper.single .ts-control.input-active {
    cursor: pointer
}

.tom-select-custom .ts-control.focus {
    box-shadow: none
}

.tom-select-custom .input-group>.form-select,
.tom-select-custom .input-group>.ts-wrapper {
    -ms-flex-positive: unset;
    flex-grow: unset
}

.tom-select-custom .input-group>.form-select {
    width: auto
}

.tom-select-custom .ts-wrapper {
    display: inline-block
}

.tom-select-custom .ts-dropdown .optgroup-header {
    font-weight: 700;
    color: #000
}

.tom-select-custom .ts-dropdown .optgroup:before {
    display: none
}

.tom-select-custom .ts-dropdown .optgroup .option {
    padding-left: 1.5rem
}

.tom-select-custom .ts-wrapper.multi .ts-control>div {
    color: #1e2022;
    background-color: #e7eaf3;
    font-size: .875rem;
    padding: .28rem .875rem;
    border-radius: 4px
}

.tom-select-custom .ts-wrapper.multi .ts-control>.ts-custom-placeholder {
    background-color: transparent
}

.tom-select-custom .ts-wrapper.plugin-remove_button .item .remove {
    border-left: none;
    color: #8c98a4
}

.tom-select-custom .ts-wrapper.plugin-remove_button .item .remove:hover {
    background: 0 0;
    color: #377dff
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap {
    padding: .4rem
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input {
    color: #1e2022;
    border: .0625rem solid rgba(231, 234, 243, .7);
    border-radius: .3125rem
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input:focus {
    color: #1e2022
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input::-webkit-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input::-moz-placeholder {
    color: #8c98a4;
    opacity: 1
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input:-ms-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input::-ms-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input::placeholder {
    color: #8c98a4;
    opacity: 1
}

.tom-select-custom .ts-custom-placeholder,
.tom-select-custom .ts-selected-count {
    color: #8c98a4 !important
}

.tom-select-custom-end .ts-dropdown {
    left: auto;
    right: 0;
    text-align: left
}

@media (min-width:576px) {
    .tom-select-custom-sm-end .ts-dropdown {
        left: auto;
        right: 0;
        text-align: left
    }
}

@media (min-width:768px) {
    .tom-select-custom-md-end .ts-dropdown {
        left: auto;
        right: 0;
        text-align: left
    }
}

@media (min-width:992px) {
    .tom-select-custom-lg-end .ts-dropdown {
        left: auto;
        right: 0;
        text-align: left
    }
}

@media (min-width:1200px) {
    .tom-select-custom-xl-end .ts-dropdown {
        left: auto;
        right: 0;
        text-align: left
    }
}

@media (min-width:1400px) {
    .tom-select-custom-xxl-end .ts-dropdown {
        left: auto;
        right: 0;
        text-align: left
    }
}

.tom-select-custom-with-tags .tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple,
.tom-select-custom-with-tags .ts-wrapper.form-select .ts-control {
    padding: calc(.375rem - 1px - 0px) .75rem calc(.375rem - 1px - 4px - 0px)
}

.tom-select-custom-with-tags .ts-wrapper.multi .ts-control>.item {
    color: #1e2022;
    background-color: rgba(19, 33, 68, .1)
}

.input-group .tom-select-custom .form-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group .tom-select-custom .form-select:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.tom-select-custom .tom-select-custom-form-select-invite-user {
    width: auto;
    height: 100%
}

.tom-select-custom .ts-wrapper.tom-select-form-select-ps-0 .ts-control.has-items {
    padding-left: 0
}

.tom-select-custom-bg-transparent .form-select {
    background-color: transparent
}

.jvm-tooltip {
    color: #1e2022;
    background-color: #fff;
    font-family: Inter, sans-serif;
    font-size: .875rem;
    z-index: 1000;
    padding: .6125rem 1rem;
    margin: -1rem;
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
    -webkit-transform: translate(1rem 1rem);
    transform: translate(1rem 1rem)
}

.jvm-zoom-btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    color: #677788;
    background-color: #fff;
    border: .0625rem solid rgba(231, 234, 243, .7);
    width: 1.53125rem;
    height: 1.53125rem;
    border-radius: .3125rem;
    padding: 0
}

.jvm-zoom-btn:focus,
.jvm-zoom-btn:hover {
    color: #1366ff
}

.jvm-zoom-btn.jvm-zoomin {
    top: 1rem;
    left: 1rem
}

.jvm-zoom-btn.jvm-zoomout {
    top: 2.84375rem;
    left: 1rem
}

.jsvectormap-custom-wrapper {
    height: 20rem
}

@media (min-width:768px) {
    .jsvectormap-custom-wrapper {
        height: 25rem
    }
}

@media (min-width:992px) {
    .jsvectormap-custom-wrapper {
        height: 30rem
    }
}

.leaflet {
    min-height: 30rem;
    height: 100%;
    z-index: 0
}

.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-layers {
    border: none
}

.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075)
}

.leaflet-bar,
.leaflet-popup-content-wrapper {
    border-radius: .5rem
}

.leaflet-bar a,
.leaflet-bar a:hover {
    color: #677788;
    background-color: #fff;
    border-bottom: none
}

.leaflet-bar a:hover {
    color: #1366ff
}

.leaflet-touch .leaflet-bar a {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    font: 600 .875rem Inter, sans-serif;
    text-indent: 0
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
    font-family: Inter, sans-serif;
    font-size: .875rem
}

.leaflet-control-zoom-in {
    margin-bottom: .25rem
}

.leaflet-touch .leaflet-bar a:first-child,
.leaflet-touch .leaflet-bar a:last-child {
    border-radius: .5rem
}

.leaflet-popup {
    margin-bottom: 2.5rem
}

.pointer{
    cursor: pointer;
}



@font-face {
    font-family: "around-icons";
    src: url("../fonts/around-icons.ttf") format("truetype"), url("../fonts/around-icons.woff") format("woff"), url("../fonts/around-icons.svg#around-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block
}

[class^=ai-],
[class*=" ai-"],
[class^=bi-],
[class*=" bi-"] {
    display: inline-block;
    font-family: "around-icons" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: middle;
    line-height: 1
}


.ai-discord:before {
    content: ""
}

.ai-discount:before {
    content: ""
}